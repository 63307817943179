import {ACCEPT_INVITATION_UNPROTECTED} from "../../../authWorkspacesCookies/workspaces/constants";
import {
    LICENSE_PATH,
    PATH_LOCAL_2FA,
    PATH_LOCAL_INITIAL_MFA_CONFIGURE,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_NEW_WORKSPACES,
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_SIGNUP,
    PATH_LOCAL_VERIFY_EMAIL
} from "../../../../newShared/constants";

export const unprotectedTopBarPaths: (string | { (pathname: string): boolean })[]  = [
    // PATH_LOCAL_SHARE_REPORTS,
    ACCEPT_INVITATION_UNPROTECTED
];

export const unprotectedPaths = [
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_SIGNUP,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_NEW_WORKSPACES,
    LICENSE_PATH,
    PATH_LOCAL_2FA,
    // (pathname: string) => pathname.indexOf(PATH_LOCAL_PRE_MANAGE_WORKSPACES) > - 1, PATH_LOCAL_SHARE_REPORTS,
    ...unprotectedTopBarPaths,

    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_VERIFY_EMAIL,
    PATH_LOCAL_INITIAL_MFA_CONFIGURE,
];

export const hideProtectedTopBarPaths: (string | { (pathname: string): boolean })[] = [
    ...unprotectedTopBarPaths,
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_SIGNUP,
    PATH_LOCAL_LOGIN,
    LICENSE_PATH,
    PATH_LOCAL_2FA,
    ACCEPT_INVITATION_UNPROTECTED,
    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_VERIFY_EMAIL,
    PATH_LOCAL_INITIAL_MFA_CONFIGURE,

    PATH_LOCAL_NEW_WORKSPACES,

    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_VERIFY_EMAIL,
    PATH_LOCAL_INITIAL_MFA_CONFIGURE,
];

export const showProtectedTopBar = (pathname: string) => !hideProtectedTopBarPaths.some(el => typeof el === 'string' ? el === pathname : el(pathname));
export const showUnProtectedTopBar = (pathname: string) => unprotectedTopBarPaths.some(el => typeof el === 'string' ? el === pathname : el(pathname));
export const hideTopBar = (pathname: string) => !(showProtectedTopBar(pathname) || showUnProtectedTopBar(pathname));
export const isUnProtectedPaths = (pathname: string) => unprotectedPaths.some(el => typeof el === 'string' ? el === pathname : el(pathname));
