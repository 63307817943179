import styled from "styled-components";
import {device} from "../../../../../newShared/constants";
import {TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import colors from "../../../../../newShared/theme/colors";

export const ListPageTopButtonContainer = styled.div`
    display: flex;
    margin: 8px 0 0 0;
    align-items: center;
    ${device.generateMax(1049)} {
        width: 100%;
    }
    ${device.generateMax(501)} {
        justify-content: space-between;
        margin: 11px 0 0 0;
    }
`;

export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    width: 25%;
    margin: 30px 0 0 0;
    & div{
        margin: 0 10px 0 0;
    }
    ${device.generateMax(858)}{
        width: 50%;
    }
    ${device.tablet} {
        width: 50%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const TextFieldCustom = styled(TextField)`
  & .MuiInputBase-input {
    height: 16px;
  }
  & label{
    top: 0 !important;
  }
`;

export const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 24px;
  }
  & label{
    top: 0 !important;
  }
`;

export const TableHeaderWrapper = styled.div<{ width?: string}>`
    width: ${props => props.width ?? '90%'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 24px 0;
    ${device.tablet} {
        width: 100%;
        margin: 25px 0 24px 0;
    }
`;


export const TableHeaderItemWrapper = styled.div<{ width?: string, ai?: string}>`
    width: ${props => props.width ?? '24%'};
    // border: 1px solid red;
    display: flex; 
    align-items: ${props => props.ai ?? 'center'};
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const MainTableRowWrapper = styled.div<{disableHover?: boolean, width?: string, minWidth?: string}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    width: ${props => props.width ?? '100%'};
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.backgrounds.blue_light_1};
            cursor: pointer;
        }
    `}
`;

export const TableRowWrapper = styled.div<{disableHover?: boolean, jc?: string, width?: string}>`
    width: ${props => props.width ?? '90%'};
    display: flex;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.backgrounds.blue_light_1};
            cursor: pointer;
        }
    `}
   
    ${device.tablet} {
        width: 100%;
    }
`;

export const TableRowItemWrapper = styled.div<{width?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 12px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
    // border: 1px solid red;
`;

export const MainTableScrollWrapper = styled.div`
    overflow: auto;
    ${device.generateMax(858)}{
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0 0;
    }
`;

export const InviteCardContainer = styled.div<{revDir: boolean}>`
    width: calc(50% - 16px);
    min-width: 330px;
    // border: 1px solid black;
    ${device.desktopNarrow}{
        width: 100%;
    }
    padding: 10px 20px;
    margin: ${props => !props.revDir ? '0 16px 16px 0' : '0 16px 16px 0'};
    
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 2px 6px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
`;

export const ProfileSettingWrapper = styled.div<{margin?: string}>`
    // border: 1px solid red;
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: ${props => props.margin ?? '16px 0 0 0'};
    ${device.generateMax(1200)}{
        width: 40%;
    }
    ${device.tablet} {
        width: 50%;
    }
    ${device.generateMax(635)}{
        width: 60%;
    }
    ${device.generateMax(545)}{
        width: 70%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const QrCodeWrapper = styled.div`
    width: 30%;
    margin: 0 auto;
    display; flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 0 0;
    ${device.tablet}{
        width: 35%;
    }
                   
`;

export const SecretRevealWrapper = styled.div<{padding?: string}>`
    width: 100%;
    display; flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: ${props => props.padding ?? '41px 0 0 0'};
    & p{
        display: inline-block;
        white-space: pre-wrap;
    }
    flex-wrap: nowrap;
    ${device.tablet}{
        width: 100%;
    }
`;