import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {ReportOrganizations} from "./pages/reportOrganizations";
import {ReportView} from "./pages/reportView";
import {PATH_LOCAL_SPACE} from "../../../newShared/constants";
import {ReportIntegrationsDetails} from "./pages/reportIntegrationsDetails";
import {ReportIntegrationExactDetails} from "./pages/reportIntegrationExactDetails";
import {OrganizationReportsRefactor} from "./pages/OrganizationReportsRefactor";

export const ReportRoutes: FC = () => {

    return (
        <Switch>
            <Route path={`${PATH_LOCAL_SPACE}`} exact component={ReportOrganizations}/>
            <Route path={`${PATH_LOCAL_SPACE}/:organizationId`} exact component={OrganizationReportsRefactor}/>

            <Route path={`${PATH_LOCAL_SPACE}/:organizationId/:reportId/integrations`} exact component={ReportIntegrationsDetails}/>
            <Route path={`${PATH_LOCAL_SPACE}/:organizationId/:reportId/integrations/:appId`} exact component={ReportIntegrationExactDetails}/>

            <Route path={[
                `${PATH_LOCAL_SPACE}/:organizationId/:reportId`,
                `${PATH_LOCAL_SPACE}/:organizationId/:reportId/:controlId`,
            ]} exact component={ReportView}/>

            <Redirect to={`${PATH_LOCAL_SPACE}`}/>
        </Switch>
    )
}