import {FC, PropsWithChildren, ReactNode, useState} from "react";
import {Box, Button, Collapse} from "@mui/material";
import {ArrowDropDown, ArrowDropUp, InfoOutlined} from "@mui/icons-material";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";

type TProps = PropsWithChildren<{  }> & {
    margin?: string,
    text?: string,
    startIcon?: ReactNode,
    initiallyOpened?: boolean
}

export const MinimizeInfoWrapper: FC<TProps> = ({children, margin, text, startIcon, initiallyOpened}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'CommonComponents'})
    const [isOpen, setIsOpen] = useState<boolean>(initiallyOpened ?? false);

    return (
        <Box width={'100%'} m={margin}>
            <Button size={"medium"} variant={"text"}
                    sx={{textTransform: 'none', pl: 0.5, pr: 0.5}}
                    onClick={() => {setIsOpen(prev => !prev)}}
                    startIcon={startIcon ?? <InfoOutlined/>}
                    endIcon={!isOpen ? (<ArrowDropDown/>) : (<ArrowDropUp/>)}>
                {text ? text : t('Information')}
            </Button>
            <Collapse in={isOpen}
                      sx={{minHeight: 'none'}}>
                {children}
            </Collapse>
        </Box>
    )
}