import {MouseEvent, useState} from "react";
import {
    MAIN_PATH_DEV,
    MAIN_PATH_PROD,
    MAIN_PATH_PROD_CA, MAIN_PATH_STAGING,
    PATH_ARI,
    REPORTS_PATH_DEV,
    REPORTS_PATH_PROD,
    REPORTS_PATH_PROD_CA, REPORTS_PATH_STAGING,
    PORTAL_PATH_DEV,
    PORTAL_PATH_PROD,
    PORTAL_PATH_PROD_CA, PORTAL_PATH_STAGING
} from "../../../../../newShared/constants";

export const useTopBar = () => {

    const [anchorWorkspaceEl, setAnchorWorkspaceEl] = useState<null | HTMLElement>(null);
    const handleWorkspacesOpen = (event: MouseEvent<HTMLElement>) => {setAnchorWorkspaceEl(event.currentTarget);};
    const handleWorkspacesClose = () => {setAnchorWorkspaceEl(null);};

    const [anchorPageEl, setAnchorPageEl] = useState<null | HTMLElement>(null);
    const handlePageOpen = (event: MouseEvent<HTMLElement>) => {setAnchorPageEl(event.currentTarget);};
    const handlePageClose = () => {setAnchorPageEl(null);};

    const [anchorNotificationEl, setAnchorNotificationEl] = useState<null | HTMLElement>(null);
    const handleNotificationOpen = (event: MouseEvent<HTMLElement>) => {setAnchorNotificationEl(event.currentTarget);};
    const handleNotificationClose = () => {setAnchorNotificationEl(null);};

    const [anchorAvatarEl, setAnchorAvatarEl] = useState<null | HTMLElement>(null);
    const handleAvatarOpen = (event: MouseEvent<HTMLElement>) => {setAnchorAvatarEl(event.currentTarget);};
    const handleAvatarClose = () => {setAnchorAvatarEl(null);};

    const [anchorThreeDotsEl, setAnchorThreeDotsEl] = useState<null | HTMLElement>(null);
    const handleThreeDotsOpen = (event: MouseEvent<HTMLElement>) => {setAnchorThreeDotsEl(event.currentTarget);};
    const handleThreeDotsClose = () => {setAnchorThreeDotsEl(null);};

    const handleGoToMain = () => {
        if (PATH_ARI && PATH_ARI.startsWith('https://dev')) {
            window.open(MAIN_PATH_DEV, "_blank")?.focus()

        } else if (PATH_ARI && PATH_ARI.startsWith('https://staging')) {
            window.open(MAIN_PATH_STAGING, "_blank")?.focus()

        } else if (PATH_ARI && PATH_ARI.startsWith('https://reports')) {
            window.open(MAIN_PATH_PROD, "_blank")?.focus()

        } else if (PATH_ARI && PATH_ARI.startsWith('https://ca')) {
            window.open(MAIN_PATH_PROD_CA, "_blank")?.focus()

        } else {
            //localhost
            window.open(MAIN_PATH_DEV, "_blank")?.focus()
        }

        handlePageClose();
    }

    const handleGoToReports = () => {
        if (PATH_ARI && PATH_ARI.startsWith('https://dev')) {
            window.open(REPORTS_PATH_DEV, "_blank")?.focus()

        } else if (PATH_ARI && PATH_ARI.startsWith('https://staging')) {
            window.open(REPORTS_PATH_STAGING, "_blank")?.focus()

        } else if (PATH_ARI && PATH_ARI.startsWith('https://reports')) {
            window.open(REPORTS_PATH_PROD, "_blank")?.focus()

        } else if (PATH_ARI && PATH_ARI.startsWith('https://ca')) {
            window.open(REPORTS_PATH_PROD_CA, "_blank")?.focus()

        } else {
            //localhost
            window.open(REPORTS_PATH_DEV, "_blank")?.focus()
        }

        handlePageClose();
    }

    const handleGoToPortal = () => {
        if (PATH_ARI && PATH_ARI.startsWith('https://dev')) {
            window.open(PORTAL_PATH_DEV, "_blank")?.focus()

        } else if (PATH_ARI && PATH_ARI.startsWith('https://staging')) {
            window.open(PORTAL_PATH_STAGING, "_blank")?.focus()

        } else if (PATH_ARI && PATH_ARI.startsWith('https://reports')) {
            window.open(PORTAL_PATH_PROD, "_blank")?.focus()

        } else if (PATH_ARI && PATH_ARI.startsWith('https://ca')) {
            window.open(PORTAL_PATH_PROD_CA, "_blank")?.focus()

        } else {
            //localhost
            window.open(PORTAL_PATH_DEV, "_blank")?.focus()
        }

        handlePageClose();
    }

    return {
        anchorWorkspaceEl, setAnchorWorkspaceEl,
        handleWorkspacesOpen, handleWorkspacesClose,

        anchorPageEl, setAnchorPageEl,
        handlePageOpen, handlePageClose,

        anchorNotificationEl, setAnchorNotificationEl,
        handleNotificationOpen, handleNotificationClose,

        anchorAvatarEl, setAnchorAvatarEl,
        handleAvatarOpen, handleAvatarClose,

        anchorThreeDotsEl, setAnchorThreeDotsEl,
        handleThreeDotsOpen, handleThreeDotsClose,

        //
        handleGoToReports, handleGoToPortal, handleGoToMain,
    }
}
