import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory} from "react-router";
import {PATH_LOCAL_SETTINGS, PATH_LOCAL_USER_PROFILE} from "../../../../../newShared/constants";
import {AvatarMenuTitle} from "../avatarMenuTitle";
import {getName} from "../../../../../newShared/utils/text";
import {CommonAvatar} from "../../../../../newShared/components/Basic/CommonAvatar";
import {MenuItemHoverBlue} from "../styled";
import {SETTINGS_LANGUAGE_PATH} from "../../../../authWorkspacesCookies/settings/constants";
import {ChangeLanguageDialog} from "../../../../../newShared/components/changeLanguageDialog/hook";
import {LanguageChangeDialog} from "../../../../../newShared/components/changeLanguageDialog";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/workspaces/hooks/useManageWorkspacesAndOrganizations";
import {Dialog, Divider, Typography, List} from "@mui/material";
import React from "react";
import colors from "../../../../../newShared/theme/colors";
//import {useTopBar} from "../../hooks/useTopBar";
import {TransitionUp} from "../../../../../newShared/components/genericFilter/components/dialogTransaction";
import {useDispatch} from "react-redux";
import {ContactUsDialog} from "../../../../components/contactUs/contactUsDialog";
import {setContactUsDialogAction} from "../../../../components/contactUs/store/slice";
import {SHOW_CHANGE_LANGUAGE_IN_TOPBAR} from "../../../../../newShared/constants";

export const MobileMenuDialog = ({isOpen, handleClose}: {isOpen: boolean, handleClose: () => void}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'TopBar.threeDots'});
    const history = useHistory();
    const {currData: {currentUser}, logout} = UseManageWorkspacesAndOrganizations();
    const {toggleVisibility, isOpen: isChangeLanguageDialogOpen} = ChangeLanguageDialog();
    const handleSelect = (path: string) => {
        history.push(path);
        handleClose();
    };
    const dispatch = useDispatch();
    const openContactUsDialog = () => {
        dispatch(setContactUsDialogAction({isOpen: true}))
        handleClose();
    }
    // const {
    //     handleOpenSelectWorkspaceDialog
    // } = useTopBar();
    return (
        currentUser ? (
                <>
                    <Dialog
                        open={isOpen}
                        onClose={handleClose}
                        fullScreen
                        TransitionComponent={TransitionUp}
                        sx={{'& .MuiPaper-root': {
                                borderRadius: '12px 12px 0 0',
                                height: '80vh', width: '100vw',
                                bottom: 0,
                                position: 'absolute'
                            }}}
                    >
                        <AvatarMenuTitle isMobile={true}  name={getName(currentUser.firstName, currentUser.lastName)} email={currentUser.email} isDesktop={false} handleClose={handleClose}>
                            <CommonAvatar name={getName(currentUser.firstName, currentUser.lastName)}
                                          size={'d28'} style={{cursor: 'default'}}/>
                        </AvatarMenuTitle>
                        <Divider color={colors.stroke.grey} sx={{margin: '0 !important', width: '100%'}}/>
                        <List sx={{width: '100%'}} disablePadding={true}>
                            {/*<MenuItemHoverBlue style={{paddingLeft: '16px'}}  onClick={() => {handleOpenSelectWorkspaceDialog(); handleClose();}}>*/}
                            {/*    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Change workspace')}</Typography>*/}
                            {/*</MenuItemHoverBlue>*/}
                            <MenuItemHoverBlue style={{paddingLeft: '16px'}}  onClick={() => {handleSelect(PATH_LOCAL_SETTINGS)}}>
                                <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Settings')}</Typography>
                            </MenuItemHoverBlue>
                            <MenuItemHoverBlue style={{paddingLeft: '16px'}}  onClick={openContactUsDialog}>
                                <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Contact us')}</Typography>
                            </MenuItemHoverBlue>
                            <MenuItemHoverBlue style={{paddingLeft: '16px'}}  onClick={() => {handleSelect(PATH_LOCAL_USER_PROFILE)}}>
                                <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Profile')}</Typography>
                            </MenuItemHoverBlue>
                            <MenuItemHoverBlue style={{paddingLeft: '16px'}}  onClick={() => {handleSelect(SETTINGS_LANGUAGE_PATH)}}>
                                <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Language')}</Typography>
                            </MenuItemHoverBlue>
                            {SHOW_CHANGE_LANGUAGE_IN_TOPBAR &&
                                <MenuItemHoverBlue style={{paddingLeft: '16px'}} onClick={() => {
                                    toggleVisibility(true);
                                    handleClose();
                                }}>
                                    <Typography color={colors.text.dark} variant={'*bodyText2'}>{t('Change language')}</Typography>
                                </MenuItemHoverBlue>
                            }

                            <Divider sx={{margin: '0 !important', width: '100%'}}/>

                            <MenuItemHoverBlue style={{paddingLeft: '16px'}}  onClick={() => {handleClose(); logout()}}>
                                <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Logout')}</Typography>
                            </MenuItemHoverBlue>
                        </List>
                    </Dialog>
                    <LanguageChangeDialog isOpen={isChangeLanguageDialogOpen} handleClose={toggleVisibility}/>
                    <ContactUsDialog />
                </>
            )
            : null
    )

}