import {useDispatch} from "react-redux";
import {
    ChangeStatusNcrByAuditorMutationVariables,
    DeleteNcrMutationVariables,
    NcrInput
} from "../../../../../newShared/GQLTypes";
import {
    changeStatusNCRByAuditorAction,
    createNcrAction,
    deleteNCRAction,
    updateNCRAction
} from "../../store/auditSpace/ncrActions";

type TResponse = {
    createNcr: (ncrControl: NcrInput) => void,
    updateNcr: (ncrControl: NcrInput) => void,
    changeStatusNcr: (data: ChangeStatusNcrByAuditorMutationVariables) => void,
    deleteNcr: (data: DeleteNcrMutationVariables) => void,
}

export const useNCR = (): TResponse => {
    const dispatch = useDispatch()

    const createNcr: TResponse["createNcr"] = (ncrControl) => {dispatch(createNcrAction({data: {ncrControl}}))};
    const updateNcr: TResponse["updateNcr"] = (ncrControl) => {dispatch(updateNCRAction({data: {ncrControl}}))};
    const changeStatusNcr: TResponse["changeStatusNcr"] = (data) => {dispatch(changeStatusNCRByAuditorAction({data}))};
    const deleteNcr: TResponse["deleteNcr"] = (data) => {dispatch(deleteNCRAction({data}))};

    return {
        createNcr,
        updateNcr,
        changeStatusNcr,
        deleteNcr,
    }
}