import {ChangeEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/workspaces/hooks/useManageWorkspacesAndOrganizations";
import {changeNameApi} from "../../api";
import {addSuccessfulSnack} from "../../../../barsEnvironment/snack/store/slice";

export const useChangeUserName = () => {
    const dispatch = useDispatch();

    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [firstName, setFirstName] = useState<string>('');
    const handleChangeFirstName = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {setFirstName(e.target.value)}
    const [lastName, setLastName] = useState<string>('');
    const handleChangeLastName = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {setLastName(e.target.value)}

    const handleClean = () => {
        setFirstName('');
        setLastName('');
    }

    const handleSubmit = () => {
        setIsLoading(true);

        currentUser && changeNameApi({data: {firstName, lastName, publicId: currentUser.publicId}})
            .then(res => {
                dispatch(addSuccessfulSnack(res.message));
                handleClean();
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    useEffect(() => {
        if (currentUser) {
            setFirstName(currentUser.firstName || '');
            setLastName(currentUser.lastName || '');
        }

        return () => {
            handleClean();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        firstName, handleChangeFirstName,
        lastName, handleChangeLastName,

        handleSubmit,
        isLoading,
    }
}