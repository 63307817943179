import {AxiosResponse} from "axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils";
import axiosSync from "../../../../newShared/utils/axios/axios";
import {
    changeReviewedControlByAuditor,
    changeStatusNCRByAuditor,
    createNcr,
    deleteAuditorsInSnapshot,
    deleteNCR,
    downloadAuditorEvidenceFile,
    downloadReportCollectorAssetByAuditor,
    getAppLogsToDate,
    getAuditorEvidenceAsset,
    getAuditorsVariantsByAuditor,
    getFullNcrReportPreview,
    getIntegrationDataByCollectionReports,
    getIntegrationsReport,
    getNCRsByControlByAuditor,
    getReportByIdByAuditor,
    getReportControlByAuditor,
    getReportControlsByAuditor,
    getReportOrganizationsByAuditor,
    getReportsByOrganizationIdByAuditor,
    updateAuditors,
    updateNCR,
    updateReportInfo,
    updateReportStatus,
} from "./query";
import {
    ChangeReviewedControlByAuditorMutation,
    ChangeReviewedControlByAuditorMutationVariables,
    ChangeStatusNcrByAuditorMutation,
    ChangeStatusNcrByAuditorMutationVariables,
    CreateNcrMutation,
    CreateNcrMutationVariables,
    DeleteAuditorsInSnapshotMutation,
    DeleteAuditorsInSnapshotMutationVariables,
    DeleteNcrMutation,
    DeleteNcrMutationVariables,
    DownloadAppLogFileQuery,
    DownloadAppLogFileQueryVariables,
    DownloadAuditorEvidenceFileQuery,
    DownloadAuditorEvidenceFileQueryVariables,
    DownloadReportCollectorAssetByAuditorQuery,
    DownloadReportCollectorAssetByAuditorQueryVariables,
    GetAppLogsToDateQuery,
    GetAppLogsToDateQueryVariables,
    GetAuditorEvidenceAssetQuery,
    GetAuditorEvidenceAssetQueryVariables,
    GetAuditorsVariantsByAuditorQuery,
    GetAuditorsVariantsByAuditorQueryVariables,
    GetFullNcrReportPreviewQuery,
    GetFullNcrReportPreviewQueryVariables,
    GetIntegrationDataByCollectionReportsQuery,
    GetIntegrationDataByCollectionReportsQueryVariables,
    GetIntegrationsReportByAuditorQuery,
    GetIntegrationsReportByAuditorQueryVariables,
    GetNcRsByControlByAuditorQuery,
    GetNcRsByControlByAuditorQueryVariables,
    GetReportByIdByAuditorQuery,
    GetReportByIdByAuditorQueryVariables,
    GetReportControlByAuditorQuery,
    GetReportControlByAuditorQueryVariables,
    GetReportControlsByAuditorQuery,
    GetReportControlsByAuditorQueryVariables,
    GetReportOrganizationsByAuditorQuery,
    GetReportOrganizationsByAuditorQueryVariables,
    GetReportsByOrganizationIdByAuditorQuery,
    GetReportsByOrganizationIdByAuditorQueryVariables,
    UpdateAuditorsMutation,
    UpdateAuditorsMutationVariables,
    UpdateNcrMutation,
    UpdateNcrMutationVariables,
    UpdateReportInfoMutation,
    UpdateReportInfoMutationVariables,
    UpdateReportStatusMutation,
    UpdateReportStatusMutationVariables,
} from "../../../../newShared/GQLTypes";
import {print} from "graphql";
import {gql} from "graphql.macro";


export const getReportOrganizationsApi = async (data: GetReportOrganizationsByAuditorQueryVariables, signal?: AbortSignal): Promise<GetReportOrganizationsByAuditorQuery["getReportOrganizationsByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getReportOrganizationsByAuditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getReportOrganizationsByAuditor
    })
)

export const getReportsByOrganizationIdApi = async (data: GetReportsByOrganizationIdByAuditorQueryVariables, signal?: AbortSignal): Promise<GetReportsByOrganizationIdByAuditorQuery["getReportsByOrganizationIdByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getReportsByOrganizationIdByAuditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getReportsByOrganizationIdByAuditor
    })
)

export const getReportByIdApi = async (data: GetReportByIdByAuditorQueryVariables, signal?: AbortSignal): Promise<GetReportByIdByAuditorQuery["getReportByIdByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getReportByIdByAuditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getReportByIdByAuditor
    })
)

export const getReportControlsApi = async (data: GetReportControlsByAuditorQueryVariables, signal?: AbortSignal): Promise<GetReportControlsByAuditorQuery["getReportControlsByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getReportControlsByAuditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getReportControlsByAuditor
    })
)

export const getReportControlApi = async (data: GetReportControlByAuditorQueryVariables, signal?: AbortSignal): Promise<GetReportControlByAuditorQuery["getReportControlByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getReportControlByAuditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getReportControlByAuditor
    })
)

export const changeReviewedControlByAuditorApi = async (data: ChangeReviewedControlByAuditorMutationVariables, signal?: AbortSignal): Promise<ChangeReviewedControlByAuditorMutation["changeReviewedControlByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(changeReviewedControlByAuditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeReviewedControlByAuditor
    })
)

export const getAuditorsVariantsByAuditorApi = async (data: GetAuditorsVariantsByAuditorQueryVariables, signal?: AbortSignal): Promise<GetAuditorsVariantsByAuditorQuery["getAuditorsVariantsByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getAuditorsVariantsByAuditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAuditorsVariantsByAuditor
    })
)

export const updateAuditorsApi = async (data: UpdateAuditorsMutationVariables, signal?: AbortSignal): Promise<UpdateAuditorsMutation["updateAuditors"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(updateAuditors),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateAuditors
    })
)

export const deleteAuditorsInSnapshotApi = async (data: DeleteAuditorsInSnapshotMutationVariables, signal?: AbortSignal): Promise<DeleteAuditorsInSnapshotMutation["deleteAuditorsInSnapshot"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(deleteAuditorsInSnapshot),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteAuditorsInSnapshot
    })
)

export const updateReportStatusApi = async (data: UpdateReportStatusMutationVariables, signal?: AbortSignal): Promise<UpdateReportStatusMutation['updateReportStatus']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(updateReportStatus),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateReportStatus
    })
)

export const getFullNcrReportPreviewApi = async (data: GetFullNcrReportPreviewQueryVariables, signal?: AbortSignal): Promise<GetFullNcrReportPreviewQuery['getFullNcrReportPreview']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getFullNcrReportPreview),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getFullNcrReportPreview
    })
)

export const updateReportConfigApi = async (data: UpdateReportInfoMutationVariables, signal?: AbortSignal): Promise<UpdateReportInfoMutation['updateReportInfo']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(updateReportInfo),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateReportInfo
    })
)




export const getNCRsByControlApi = async (data: GetNcRsByControlByAuditorQueryVariables, signal?: AbortSignal): Promise<GetNcRsByControlByAuditorQuery["getNCRsByControlByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getNCRsByControlByAuditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getNCRsByControlByAuditor
    })
)

export const createNcrApi = async (data: CreateNcrMutationVariables, signal?: AbortSignal): Promise<CreateNcrMutation["createNcr"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(createNcr),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createNcr
    })
)

export const updateNCRApi = async (data: UpdateNcrMutationVariables, signal?: AbortSignal): Promise<UpdateNcrMutation["updateNCR"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(updateNCR),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateNCR
    })
)

export const changeStatusNCRByAuditorApi = async (data: ChangeStatusNcrByAuditorMutationVariables, signal?: AbortSignal): Promise<ChangeStatusNcrByAuditorMutation["changeStatusNCRByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(changeStatusNCRByAuditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeStatusNCRByAuditor
    })
)

export const deleteNCRApi = async (data: DeleteNcrMutationVariables, signal?: AbortSignal): Promise<DeleteNcrMutation["deleteNCR"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(deleteNCR),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteNCR
    })
)

export const downloadAuditorEvidenceFileApi = async (data: DownloadAuditorEvidenceFileQueryVariables, signal?: AbortSignal): Promise<DownloadAuditorEvidenceFileQuery["downloadAuditorEvidenceFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadAuditorEvidenceFile),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadAuditorEvidenceFile
    })
)

export const getAuditorEvidenceAssetApi = async (data: GetAuditorEvidenceAssetQueryVariables, signal?: AbortSignal): Promise<GetAuditorEvidenceAssetQuery["getAuditorTableEvidence"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getAuditorEvidenceAsset),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAuditorTableEvidence
    })
)

export const downloadReportCollectorAssetByAuditorApi = async (data: DownloadReportCollectorAssetByAuditorQueryVariables, signal?: AbortSignal): Promise<DownloadReportCollectorAssetByAuditorQuery["downloadReportCollectorAssetByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadReportCollectorAssetByAuditor),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadReportCollectorAssetByAuditor
    })
)


export const getIntegrationsReportApi = async (data: GetIntegrationsReportByAuditorQueryVariables, signal?: AbortSignal): Promise<GetIntegrationsReportByAuditorQuery["getIntegrationsReportByAuditor"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getIntegrationsReport),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getIntegrationsReportByAuditor
    })
)

export const getIntegrationDataByCollectionReportsApi = async (data: GetIntegrationDataByCollectionReportsQueryVariables, signal?: AbortSignal): Promise<GetIntegrationDataByCollectionReportsQuery["getIntegrationDataByCollectionReports"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getIntegrationDataByCollectionReports),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getIntegrationDataByCollectionReports
    })
)

export const getAppLogsToDateApi = async (data: GetAppLogsToDateQueryVariables, signal?: AbortSignal): Promise<GetAppLogsToDateQuery["getAppLogsToDate"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getAppLogsToDate),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getAppLogsToDate
    })
)

export const downloadAppLogFileApi = async (data: DownloadAppLogFileQueryVariables, signal?: AbortSignal): Promise<DownloadAppLogFileQuery["downloadAppLogFile"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query downloadAppLogFile($fileId: String!, $workspaceId: String!) {
                        downloadAppLogFile (fileId: $fileId, workspaceId: $workspaceId) {
                            filename
                            filetype
                            file
                        }
                    }
                `),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadAppLogFile;
    })
)