import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../redux/rootReducer";

type TSlice = {
    isOpen: boolean,
    fileId: string | null,
    fileName: string | null,
    isLicense: boolean,
    isEndUserAgreementLicense: boolean
}

const initialState: TSlice = {
    isOpen: false,
    fileId: null,
    fileName: null,
    isLicense: false,
    isEndUserAgreementLicense: false
}

const Slice = createSlice({
    name: 'pdfView',
    initialState,
    reducers: {
        openDocViewer: (state:TSlice, {payload}: {payload: {fileId: string, fileName: string}}) => {
            state.isOpen = true;
            state.fileId = payload.fileId;
            state.fileName = payload.fileName;
        },
        openLicenseViewer: (state:TSlice) => {
            state.isOpen = true;
            state.isLicense = true;
        },
        openEndUserAgreementViewer: (state:TSlice) => {
            state.isOpen = true;
            state.isEndUserAgreementLicense = true;
        },
        hideDocViewer: (state) => {
            state.isOpen = false;
            state.fileId = null;
            state.fileName = null;
            state.isLicense = false;
            state.isEndUserAgreementLicense = false;
        }
    },
})

export const PDFViewReducer = Slice.reducer;
export const {
    hideDocViewer, openDocViewer, openLicenseViewer, openEndUserAgreementViewer
} = Slice.actions;

export const selectSelf = (state: AppState): TSlice => state.pdfView;
export const docViewer = createSelector(selectSelf, state => state);



