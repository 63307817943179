import {print} from "graphql";
import {gql} from "@apollo/react-hooks";

export const changeUserProfileInfoGQL = print(gql`
    mutation changeUserProfileInfoGQL($data: ChangeNameInput!) {
        changeName(data: $data) {
            message
        }
    }
`)

export const validate2faCode = print(gql`
    mutation validate2faCode($code: String!, $secret: String!) {
        initialValidateGoogleAuthorization(code: $code, secret: $secret)
    }
`)

export const reconfigureGoogleAuthInSettings = print(gql`
    mutation reconfigureGoogleAuthInSettings {
        reconfigureGoogleAuth {
            qrCode
            secret
        }
    }
`)

export const disableGoogleAuth = print(gql`
    mutation disableGoogleAuth {
        disableGoogleAuth {
            message
        }
    }
`)