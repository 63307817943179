import {FC} from "react";
import {Box, CircularProgress, IconButton, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {Download, Visibility} from "@mui/icons-material";
import colors from "../../../../../newShared/theme/colors";
import {EvidenceAssetsModel} from "../../../../../newShared/GQLTypes";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";
import {useAuditSpace} from "../../hooks/useAuditSpace";
import {useEvidence} from "../../hooks/useEvidence";

type TProps = {
    assets: EvidenceAssetsModel[]
}
export const AdditionalAssets: FC<TProps> = ({assets}) => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'AuditSpace'});
    const {handleOpen: pdfOpen} = usePDFView();

    const {
        isLoading: {isDownloadingFile}
    } = useAuditSpace();
    const {downloadEvidenceFile} = useEvidence()

    return (
        <Table size={"small"}>
            <TableBody>
                {assets.map(asset => (
                    <TableRow sx={{'& td': {borderBottom: 'none'}}} key={asset.assetId}>
                        <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                            <Box display={"flex"} alignItems={"center"}>
                                <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                            disabled={isDownloadingFile.includes(asset.assetId)}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                downloadEvidenceFile(asset.assetId, asset.fileName)
                                            }}>
                                    {isDownloadingFile.includes(asset.assetId)
                                        ? <CircularProgress size={24}/>
                                        : <Download style={{color: colors.text.blue}}/>}
                                </IconButton>

                                {asset.fileName.endsWith('.pdf') && (
                                    <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    pdfOpen({fileId: asset.assetId, fileName: asset.fileName})
                                                }}>
                                        <Visibility style={{color: colors.text.blue}}/>
                                    </IconButton>
                                )}

                                {asset.fileName}
                            </Box>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}