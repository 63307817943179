import React, {FC, Fragment} from "react";
import {
    Box,
    CircularProgress,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {useAuditSpace} from "../../hooks/useAuditSpace";
import {useReportDialogs} from "../../hooks/useReportsDialogs";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../newShared/theme/colors";
import {TAssetDataAUTOMATION, TAssetDataFILE} from "../../types";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {useRoutes} from "../../hooks/useRoutes";
import {useEvidence} from "../../hooks/useEvidence";
import {TableAssetDialog} from "../dialogs/tableAssetDialog";
import {StatementReportDialog} from "../dialogs/statementReportDialog";

export const EvidencesReportControl: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'AuditSpace'});
    const {organizationId, controlId} = useRoutes();

    const {
        selectedControl: {selectedControl}, controls: {report},
        isLoading: {isLoadingControl, isDownloadingFile, isLoadingAsset},
    } = useAuditSpace();
    const {downloadEvidenceFile, downloadEvidenceCollector, getCollector} = useEvidence();
    const {
        collectorView: {setCollectorView, isOpen, data, pageInfo},
        statement: {setStatementDialog}
    } = useReportDialogs();

    const {handleOpen} = usePDFView();
    return (
        <Box width={'100%'} display={"flex"} flexDirection={"column"} overflow={"auto"}>
            {isLoadingControl ? (
                <>
                    <Typography variant={"body2"} color={colors.text.dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                    <Typography variant={"body2"} color={colors.text.dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                </>
            ) : (
                <>
                    <Table size={"small"}>
                        <TableBody>
                            {selectedControl?.evidences && selectedControl?.evidences?.length === 0 && (
                                <Typography variant={"body1"} color={colors.text.dark} marginTop={'6px'}>
                                    {t('The user has not published any evidence assets.')}
                                </Typography>
                            )}

                            {selectedControl?.evidences?.map(asset => (
                                <Fragment key={asset.id}>
                                    {asset.type === 'COLLECTOR' && (
                                        <TableRow sx={{'& td': {borderBottom: 'none'}}}>
                                            <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                disabled={isDownloadingFile.includes(asset.id)}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    report && organizationId && downloadEvidenceCollector({
                                                                        organizationId: organizationId,
                                                                        workspaceId: report.workspaceId,
                                                                        reportId: report.id,
                                                                        evidenceId: asset.evidenceId,
                                                                        assetId: asset.id,
                                                                    })
                                                                }}>
                                                        {isDownloadingFile.includes(asset.id)
                                                            ? <CircularProgress size={24}/>
                                                            : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                    </IconButton>

                                                    <IconButton size={'small'} sx={{marginRight: '10px'}}
                                                                disabled={!!isLoadingAsset}
                                                                onClick={() => {
                                                                    organizationId && report && controlId && selectedControl &&
                                                                    getCollector({
                                                                        id: asset.id,
                                                                        evidenceId: asset.evidenceId,
                                                                        reportId: report.id,
                                                                        workspaceId: report.workspaceId,
                                                                        pageInfo: {page: 0, count: 10, sort: ''}
                                                                    })
                                                                    }}>
                                                        {isLoadingAsset === asset.id ? (
                                                            <CircularProgress size={24}/>
                                                        ) : (
                                                            <ArticleOutlinedIcon style={{color: colors.text.blue}}/>
                                                        )}
                                                    </IconButton>

                                                    {asset.name}
                                                </Box>
                                            </TableCell>
                                            <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(asset.lastUpdate)}</TableCell>
                                        </TableRow>
                                    )}

                                    {asset.type === 'AUTOMATION' && (asset.data as TAssetDataAUTOMATION)
                                        .reduce((prev, curr) => {
                                            const array: TAssetDataFILE = [];
                                            Object.keys(curr).forEach((value) => {
                                                array.push(...curr[value]);
                                            })
                                            return [...prev, ...array]
                                        }, [] as TAssetDataFILE)
                                        .map((file, index, array) => (
                                            <TableRow key={file.fileId} sx={{'& td': {borderBottom: 'none'}}}>
                                                <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                    disabled={isDownloadingFile.includes(file.fileId)}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        downloadEvidenceFile(file.fileId as string || '', file.name as string || '')
                                                                    }}>
                                                            {isDownloadingFile.includes(file.fileId)
                                                                ? <CircularProgress size={24}/>
                                                                : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                        </IconButton>

                                                        {file.name.endsWith('.pdf') && (
                                                            <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            handleOpen({fileId: file.fileId as string || '', fileName: file.name as string || ''})
                                                                        }}>
                                                                <VisibilityIcon style={{color: colors.text.blue}}/>
                                                            </IconButton>
                                                        )}

                                                        {file.name}
                                                    </Box>
                                                </TableCell>
                                                <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(file.lastUpdate)}</TableCell>
                                            </TableRow>
                                        ))}

                                    {asset.type === 'FILE' && (
                                        <TableRow sx={{'& td': {borderBottom: 'none'}}} key={asset.id}>
                                            <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                disabled={isDownloadingFile.includes(asset.data[0]?.fileId)}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    downloadEvidenceFile(asset.data[0]?.fileId as string || '', asset.data[0]?.name as string || '')
                                                                }}>
                                                        {isDownloadingFile.includes(asset.data[0]?.fileId)
                                                            ? <CircularProgress size={24}/>
                                                            : <DownloadIcon style={{color: colors.text.blue}}/>}
                                                    </IconButton>

                                                    {asset.name.endsWith('.pdf') && (
                                                        <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleOpen({fileId: asset.data[0]?.fileId as string || '', fileName: asset.data[0]?.name as string || ''})
                                                                    }}>
                                                            <VisibilityIcon style={{color: colors.text.blue}}/>
                                                        </IconButton>
                                                    )}

                                                    {asset.name}
                                                </Box>
                                            </TableCell>
                                            <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(asset.lastUpdate)}</TableCell>
                                        </TableRow>
                                    )}

                                    {asset.type === 'CUSTOM' && (
                                        <TableRow sx={{'& td': {borderBottom: 'none'}}} key={asset.id}>
                                            <TableCell width={1000} style={{paddingLeft: !revDir ? 0 : undefined, paddingRight: !revDir ? undefined : 0}}>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <IconButton size={'small'} sx={!revDir ? {marginRight: '10px'} : {marginLeft: '10px'}}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    setStatementDialog({data: asset.data, isOpen: true})
                                                                }}>
                                                        <VisibilityIcon style={{color: colors.text.blue}}/>
                                                    </IconButton>

                                                    {asset.data?.[0]?.name || ''}
                                                </Box>
                                            </TableCell>
                                            <TableCell align={"center"} sx={{whiteSpace: 'nowrap'}}>{parseDateAuto(asset.lastUpdate)}</TableCell>
                                        </TableRow>
                                    )}
                                </Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}

            <StatementReportDialog/>
            {data && (
                <TableAssetDialog tableFields={data.tableFields} data={data.data} pageInfo={pageInfo} isOpen={isOpen}
                                  withPagination={true} isLoading={!!isLoadingAsset}
                                  handleClose={() => setCollectorView({clear: true})}
                                  getData={(pageInfo) => {
                                      organizationId && report && controlId && selectedControl &&
                                      getCollector({
                                          id: data.id,
                                          evidenceId: data.evidenceId,
                                          reportId: report.id,
                                          workspaceId: report.workspaceId,
                                          pageInfo
                                      })
                                  }}
                />
            )}
        </Box>
    )
}