import {OnSubscriptionDataOptions, useSubscription} from "@apollo/client";
import {subscriptionMessage} from "../../types";
import {useDispatch} from "react-redux";
import {
    SEND_CREATE_UPDATE_NCR_CONFIG_ACTION,
    SEND_DELETE_AUDITOR_ACTION,
    SEND_REPORT_STATUS_CHANGED_ACTION,
    SEND_SHARED_FOR_AUDITORS_REPORT_ACTION,
    SEND_UPDATE_NCR_REPORT_ACTION,
    SEND_UPDATE_REVIEWED_CONTROL_ACTION
} from "../../../../components/auditSpaceNew/actions";
import {
    SEND_CREATE_UPDATE_NCR_CONFIG_payload,
    SEND_DELETE_AUDITOR_payload,
    SEND_REPORT_STATUS_CHANGED_payload,
    SEND_SHARED_FOR_AUDITORS_REPORT_payload,
    SEND_UPDATE_NCR_REPORT_payload,
    SEND_UPDATE_REVIEWED_CONTROL_payload
} from "../../../../components/auditSpaceNew/types";
import {useHistory} from "react-router";
import {PATH_LOCAL_SPACE} from "../../../../../newShared/constants";
import {Logging} from "../../../../../newShared/utils/logs/log";
import {PUBLIC_ID_SUBSCRIPTION_GQL} from "../../../../../newDomain/barsEnvironment/SubscriptionHandler/gql";


//actions

export const usePublicIdSubscription = (publicId: string, token: string) => {
    const dispatch = useDispatch();
    const {location: {pathname}} = useHistory();

    const onNewDataReceived = (data: OnSubscriptionDataOptions<{ subscribeToPublicId: subscriptionMessage }>) => {
        const newData: subscriptionMessage | undefined = data?.subscriptionData?.data?.subscribeToPublicId;
        if(newData){
            switch (newData.type){
                //Reports
                case "SEND_SHARED_FOR_AUDITORS_REPORT": {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_SHARED_FOR_AUDITORS_REPORT_payload;
                        if (pathname !== `${PATH_LOCAL_SPACE}/${payload.organizationId}`) return;
                        dispatch(SEND_SHARED_FOR_AUDITORS_REPORT_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_SHARED_FOR_AUDITORS_REPORT error: ', ex);
                        return;
                    }
                }
                case "SEND_CREATE_UPDATE_NCR_CONFIG": {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_CREATE_UPDATE_NCR_CONFIG_payload;
                        dispatch(SEND_CREATE_UPDATE_NCR_CONFIG_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_CREATE_UPDATE_NCR_CONFIG error: ', ex);
                        return;
                    }
                }
                case "SEND_DELETE_AUDITOR": {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_DELETE_AUDITOR_payload;
                        if (!pathname.includes(PATH_LOCAL_SPACE)) return;
                        if (newData.publicId === payload.deletedAuditorId) return;
                        dispatch(SEND_DELETE_AUDITOR_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_DELETE_AUDITOR error: ', ex);
                        return;
                    }
                }
                case "SEND_UPDATE_NCR_REPORT": {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_UPDATE_NCR_REPORT_payload;
                        dispatch(SEND_UPDATE_NCR_REPORT_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_UPDATE_NCR_REPORT error: ', ex);
                        return;
                    }
                }
                case "SEND_REPORT_STATUS_CHANGED": {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_REPORT_STATUS_CHANGED_payload;
                        dispatch(SEND_REPORT_STATUS_CHANGED_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_REPORT_STATUS_CHANGED error: ', ex);
                        return;
                    }
                }
                case 'SEND_UPDATE_REVIEWED_CONTROL': {
                    try{
                        const payload = JSON.parse(newData.payload) as SEND_UPDATE_REVIEWED_CONTROL_payload;
                        dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION(payload));
                        return;
                    }catch (ex){
                        Logging.log('SEND_UPDATE_REVIEWED_CONTROL error: ', ex);
                        return;
                    }
                }

                default: {
                    Logging.log(`useWorkspaceSubscription: ${newData.type} type is not defined!`);
                }
            }
        }
        // console.log(JSON.stringify(newData));
    }

    useSubscription<{ subscribeToPublicId: subscriptionMessage }>(PUBLIC_ID_SUBSCRIPTION_GQL, {onSubscriptionData: onNewDataReceived, variables: {publicId, token}, fetchPolicy: 'no-cache'});
}