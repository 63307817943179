import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSetBreadcrumbs} from "../../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {SETTING_ROOT_PATH, SETTINGS_LANGUAGE_PATH} from "../../../constants";

export const useLanguage = () => {
    //reusing hook for dev language dialog
    const {setLanguage, currentLanguage} = useMainTranslation();

    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    useSetBreadcrumbs([
        {
            title: tMenu('Settings'),
            path: SETTING_ROOT_PATH
        },
        {
            title: tMenu('Language'),
            path: SETTINGS_LANGUAGE_PATH
        }
    ]);

    return{
        setLanguage, currentLanguage
    }

}