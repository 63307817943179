import {FC, useEffect, useRef, useState} from "react";
import {Avatar, AvatarGroup, IconButton, Popover} from "@mui/material";
import {TIdNameLogo} from "../../types";
import {Flex} from "../Layouts";
import {Typo} from "../Typography";
import ClearIcon from '@mui/icons-material/Clear';
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import {toInitials} from "../../utils/text";

type TProps = {
    assignments: TIdNameLogo[],

    handleDelete?: (person: TIdNameLogo) => void,
}
export const AssignmentsGroup: FC<TProps> = ({assignments, handleDelete}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [ids, setIds] = useState<string[]>([]);

    useEffect(() => {
        setIds(prev => prev.filter(value => assignments.some(a => a.id === value)))
    }, [assignments])

    // const {deleteAssignment} = useAssignments()

    const {revDir} = useMainTranslation('', {keyPrefix: 'frameworks'});
    return (
        <>
            <AvatarGroup max={4}
                         total={assignments.length}
                         ref={ref}
                         onClick={assignments.length > 4 ? (e) => {
                             e.stopPropagation();
                             setIds([...assignments.slice(3, assignments.length).map(el => el.id)])
                         } : undefined}
                         sx={{
                             // flexDirection: !revDir ? undefined : 'row',
                             '& .MuiAvatar-root': {
                                 maxWidth: '28px', maxHeight: '28px',
                                 fontSize: '12px',
                                 background: '#0684A8',
                             },
                             '& .MuiAvatar-root:hover': {
                                 background: '#085D75', cursor: 'pointer'
                             },
                             '& .MuiAvatar-root:last-child': {
                                 marginLeft: !revDir ? undefined : '-8px'
                             },
                             // '& .css-129p0cd-MuiAvatar-root-MuiAvatarGroup-avatar': {background: '#878BA1'},
                             // '& .css-129p0cd-MuiAvatar-root-MuiAvatarGroup-avatar: hover': {background: '#878BA1'},
                         }}>
                {assignments.map(assignment => (
                    <Avatar key={assignment.id}
                            alt={assignment.name}
                            src={assignment.logo || undefined}
                            onClick={e => {
                                e.stopPropagation();
                                setIds([assignment.id]);
                            }}
                    >
                        {toInitials(assignment.name)}
                    </Avatar>
                ))}
            </AvatarGroup>
            {ref.current && (
                <Popover open={ids.length > 0}
                         anchorEl={ref.current}
                         anchorOrigin={{
                             horizontal: "left",
                             vertical: "bottom"
                         }}
                         sx={{'& .MuiPopover-paper': {padding: '6px', borderRadius: '12px', minWidth: '120px'}}}
                         onClick={e => {e.stopPropagation()}}
                         onClose={() => {setIds([])}}
                >
                    {
                        assignments.filter(e => ids.includes(e.id)).map((person, index) => (
                            <Flex ai={'center'} jc={"space-between"}
                                  m={index !== 0 ? '4px 0 0 0' : undefined}>
                                <Typo fontWeight={700} fontSize={'10px'} color={'rgba(0, 0, 0, 0.53)'}
                                      margin={'0 5px 0 0'}>
                                    {person.name || ''}
                                </Typo>
                                {handleDelete && <IconButton color={"error"} sx={{width: '20px', height: '20px'}}
                                             onClick={() => {
                                                 handleDelete(person)
                                             }}>
                                    <ClearIcon sx={{width: '16px', height: '16px'}}/>
                                </IconButton>}
                            </Flex>
                        ))
                    }
                </Popover>
            )}
        </>

    )
}