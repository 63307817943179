import React, {FC, useEffect, useState} from "react";
import {Box, Skeleton, Switch, Tab, Tabs, Typography} from "@mui/material";
import {useRoutes} from "../../hooks/useRoutes";
import {useAuditSpace} from "../../hooks/useAuditSpace";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../newShared/theme/colors";
import {NcrReport} from "../../components/ncrReport";
import {EvidencesReportControl} from "../../components/evidencesReportControl";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";


export const ReportControlView: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'AuditSpace'});

    const {
        goToReport,
        organizationId, reportId, controlId,
    } = useRoutes();
    const {
        selectedControl: {selectedControl},
        controls: {report},
        isLoading: {isLoadingControl, isReviewingControl},
        getControlById, getNCRsByControl,
        setReviewed,
        cleanControl,
    } = useAuditSpace();


    const controller = new AbortController();
    useEffect(() => {
        if (organizationId && reportId && controlId) {
            getControlById(organizationId, reportId, controlId, controller.signal);
            getNCRsByControl(organizationId, reportId, controlId, controller.signal)
        }
        return () => {
            controller.abort();
            cleanControl();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId, controlId]);

    const [tab, setTab] = useState('Evidences');
    const tabs: {title: string, handleClick: () => void, hide?: boolean}[] = [
        {
            title: 'NCR',
            handleClick: () => {setTab('NCR')},
            hide: report ? !report.ncrEnabled : false,
        },
        {
            title: 'Evidences',
            handleClick: () => {setTab('Evidences')}
        },
        {
            title: 'Description',
            handleClick: () => {setTab('Description')}
        },
    ]

    useEffect(() => {
        if (selectedControl?.id !== controlId) setTab('Evidences')
        else selectedControl?.smallNCRs.some(e => e.status) && setTab('NCR')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlId, selectedControl?.smallNCRs.some(e => e.status)]);

    const IN_REVIEW = report?.status === 'IN_REVIEW';
    const RESPONSE = report?.status === 'RESPONSE';

    return (
        <Box flexDirection={"column"} height={'100%'} display={"flex"} overflow={'hidden'} flexBasis={"100%"}>
            <PageTitleAndActions
                title={selectedControl?.name}
                actions={[]}
                handleGoBack={() => organizationId && reportId && goToReport(organizationId, reportId)}
            >
                {(IN_REVIEW || RESPONSE) && (
                    <Switch disabled={!selectedControl || isReviewingControl.includes(selectedControl.id)} checked={selectedControl?.reviewed ?? false}
                            onChange={e => {report && selectedControl && setReviewed(report.workspaceId, report.id, report.framework.id, selectedControl.id, e.target.checked)}}/>
                )}
            </PageTitleAndActions>

            {/*<Box display={"flex"} alignItems={"center"} mt={'12px'} flexWrap={"wrap"}>*/}
            {/*    <Typography variant={"h3"} margin={!revDir ? '0 32px 0 8px' : '0 8px 0 32px'}>*/}
            {/*        {selectedControl?.name && reportId && (*/}
            {/*            <IconButton sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}*/}
            {/*                        onClick={() => {organizationId && reportId && goToReport(organizationId, reportId)}}>*/}
            {/*                {!revDir ? <ArrowBack/> : <ArrowForward/>}*/}
            {/*            </IconButton>*/}
            {/*        )}*/}

            {/*        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>*/}
            {/*            <Typography variant={"h3"}>*/}
            {/*                {selectedControl ? selectedControl.name : <Skeleton width={'300px'}/>}*/}
            {/*            </Typography>*/}
            {/*            {(IN_REVIEW || RESPONSE) && (*/}
            {/*                <Switch disabled={!selectedControl || isReviewingControl.includes(selectedControl.id)} checked={selectedControl?.reviewed ?? false}*/}
            {/*                        onChange={e => {report && selectedControl && setReviewed(report.workspaceId, report.id, report.framework.id, selectedControl.id, e.target.checked)}}/>*/}
            {/*            )}*/}
            {/*        </Box>*/}
            {/*    </Typography>*/}
            {/*</Box>*/}

            <Tabs value={tab} sx={{borderBottom: '2px solid ' + colors.stroke.grey, marginBottom: '16px'}} variant={"scrollable"}>
                {tabs.map(tab => !tab.hide && (
                    <Tab key={tab.title} label={t(tab.title)} value={tab.title} onClick={tab.handleClick}
                         disabled={isLoadingControl}/>
                ))}
            </Tabs>

            {tab === 'NCR' && (
                <NcrReport/>
            )}

            {tab === 'Evidences' && (
                <EvidencesReportControl/>
            )}

            {tab === 'Description' && (
                selectedControl ? selectedControl?.description.map((description, index) => (
                    // <ReactMarkdown className={styles.FrameworkControlDescription} key={description}>
                    //     {description}
                    // </ReactMarkdown>
                    <Typography variant={"body2"} key={description + index}>{description}</Typography>
                )) : (
                    <>
                        <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                        <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                        <Typography variant={"body2"} color={colors.text.grey_dark} maxWidth={'800px'} margin={'4px'}>{<Skeleton/>}</Typography>
                    </>
                )
            )}
            
            {/*<StatementDialog/>*/}
        </Box>
    )
}