// Core
import {combineReducers} from 'redux';

// Reducers
import {I18NextReducer} from "../hooks/useMainTranslationHooks/slice";
import {snackReducer} from "../../newDomain/barsEnvironment/snack/store/slice";
import {
    SharedWorkspacesAndOrganizationsReducer
} from '../../newDomain/authWorkspacesCookies/workspaces/store/sharedWorkspacesAndOrganizations/slice';
import {ChooseWorkspaceReducer} from "../../newDomain/authWorkspacesCookies/workspaces/store/chooseWorkspace/slice";
import {leftMenuReducerReducer} from "../../newDomain/barsEnvironment/leftMenu/store/slice";
import {
    ManageOrganizationReducer
} from "../../newDomain/authWorkspacesCookies/workspaces/store/manageOrganization/slice";
import {ManageNewWorkspaceReducer} from '../../newDomain/authWorkspacesCookies/workspaces/store/manageWorkpsace/slice';
import {InvitesReducer} from '../../newDomain/authWorkspacesCookies/workspaces/store/invites/slice';
import {SigninReducer} from "../../newDomain/authWorkspacesCookies/signIn/store/slice";
import {breadcrumbsReducer} from "../../newDomain/barsEnvironment/breadcrumbs/store/slice";
import {messageDialogReducerReducer} from "../../newDomain/barsEnvironment/MessageDialog/store/store";
import {settingsProfileReducer} from "../../newDomain/authWorkspacesCookies/settingsProfile/reducer";
import {ConfigReducer} from "../../newDomain/barsEnvironment/config/store/slice";
import {PDFViewReducer} from "../components/docViewer/store/slice";
import {SettingsReducer} from "../../newDomain/authWorkspacesCookies/settings/store/slice";
import {AuditSpaceReducer} from "../../newDomain/components/auditSpaceNew/store/auditSpace/slice";
import {genericFiltersReducer} from "../components/genericFilter/store/slice";
import {contactUsReducer} from "../../newDomain/components/contactUs/store/slice";


export const rootReducer = combineReducers({
    //INIT
    auth: SigninReducer,
    snack: snackReducer,
    I18Next: I18NextReducer,
    settingsProfile: settingsProfileReducer,
    Config: ConfigReducer,
    //WORKSPACES
    SharedWorkspacesAndOrganizations: SharedWorkspacesAndOrganizationsReducer,
    ChooseWorkspace: ChooseWorkspaceReducer,
    ManageOrganization: ManageOrganizationReducer,
    ManageNewWorkspaceReducer: ManageNewWorkspaceReducer,
    Invites: InvitesReducer,
    //
    Settings: SettingsReducer,
    //
    leftMenu: leftMenuReducerReducer,
    breadcrumbs: breadcrumbsReducer,
    messageDialog: messageDialogReducerReducer,
    //
    pdfView: PDFViewReducer,

    auditSpace: AuditSpaceReducer,

    genericFilters: genericFiltersReducer,
    contactUs: contactUsReducer,
    // Support: SupportReducer,
    // editorDialog: EditorDialogReducer,
});


export type AppState = ReturnType<typeof rootReducer>;
