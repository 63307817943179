import {createAsyncThunk} from "@reduxjs/toolkit";
import {TInviteExtended} from "../../workspaces/types";
import {
    acceptInvite,
    changeCurrentUserInfo,
    changeCurrentUserPass,
    declineInvite,
    disableGoogleAuthApi,
    getInvites,
    reconfigureGoogleAuthApi,
    validate2faCodeApi
} from "../api";
import {TChangeUserInfo, TChangeUserPass} from "../types";
import {Validate2faCodeMutationVariables} from "../../../../newShared/GQLTypes";


export const GetInvites = createAsyncThunk(
    'Settings/GetInvites',
    async (): Promise<TInviteExtended[]> => {
        return await getInvites();
    }
);

export const AcceptInvite = createAsyncThunk(
    'Settings/acceptInvite',
    async (data: {inviteId: string, type: string, onSuccess?: () => void}, {dispatch}): Promise<string> => {
        await acceptInvite(data.inviteId, data.type);
        data.onSuccess && data.onSuccess();
        return data.inviteId;
    }
);

export const DeclineInvite = createAsyncThunk(
    'Settings/declineInvite',
    async (data: {inviteId: string, type: string}, {dispatch}): Promise<string> => {
        await declineInvite(data.inviteId, data.type);
        return data.inviteId;
    }
);

//USER PROFILE
export const ChangeCurrentUserInfo = createAsyncThunk(
    'Settings/changeCurrentUserInfo',
    async ({data, onSuccess}: { data: TChangeUserInfo, onSuccess: () => void }, {dispatch, getState}) => {
        const resp = await changeCurrentUserInfo(data);
        data && onSuccess();
        return resp
    },
);

//SECURITY
export const ChangeCurrentUserPassword = createAsyncThunk(
    'Settings/ChangeCurrentUserPassword',
    async ({data, onSuccess}: { data: TChangeUserPass, onSuccess: () => void }, {dispatch, getState}) => {
        const resp = await changeCurrentUserPass(data);
        resp  && onSuccess();
        return resp
    },
);

export const validate2faCodeAction = createAsyncThunk(
    'Settings/validate2faCode',
    async (data: Validate2faCodeMutationVariables & {onSuccess: () => void}, {dispatch, getState}) => {
        // const state = getState() as AppState;
        // const workspaceId = state.workspace.workspaceId || '';
        const resp = await validate2faCodeApi({code: data.code, secret: data.secret});
        data.onSuccess();
        return resp
    },
);

export const reconfigureGoogleAuthAction = createAsyncThunk(
    'Settings/reconfigureGoogleAuth',
    async (data: {onSuccess: () => void}, {dispatch, getState}) => {
        // const state = getState() as AppState;
        // const workspaceId = state.workspace.workspaceId || '';
        const resp = await reconfigureGoogleAuthApi({});
        data.onSuccess();
        return resp
    },
);

export const disableGoogleAuthAction = createAsyncThunk(
    'Settings/disableGoogleAuth',
    async (data: {onSuccess: () => void}, {dispatch, getState}) => {
        // const state = getState() as AppState;
        // const workspaceId = state.workspace.workspaceId || '';
        const resp = await disableGoogleAuthApi({});
        data.onSuccess();
        return resp
    },
);



