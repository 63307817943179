import React from "react";
import {genericFiltersArrayPropsTypes} from "../../types";
import {useGenericFiltersArray} from "../../hooks/useGenericFiltersArray";
import {GenericFilter} from "../genericFilter";
import {AddFiltersComponent} from "../addFiltersComponent";
import {InputAdornment, OutlinedInput} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {useMedia} from "../../../../hooks/useMedia";
import {Flex} from "../../../Layouts";
import {MinimizeInfoWrapper} from "../../../minimizeInfoWrapper";
import colors from "../../../../theme/colors";
import {sortFilters} from "../../helpers";
import {useMainTranslation} from "../../../../hooks/useMainTranslationHooks/useMainTranslation";

export default function GenericFiltersArray({configs, fetchResultWithSelectedFilters, disableSearch, isAddFilters = true, id, isSearchDisabled = false, dateFilterFormat = 'L', collapsable = false}: genericFiltersArrayPropsTypes){
    const {
        visibleFilters,
        handleSelectVisibleFilter,
        handleResetFilters,
        handleDeselectVisibleFilter,
        updateFilterAndFetch,
        search
    } = useGenericFiltersArray({configs, fetchResultWithSelectedFilters, disableSearch, id});
    const { isLessThenCustomQuery, isMobile} = useMedia(834);
    const {t} = useMainTranslation('', {keyPrefix: 'genericFilter'});

    return(
        <>
            {collapsable ?
                <MinimizeInfoWrapper text={t('Filters')} startIcon={<FilterAltIcon />} initiallyOpened>
                    <Flex
                        m={'8px 0 0 0'}
                        w={isLessThenCustomQuery ? '100%' : 'calc(100% - 160px)'}
                        direction={isLessThenCustomQuery ? 'column' : undefined}
                        // w={isMobile ? '100%' : '82%'}
                        overflow={isMobile ? 'auto' : undefined}
                        gap={'10px'}
                        wrap={!isLessThenCustomQuery ? 'wrap' : undefined}
                    >
                        <Flex gap={'10px'} jc={'space-between'}>
                            {!disableSearch &&
                                <OutlinedInput
                                    placeholder={t('Search')}
                                    value={search.value}
                                    onChange={search.handleChangeSearch}
                                    fullWidth
                                    disabled={isSearchDisabled}
                                    sx={{
                                        minWidth: '200px',
                                        maxWidth: !isLessThenCustomQuery ? '250px' : undefined,
                                        padding: '0',
                                        height: '33px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            // border: 'none',
                                        },
                                        '& .MuiButtonBase-root': {
                                            padding: '13px 10px',
                                        },
                                        '& .MuiInputAdornment-root': {
                                            padding: '8px'
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            // width: '78%',
                                            padding: '5.5px 8px',
                                            height: '14px'
                                        }
                                    }
                                    }
                                    size={'small'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SearchIcon sx={{width: '16px', height: '16px', color: colors.text.grey_dark}}/>
                                        </InputAdornment>
                                    }
                                />
                            }

                            {isAddFilters && isLessThenCustomQuery &&
                                <AddFiltersComponent
                                    selectedOptions={visibleFilters}
                                    options={configs.map(e => {return {name: e.name, key: e.key}})}
                                    disabledChangeOptions={configs.filter(e => e.default).map(e => {return {name: e.name, key: e.key}})}
                                    handleSelectOption={handleSelectVisibleFilter}
                                    handleDeselectOption={handleDeselectVisibleFilter}
                                    handleReset={handleResetFilters}
                                />
                            }
                        </Flex>

                        {!isLessThenCustomQuery ?
                            <>
                                {isAddFilters && <AddFiltersComponent
                                    selectedOptions={visibleFilters}
                                    options={configs.map(e => {
                                        return {name: e.name, key: e.key}
                                    })}
                                    disabledChangeOptions={configs.filter(e => e.default).map(e => {
                                        return {name: e.name, key: e.key}
                                    })}
                                    handleSelectOption={handleSelectVisibleFilter}
                                    handleDeselectOption={handleDeselectVisibleFilter}
                                    handleReset={handleResetFilters}
                                />}

                                {configs
                                    .filter(e => visibleFilters.some(v => v.key === e.key))
                                    .sort((a, b) => sortFilters(a, b, configs.filter(e => e.default).map(e => e.key), visibleFilters.map(e => e.key)))
                                    .map(e => {
                                        // console.log(`e`, e)
                                        return (
                                            <GenericFilter
                                                dateFilterFormat={dateFilterFormat}
                                                key={e.key}
                                                config={e}
                                                updateFilterAndFetch={updateFilterAndFetch}
                                                handleDeselectVisibleFilter={handleDeselectVisibleFilter}
                                            />
                                        )
                                    })
                                }
                            </>
                            :
                            <Flex
                                overflowy={isLessThenCustomQuery ? 'auto' : undefined}
                                gap={'10px'}
                            >
                                {configs
                                    .filter(e => visibleFilters.some(v => v.key === e.key))
                                    .sort((a, b) => sortFilters(a, b, configs.filter(e => e.default).map(e => e.key), visibleFilters.map(e => e.key)))
                                    .map(e => {
                                            // console.log(`e`, e)
                                            return (
                                                <GenericFilter
                                                    key={e.key}
                                                    config={e}
                                                    updateFilterAndFetch={updateFilterAndFetch}
                                                    handleDeselectVisibleFilter={handleDeselectVisibleFilter}
                                                    dateFilterFormat={dateFilterFormat}
                                                />
                                            )
                                        }
                                    )}
                            </Flex>
                        }
                    </Flex>
                </MinimizeInfoWrapper>
                :
                <Flex
                    m={'8px 0 0 0'}
                    w={isLessThenCustomQuery ? '100%' : 'calc(100% - 160px)'}
                    direction={isLessThenCustomQuery ? 'column' : undefined}
                    // w={isMobile ? '100%' : '82%'}
                    overflow={isMobile ? 'auto' : undefined}
                    gap={'10px'}
                    wrap={!isLessThenCustomQuery ? 'wrap' : undefined}
                >
                    <Flex gap={'10px'} jc={'space-between'}>
                        {!disableSearch &&
                            <OutlinedInput
                                placeholder={t('Search')}
                                value={search.value}
                                onChange={search.handleChangeSearch}
                                fullWidth
                                disabled={isSearchDisabled}
                                sx={{
                                    minWidth: '200px',
                                    maxWidth: !isLessThenCustomQuery ? '250px' : undefined,
                                    padding: '0',
                                    height: '33px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        // border: 'none',
                                    },
                                    '& .MuiButtonBase-root': {
                                        padding: '13px 10px',
                                    },
                                    '& .MuiInputAdornment-root': {
                                        padding: '8px'
                                    }
                                }}
                                inputProps={{
                                    style: {
                                        // width: '78%',
                                        padding: '5.5px 8px',
                                        height: '14px'
                                    }
                                }
                                }
                                size={'small'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon sx={{width: '16px', height: '16px', color: colors.text.grey_dark}}/>
                                    </InputAdornment>
                                }
                            />
                        }

                        {isAddFilters && isLessThenCustomQuery &&
                            <AddFiltersComponent
                                selectedOptions={visibleFilters}
                                options={configs.map(e => {return {name: e.name, key: e.key}})}
                                disabledChangeOptions={configs.filter(e => e.default).map(e => {return {name: e.name, key: e.key}})}
                                handleSelectOption={handleSelectVisibleFilter}
                                handleDeselectOption={handleDeselectVisibleFilter}
                                handleReset={handleResetFilters}
                            />
                        }
                    </Flex>

                    {!isLessThenCustomQuery ?
                        <>
                            {isAddFilters && <AddFiltersComponent
                                selectedOptions={visibleFilters}
                                options={configs.map(e => {
                                    return {name: e.name, key: e.key}
                                })}
                                disabledChangeOptions={configs.filter(e => e.default).map(e => {
                                    return {name: e.name, key: e.key}
                                })}
                                handleSelectOption={handleSelectVisibleFilter}
                                handleDeselectOption={handleDeselectVisibleFilter}
                                handleReset={handleResetFilters}
                            />}

                            {configs
                                .filter(e => visibleFilters.some(v => v.key === e.key))
                                .sort((a, b) => sortFilters(a, b, configs.filter(e => e.default).map(e => e.key), visibleFilters.map(e => e.key)))
                                .map(e => {
                                    // console.log(`e`, e)
                                    return (
                                        <GenericFilter
                                            dateFilterFormat={dateFilterFormat}
                                            key={e.key}
                                            config={e}
                                            updateFilterAndFetch={updateFilterAndFetch}
                                            handleDeselectVisibleFilter={handleDeselectVisibleFilter}
                                        />
                                    )
                                })
                            }
                        </>
                        :
                        <Flex
                            overflowy={isLessThenCustomQuery ? 'auto' : undefined}
                            gap={'10px'}
                        >
                            {configs
                                .filter(e => visibleFilters.some(v => v.key === e.key))
                                .sort((a, b) => sortFilters(a, b, configs.filter(e => e.default).map(e => e.key), visibleFilters.map(e => e.key)))
                                .map(e => {
                                        // console.log(`e`, e)
                                        return (
                                            <GenericFilter
                                                key={e.key}
                                                config={e}
                                                updateFilterAndFetch={updateFilterAndFetch}
                                                handleDeselectVisibleFilter={handleDeselectVisibleFilter}
                                                dateFilterFormat={dateFilterFormat}
                                            />
                                        )
                                    }
                                )}
                        </Flex>
                    }
                </Flex>
            }
        </>
    )
}
