import React, {FC} from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import * as S from "../styled";
import {TReportIntegration} from "../../types";
import {useRoutes} from "../../hooks/useRoutes";

type TProps = {
    app: TReportIntegration
}
export const ReportIntegrationCard: FC<TProps> = ({app}) => {
    const {organizationId, reportId, goToReportApp} = useRoutes();

    return (
        <S.IntegrationCard onClick={() => reportId && organizationId && goToReportApp(organizationId, reportId, app.id)}>
            <Flex w={'100%'} jc={'center'}>
                <img src={app.logo || undefined} alt={app.name} width={'40%'}/>
            </Flex>

            <Flex w={'100%'} jc={'center'}>
                <Typography
                    color={colors.grayText}
                    fontSize={'16px'}
                    fontWeight={700}
                    sx={{
                        margin: '8px 0 0 0',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineHeight: 'normal',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {app.name}
                </Typography>
            </Flex>
        </S.IntegrationCard>
    )
}