import {Autocomplete, Avatar, Box, Divider, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import React, {FC, Fragment, useEffect} from "react";
import {Box as BBox, Flex, PageWrapper} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import colors from "../../../../../newShared/theme/colors";
import {useReportsIntegrations} from "../../hooks/useReportsIntegrations";
import {TabContext} from "@mui/lab";
import * as S from "../../components/styled";
import {ExactAppLogsTableHeaderWrapper, TextFieldCustom} from "../../components/styled";
// import {CustomSearchField} from "../../../employees/components/customSearch";
// import {TextFieldCustom} from "../../../employees/components/styled";
//@ts-ignore
import InfiniteScroll from "react-infinite-scroller";
import {IconTextButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastIcon from "@mui/icons-material/East";
// @ts-ignore
import SyntaxHighlighter from "react-syntax-highlighter";
// @ts-ignore
import {docco} from "react-syntax-highlighter/dist/esm/styles/hljs";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import DownloadIcon from "@mui/icons-material/Download";
import {LocalSpinner} from "../../../../../newShared/components/Spinner";
import {useReportIntegrationExactDetailsPage} from "./useReportIntegrationExactDetailsPage";
import {ReportAppLogsTableRow} from "../../components/reportAppLogsTableRow";
import {useRoutes} from "../../hooks/useRoutes";
import {exactAppTabsType} from "../../types";
import {rawDataValueGetter} from "../../helpers";
import {AppLogsTableRowSkeleton} from "../../components/appLogsTableRowSkeleton";
import {MAX_LOG_SIZE_PREVIEW} from "../../constants";
import {CustomSearchField} from "../../../../authWorkspacesCookies/settings/components/customSearch";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {getAvatarInitials} from "../../../../../newShared/utils/text";

export const ReportIntegrationExactDetails: FC = () => {
    // const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {revDir} = useMainTranslation('', {keyPrefix: 'ReportsPage.View'});

    const {isMobile, isLessThenCustomQuery} = useMedia(912);

    const {
        getReportIntegrations,
    } = useReportsIntegrations();
    const {organizationId, reportId, goToReportApp} = useRoutes();

    const {
        isLoading,
        app,
        tab,
        actions,
        data,
        logsData
    } = useReportIntegrationExactDetailsPage();

    useEffect(() => {
        reportId && organizationId && getReportIntegrations({organizationId, reportId})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId])

    return (
        <Box display={"flex"} width={'100%'} height={'100%'} overflow={"hidden"}>
            <PageWrapper>

                <PageTitleAndActions
                    title={app?.name}
                    actions={[]}
                    handleGoBack={() => reportId && organizationId && goToReportApp(organizationId, reportId, '')}
                    icon={
                        <Avatar src={app?.logo ?? ''} sx={{width: '25px', height: '25px', backgroundColor: 'inherit'}}>
                            <Typography fontSize={'10px'} fontWeight={300}>{getAvatarInitials(app?.name ?? '')}</Typography>
                        </Avatar>
                    }
                />

                {/*TABS*/}
                <TabContext value={tab} >
                    <Box>
                        <Tabs variant={'scrollable'} value={tab} onChange={(e, newValue: exactAppTabsType) => actions.handleSetTab(newValue)}>
                            <Tab label={("Data")} value={'data'} disabled={isLoading} sx={{paddingLeft: '0'}}/>
                            <Tab label={("Logs")} value={'logs'} disabled={isLoading} />
                        </Tabs>
                    </Box>
                </TabContext>

                <Box display={"flex"} flexDirection={"column"} width={'100%'} flexGrow={1} overflow={"hidden"}>
                    {tab === 'data' &&
                        <Flex w={'100%'} jc={'space-between'} h={'100%'}>
                            {/*COLLECTION SELECT*/}
                            {!isMobile &&
                                <S.ExactAppCollectionsListContainer>
                                    <Flex w={'100%'} jc={'space-between'} h={'100%'}>
                                        <BBox w={'99%'} overflow={'auto'} h={'100%'} maxh={'100%'}>
                                            {app && app.config && app.config.collectionConfigs && app.config.collectionConfigs?.map((e) => {
                                                const isSelected = data.collectionsTab === e.alias;
                                                return <Typography
                                                    key={e.alias}
                                                    onClick={() => data.handleSetCollectionTab(e.alias)}
                                                    color={isSelected ? colors.text.blue : colors.grayText}
                                                    sx={{cursor: 'pointer', margin: '32px 0 0 0'}}
                                                >
                                                    {e.alias}
                                                </Typography>
                                            })}
                                        </BBox>
                                        <Divider flexItem orientation={'vertical'} style={{borderColor: colors.primary.disable, backgroundColor: colors.darkText}}/>
                                    </Flex>
                                </S.ExactAppCollectionsListContainer>
                            }

                            {/*TABLE CONTAINER*/}
                            <S.ExactAppItemsListContainer rightSideOpened={data.selectedCollectionObject !== null}>
                                <S.ExactAppItemsListSearchContainer rightSideOpened={data.selectedCollectionObject !== null}>
                                    <CustomSearchField
                                        variant={'outlined'}
                                        onChange={data.handleChange}
                                        value={data.search}
                                        onClear={data.handleCleanSearch}
                                        disabled={isLoading}
                                        onKeyDown={data.handleEnterKey}
                                        size="small"
                                        fullWidth
                                        name={'search'}
                                        label={('Search')}
                                        dir={'ltr'}
                                        placeholder={('Search')}
                                    />
                                    {isMobile &&
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={app?.config?.collectionConfigs.map(e => e.alias) ?? []}
                                            disabled={isLoading}
                                            // getOptionLabel={(option) => option}
                                            loading={isLoading}
                                            value={data.collectionsTab}
                                            onChange={(e, newValue: string | null) => newValue && data.handleSetCollectionTab(newValue)}
                                            sx={{margin: '16px 0 0 0'}}
                                            renderInput={(params) => <TextFieldCustom {...params} label={("Collection")} size={'small'}/>}
                                        />
                                    }
                                </S.ExactAppItemsListSearchContainer>

                                {/*TABLE HEADER*/}
                                <Flex w={'100%'} jc={'space-between'}>
                                    {data.currentCollectionConfig?.headers.map((e: string, id, arr) => <Flex key={String(e)} w={`calc(100% / ${arr.length ?? 1})`}>
                                        <Typography fontSize={'18px'} fontWeight={600} sx={{margin: '16px 0'}}>{e}</Typography>
                                    </Flex>)}
                                </Flex>

                                {/*TABLE*/}
                                <S.MainTableScrollWrapper>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={(page: number) => data.handleLoadMore(page)}
                                        hasMore={data.hasMore}
                                        initialLoad={false}
                                        threshold={50}
                                        useWindow={false}
                                        style={{width: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto'}}
                                    >
                                        {data.collectionData.map(e => {
                                            const values = [];
                                            const selected = JSON.stringify(data.selectedCollectionObject) === JSON.stringify(e);
                                            for(let i = 0; i < (data.currentCollectionConfig?.headers.length ?? 0); i++){
                                                values.push(rawDataValueGetter(e, app?.config, data.collectionsTab, i));
                                            }
                                            return(
                                                <Fragment key={JSON.stringify(e)}>
                                                    <S.ExactAppObjListRow key={values[0]} onClick={() => data.setSelectedCollectionObject(e)} selected={selected}>
                                                        {values.map(v => <Flex key={v} w={`calc(100% / ${(data.currentCollectionConfig?.headers.length ?? 1)} - 8px)`}>
                                                            <Typography variant={'body1'} color={colors.grayText} whiteSpace={'nowrap'} textOverflow={'ellipsis'} lineHeight={'normal'} overflow={'hidden'}>{String(v)}</Typography>
                                                        </Flex>)}
                                                    </S.ExactAppObjListRow>
                                                    {<Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '4px 0 0 0'}}/>}
                                                </Fragment>
                                            )
                                        })}
                                        {data.isLoading && <Flex w={'100%'} jc={'space-between'}>
                                            {(data.currentCollectionConfig?.headers ?? ['']).map(e => <Flex key={e} w={`calc(100% / ${(data.currentCollectionConfig?.headers.length ?? 1)})`}><Skeleton width={200}/></Flex>)}
                                        </Flex>}
                                    </InfiniteScroll>
                                </S.MainTableScrollWrapper>
                            </S.ExactAppItemsListContainer>

                            {data.selectedCollectionObject &&
                                <S.ExactAppExactItemContainer>
                                    <Flex w={'100%'} jc={'space-between'} h={'100%'}>
                                        {!isMobile &&
                                            <Divider flexItem orientation={'vertical'} style={{
                                                borderColor: colors.primary.disable,
                                                backgroundColor: colors.darkText
                                            }}/>
                                        }
                                        <Flex w={'99%'} direction={'column'} overflow={'auto'}>
                                            {isMobile &&
                                                <Flex w={'100%'}>
                                                    <IconTextButton
                                                        variant={'text'}
                                                        disabled={isLoading}
                                                        icon={!revDir ?
                                                            <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                            : <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                        }
                                                        onClick={data.deselectCollectionObject}
                                                        sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}}}
                                                    >{('back to table')}
                                                    </IconTextButton>
                                                </Flex>
                                            }

                                            <Flex w={'100%'} maxh={'100%'}>
                                                <SyntaxHighlighter language="yaml" style={docco}>
                                                    {data.selectedCollectionObjectInYaml}
                                                </SyntaxHighlighter>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </S.ExactAppExactItemContainer>
                            }
                        </Flex>
                    }

                    {tab === 'logs' &&
                        <Flex w={'100%'} h={'100%'} jc={'space-between'}>
                            <S.ExactAppLogsLeftSide isRightSideOpened={logsData.selectedLog !== null}>
                                <ExactAppLogsTableHeaderWrapper isRightSideOpened={logsData.selectedLog !== null}>
                                    <Flex w={'50%'}>
                                        <Typography sx={{fontSize: '14px', fontWeight: 700, color: colors.grayText}}>{('Launch date')}</Typography>
                                    </Flex>

                                    <Flex w={'50%'}>
                                        <Typography sx={{fontSize: '14px', fontWeight: 700, color: colors.grayText}}>{('ID')}</Typography>
                                    </Flex>
                                </ExactAppLogsTableHeaderWrapper>

                                <S.AppLogsTableMainWrapper>
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={(page: number) => logsData.handleLoadMoreLogs(page)}
                                        hasMore={logsData.hasMore}
                                        initialLoad={false}
                                        threshold={50}
                                        useWindow={false}
                                        style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}
                                    >
                                        {logsData.logs.map(e => e && <ReportAppLogsTableRow key={e.id} log={e} selectedLog={logsData.selectedLog}/>)}
                                        {logsData.isLoadingList && <AppLogsTableRowSkeleton isRightSideOpened={logsData.selectedLog !== null}/>}
                                    </InfiniteScroll>
                                </S.AppLogsTableMainWrapper>
                            </S.ExactAppLogsLeftSide>

                            <S.ExactAppLogsRightSide isRightSideOpened={logsData.selectedLog !== null}>
                                <Flex w={'100%'} jc={'space-between'} h={'100%'} p={isMobile ? '0 20px 25px 20px' : '25px 20px'}>
                                    {!isMobile &&
                                        <Divider flexItem orientation={'vertical'} style={{
                                            borderColor: colors.primary.disable,
                                            backgroundColor: colors.darkText
                                        }}/>
                                    }

                                    <Flex w={'99%'} direction={'column'} overflow={'auto'}>
                                        {isMobile &&
                                            <Flex w={'100%'}>
                                                <IconTextButton
                                                    variant={'text'}
                                                    disabled={isLoading}
                                                    icon={!revDir ?
                                                        <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                        : <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}}/>
                                                    }
                                                    onClick={logsData.deselectLog}
                                                    sx={{'& .MuiButton-startIcon': {margin: !revDir ? '0 8px 0 -4px': '0 -4px 0 8px',}}}
                                                >{('back to table')}
                                                </IconTextButton>
                                            </Flex>
                                        }

                                        <Flex w={'100%'} ai={'center'} jc={'space-between'} wrap={'wrap'}>
                                            <Typography sx={{color: colors.grayText}} variant={'body1'}>{app?.name} log dated {parseDateAuto(logsData.selectedLog?.timestamp)}</Typography>
                                            <LoadingButton
                                                sx={{margin: isLessThenCustomQuery ? '16px 0 0 0' : ''}}
                                                loading={logsData.isLoadingDownload}
                                                size={'small'}
                                                startIcon={<DownloadIcon />}
                                                onClick={logsData.handleDownloadLog}
                                            >
                                                {('Download')}
                                            </LoadingButton>
                                        </Flex>
                                        {<Divider flexItem sx={{bgcolor: colors.primary.disable, borderColor: colors.primary.disable, margin: '16px 0 0 0'}}/>}
                                        {logsData.isLoadingExact && <LocalSpinner />}
                                        {logsData.selectedLog && logsData.selectedLog.size >= MAX_LOG_SIZE_PREVIEW ?
                                            <Typography sx={{margin: '16px 0 0 0', color: colors.grayText}} variant={'body2'}>The log is too big for preview. But you can download it.</Typography>
                                            :
                                            <Flex w={'100%'} m={'16px 8px'} ai={'center'}>
                                                <SyntaxHighlighter language="text" style={docco} wrapLines wrapLongLines>
                                                    {logsData.logResponse}
                                                </SyntaxHighlighter>
                                            </Flex>
                                        }
                                    </Flex>
                                </Flex>
                            </S.ExactAppLogsRightSide>
                        </Flex>
                    }
                </Box>
            </PageWrapper>

            {/*DIALOGS*/}
            {/*<PreviewErrorReason />*/}
        </Box>
    )
}