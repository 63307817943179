import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {TChangeUserInfo, TChangeUserPass, TInviteExtended} from "../types";
import {changeUserProfilePassGQL} from "../../settingsProfile/api/queries";
import {changeUserProfileInfoGQL, disableGoogleAuth, reconfigureGoogleAuthInSettings, validate2faCode} from "./query";
import {
    DisableGoogleAuthMutation,
    DisableGoogleAuthMutationVariables,
    ReconfigureGoogleAuthInSettingsMutation,
    ReconfigureGoogleAuthInSettingsMutationVariables,
    Validate2faCodeMutation,
    Validate2faCodeMutationVariables
} from "../../../../newShared/GQLTypes";

export const getInvites = async (): Promise<TInviteExtended[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getInvites{
                  getInvites{
                    type
                    id
                    inviteStatus
                    role
                    expirationDate
                    activationDate
                    creationDate
                    organizationName
                    emailAddress
                    sender{
                      role
                      publicId
                      firstName
                      lastName
                      email
                    }
                  }
                }
                `,
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getInvites;
    })
)

export const acceptInvite = async (inviteId: string, type: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation acceptInvite($inviteId:String!, $type:String!){
                  acceptInvite(inviteId:$inviteId, type:$type){
                    message
                  }
                }
                `,
                variables: {
                    inviteId,
                    type
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.acceptInvite.message;
    })
)

export const declineInvite = async (inviteId: string, type: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation declineInvite($inviteId:String!, $type:String!){
                  declineInvite(inviteId:$inviteId, type:$type){
                    message
                  }
                }
                `,
                variables: {
                    inviteId,
                    type,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.declineInvite.message;
    })
)

//user profile

export const changeCurrentUserInfo = async (data: TChangeUserInfo) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: changeUserProfileInfoGQL,
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeName as {message: string}
    })
)


//SECURITY
export const changeCurrentUserPass = async (data: TChangeUserPass) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: changeUserProfilePassGQL,
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changePassword as {message: string}
    })
)

export const validate2faCodeApi = async (data: Validate2faCodeMutationVariables): Promise<Validate2faCodeMutation["initialValidateGoogleAuthorization"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: validate2faCode,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.initialValidateGoogleAuthorization
    })
)

export const reconfigureGoogleAuthApi = async (data: ReconfigureGoogleAuthInSettingsMutationVariables): Promise<ReconfigureGoogleAuthInSettingsMutation["reconfigureGoogleAuth"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: reconfigureGoogleAuthInSettings,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.reconfigureGoogleAuth
    })
)

export const disableGoogleAuthApi = async (data: DisableGoogleAuthMutationVariables): Promise<DisableGoogleAuthMutation["disableGoogleAuth"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: disableGoogleAuth,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.disableGoogleAuth
    })
)