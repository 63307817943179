import {print} from "graphql";
import {gql} from "@apollo/react-hooks";

export const changeName = print(gql`
    mutation changeName($data: ChangeNameInput!) {
        changeName(data: $data) {
            message
        }
    }
`)

export const changePassword = print(gql`
    mutation changePassword($data: ChangePasswordInput!) {
        changePassword(data: $data) {
            message
        }
    }
`)