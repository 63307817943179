import {CaseReducer} from "@reduxjs/toolkit/src/createReducer";
import {PayloadAction} from "@reduxjs/toolkit/src/createAction";
import {Draft} from "@reduxjs/toolkit";

type TAddSetDialogAction<TState extends {dialogs: Record<string, Record<string, any>>}, TFieldName extends Exclude<keyof TState["dialogs"], number | symbol>> = CaseReducer<TState, PayloadAction<Partial<TState["dialogs"][TFieldName]> & {clear?: boolean}>>;

export const addSetDialogAction =
    <TState extends {dialogs: Record<string, Record<string, any>>}, TFieldName extends Exclude<keyof TState["dialogs"], number | symbol>>(
        dialogField: TFieldName, initialState: TState,
    ) => {
        const actionName = `set${dialogField.charAt(0).toUpperCase() + dialogField.slice(1)}Action` as `set${Capitalize<TFieldName>}Action`;

        const action: TAddSetDialogAction<TState, TFieldName> = ((slice, {payload}) => {
            Object.keys(payload).forEach(key => {
                if (payload[key] !== undefined) slice.dialogs[dialogField][key] = payload[key];
            })

            if (payload.clear) slice.dialogs[dialogField] = (initialState as Draft<TState>).dialogs[dialogField];
        });

        return {[actionName]: action} as {
            [key in typeof actionName]: TAddSetDialogAction<TState, TFieldName>
        }
    }