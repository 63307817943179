import {messageDialogSelector, setMessageDialogAction, TMessageDialogSlice} from "../store/store";
import {useDispatch, useSelector} from "react-redux";
import {TMessage} from "../types";

type TResponse = {
    message: TMessageDialogSlice["message"],
    isOpenMessageDialog: boolean,
    setMessage: (message: TMessage | null) => void,
    handleClose: () => void,
}
export const useMessageDialog = (): TResponse => {
    const dispatch = useDispatch();

    const message = useSelector(messageDialogSelector);

    const setMessage = (message: TMessage | null) => {dispatch(setMessageDialogAction(message))};
    const handleClose = () => {dispatch(setMessageDialogAction(null))};

    return {
        message,
        isOpenMessageDialog: !!message,
        setMessage,
        handleClose,
    }
}