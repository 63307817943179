import {createAsyncThunk} from "@reduxjs/toolkit";
import {TWithOptions} from "../../../../../newShared/types";
import {
    ChangeStatusNcrByAuditorMutationVariables,
    CreateNcrMutationVariables,
    DeleteNcrMutationVariables,
    UpdateNcrMutationVariables
} from "../../../../../newShared/GQLTypes";
import {changeStatusNCRByAuditorApi, createNcrApi, deleteNCRApi, updateNCRApi} from "../../api";
import {SEND_UPDATE_REVIEWED_CONTROL_ACTION} from "../../actions";

export const createNcrAction = createAsyncThunk(
    'AuditSpace/NCR/createNcr',
    async (data: TWithOptions<CreateNcrMutationVariables>) => {

        const resp = await createNcrApi({...data.data}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const updateNCRAction = createAsyncThunk(
    'AuditSpace/NCR/updateNCR',
    async (data: TWithOptions<UpdateNcrMutationVariables>) => {

        const resp = await updateNCRApi({...data.data}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const changeStatusNCRByAuditorAction = createAsyncThunk(
    'AuditSpace/NCR/changeStatusNCRByAuditor',
    async (data: TWithOptions<ChangeStatusNcrByAuditorMutationVariables>, {dispatch}) => {

        const resp = await changeStatusNCRByAuditorApi({...data.data, workspaceId: data.data.workspaceId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)

        switch (data.data.status) {
            case "REVIEW": {
                dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION({controlId: data.data.controlId, reportId: data.data.reportId, reviewed: false}));
                break;
            }
            case "OPEN": {
                dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION({controlId: data.data.controlId, reportId: data.data.reportId, reviewed: true}));
                break;
            }
            case "REVOKE": {
                dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION({controlId: data.data.controlId, reportId: data.data.reportId, reviewed: true}));
                break;
            }
            case "APPROVED": {
                dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION({controlId: data.data.controlId, reportId: data.data.reportId, reviewed: true}));
                break;
            }
            case "REJECTED": {
                dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION({controlId: data.data.controlId, reportId: data.data.reportId, reviewed: true}));
                break;
            }
        }

        return {
            data: data.data,
            resp
        };
    }
);

export const deleteNCRAction = createAsyncThunk(
    'AuditSpace/NCR/deleteNCR',
    async (data: TWithOptions<DeleteNcrMutationVariables>) => {

        const resp = await deleteNCRApi({...data.data}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);