import {gql} from "graphql.macro";

export const WORKSPACE_SUBSCRIPTION_GQL = gql`
    subscription subscribeToWorkspace($workspaceId:String!, $organizationId:String!, $token: String!, $publicId: String!){
        subscribeToWorkspace(workspaceId:$workspaceId, organizationId:$organizationId, token: $token, publicId: $publicId){
            type
            publicId
            workspaceId
            organizationId
            payload
        }
    }
`;

export const PUBLIC_ID_SUBSCRIPTION_GQL = gql`
    subscription subscribeToPublicId($publicId: String!, $token: String!){
        subscribeToPublicId(publicId: $publicId, token: $token){
            type
            publicId
            workspaceId
            organizationId
            payload
        }
    }
`;