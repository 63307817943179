import {extendedOrganization, organizationSubInitialState, settingsInitialState, TInviteExtended,} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    AcceptInvite,
    ChangeCurrentUserInfo,
    ChangeCurrentUserPassword,
    DeclineInvite,
    disableGoogleAuthAction,
    GetInvites,
    reconfigureGoogleAuthAction,
    validate2faCodeAction
} from "./actions";

export const initialSubOrganizationsState: organizationSubInitialState = {
    loadings: {
        organizations: {
            exact: false,
            list: false,
            create: false,
            _delete: false,
            changeName: false,
            changeLogo: false,
            //
            inviteUser: false,
            changeCollabRole: {
                isLoading: false,
                prevRole: null,
                publicId: null,
            },
            changeInviteRole: {
                isLoading: false,
                prevRole: null,
                email: null,
            },
            resendInvite: {
                isLoading: false,
                email: null
            },
            cancelInvite: {
                isLoading: false,
                email: null
            },
            sendInvite: false,
            deleteCollab: false,
            transferOwnership: false,
        },
        workspaces: {
            list: false,
            exact: false,
            create: false,
            _delete: false,
            changeName: false,
            //
            inviteUser: false,
            changeCollabRole: {
                isLoading: false,
                prevRole: null,
                publicId: null,
            },
            changeInviteRole: {
                isLoading: false,
                prevRole: null,
                email: null,
            },
            resendInvite: {
                isLoading: false,
                email: null
            },
            cancelInvite: {
                isLoading: false,
                email: null
            },
            sendInvite: false,
            deleteCollab: false,
        }
    },
    dialogs: {
        organizations: {
            deleteCollaboratorDialog: {
                collab: null,
                isOpen: false,
            },
            transferOwnershipDialog: {
                collab: null,
                isOpen: false,
            },
            deleteOrganization: false,
            changeName: false,
            changeLogo: false,
            cancelInvite: false,

        },
        workspaces: {
            userNotFound: {
                isOpen: false,
                email: null,
            },
            deleteCollaboratorDialog: {
                collab: null,
                isOpen: false,
            },
            deleteWorkspace: false,
            createWorkspace: false,
            cancelInvite: false,
        }
    },
    organizations: [],
    organizationsPageInfo: {
        page: 0,
        count: 0,
        total: 0
    },
    selectedOrganization: null,
    workspaces: [],
    workspacesPageInfo: {
        page: 0,
        count: 0,
        total: 0
    },
    selectedWorkspace: null,
}

export const initialState: settingsInitialState = {
    invitesCounter: 0,
    selectWorkspaceDialog: false,
    organizations: initialSubOrganizationsState,
    invites: {
        loading: {
            list: false,
            accept: {
                isLoading: false,
                inviteId: null
            },
            decline: {
                isLoading: false,
                inviteId: null
            },
        },
        invites: [],
        dialogs: {
            declineInvite: {
                isOpen: false,
                invite: null
            },
        }
    },
    profile: {
        loadings: {
            isLoadingUpdateProfile: false,
        }
    },
    security: {
        loadings: {
            isLoadingInitiateReconfigure: false, // returns secret
            isLoadingInitiateConfigure: false, // returns secret
            isLoadingConfigure: false, //returns recovery codes,
            isLoadingChangePassword: false,
            isLoadingDisable: false
        },
        recoveryCodes: [], //saving after fetch
        secret: null, //saving after fetch,
        qrCode: null
    },
    language: {
        //for future fetch update language
    },
    forbiddenWrapper: {
        workspaces: [], //
        organizations: [],
        is403: false, //when axios will receive 403 in marked endpoint - we will show 403 page instead of current page
        is401: false, //handling 401 to save current path in localStorage
        isLoading: false, //loading short workspaces/organizations
    },
    cookieData: null //data stored in cookies (pulling on init + updating by selecting workspace)
}

export const SettingSlice = createSlice({
    name: 'Settings',
    initialState,
    reducers: {
        setSelectedOrganization: (slice, {payload}: {payload: extendedOrganization}) => {
            slice.organizations.selectedOrganization = payload;
        },

        openDeclineInviteDialog: (slice, {payload}: {payload: TInviteExtended}) => {
            slice.invites.dialogs.declineInvite = {invite: payload, isOpen: true};
        },
        hideDeclineInviteDialog: (slice) => {slice.invites.dialogs.declineInvite = {invite: null, isOpen: false}},
    },
    extraReducers: builder => {
        builder
            .addCase(GetInvites.pending, (slice) => {
                slice.invites.loading.list = true;
            })
            .addCase(GetInvites.rejected, (slice) => {
                slice.invites.loading.list = false;
            })
            .addCase(GetInvites.fulfilled, (slice, {payload}) => {
                slice.invites.loading.list = false;
                slice.invites.invites = payload;
            })
        //AcceptInvite
            .addCase(AcceptInvite.pending, (slice) => {
                slice.invites.loading.accept.isLoading = true;
            })
            .addCase(AcceptInvite.rejected, (slice) => {
                slice.invites.loading.accept.isLoading = false;
                slice.invites.loading.accept.inviteId = null;
            })
            .addCase(AcceptInvite.fulfilled, (slice, {payload}) => {
                slice.invites.loading.accept.isLoading = false;
                slice.invites.loading.accept.inviteId = null;
                slice.invites.invites = slice.invites.invites.filter(e => e.id !== payload);
            })
        //DeclineInvite
            .addCase(DeclineInvite.pending, (slice) => {
                slice.invites.loading.decline.isLoading = true;
            })
            .addCase(DeclineInvite.rejected, (slice) => {
                slice.invites.loading.decline.isLoading = false;
                slice.invites.loading.accept.inviteId = null;
            })
            .addCase(DeclineInvite.fulfilled, (slice, {payload}) => {
                slice.invites.loading.decline.isLoading = false;
                slice.invites.loading.accept.inviteId = null;
                slice.invites.dialogs.declineInvite = {isOpen: false, invite: null};
                slice.invites.invites = slice.invites.invites.filter(e => e.id !== payload);
            })
        //ChangeCurrentUserInfo
            .addCase(ChangeCurrentUserInfo.pending, (slice) => {
                slice.profile.loadings.isLoadingUpdateProfile = true;
            })
            .addCase(ChangeCurrentUserInfo.rejected, (slice) => {
                slice.profile.loadings.isLoadingUpdateProfile = false;
            })
            .addCase(ChangeCurrentUserInfo.fulfilled, (slice, {payload}) => {
                slice.profile.loadings.isLoadingUpdateProfile = false;
            })
        //ChangeCurrentUserPassword
            .addCase(ChangeCurrentUserPassword.pending, (slice) => {
                slice.security.loadings.isLoadingChangePassword = true;
            })
            .addCase(ChangeCurrentUserPassword.rejected, (slice) => {
                slice.security.loadings.isLoadingChangePassword = false;
            })
            .addCase(ChangeCurrentUserPassword.fulfilled, (slice, {payload}) => {
                slice.security.loadings.isLoadingChangePassword = false;
            })
        //
            .addCase(reconfigureGoogleAuthAction.pending, (slice) => {
                slice.security.loadings.isLoadingInitiateReconfigure = true;
                // slice.app2fa.isGettingSecret = true;
            })
            .addCase(reconfigureGoogleAuthAction.rejected, (slice) => {
                // slice.app2fa.isGettingSecret = false;
                slice.security.loadings.isLoadingInitiateReconfigure = false;

            })
            .addCase(reconfigureGoogleAuthAction.fulfilled, (slice, {payload}) => {
                slice.security.loadings.isLoadingInitiateReconfigure = false;
                slice.security.secret = payload.secret;
                slice.security.qrCode = payload.qrCode;
            })
        //validate2faCodeAction
            .addCase(validate2faCodeAction.pending, (slice) => {
                slice.security.loadings.isLoadingConfigure = true;
            })
            .addCase(validate2faCodeAction.rejected, (slice) => {
                slice.security.loadings.isLoadingConfigure = false;
            })
            .addCase(validate2faCodeAction.fulfilled, (slice, {payload}) => {
                slice.security.loadings.isLoadingConfigure = false;
                slice.security.recoveryCodes = payload;
            })

            .addCase(disableGoogleAuthAction.pending, (slice) => {
                slice.security.loadings.isLoadingDisable = true;
            })
            .addCase(disableGoogleAuthAction.rejected, (slice) => {
                slice.security.loadings.isLoadingDisable = false;
            })
            .addCase(disableGoogleAuthAction.fulfilled, (slice, {payload}) => {
                slice.security.loadings.isLoadingDisable = false;
            })
    }
});

export const SettingsReducer = SettingSlice.reducer;

export const {
    //orgs
    setSelectedOrganization,
    //invites
    openDeclineInviteDialog,
    hideDeclineInviteDialog,

    //language

    //security
} = SettingSlice.actions;

const selectSelf = (state: AppState):settingsInitialState => state.Settings as settingsInitialState;

export const invitesCounter = createSelector(selectSelf, state => state.invitesCounter);
export const selectWorkspaceDialog = createSelector(selectSelf, state => state.selectWorkspaceDialog);

//orgs
export const organizationsDialogs = createSelector(selectSelf, state => state.organizations.dialogs.organizations);
export const organizationsLoadings = createSelector(selectSelf, state => state.organizations.loadings.organizations);
export const organizationPageInfo = createSelector(selectSelf, state => state.organizations.organizationsPageInfo);
export const organizations = createSelector(selectSelf, state => state.organizations.organizations);
export const selectedOrganization = createSelector(selectSelf, state => state.organizations.selectedOrganization);


export const workspacesDialogs = createSelector(selectSelf, state => state.organizations.dialogs.workspaces);
export const workspacesLoadings = createSelector(selectSelf, state => state.organizations.loadings.workspaces);
export const workspacesPageInfo = createSelector(selectSelf, state => state.organizations.workspacesPageInfo);
export const workspaces = createSelector(selectSelf, state => state.organizations.workspaces);
export const selectedWorkspace = createSelector(selectSelf, state => state.organizations.selectedWorkspace);


//invites
export const invites = createSelector(selectSelf, state => state.invites.invites);
export const invitesDialogs = createSelector(selectSelf, state => state.invites.dialogs);
export const invitesLoadings = createSelector(selectSelf, state => state.invites.loading);

//profile
export const profileLoadings = createSelector(selectSelf, state => state.profile.loadings);

//security
export const securityLoadings = createSelector(selectSelf, state => state.security.loadings);
export const securitySecret = createSelector(selectSelf, state => state.security.secret);
export const qrCode = createSelector(selectSelf, state => state.security.qrCode);
export const recoveryCodes = createSelector(selectSelf, state => state.security.recoveryCodes);

//forbidden wrapper
export const forbiddenWrapperOrganizations = createSelector(selectSelf, state => state.forbiddenWrapper.organizations);
export const forbiddenWrapperIsLoading = createSelector(selectSelf, state => state.forbiddenWrapper.isLoading);
export const is403 = createSelector(selectSelf, state => state.forbiddenWrapper.is403);
export const is401 = createSelector(selectSelf, state => state.forbiddenWrapper.is401);




