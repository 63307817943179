import React, {useCallback, useEffect, useMemo, useState} from "react";
import debounce from "lodash.debounce";
import {filterType, genericFiltersArrayPropsTypes} from "../../types";
import {useDispatch, useSelector} from "react-redux";
import {
    currentFilterSelector,
    handleDeselectVisibleFilterAction,
    handleReplaceFilters,
    handleSelectVisibleFilterAction,
    handleSetCurrentFiltersForFetch,
    handleSetSearch,
    visibleFiltersSelector
} from "../../store/slice";
import {GENERAL_FILTER_DEBOUNCE} from "../../constants";

export const useGenericFiltersArray = ({fetchResultWithSelectedFilters, configs, id}: genericFiltersArrayPropsTypes) => {
    const dispatch = useDispatch();
    const _visibleFilters = useSelector(visibleFiltersSelector);

    // useEffect(() => {
    //     console.log(`useGenericFiltersArray ${id} visibleFilters updated`, _visibleFilters, configs)
    // }, [_visibleFilters]);
    //
    // const propsVisibleFilters = configs.filter(e => e.default).map(e => {return {name: e.name, key: e.key}});
    // if(!_visibleFilters.length) {
    //     console.log(`_visibleFilters in store is empty, replacing with new config`, configs, propsVisibleFilters)
    //     dispatch(handleReplaceFilters(propsVisibleFilters)); //setting defaults into store if not filled before
    // }
    const propsVisibleFilters = configs.filter(e => e.default).map(e => {return {name: e.name, key: e.key}});
    const currentFilter = useSelector(currentFilterSelector);
    const [visibleFilters, setVisibleFilters] = useState<filterType[]>(_visibleFilters.length > 0 ? _visibleFilters : propsVisibleFilters);
    const [filters, setFilters] = useState<Record<string, string[]>>(currentFilter.filters ?? {});
    const controller = new AbortController();

    useEffect(() => {
        //when filter init - replacing
        dispatch(handleReplaceFilters(propsVisibleFilters)); //setting defaults into store if not filled before
        return () => {
            controller.abort();
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!currentFilter.search.length){
            setSearch('')
        }
    }, [currentFilter.search]);

    useEffect(() => {
        setVisibleFilters(_visibleFilters.length > 0 ? _visibleFilters : propsVisibleFilters);
        //eslint-disable-next-line
    }, [configs]);


    const handleSelectVisibleFilter = (filter: filterType) => {
        setVisibleFilters([filter, ...visibleFilters]);
        dispatch(handleSelectVisibleFilterAction(filter));
    }

    const handleDeselectVisibleFilter = (filter: filterType) => {
        setVisibleFilters(visibleFilters.filter(e => e.key !== filter.key));
        const newFilters = {...filters, [filter.key]: []};
        sendRequest(search, newFilters); //fetching without removed filter
        dispatch(handleDeselectVisibleFilterAction(filter));
    }

    const handleResetFilters = () => {
        const initialVisibleFilters = configs.filter(e => e.default).map(e => {return {name: e.name, key: e.key}});
        setVisibleFilters(initialVisibleFilters);
        dispatch(handleReplaceFilters(initialVisibleFilters))
    }

    const updateFilterAndFetch = (filterKey: string, filterValues: string[]) => {
        const newFilters = {...filters, [filterKey]: filterValues};
        setFilters(newFilters);
        dispatch(handleSetCurrentFiltersForFetch(newFilters));
        debouncedSendRequest(search, newFilters);
    }

    //search
    const [search, setSearch] = useState<string>(currentFilter.search ?? '');

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value as string;
        setSearch(value);
        dispatch(handleSetSearch(value));
        debouncedSendRequest(value, filters);
    }

    const sendRequest = useCallback((value: string, _filters: Record<string, string[]>) => {
        controller.abort();

        const filters: Record<string, string[]> = {};
        Object.keys(_filters).forEach(key => {
            const filter = _filters[key].filter(e => e);
            if(filter.length > 0) {
                filters[key] = filter;
            }
        }); //removing empty filters

        console.log(`useGenericFiltersArray ${id} sendRequest`, value, _filters, filters)

        fetchResultWithSelectedFilters(value, filters, controller.signal);
        //eslint-disable-next-line
    }, [fetchResultWithSelectedFilters]);

    const debouncedSendRequest = useMemo(() => {
        controller.abort();
        return debounce(sendRequest, GENERAL_FILTER_DEBOUNCE);
        //eslint-disable-next-line
    }, [sendRequest]);

    return {
        visibleFilters,
        handleResetFilters,
        handleDeselectVisibleFilter,
        handleSelectVisibleFilter,
        updateFilterAndFetch,
        search: {
            value: search,
            handleChangeSearch
        },
    }
}