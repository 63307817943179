//types
import {TMenuItem} from "./types";

//icons
import {ReactComponent as ReportsIcon} from "../../../newShared/images/menu/svg-optimized/chart-box-outline.svg";
//
import {PATH_LOCAL_SPACE} from "../../../newShared/constants";


// const reports: TMenuItem = {title: 'Reports', path: PATH_LOCAL_SPACE_REPORTS, child: []};
const auditSpace: TMenuItem = {title: 'Audit space', path: PATH_LOCAL_SPACE, icon: ReportsIcon, child: []};

export const leftMenuItems: TMenuItem[] = [
    auditSpace
]