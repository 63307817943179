import {
    leftMenuIsOpenSelector,
    leftMenuPinnedItemsSelector,
    setOpenMenuAction,
    setPinnedMenuItemsAction,
    TLeftMenuSlice
} from "../../store/slice";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../../../../newShared/redux";
import {leftMenuItems} from "../../constants";

export const useLeftMenu = () => {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(leftMenuIsOpenSelector);
    const items = leftMenuItems;
    const pinnedItems = useSelector(leftMenuPinnedItemsSelector);

    const setOpen = (data: TLeftMenuSlice["isOpen"] = !isOpen) => {dispatch(setOpenMenuAction(data))}
    const setPinnedItems = (data: TLeftMenuSlice["pinnedItems"]) => {dispatch(setPinnedMenuItemsAction(data))}

    return {
        isOpen,
        pinnedItems,
        items,

        setOpen,
        setPinnedItems,
    }
}