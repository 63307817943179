import {Dialog, TablePagination} from "@mui/material";
import {FC} from "react";
import {SharedPaginationInput} from "../../../../../../newShared/GQLTypes";
import {TAssetDataCOLLECTOR} from "../../../types";
import {TPageInfo} from "../../../../../../newShared/types";
import {Spinner} from "../../../../../../newShared/components/Spinner";
import {TextTable} from "../../../../../../newShared/components/TextTable";

type PropsType = {
    withPagination?: boolean,
    handleClose: () => void,
    data: TAssetDataCOLLECTOR,
    tableFields: string[],
    getData: (pageInfo: SharedPaginationInput) => void,
    pageInfo: TPageInfo,
    isOpen: boolean,
    isLoading: boolean,
}
export const TableAssetDialog: FC<PropsType> = ({data, getData, withPagination, handleClose, isOpen, isLoading, tableFields, pageInfo}) => {
    const {page, count, total} = pageInfo;

    return (
        <Dialog onClose={handleClose}
                open={isOpen}
                PaperProps={{
                    style:{
                        padding: '20px',
                        minWidth: '80%',
                        borderRadius: '25px',
                        maxHeight: '80vh',
                    }
                }}
                maxWidth={"xl"}>
            {isLoading && <Spinner/>}

            <TextTable data={data} keys={tableFields}/>
            {withPagination && (
                <TablePagination count={total} page={page} rowsPerPage={count}
                                 onPageChange={(e, page) => {
                                     withPagination && getData({page, count, sort: ''})
                                 }}
                                 onRowsPerPageChange={(e) => {
                                     withPagination && getData({page: 0, count: Number(e.target.value), sort: ''})
                                 }}
                                 component={'div'}
                                 style={{borderBottom: 'none', marginTop: 5}}
                                 rowsPerPageOptions={[5,10,20,50]}
                />
            )}
        </Dialog>
    )
}