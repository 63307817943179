import colors from "../../../newShared/theme/colors";
import {AvailableAppConfig} from "../../../newShared/GQLTypes";

export const getReportStatus = (t: (str: string) => string, status?: string): {color: string, status: string} => {
    switch (status) {
        case 'IN_REVIEW': {return {color: colors.decorative.blue_light, status: t('In review')}}
        case 'PUBLISHED': {return {color: colors.decorative.orange, status: t('NCR Published')}}
        case 'RESPONSE': {return {color: colors.decorative.violet, status: t('NCR Response')}}
        case 'COMPLETED': {return {color: colors.text.success, status: t('Completed')}}
        case 'REJECTED': {return {color: colors.warningActions.red, status: t('Rejected')}}
        default: {return {color: colors.text.dark, status: status || ''}}
    }

}

export const getReportType = (t: (str: string) => string, type?: string): string => {
    switch (type) {
        case 'SNAPSHOT': {return t('Snapshot')}
        case 'LIVE_LITE': {return t('Live (lite view)')}
        case 'LIVE_FULL': {return t('Live (full view)')}
        default: {return type || ''}
    }
}

export const getNCRStatus = (t: (str: string) => string, status?: string | null | (string | null | undefined)[]): {color: string, status: string} => {
    if (typeof status === 'string' || !status) {
        switch (status) {
            case 'REVIEW': {return {color: colors.decorative.orange, status: t('Review')}}
            case 'OPEN': {return {color: colors.text.blue, status: t('Open')}}
            case 'REVOKE': {return {color: colors.text.warning, status: t('Revoked')}}
            case 'APPROVED': {return {color: colors.text.success, status: t('Approved')}}
            case 'REJECTED': {return {color: colors.text.critical, status: t('Rejected')}}
            default: {return {color: colors.text.dark, status: status || '---'}}
        }
    } else {
        const statuses = status.filter(e => e);
        if (statuses.includes('REVIEW')) {return {color: colors.decorative.orange, status: t('Review')}}
        else if (statuses.includes('OPEN')) {return {color: colors.text.blue, status: t('Open')}}
        else if (statuses.includes('REVOKE')) {return {color: colors.text.warning, status: t('Revoked')}}
        else if (statuses.includes('APPROVED')) {return {color: colors.text.success, status: t('Approved')}}
        else if (statuses.includes('REJECTED')) {return {color: colors.text.critical, status: t('Rejected')}}
        else {return {color: colors.text.dark, status: '---'}}
    }
}

export const getNormalizedStatus = (status: string): {status: string, color: string} => {
    switch (status) {
        case "COMPLETED":
            return {status: 'Completed', color: colors.text.success};
        case "FAILED":
            return {status: 'Failed', color: colors.text.critical};
        case "Inactive":
            return {status: 'Inactive', color: ''};
        case "IN_PROGRESS":
            return {status: 'In progress', color: colors.decorative.orange};
        //app history status
        case "SUCCESS": return {status: 'Success', color: colors.text.success};
        default: return {status: 'status not found', color: 'black'}
    }
}

export const rawDataValueGetter = (object: { [key: string]: string }, config: AvailableAppConfig | null | undefined, collectionAlias: string, index: number): string => {

    if(!config || !config.collectionConfigs){
        // console.log(`Config or config.collectionConfigs is null`);
        return 'Representation not found! Config is null.';
    }
    const currConfig = config.collectionConfigs.find(e => e.alias === collectionAlias);
    // console.log(`currConfig: ${JSON.stringify(currConfig)}`)
    if(currConfig){
        let obj: any = object;
        const splittedGetter = currConfig.data[index].split('.');
        splittedGetter.forEach(e => {
            try{
                obj = obj[e];
                // console.log(`${e} => ${JSON.stringify(obj)}`);
            }catch (ex){
                console.log(`ERROR: ${e} => ${JSON.stringify(obj)}`);
            }
        })
        return obj ?? 'Field not found.';
    }
    return 'currConfig not found.';
}