import {Flex} from "../Layouts"
import {Typography} from "@mui/material";
import colors from "../../theme/colors";
import {TPageInfo} from "../../types";

export type DataHasBeenUpdatedFlagPropsType = {
    show: boolean,
    onClick: () => void,
    startMessage?: string;
    endMessage?: string;
}

export const getDataForRefresh = (pageInfo: TPageInfo): {page: number, count: number} => {
    //When reloading data - fetching all before fetched data
    //if user on page 1 with count 20 - loading 40
    return {
        page: 0,
        count: (pageInfo.page + 1) * pageInfo.count
    }
}

export const DataHasBeenUpdatedFlag = ({show, onClick, startMessage = 'The data has been updated.', endMessage = 'to see the latest data.'}: DataHasBeenUpdatedFlagPropsType) => {
    if(show){
        return(
            <Flex w={'100%'} gap={'4px'} ai={'center'} background={colors.backgrounds.warning}  p={'6px 16px'} m={'0 0 16px 0'}>
                <Typography variant={'body2'} color={colors.text.grey_dark}>{startMessage}</Typography>
                <Typography
                    variant={'body2'}
                    color={colors.primary.blue}
                    sx={{cursor: 'pointer'}}
                    fontWeight={500}
                    onClick={onClick}>
                    {('Click here')}
                </Typography>
                <Typography variant={'body2'} color={colors.text.grey_dark} sx={{fontSize: '14px !important'}}>{endMessage}</Typography>
            </Flex>
        )
    }else{
        return <></>;
    }
}
