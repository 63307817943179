import {
    chooseWorkspaceInitState, TOrganizationWithWorkspaces,
} from "../../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {
    CreateWorkspace,
    GetOrganizationsWithWorkspacesWithPaging, GetWorkspacesByOrgIdWithPaging, JoinWorkspaceByOrgAdmin,
} from "./actions";
import {WORKSPACE_ADMIN} from "../../constants";
import { AppState } from "../../../../../newShared/redux/rootReducer";

const initialState: chooseWorkspaceInitState = {
    allOrganizations: [],

    invitesCounter: 0,

    orgPageInfo: {
        page: 0,
        count: 0,
        total: 0,
        sort: ''
    },

    isLoadingOrganizations: false,
    isLoadingWorkspaces: false,
    isLoadingAddWorkspace: false,
    isLoadingJoinWorkspace: {
        isLoading: false,
        workspaceId: null,
        organizationId: null,
        publicId: null,
    },
    addWorkspaceDialog: {
        isOpen: false,
        organizationId: null,
        availableWorkspaces: 0,
        usedWorkspaces: 0,
    },
    workspaceNotSelectedDialog: {
        isOpen: false,
    }
}

export const ChooseWorkspaceSlice = createSlice({
    name: 'ChooseWorkspace',
    initialState,
    reducers: {
        openAddWorkspace: (state, {payload}: {payload: {organizationId: string, availableWorkspaces: number, usedWorkspaces: number}}) => {
            state.addWorkspaceDialog = {isOpen: true, organizationId: payload.organizationId, availableWorkspaces: payload.availableWorkspaces, usedWorkspaces: payload.usedWorkspaces};
        },
        hideAddWorkspace: (state) => {
            state.addWorkspaceDialog = {isOpen: false, organizationId: null, usedWorkspaces: 0, availableWorkspaces: 0};
        },
        setIsLoadingJoinWorkspace: (state, {payload}: {payload: {publicId: string, workspaceId: string, organizationId: string}}) => {
            state.isLoadingJoinWorkspace = {
                isLoading: true,
                publicId: payload.publicId,
                workspaceId: payload.workspaceId,
                organizationId: payload.organizationId
            }
        },
        openWorkspaceNotSelectedDialog: (state) => {state.workspaceNotSelectedDialog.isOpen = true},
        hideWorkspaceNotSelectedDialog: (state) => {state.workspaceNotSelectedDialog.isOpen = false},
        cleanUp: () => initialState,

    },
    extraReducers: builder => {
        builder
            .addCase(GetOrganizationsWithWorkspacesWithPaging.pending, (slice) => {
                slice.isLoadingOrganizations = true;
            })
            .addCase(GetOrganizationsWithWorkspacesWithPaging.rejected, (slice) => {
                slice.isLoadingOrganizations = false;
            })
            .addCase(GetOrganizationsWithWorkspacesWithPaging.fulfilled, (slice, {payload}) => {
                slice.isLoadingOrganizations = false;
                slice.allOrganizations = payload.organizations;
                slice.orgPageInfo = payload.pageInfo;
                slice.invitesCounter = payload.invitesCounter;
            })
        // GetWorkspacesWithPaginationByOrganizationId
            .addCase(GetWorkspacesByOrgIdWithPaging.pending, (slice) => {
                slice.isLoadingWorkspaces = true;
            })
            .addCase(GetWorkspacesByOrgIdWithPaging.rejected, (slice) => {
                slice.isLoadingWorkspaces = false;
            })
            .addCase(GetWorkspacesByOrgIdWithPaging.fulfilled, (slice, {payload}) => {
                slice.isLoadingWorkspaces = false;
                slice.allOrganizations = slice.allOrganizations.map((e: TOrganizationWithWorkspaces) => e.id === payload.organizationId ? {...e, workspaces: payload.workspaces, pageInfo: payload.pageInfo} : e);
                //updating workspaces and pageInfo inside of selected organization
            })
        //CreateWorkspace
            .addCase(CreateWorkspace.pending, (slice) => {
                slice.isLoadingAddWorkspace = true;
            })
            .addCase(CreateWorkspace.rejected, (slice) => {
                slice.isLoadingAddWorkspace = false;
            })
            .addCase(CreateWorkspace.fulfilled, (slice, {payload}) => {
                slice.isLoadingAddWorkspace = false;
                slice.addWorkspaceDialog = {
                    isOpen: false,
                    organizationId: null,
                    availableWorkspaces: 0,
                    usedWorkspaces: 0,
                };
                slice.allOrganizations = slice.allOrganizations.map(e => {
                    if(e.id === payload.organizationId){
                        e.workspaces.push(payload);
                        e.pageInfo.total = e.pageInfo.total + 1;
                        return e;
                    }
                    return e;
                })
                // slice.allOrganizations = slice.allOrganizations.map((e: TOrganizationWithWorkspaces) => e.id === payload.organizationId ? {...e, workspaces: [payload, ...e.workspaces]} : e);
            })
        //JoinWorkspaceByOrgAdmin
            .addCase(JoinWorkspaceByOrgAdmin.pending, (slice) => {
                slice.isLoadingJoinWorkspace.isLoading = true;
            })
            .addCase(JoinWorkspaceByOrgAdmin.rejected, (slice) => {
                slice.isLoadingJoinWorkspace.isLoading = false;
            })
            .addCase(JoinWorkspaceByOrgAdmin.fulfilled, (slice, {payload}) => {
                if(slice.isLoadingJoinWorkspace.publicId && slice.isLoadingJoinWorkspace.workspaceId && slice.isLoadingJoinWorkspace.organizationId){
                    slice.allOrganizations = slice.allOrganizations.map(e => {
                        if(e.id === slice.isLoadingJoinWorkspace.organizationId){
                            e.workspaces.map(w => {
                                if(w.id === slice.isLoadingJoinWorkspace.workspaceId){
                                    w.collaborators = [...w.collaborators, {publicId: slice.isLoadingJoinWorkspace.publicId!, role: WORKSPACE_ADMIN}]
                                }
                                return w;
                            })
                        }
                        return e;
                    })
                }

                slice.isLoadingJoinWorkspace = {
                    isLoading: false,
                    publicId: null,
                    workspaceId: null,
                    organizationId: null
                }
                //adding current user to collabs of workspace
            })
    }
});

export const ChooseWorkspaceReducer = ChooseWorkspaceSlice.reducer;

export const {
    openAddWorkspace,
    hideAddWorkspace,
    cleanUp,
    setIsLoadingJoinWorkspace,
    openWorkspaceNotSelectedDialog,
    hideWorkspaceNotSelectedDialog,
} = ChooseWorkspaceSlice.actions;
//ChooseWorkspace
const selectSelf = (state: AppState): chooseWorkspaceInitState => state.ChooseWorkspace as chooseWorkspaceInitState;

export const isLoadingOrganizations = createSelector(selectSelf, state => state.isLoadingOrganizations);
export const isLoadingWorkspaces = createSelector(selectSelf, state => state.isLoadingWorkspaces);
export const isLoadingAddWorkspace = createSelector(selectSelf, state => state.isLoadingAddWorkspace);

export const allOrganizations = createSelector(selectSelf, state => state.allOrganizations);

export const orgPageInfo = createSelector(selectSelf, state => state.orgPageInfo);

export const addWorkspaceDialog = createSelector(selectSelf, state => state.addWorkspaceDialog);


export const invitesCounter = createSelector(selectSelf, state => state.invitesCounter);

export const isLoadingJoinWorkspace = createSelector(selectSelf, state => state.isLoadingJoinWorkspace);

export const workspaceNotSelectedDialog = createSelector(selectSelf, state => state.workspaceNotSelectedDialog);

