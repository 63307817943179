import {
    cleanUpAction,
    initialMfaSelector,
    isErrorSelector,
    isLoadingSelector,
    setIsErrorAction,
    setIsLoadingAction,
    setMfaStartAction,
    signInEmailSelector,
    TSignInSlice
} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {LoginMutationVariables} from "../../../../../newShared/GQLTypes";
import {
    initialValidateGoogleAuthorizationAction,
    loginAction,
    resendEmailVerificationCodeAction,
    signUpAction,
    verifyEmailAction
} from "../../store/actions";
import {validateNotEmpty} from "../../../../../newShared/utils/text";
import {useRoutes} from "../useRoutes";
import {addSuccessfulSnack} from "../../../../barsEnvironment/snack/store/slice";
import {loginByRecoveryCode, resendCode, validateCode} from "../../api";
import {useEffect} from "react";
import {TAuthObj} from "../../types";
import {UseManageWorkspacesAndOrganizations} from "../../../workspaces/hooks/useManageWorkspacesAndOrganizations";

type TResponse = {
    // step: TSignInSlice["step"],
    initialMfa: TSignInSlice["initialMfaConfig"],
    signInEmail: TSignInSlice["email"],
    isLoading: TSignInSlice["isLoading"],
    isError: TSignInSlice["isError"],

    // setStep: (step: TSignInSlice["step"]) => void,
    setIsLoading: (data: {key: keyof TSignInSlice["isLoading"], value: boolean}[]) => void,
    setIsError: (data: {
        isCodeExpiry?: boolean,
        errorMessage?: string | null,
    }) => void,

    login: (data: LoginMutationVariables["data"]) => void,
    confirmMfa: (code: string) => void,
    handleLoginByRecoveryCode: (code: string) => void,

    handleResendCodeViaEmail: () => void,

    registration: (data: TAuthObj) => void,

    emailVerify: (email: string, verificationCode: string) => void,
    emailVerificationResendCode: (email: string) => void,

    checkInitialCode: (code: string, secret: string) => void,

    startMfa: () => void,
    clean: () => void,
};
export const useSignIn = (): TResponse => {
    const {setAuthComplete, setUser, logout} = UseManageWorkspacesAndOrganizations();
    const {goToMfa, goToMain, goToVerifyEmail, goToInitialMfa} = useRoutes();

    const dispatch = useDispatch();
    const controller = new AbortController();

    const initialMfa = useSelector(initialMfaSelector);
    const signInEmail = useSelector(signInEmailSelector);
    const isLoading = useSelector(isLoadingSelector);
    const isError = useSelector(isErrorSelector);

    const setIsLoading: TResponse["setIsLoading"] = (data) => {
        dispatch(setIsLoadingAction(data));
    }
    const setIsError: TResponse["setIsError"] = (data) => {
        dispatch(setIsErrorAction(data));
    }

    const login: TResponse["login"] = (data) => {
        if (!validateNotEmpty(data.username)) return;
        if (!validateNotEmpty(data.password)) return;

        dispatch(loginAction({
            data: {username: data.username.toLowerCase(), password: data.password},
            finallyFunc: (isAppConfigured, isEmailVerified) => {
                setAuthComplete(isAppConfigured, isEmailVerified);
                if (isEmailVerified) goToMfa()
                else goToVerifyEmail();
            },
        }));
    }

    const confirmMfa: TResponse['confirmMfa'] = async (code: string) => {
        setIsLoading([{key: "isCheckingMfa", value: true}]);

        try {
            const resp = await validateCode(code);

            setUser(resp.currentUser);
            goToMain();
        } catch (e: any) {
            if (e.message === 'Token error: The time to use this code has expired! Please use resend code!') {
                setIsError({isCodeExpiry: true});
            } else {
                setIsError({errorMessage: e.message as string});
            }
        } finally {
            setIsLoading([{key: "isCheckingMfa", value: false}]);
        }
    }

    const handleLoginByRecoveryCode: TResponse["handleLoginByRecoveryCode"] = async (recoveryCode) => {
        try {
            setIsLoading([{key: "isCheckingMfa", value: true}]);
            const resp = await loginByRecoveryCode(recoveryCode);

            if (resp){
                console.log()
                setUser(resp.currentUser);
                goToMain()
            }
        } catch (ex: any) {
            console.log('2fa error')
        } finally {
            setIsLoading([{key: "isCheckingMfa", value: false}]);
        }

    }

    const handleResendCodeViaEmail: TResponse["handleResendCodeViaEmail"] = async () => {
        setIsLoading([{key: "isResendingMfa", value: true}]);
        const message = await resendCode();
        dispatch(addSuccessfulSnack(message));
        setIsError({isCodeExpiry: false});
        setIsLoading([{key: "isResendingMfa", value: false}]);
    }

    const registration: TResponse["registration"] = (data) => {
        dispatch(signUpAction({
            ...data,
            onSuccess: goToVerifyEmail
        }))
    }

    const emailVerify: TResponse["emailVerify"] = (email, verificationCode) => {
        dispatch(verifyEmailAction({email, verificationCode, onSuccess: (skip) => {
                if (skip) logout()
                else goToInitialMfa();
            }, signal: controller.signal}))
    }

    const emailVerificationResendCode: TResponse["emailVerificationResendCode"] = (email) => {
        dispatch(resendEmailVerificationCodeAction({email, signal: controller.signal}))
    }

    const checkInitialCode: TResponse["checkInitialCode"] = (code, secret) => {
        dispatch(initialValidateGoogleAuthorizationAction({code, secret, signal: controller.signal}))
    }

    const startMfa: TResponse["startMfa"] = () => {
        dispatch(setMfaStartAction());
    }
    const clean: TResponse["clean"] = () => {
        dispatch(cleanUpAction());
    }

    useEffect(() => {
        return () => {
            controller.abort();
        }
        //eslint-disable-next-line
    }, [])

    return {
        initialMfa,
        signInEmail,
        isLoading,
        isError,

        setIsLoading,
        setIsError,

        emailVerify,
        emailVerificationResendCode,

        login,
        confirmMfa,
        handleLoginByRecoveryCode,

        handleResendCodeViaEmail,

        registration,
        checkInitialCode,

        startMfa,
        clean,
    }
}