import {FC} from "react";
import {Box} from "@mui/material";
import {ChangeName} from "./components/changeName";
import {ChangePassword} from "./components/changePassword";

export const UserProfile: FC = () => {

    return (
        <Box p={'40px 20px 20px'} width={'100%'} height={'100%'} overflow={"auto"}>
            <ChangeName/>
            <Box width={'100%'} height={'16px'}/>
            <ChangePassword/>
        </Box>
    )
}