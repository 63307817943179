import React, {FC, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSignIn} from "../../hooks/useSignIn";
import {Menu, MenuItem, TextField, Typography} from "@mui/material";
import {SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";
import {DropdownButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {Language} from "@mui/icons-material";
import {useRoutes} from "../../hooks/useRoutes";
import colors from "../../../../../newShared/theme/colors";

export const BlockEmailVerification: FC = () => {
    const {t, currentLanguage: {name, code}, handleChooseLanguage} = useMainTranslation('', {keyPrefix: ''});
    // const {logout} = UseManageWorkspacesAndOrganizations();

    const {
        signInEmail,
        emailVerify, emailVerificationResendCode,
        isLoading: {isEmailVerifying}
    } = useSignIn();
    const {goToLogin} = useRoutes();

    const [verificationCode, setVerificationCode] = useState<string>('')

    const {anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose} = useThreeDotsMenu();

    // useEffect(() => {
    //     // !signEmail && logout()
    //
    //     //eslint-disable-next-line
    // }, [])

    return (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Email verification')}</Typography>

            <DropdownButton value={code} sx={{m: '32px auto 0', alignSelf: 'center'}} variant={"text"}
                            icon={<Language/>} open={!!anchorThreeDotsEl} onClick={handleThreeDotsOpen}>
                <Typography variant={"body1"}>{name}</Typography>
            </DropdownButton>
            <Menu open={!!anchorThreeDotsEl} anchorEl={anchorThreeDotsEl} onClose={handleThreeDotsClose}>
                {SUPPORTED_LANGUAGES.map((e) => (
                    <MenuItem value={e.code} key={e.code}
                              sx={{width: anchorThreeDotsEl?.offsetWidth}}
                              onClick={() => {
                                  handleChooseLanguage(e.code);
                                  handleThreeDotsClose();
                              }}>
                        {e.name}
                    </MenuItem>
                ))}
            </Menu>

            <TextField name={'email'} label={t('Email')} value={signInEmail || ''} disabled
                       sx={{mt: '16px'}} type={"email"}/>

            <TextField name={'name'} label={t('Verification code')} value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)}
                       sx={{mt: '16px'}}/>

            <LoadingButton loading={isEmailVerifying} onClick={() => signInEmail && emailVerify(signInEmail.trim(), verificationCode.trim())}
                           disabled={!signInEmail || signInEmail.trim() === '' || verificationCode.trim() === ''}
                           sx={{mt: '16px', alignSelf: 'flex-start'}}>
                {t('Send')}
            </LoadingButton>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                <span onClick={() => signInEmail && emailVerificationResendCode(signInEmail)} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Resend code')}</span>{` ${t('via email')}`}
            </Typography>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('Return to')} `}<span onClick={goToLogin} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Log In')}</span>
            </Typography>
        </>
    )
}