import styled from 'styled-components';
import {device} from '../../../../../newShared/constants';
import background from "../../../../../newShared/images/login/background.svg";

export const LoginMainWrapper = styled.div`
    width: 100%;
    min-width: 322px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 570px){
        justify-content: center;
    }
    ${device.mobileL}{
        justify-content: center;
    }
`;

export const LoginImage = styled.div<{revDir: boolean}>`
    width: 50%;
    height: 100vh;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    transform: ${props => props.revDir ? 'scaleX(-1)' : 'none'};
    margin: ${props => !props.revDir ? '0 100px 0 0' : '0 0 0 100px'};
    
    ${device.desktopL} {
        width: 55%;
    }
    ${device.desktop} {
        width: 50%;
        margin: ${props => !props.revDir ? '0 60px 0 0' : '0 0 0 60px'};
    }
    ${device.tablet}{
        width: 40%;
        margin: ${props => !props.revDir ? '0 60px 0 0' : '0 0 0 60px'};
    }
    @media screen and (max-width: 660px){
        width: 30%;
        padding: 20px 0 0 0;
    }
    @media screen and (max-width: 570px){
        display: none;
    }
    ${device.mobileL}{
        display: none;
    }
    
    
    
`;

export const FormContainer = styled.div`
    width: 35%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 100px 0 0 0;
    display: flex; 
    flex-direction: column;
    & h1{
        margin: 30px 0 0 0;
    }
    
   
   
    ${device.desktopM} {
        width: 25%;
        padding: 30px 0 0 0;
        & h1{
            margin: 15px 0 0 0;
        }
        & h4{
            margin: 16px 0 0 0;
        }
    }
    ${device.desktop} {
        padding: 50px 0 0 0;
        width: 30%;
        & h1{
            margin: 10px 0 0 0;
        }
    }
    @media screen and (max-width: 1070px){
        width: 40%;
    }
    ${device.tablet}{
        width: 50%;
        
    }
    @media screen and (max-width: 660px){
        width: 50%;
        padding: 20px 0 0 0;
    }
    
    @media screen and (max-width: 570px){
        width: 80%;
    }
    ${device.mobileL}{
        width: 90%;
        & h1{
            margin: 30px 0 0 0;
        }
        & h4{
            margin: 16px 0 0 0;
        }
    }
`;

export const LogoAndNameContainer = styled.div`
    & img {
        width: 320px;
    }
    ${device.desktopM}{
        & img{
            width: 200px;
        }
    }
    ${device.tablet}{
        & img{
            width: 200px;
        }
    }
    @media screen and (max-width: 570px){
        & img{
            width: 200px;
        }
    }
    ${device.mobile}{
        & img{
            width: 200px;
        }
    }
`;

export const ButtonContainer = styled.div<{disableDirectionColumn?: boolean, margin?: string}>`
    margin: ${props => props.margin ?? '0'};
    width: 35%;
    display: flex;
    flex-direction: ${props => props.disableDirectionColumn ? '' : 'column'};
    // align-items: center;
    ${device.desktop} {
        width: 35%;
    }
    ${device.tablet} {
        width: 35%;
    }
    @media screen and (max-width: 570px){
        & img{
            width: 65%;
        }
    }
`;

export const LoaderContainer = styled.div`
    width: 100%;
    display: flex;
    // justify
    margin: 16px 0 0 0;
`;

export const VersionContainer = styled.div`
    justify-self: flex-end;
`;


export const ErrorMessageBox = styled.div`
    width: 95%;
    margin: 10px 0 0 0;
    align-self: center;
    padding: 16px 16px 24px 16px;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(125, 151, 174, 0.08), 0 2px 6px rgba(125, 151, 174, 0.02), 0 0 1px rgba(125, 151, 174, 0.02);
  
`;

export const TwoFaInputWrapper = styled.div`
    width: 65%;
    ${device.mobileL} {
        width: 100%;
    }
`;

export const TwoFaLoaderWrapper = styled.div`
    width: 65%;
    margin: 16px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ${device.mobileL} {
        width: 100%;
    }
`;

export const PasswordHintWrapper = styled.div`
    background-color: white;
`;

export const QrCodeWrapper = styled.div`
    width: 30%;
    margin: 0 auto;
    display; flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 0 0;
    ${device.tablet}{
        width: 100%;
    }
                     
`;

export const SecretRevealWrapper = styled.div`
    width: 110%;
    min-width: 300px;
    display; flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 41px 0 0 0;
    & p{
        display: inline-block;
    }
    flex-wrap: nowrap;
    ${device.tablet}{
        width: 100%;
    }
`;