import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {AxiosResponse} from "axios";
import {changeName, changePassword} from "./queries";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {
    ChangeNameMutation,
    ChangeNameMutationVariables,
    ChangePasswordMutation,
    ChangePasswordMutationVariables
} from "../../../../newShared/GQLTypes";

export const changeNameApi = async (variables: ChangeNameMutationVariables): Promise<ChangeNameMutation["changeName"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: changeName,
                variables
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeName
    })
)

export const changePasswordApi = async (variables: ChangePasswordMutationVariables): Promise<ChangePasswordMutation["changePassword"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: changePassword,
                variables
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changePassword
    })
)
