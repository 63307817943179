import {useDispatch, useSelector} from "react-redux";
import {
    clearState,
    isLoadingSelector,
    reportOrganizationsSelector,
    reportsSelector,
    reportViewSelector,
    selectedControlSelector,
    TAuditSpaceSlice
} from "../../store/auditSpace/slice";
import {
    NcrReportConfigInput,
    PaginationInput,
    UpdateAuditorsMutationVariables
} from "../../../../../newShared/GQLTypes";
import {
    changeReviewedControlByAuditorAction,
    deleteAuditorsInSnapshotAction,
    getAuditorsVariantsByAuditorAction,
    getFullNcrReportPreviewAction,
    getNCRsByControlAction,
    getReportByIdAction,
    getReportControlAction,
    getReportControlsAction,
    getReportOrganizationsAction,
    getReportsByOrganizationIdAction,
    updateAuditorsApiAction,
    updateReportConfigAction,
    updateReportStatusAction
} from "../../store/auditSpace/actions";
import {TAuditor} from "../../types";


type TResponse = {
    organizations: TAuditSpaceSlice["organizations"];
    reports: TAuditSpaceSlice["reports"];
    controls: TAuditSpaceSlice["controls"];
    selectedControl: TAuditSpaceSlice["selectedControl"];
    isLoading: TAuditSpaceSlice["isLoading"];

    getOrganizations: (pageInfo: PaginationInput, signal?: AbortSignal, clean?: boolean) => void,
    getReports: (organizationId: string, pageInfo: PaginationInput, signal?: AbortSignal, clean?: boolean) => void,
    getControls: (organizationId: string, reportId: string, pageInfo: PaginationInput, signal?: AbortSignal, clean?: boolean) => void,

    getReportById: (organizationId: string, reportId: string, signal: AbortSignal) => void,
    getControlById: (organizationId: string, reportId: string, controlId: string, signal: AbortSignal) => void,
    getNCRsByControl: (organizationId: string, reportId: string, controlId: string, signal: AbortSignal) => void,

    setReviewed: (workspaceId: string, reportId: string, frameworkId: string, controlId: string, reviewed: boolean) => void,
    changeReportStatus: (workspaceId: string, reportId: string, status: string) => void,
    getReportPreview: (organizationId: string, workspaceId: string, reportId: string) => void,
    setReportConfig: (reportId: string, data: NcrReportConfigInput) => void,

    getAuditorsVariants: () => void,
    updateAuditorsInfo: (data: UpdateAuditorsMutationVariables, idNames: TAuditor[]) => void,
    deleteAuditorsInfo: (id: string, reportId: string) => void,

    cleanAuditSpace: () => void,
    cleanOrganizations: () => void,
    cleanReports: () => void,
    cleanReport: () => void,
    cleanControls: () => void,
    cleanControl: () => void,
}
export const useAuditSpace = (): TResponse => {

    const dispatch = useDispatch();

    const organizations = useSelector(reportOrganizationsSelector);
    const reports = useSelector(reportsSelector);
    const controls = useSelector(reportViewSelector);
    const selectedControl = useSelector(selectedControlSelector);
    const isLoading = useSelector(isLoadingSelector);

    const getOrganizations: TResponse["getOrganizations"] = (pageInfo, signal, clean) => {
        dispatch(getReportOrganizationsAction({data: {pageInfo}, signal, clean}))
    };
    const getReports: TResponse["getReports"] = (organizationId, pageInfo, signal, clean) => {
        dispatch(getReportsByOrganizationIdAction({data: {organizationId, pageInfo}, signal, clean}))
    };
    const getControls: TResponse["getControls"] = (organizationId, reportId, pageInfo, signal, clean) => {
        dispatch(getReportControlsAction({data: {organizationId, reportId, pageInfo}, signal, clean}))
    };

    const getReportById: TResponse["getReportById"] = (organizationId, reportId, signal) => {
        dispatch(getReportByIdAction({data: {organizationId, reportId}, signal}))
    };
    const getControlById: TResponse["getControlById"] = (organizationId, reportId, controlId, signal) => {
        dispatch(getReportControlAction({data: {organizationId, reportId, controlId}, signal}))
    };
    const getNCRsByControl: TResponse["getNCRsByControl"] = (organizationId, reportId, controlId, signal) => {
        dispatch(getNCRsByControlAction({data: {organizationId, reportId, controlId}, signal}))
    };

    const setReviewed: TResponse["setReviewed"] = (workspaceId, reportId, frameworkId, controlId, reviewed) => {
        dispatch(changeReviewedControlByAuditorAction({data: {workspaceId, reportId, frameworkId, controlId, reviewed}}))
    };
    const changeReportStatus: TResponse["changeReportStatus"] = (workspaceId, reportId, status) => {
        dispatch(updateReportStatusAction({data: {workspaceId, reportId, status}}))
    };
    const getReportPreview: TResponse["getReportPreview"] = (organizationId, workspaceId, reportId) => {
        dispatch(getFullNcrReportPreviewAction({data: {organizationId, workspaceId, reportId}}))
    };
    const setReportConfig: TResponse["setReportConfig"] = (reportId, data) => {
        dispatch(updateReportConfigAction({data: {reportId, data}}))
    };

    const getAuditorsVariants: TResponse["getAuditorsVariants"] = () => {
        dispatch(getAuditorsVariantsByAuditorAction({data: {}}))
    };
    const updateAuditorsInfo: TResponse["updateAuditorsInfo"] = (data, idNames) => {
        dispatch(updateAuditorsApiAction({data, addictiveData: idNames}))
    };
    const deleteAuditorsInfo: TResponse["deleteAuditorsInfo"] = (id, reportId) => {
        dispatch(deleteAuditorsInSnapshotAction({data: {id, reportId}}))
    };


    const cleanAuditSpace = () => {dispatch(clearState([]))};
    const cleanOrganizations = () => {dispatch(clearState(['organizations']))};
    const cleanReports = () => {dispatch(clearState(['reports']))};
    const cleanReport = () => {dispatch(clearState(['controls']))};
    const cleanControls = () => {dispatch(clearState(['controls.controls']))};
    const cleanControl = () => {dispatch(clearState(['selectedControl']))};

    return {
        organizations,
        reports,
        controls,
        selectedControl,
        isLoading,

        getOrganizations,
        getReports,
        getControls,

        getReportById,
        getControlById,
        getNCRsByControl,

        setReviewed,
        changeReportStatus,
        getReportPreview,
        setReportConfig,

        getAuditorsVariants,
        updateAuditorsInfo,
        deleteAuditorsInfo,

        cleanAuditSpace,
        cleanOrganizations,
        cleanReports,
        cleanReport,
        cleanControls,
        cleanControl,
    }
}