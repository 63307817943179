import React, {FC} from "react";
import {FlexStart} from "../../../../../newShared/components/Layouts";
import MenuIcon from "@mui/icons-material/Menu";
import {LogoText} from "./logo";
import {TOP_BAR_PERCENT} from "../../constants";
import {IconButton} from "@mui/material";

type PropTypes = {
    expanded: boolean;
    expand: () => void;
};
const burgerHideStyle = {color: '#0C1629'};

export const TopBarUnProtectedLeftSide: FC<PropTypes> = () => {
    return (
        <FlexStart w={`${TOP_BAR_PERCENT}%`} shrink={'0'} minw={'190px'}  m={'0 5px 0 0'}>
            <IconButton
                onClick={() => {}}
                aria-label="Menu header"
                style={burgerHideStyle}
                disabled={true}
            >
                <MenuIcon/>
            </IconButton>
            <LogoText goToOverview={() => {}}/>
        </FlexStart>
    )
};
