import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../../newShared/redux/rootReducer";
import {
    changeReviewedControlByAuditorAction,
    deleteAuditorsInSnapshotAction,
    downloadAuditorEvidenceFileAction,
    DownloadLogAction,
    DownloadLogForPreviewAction,
    downloadReportCollectorAssetByAuditorAction,
    GetAppLogsAction,
    getAuditorEvidenceAssetStatementAction,
    getAuditorEvidenceAssetTableAction,
    getAuditorsVariantsByAuditorAction,
    getFullNcrReportPreviewAction,
    getIntegrationDataByCollectionReportsAction,
    getIntegrationsReportAction,
    getNCRsByControlAction,
    getReportByIdAction,
    getReportControlAction,
    getReportControlsAction,
    getReportOrganizationsAction,
    getReportsByOrganizationIdAction,
    updateAuditorsApiAction,
    updateReportConfigAction,
    updateReportStatusAction
} from "./actions";
import {TDialog, TPageInfo} from "../../../../../newShared/types";
import {
    TAssetDataCUSTOM,
    TAuditor,
    TAuditorVariant,
    TEvidencesAssetCollector,
    TFullNcrReportPreview,
    TReport,
    TReportControl,
    TReportControlNCR,
    TReportIntegration,
    TReportIntegrationLog,
    TReportOrganization,
    TReportSelectedControl,
    TReportSmall,
    TSetAuditorsDialog,
    TSetChangeNcrStatusDialog,
    TSetCollectorView,
    TSetPreviewReportDialog,
    TSetRejectReportDialog,
    TSetStatementDialog
} from "../../types";
import {changeStatusNCRByAuditorAction, createNcrAction, deleteNCRAction, updateNCRAction} from "./ncrActions";
import {
    SEND_CREATE_UPDATE_NCR_CONFIG_ACTION,
    SEND_DELETE_AUDITOR_ACTION,
    SEND_REPORT_STATUS_CHANGED_ACTION,
    SEND_SHARED_FOR_AUDITORS_REPORT_ACTION,
    SEND_UPDATE_NCR_REPORT_ACTION,
    SEND_UPDATE_REVIEWED_CONTROL_ACTION
} from "../../actions";
import {setPaginationInStore} from "../../../../../newShared/hooks/useScroll/helpers";
import {ChangeStatusNcrByAuditorMutationVariables, IdNameEmailModel} from "../../../../../newShared/GQLTypes";

export type TAuditSpaceSlice = {
    isLoading: {
        isLoadingOrganizations: boolean,
        isLoadingReports: boolean,

        isLoadingReport: boolean,
        isLoadingControls: boolean,
        isLoadingControl: boolean,
        isReviewingControl: string[],
        isChangingStatusReport: string | null,
        isRejectingReport: boolean,
        isLoadingPreview: boolean,
        isUpdatingReportConfig: boolean,

        isLoadingNCRs: boolean,
        isCreatingNcr: boolean,
        isUpdatingNcr: string[],
        isChangingStatusNcr: { id: string, status: string }[],
        isDeletingNcr: string[],

        isDownloadingFile: string[],
        isLoadingAsset: string | null,
    },

    organizations: {
        organizations: (TReportOrganization | null)[],
        pageInfo: TPageInfo,
    },

    reports: {
        organization: TReportOrganization | null,
        reports: (TReportSmall | null)[],
        pageInfo: TPageInfo,
    },

    controls: {
        organization: TReportOrganization | null,
        report: TReport | null,
        controls: (TReportControl | null)[],
        pageInfo: TPageInfo,
    },

    selectedControl: {
        selectedControl: TReportSelectedControl | null,
        NCRs: TReportControlNCR[]
        users: IdNameEmailModel[]
    },

    integrations: {
        report: TReportSmall | null,
        organization: TReportOrganization | null,
        integrations: TReportIntegration[],
        selectedApp: {
            app: TReportIntegration | null;
            selectedCollection: string | null;
            collectionData: {[key: string]: any}[];
            collectionDataPageInfo: TPageInfo;
            selectedCollectionObject: {[key: string]: any} | null;
            logsData: {
                logs: (TReportIntegrationLog | null)[];
                pageInfo: TPageInfo;
                selectedLog: TReportIntegrationLog | null;
                selectedLogResponse: string;
            }
        },
        loading: {
            isAppListLoading: boolean,
            isAppLoading: boolean,
            isDataByCollectionLoading: boolean,

            isLogsDownloading: boolean,
            isLogDownloading: boolean,
            isLogPreviewLoading: boolean,
        },
    },

    dialogs: {
        auditors: TDialog & {
            isLoadingVariants: boolean,
            auditors: TAuditor[],
            auditorVariants: TAuditorVariant[],
        },

        rejectReport: TDialog & {
            ids: {reportId: string, organizationId: string, name: string} | null,
        },

        preview: TDialog & {
            data: TFullNcrReportPreview | null,
        },

        collectorView: TDialog & {
            ids: {organizationId: string, reportId: string, controlId: string, assetId: string} | null,
            data: TEvidencesAssetCollector | null,
            pageInfo: TPageInfo,
        },

        statement: TDialog & {
            data: TAssetDataCUSTOM | null,
        },

        changeStatusNcr: TDialog & {
            data: ChangeStatusNcrByAuditorMutationVariables | null,
        },
    }
}


const initialState: TAuditSpaceSlice = {
    isLoading: {
        isLoadingOrganizations: false,
        isLoadingReports: false,

        isLoadingReport: false,
        isLoadingControls: false,
        isLoadingControl: false,
        isReviewingControl: [],
        isChangingStatusReport: null,
        isRejectingReport: false,
        isLoadingPreview: false,
        isUpdatingReportConfig: false,

        isLoadingNCRs: false,
        isCreatingNcr: false,
        isUpdatingNcr: [],
        isChangingStatusNcr: [],
        isDeletingNcr: [],

        isDownloadingFile: [],
        isLoadingAsset: null,
    },

    organizations: {
        organizations: [],
        pageInfo: {page: 0, count: 25, total: 0, sort: ''},
    },

    reports: {
        organization: null,
        reports: [],
        pageInfo: {page: 0, count: 25, total: 0, sort: ''},
    },

    controls: {
        organization: null,
        report: null,
        controls: [],
        pageInfo: {page: 0, count: 25, total: 0, sort: ''},
    },

    selectedControl: {
        selectedControl: null,
        NCRs: [],
        users: [],
    },

    integrations: {
        report: null,
        organization: null,
        integrations: [],
        selectedApp: {
            app: null,
            selectedCollection: null,
            collectionData: [],
            collectionDataPageInfo: {page: 0, count: 50, total: 0, sort: ''},
            selectedCollectionObject: null,
            logsData: {
                logs: [],
                pageInfo: {page: 0, count: 50, total: 0, sort: ''},
                selectedLog: null,
                selectedLogResponse: ''
            }
        },
        loading: {
            isAppListLoading: false,
            isAppLoading: false,
            isDataByCollectionLoading: false,

            isLogsDownloading: false,
            isLogDownloading: false,
            isLogPreviewLoading: false,
        },
    },

    dialogs: {
        auditors: {
            isOpen: false,
            isLoading: false,
            isLoadingVariants: false,
            auditors: [],
            auditorVariants: [],
        },

        rejectReport: {
            isLoading: false,
            isOpen: false,
            ids: null,
        },

        preview: {
            isLoading: false,
            isOpen: false,
            data: null,
        },

        collectorView: {
            isLoading: false,
            isOpen: false,
            ids: null,
            data: null,
            pageInfo: {page: 0, count: 25, total: 0, sort: ''},
        },

        statement: {
            isLoading: false,
            isOpen: false,
            data: null
        },

        changeStatusNcr: {
            isLoading: false,
            isOpen: false,
            data: null,
        }
    }
}

const slice = createSlice({
    name: 'auditSpace',
    initialState,
    reducers: {
        clearState(slice: TAuditSpaceSlice, {payload}: {payload: string[]}) {
            if (payload.length === 0 || payload.includes('isLoading')) slice.isLoading = initialState.isLoading;
            if (payload.length === 0 || payload.includes('organizations')) slice.organizations = initialState.organizations;
            if (payload.length === 0 || payload.includes('reports')) slice.reports = initialState.reports;
            if (payload.length === 0 || payload.includes('controls')) slice.controls = initialState.controls;
            if (payload.length === 0 || payload.includes('controls.controls')) slice.controls.controls = initialState.controls.controls;
            if (payload.length === 0 || payload.includes('controls.controls')) slice.controls.pageInfo = initialState.controls.pageInfo;
            if (payload.length === 0 || payload.includes('selectedControl')) slice.selectedControl = initialState.selectedControl;
            if (payload.length === 0 || payload.includes('integrations')) slice.integrations = initialState.integrations;
            if (payload.length === 0 || payload.includes('dialogs')) slice.dialogs = initialState.dialogs;
        },

        deselectApplicationAction: (slice: TAuditSpaceSlice) => {
            slice.integrations.selectedApp = {
                app: null,
                selectedCollection: null,
                collectionData: [],
                collectionDataPageInfo: {page: 0, count: 50, sort: '', total: 0},
                selectedCollectionObject: null,
                logsData: {
                    logs: [],
                    pageInfo: {page: 0, count: 50, sort: '', total: 0},
                    selectedLog: null,
                    selectedLogResponse: ''
                }
            }
        },
        selectLogAction: (slice: TAuditSpaceSlice, {payload}: {payload: TReportIntegrationLog}) => {
            if (slice.integrations.selectedApp){
                slice.integrations.selectedApp.logsData.selectedLog = payload;
            }
        },
        deselectLogAction: (slice: TAuditSpaceSlice) => {slice.integrations.selectedApp.logsData.selectedLog = null},
        deselectLogFetchResultAction: (slice: TAuditSpaceSlice) => {slice.integrations.selectedApp.logsData.selectedLogResponse = ''},

        eraseCollectionDataBeforeFetch: (slice) => {
            slice.integrations.selectedApp.selectedCollectionObject = null;
            slice.integrations.selectedApp.collectionData = [];
        },
        setSelectedCollectionObject: (slice, {payload}: {payload: {[key: string]: any}}) => {
            slice.integrations.selectedApp.selectedCollectionObject = payload;
        },
        deselectCollectionObject: (slice) => {slice.integrations.selectedApp.selectedCollectionObject = null},

        setAuditorsDialogAction(slice: TAuditSpaceSlice, {payload}: {payload: TSetAuditorsDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.auditors.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.auditors.isLoading = payload.isLoading;
            if (payload.auditors !== undefined) slice.dialogs.auditors.auditors = payload.auditors;
            if (payload.auditorVariants !== undefined) slice.dialogs.auditors.auditorVariants = payload.auditorVariants;

            if (payload.clear) slice.dialogs.auditors = initialState.dialogs.auditors;
        },

        setRejectReportDialogAction(slice: TAuditSpaceSlice, {payload}: {payload: TSetRejectReportDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.rejectReport.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.rejectReport.isLoading = payload.isLoading;
            if (payload.ids !== undefined) slice.dialogs.rejectReport.ids = payload.ids;

            if (payload.clear) slice.dialogs.rejectReport = initialState.dialogs.rejectReport;
        },

        setPreviewDialogAction(slice: TAuditSpaceSlice, {payload}: {payload: TSetPreviewReportDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.preview.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.preview.isLoading = payload.isLoading;

            if (payload.clear) slice.dialogs.preview = initialState.dialogs.preview;
        },

        setCollectorViewAction(slice: TAuditSpaceSlice, {payload}: {payload: TSetCollectorView}) {
            if (payload.isOpen !== undefined) slice.dialogs.collectorView.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.collectorView.isLoading = payload.isLoading;
            if (payload.ids !== undefined) slice.dialogs.collectorView.ids = payload.ids;

            if (payload.clear) slice.dialogs.collectorView = initialState.dialogs.collectorView;
        },

        setStatementDialogAction(slice: TAuditSpaceSlice, {payload}: {payload: TSetStatementDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.statement.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.statement.isLoading = payload.isLoading;
            if (payload.data !== undefined) slice.dialogs.statement.data = payload.data;

            if (payload.clear) slice.dialogs.statement = initialState.dialogs.statement;
        },

        setChangeNcrStatusDialogAction(slice: TAuditSpaceSlice, {payload}: {payload: TSetChangeNcrStatusDialog}) {
            if (payload.isOpen !== undefined) slice.dialogs.changeStatusNcr.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.changeStatusNcr.isLoading = payload.isLoading;
            if (payload.data !== undefined) slice.dialogs.changeStatusNcr.data = payload.data;

            if (payload.clear) slice.dialogs.changeStatusNcr = initialState.dialogs.changeStatusNcr;
        },
    },
        extraReducers:(builder) => {
            builder
                .addCase(getReportOrganizationsAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean}}}) => {
                    if (clean) state.organizations = initialState.organizations;
                    state.isLoading.isLoadingOrganizations = true;
                })
                .addCase(getReportOrganizationsAction.rejected, (state: TAuditSpaceSlice) => {
                    state.isLoading.isLoadingOrganizations = false;
                })
                .addCase(getReportOrganizationsAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    state.isLoading.isLoadingOrganizations = false;

                    const {array, pageInfo} = setPaginationInStore<TReportOrganization>(
                        state.organizations.pageInfo,
                        state.organizations.organizations,
                        payload.resp.pageInfo,
                        payload.resp.organizations,
                        payload.isNewOnTop
                    );
                    state.organizations.organizations = array;
                    state.organizations.pageInfo = pageInfo;
                })

                .addCase(getReportsByOrganizationIdAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean}}}) => {
                    if (clean) state.reports.reports = initialState.reports.reports;
                    if (clean) state.reports.pageInfo = initialState.reports.pageInfo;
                    state.isLoading.isLoadingReports = true;
                })
                .addCase(getReportsByOrganizationIdAction.rejected, (state: TAuditSpaceSlice) => {
                    state.isLoading.isLoadingReports = false;
                })
                .addCase(getReportsByOrganizationIdAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    state.isLoading.isLoadingReports = false;
                    state.reports.organization = payload.resp.organization;

                    const {array, pageInfo} = setPaginationInStore<TReportSmall>(
                        state.reports.pageInfo,
                        state.reports.reports,
                        payload.resp.pageInfo,
                        payload.resp.reports,
                        payload.isNewOnTop
                    );
                    state.reports.reports = array;
                    state.reports.pageInfo = pageInfo;
                })

                .addCase(getReportByIdAction.pending, (state: TAuditSpaceSlice) => {
                    state.isLoading.isLoadingReport = true;
                })
                .addCase(getReportByIdAction.rejected, (state: TAuditSpaceSlice) => {
                    state.isLoading.isLoadingReport = false;
                })
                .addCase(getReportByIdAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    state.isLoading.isLoadingReport = false;

                    state.controls.report = payload.resp.report;
                    state.controls.organization = payload.resp.organization;
                })

                .addCase(getReportControlsAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean}}}) => {
                    if (clean) state.controls.controls = initialState.controls.controls;
                    if (clean) state.controls.pageInfo = initialState.controls.pageInfo;
                    state.isLoading.isLoadingControls = true;
                })
                .addCase(getReportControlsAction.rejected, (state: TAuditSpaceSlice) => {
                    state.isLoading.isLoadingControls = false;
                })
                .addCase(getReportControlsAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    state.isLoading.isLoadingControls = false;

                    const {array, pageInfo} = setPaginationInStore<TReportControl>(
                        state.controls.pageInfo,
                        state.controls.controls,
                        payload.resp.pageInfo,
                        payload.resp.controls,
                        payload.isNewOnTop
                    );
                    state.controls.controls = array;
                    state.controls.pageInfo = pageInfo;
                })

                .addCase(getAuditorsVariantsByAuditorAction.pending, (state: TAuditSpaceSlice) => {
                    state.dialogs.auditors.isLoadingVariants = true;
                })
                .addCase(getAuditorsVariantsByAuditorAction.rejected, (state: TAuditSpaceSlice) => {
                    state.dialogs.auditors.isLoadingVariants = false;
                })
                .addCase(getAuditorsVariantsByAuditorAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    state.dialogs.auditors.isLoadingVariants = false;
                    state.dialogs.auditors.auditorVariants = payload.resp;
                })

                .addCase(updateAuditorsApiAction.pending, (state: TAuditSpaceSlice) => {
                    state.dialogs.auditors.isLoading = true;
                })
                .addCase(updateAuditorsApiAction.rejected, (state: TAuditSpaceSlice) => {
                    state.dialogs.auditors.isLoading = false;
                })
                .addCase(updateAuditorsApiAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    state.dialogs.auditors.isLoading = false;
                    if (state.controls.report) state.controls.report.auditors = [
                        ...state.controls.report.auditors.filter(e => e.type === 'LEADER'),
                        ...payload.idNames.filter(a => !state.controls.report?.auditors.some(e => e.type === 'LEADER' && e.id === a.id))
                    ];

                    state.dialogs.auditors = initialState.dialogs.auditors;
                })

                .addCase(deleteAuditorsInSnapshotAction.pending, (state: TAuditSpaceSlice) => {
                    state.dialogs.auditors.isLoading = true;
                })
                .addCase(deleteAuditorsInSnapshotAction.rejected, (state: TAuditSpaceSlice) => {
                    state.dialogs.auditors.isLoading = false;
                })
                .addCase(deleteAuditorsInSnapshotAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    state.dialogs.auditors.isLoading = false;
                    if (state.controls.report) state.controls.report.auditors = state.controls.report.auditors.filter(e => e.id !== payload.data.id);
                    state.dialogs.auditors.auditors = state.dialogs.auditors.auditors.filter(e => e.id !== payload.data.id);
                })

                .addCase(getReportControlAction.pending, (state: TAuditSpaceSlice) => {
                    state.selectedControl = initialState.selectedControl;
                    state.isLoading.isLoadingControl = true;
                })
                .addCase(getReportControlAction.rejected, (state: TAuditSpaceSlice) => {
                    state.isLoading.isLoadingControl = false;
                })
                .addCase(getReportControlAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    state.isLoading.isLoadingControl = false;
                    state.selectedControl.selectedControl = payload.resp;
                })

                .addCase(getNCRsByControlAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean}}}) => {
                    if (clean) state.selectedControl.NCRs = initialState.selectedControl.NCRs;
                    state.isLoading.isLoadingNCRs = true;
                })
                .addCase(getNCRsByControlAction.rejected, (state: TAuditSpaceSlice) => {
                    state.isLoading.isLoadingNCRs = false;
                })
                .addCase(getNCRsByControlAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    state.isLoading.isLoadingNCRs = false;
                    state.selectedControl.NCRs = payload.resp.NCRs;
                    state.selectedControl.users = payload.resp.users;
                })

                .addCase(changeReviewedControlByAuditorAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isReviewingControl.push(data.controlId);
                })
                .addCase(changeReviewedControlByAuditorAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {data}}}) => {
                    state.isLoading.isReviewingControl = state.isLoading.isReviewingControl.filter(e => e !== data.controlId);
                })
                .addCase(changeReviewedControlByAuditorAction.fulfilled, (state: TAuditSpaceSlice, {payload: {data, resp}}) => {
                    state.isLoading.isReviewingControl = state.isLoading.isReviewingControl.filter(e => e !== data.controlId);

                    if (resp.reportId === state.controls.report?.id) {
                        if (resp.reviewed) {
                            state.controls.report.reviewedControls = [...new Set([...state.controls.report?.reviewedControls, data.controlId])];
                        }
                        else {
                            state.controls.report.reviewedControls = state.controls.report.reviewedControls.filter(e => e !== resp.controlId);
                        }

                        const tmp = state.controls.controls;
                        const index = tmp.findIndex(e => e?.id === resp.controlId);
                        if (index > -1) {
                            tmp[index]!.reviewed = resp.reviewed;
                        }
                    }

                    if (state.selectedControl.selectedControl?.id === resp.controlId) {
                        state.selectedControl.selectedControl!.reviewed = resp.reviewed;
                    }
                })

                .addCase(updateReportStatusAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isChangingStatusReport = data.status;
                })
                .addCase(updateReportStatusAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {data}}}) => {
                    state.isLoading.isChangingStatusReport = null;
                })
                .addCase(updateReportStatusAction.fulfilled, (state: TAuditSpaceSlice, {payload: {data, resp}}) => {
                    state.isLoading.isChangingStatusReport = null;

                    if (data.reportId === state.controls.report?.id) {
                        state.controls.report!.status = data.status
                    }
                })

                .addCase(getFullNcrReportPreviewAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isLoadingPreview = true;
                })
                .addCase(getFullNcrReportPreviewAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {data}}}) => {
                    state.isLoading.isLoadingPreview = false;
                })
                .addCase(getFullNcrReportPreviewAction.fulfilled, (state: TAuditSpaceSlice, {payload: {data, resp}}) => {
                    state.isLoading.isLoadingPreview = false;

                    state.dialogs.preview.isOpen = true;
                    state.dialogs.preview.data = resp;
                })

                .addCase(updateReportConfigAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isUpdatingReportConfig = true;
                })
                .addCase(updateReportConfigAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {data}}}) => {
                    state.isLoading.isUpdatingReportConfig = false;
                })
                .addCase(updateReportConfigAction.fulfilled, (state: TAuditSpaceSlice, {payload: {data, resp}}) => {
                    state.isLoading.isUpdatingReportConfig = false;

                    if (state.controls.report) {
                        state.controls.report.reportNCR = {
                            title: resp.title || '',
                            owner: resp.owner || '',
                            classification: resp.classification || '',
                            auditCommittee: resp.auditCommittee || '',
                            status: resp.status || '',
                            approver: resp.approver || '',
                            approvalDate: resp.approvalDate || '',
                            version: resp.version || '',
                            logo: resp.logo || null,
                            typeOfAudit: resp.auditType || '',
                            notes: state.controls.report.reportNCR?.notes,
                        }
                    }
                })

                .addCase(createNcrAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean}}}) => {
                    state.isLoading.isCreatingNcr = true;
                })
                .addCase(createNcrAction.rejected, (state: TAuditSpaceSlice) => {
                    state.isLoading.isCreatingNcr = false;
                })
                .addCase(createNcrAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    state.isLoading.isCreatingNcr = false;
                    if (state.selectedControl.selectedControl?.id === payload.resp.controlId) {
                        state.selectedControl.NCRs.push(payload.resp);
                    }

                    if (state.selectedControl.selectedControl?.id === payload.resp.controlId) {
                        state.selectedControl.selectedControl.smallNCRs.push({
                            id: payload.resp.id, status: payload.resp.status, type: payload.resp.type
                        });

                        state.selectedControl.selectedControl.reviewed = true;
                    }

                    {
                        const tmp = state.controls.controls;
                        const index = tmp.findIndex(e => e?.id === payload.resp.controlId);
                        if (index > -1) {
                            tmp[index]!.smallNCRs.push({
                                id: payload.resp.id, status: payload.resp.status, type: payload.resp.type
                            });
                            tmp[index]!.reviewed = true;

                            state.controls.controls = tmp;
                        }
                    }

                    if (state.controls.report?.id === payload.resp.reportId) {
                        state.controls.report.smallNCRs.push({
                            id: payload.resp.id, status: payload.resp.status, type: payload.resp.type
                        });
                        state.controls.report.reviewedControls = [...new Set([...state.controls.report.reviewedControls, payload.resp.controlId])]
                    }
                })

                .addCase(deleteNCRAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isDeletingNcr.push(data.ncrControlId);
                })
                .addCase(deleteNCRAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isDeletingNcr = state.isLoading.isDeletingNcr.filter(e => e !== data.ncrControlId);
                })
                .addCase(deleteNCRAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    const {data: {ncrControlId}} = payload;
                    state.isLoading.isDeletingNcr = state.isLoading.isDeletingNcr.filter(e => e !== ncrControlId);

                    if (state.selectedControl.selectedControl?.smallNCRs.some(e => e.id === ncrControlId)) {
                        state.selectedControl.selectedControl!.smallNCRs = state.selectedControl.selectedControl!.smallNCRs.filter(e => e.id !== ncrControlId);
                        state.selectedControl.selectedControl!.reviewed = true;
                    }

                    if (state.selectedControl.NCRs.some(e => e.id === ncrControlId)) {
                        state.selectedControl.NCRs = state.selectedControl.NCRs.filter(e => e.id !== ncrControlId);
                    }

                    {
                        const tmp = state.controls.controls;
                        const index = tmp.findIndex(e => e?.smallNCRs.some(e => e.id === ncrControlId));
                        if (index > -1) {
                            tmp[index]!.smallNCRs = tmp[index]!.smallNCRs.filter(e => e.id !== ncrControlId);
                            tmp[index]!.reviewed = true;

                            state.controls.controls = tmp;
                        }
                    }

                    if (state.controls.report) {
                        state.controls.report.smallNCRs = state.controls.report.smallNCRs.filter(e => e.id !== ncrControlId);

                        if (state.selectedControl.selectedControl?.id)
                            state.controls.report!.reviewedControls = [...new Set([...state.controls.report!.reviewedControls, state.selectedControl.selectedControl.id])];
                    }
                })

                .addCase(updateNCRAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isUpdatingNcr.push(data.ncrControl.id);
                })
                .addCase(updateNCRAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isUpdatingNcr = state.isLoading.isUpdatingNcr.filter(e => e !== data.ncrControl.id);
                })
                .addCase(updateNCRAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    const {data: {ncrControl: {id}}} = payload;
                    state.isLoading.isUpdatingNcr = state.isLoading.isUpdatingNcr.filter(e => e !== id);

                    {
                        const tmp = state.selectedControl.NCRs;
                        const index = tmp.findIndex(e => e.id === id);
                        if (index > -1) {
                            tmp[index] = payload.resp;
                            state.selectedControl.NCRs = tmp;
                        }
                    }

                    {
                        const tmp = state.selectedControl.selectedControl?.smallNCRs;
                        const index = tmp?.findIndex(e => e.id === id) ?? -1;
                        if (index > -1 && tmp) {
                            tmp[index] = {id: payload.data.ncrControl.id, status: payload.data.ncrControl.status, type: payload.data.ncrControl.type};
                            state.selectedControl.selectedControl!.smallNCRs = tmp;
                        }
                    }

                    {
                        const tmp = state.controls.controls;
                        let indexSmall = -1;
                        const index = tmp.findIndex(e => e?.smallNCRs.some((e, index) => {if (e.id === id) {indexSmall = index; return true} else return false}));
                        if (index > -1 && indexSmall > -1) {
                            tmp[index]!.smallNCRs[indexSmall] = {id: payload.data.ncrControl.id, status: payload.data.ncrControl.status, type: payload.data.ncrControl.type};
                            state.controls.controls = tmp;
                        }
                    }

                    {
                        const tmp = state.controls.report?.smallNCRs;
                        const index = tmp?.findIndex(e => e.id === id) ?? -1;
                        if (index > -1 && tmp) {
                            tmp[index] = {id: payload.data.ncrControl.id, status: payload.data.ncrControl.status, type: payload.data.ncrControl.type};
                            state.controls.report!.smallNCRs = tmp;

                            state.controls.report!.reviewedControls = [...new Set([...state.controls.report!.reviewedControls, payload.resp.controlId])]
                        }
                    }
                })

                .addCase(changeStatusNCRByAuditorAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isChangingStatusNcr.push({id: data.ncrId, status: data.status});
                    state.dialogs.changeStatusNcr.isLoading = true;
                })
                .addCase(changeStatusNCRByAuditorAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isChangingStatusNcr = state.isLoading.isChangingStatusNcr.filter(e => e.id !== data.ncrId);
                    state.dialogs.changeStatusNcr.isLoading = false;
                })
                .addCase(changeStatusNCRByAuditorAction.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    const {data: {ncrId}, resp: {status}} = payload;
                    state.isLoading.isChangingStatusNcr = state.isLoading.isChangingStatusNcr.filter(e => e.id !== ncrId);
                    state.dialogs.changeStatusNcr.isLoading = false;

                    {
                        const tmp = state.selectedControl.NCRs;
                        const index = tmp.findIndex(e => e.id === ncrId);
                        if (index > -1) {
                            tmp[index] = {...tmp[index], status};
                            state.selectedControl.NCRs = tmp;
                        }
                    }

                    {
                        const tmp = state.selectedControl.selectedControl?.smallNCRs;
                        const index = tmp?.findIndex(e => e.id === ncrId) ?? -1;
                        if (index > -1 && tmp) {
                            tmp[index] = {...tmp[index], status};
                            state.selectedControl.selectedControl!.smallNCRs = tmp;
                        }
                    }

                    {
                        const tmp = state.controls.controls;
                        let indexSmall = -1;
                        const index = tmp.findIndex(e => e?.smallNCRs.some((e, index) => {if (e.id === ncrId) {indexSmall = index; return true} else return false}));
                        if (index > -1 && indexSmall > -1) {
                            tmp[index]!.smallNCRs[indexSmall] = {...tmp[index]!.smallNCRs[indexSmall], status}
                            state.controls.controls = tmp;
                        }
                    }

                    {
                        const tmp = state.controls.report?.smallNCRs;
                        const index = tmp?.findIndex(e => e.id === ncrId) ?? -1;
                        if (index > -1 && tmp) {
                            tmp[index] = {...tmp[index], status};
                            state.controls.report!.smallNCRs = tmp;
                        }
                    }

                    state.dialogs.changeStatusNcr = initialState.dialogs.changeStatusNcr;
                })

                .addCase(downloadAuditorEvidenceFileAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isDownloadingFile.push(data.fileId);
                })
                .addCase(downloadAuditorEvidenceFileAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isDownloadingFile = state.isLoading.isDownloadingFile.filter(e => e !== data.fileId);
                })
                .addCase(downloadAuditorEvidenceFileAction.fulfilled, (state: TAuditSpaceSlice, {payload: {data, resp}}) => {
                    state.isLoading.isDownloadingFile = state.isLoading.isDownloadingFile.filter(e => e !== data.fileId);
                })

                .addCase(downloadReportCollectorAssetByAuditorAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isDownloadingFile.push(data.assetId);
                })
                .addCase(downloadReportCollectorAssetByAuditorAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isDownloadingFile = state.isLoading.isDownloadingFile.filter(e => e !== data.assetId);
                })
                .addCase(downloadReportCollectorAssetByAuditorAction.fulfilled, (state: TAuditSpaceSlice, {payload: {data, resp}}) => {
                    state.isLoading.isDownloadingFile = state.isLoading.isDownloadingFile.filter(e => e !== data.assetId);
                })

                .addCase(getAuditorEvidenceAssetTableAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isLoadingAsset = data.id;
                })
                .addCase(getAuditorEvidenceAssetTableAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isLoadingAsset = null;
                })
                .addCase(getAuditorEvidenceAssetTableAction.fulfilled, (state: TAuditSpaceSlice, {payload: {data, resp}}) => {
                    state.isLoading.isLoadingAsset = null;

                    state.dialogs.collectorView.isOpen = true;
                    state.dialogs.collectorView.pageInfo = resp.pageInfo;
                    state.dialogs.collectorView.data = {
                        evidenceId: data.evidenceId,
                        tableFields: resp.tableFields,
                        data: resp.data,
                        id: data.id,
                        type: 'COLLECTOR',
                        name: '',
                        lastCollected: ''
                    };
                })

                .addCase(getAuditorEvidenceAssetStatementAction.pending, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isLoadingAsset = data.id;
                })
                .addCase(getAuditorEvidenceAssetStatementAction.rejected, (state: TAuditSpaceSlice, {meta: {arg: {clean, data}}}) => {
                    state.isLoading.isLoadingAsset = null;
                })
                .addCase(getAuditorEvidenceAssetStatementAction.fulfilled, (state: TAuditSpaceSlice, {payload: {data, resp}}) => {
                    state.isLoading.isLoadingAsset = null;

                    state.dialogs.statement.isOpen = true;
                    state.dialogs.statement.data = resp.data;
                })

                //GetInitIntegrationsData
                .addCase(getIntegrationsReportAction.pending, (slice: TAuditSpaceSlice) => {
                    slice.integrations.loading.isAppListLoading = true;
                })
                .addCase(getIntegrationsReportAction.rejected, (slice: TAuditSpaceSlice) => {
                    slice.integrations.loading.isAppListLoading = false;
                })
                .addCase(getIntegrationsReportAction.fulfilled, (slice: TAuditSpaceSlice, {payload: {data, resp}}) => {
                    slice.integrations.loading.isAppListLoading = false;
                    slice.integrations.integrations = resp.apps;
                    slice.integrations.report = resp.report;
                    slice.integrations.organization = resp.organization;
                })
                //GetDataByCollection
                .addCase(getIntegrationDataByCollectionReportsAction.pending, (slice: TAuditSpaceSlice) => {
                    slice.integrations.loading.isDataByCollectionLoading = true;
                })
                .addCase(getIntegrationDataByCollectionReportsAction.rejected, (slice: TAuditSpaceSlice) => {
                    slice.integrations.loading.isDataByCollectionLoading = false;
                })
                .addCase(getIntegrationDataByCollectionReportsAction.fulfilled, (slice: TAuditSpaceSlice, {payload}) => {
                    slice.integrations.loading.isDataByCollectionLoading = false;

                    const {array, pageInfo} = setPaginationInStore<Record<string, any>[number]>(
                        slice.integrations.selectedApp.collectionDataPageInfo,
                        slice.integrations.selectedApp.collectionData,
                        payload.resp.pageInfo,
                        payload.resp.data,
                        false
                    );
                    slice.integrations.selectedApp.collectionData = array;
                    slice.integrations.selectedApp.collectionDataPageInfo = pageInfo;
                })
                //GetAppLogs
                .addCase(GetAppLogsAction.pending, (slice: TAuditSpaceSlice) => {
                    slice.integrations.loading.isLogsDownloading = true;
                })
                .addCase(GetAppLogsAction.rejected, (slice: TAuditSpaceSlice) => {
                    slice.integrations.loading.isLogsDownloading = false;
                })
                .addCase(GetAppLogsAction.fulfilled, (slice: TAuditSpaceSlice, {payload: {resp, data}}) => {
                    slice.integrations.loading.isLogsDownloading = false;

                    const {array, pageInfo} = setPaginationInStore<TReportIntegrationLog>(
                        slice.integrations.selectedApp.logsData.pageInfo,
                        slice.integrations.selectedApp.logsData.logs,
                        resp.pageInfo,
                        resp.logs,
                        false
                    );
                    slice.integrations.selectedApp.logsData.logs = array;
                    slice.integrations.selectedApp.logsData.pageInfo = pageInfo;
                })
                //DownloadReport
                .addCase(DownloadLogAction.pending, (slice: TAuditSpaceSlice) => {
                    slice.integrations.loading.isLogDownloading = true;
                })
                .addCase(DownloadLogAction.rejected, (slice: TAuditSpaceSlice) => {
                    slice.integrations.loading.isLogDownloading = false;
                })
                .addCase(DownloadLogAction.fulfilled, (slice: TAuditSpaceSlice, {payload}) => {
                    slice.integrations.loading.isLogDownloading = false;
                })
                //DownloadLogForPreview
                .addCase(DownloadLogForPreviewAction.pending, (slice: TAuditSpaceSlice) => {
                    slice.integrations.loading.isLogPreviewLoading = true;
                })
                .addCase(DownloadLogForPreviewAction.rejected, (slice: TAuditSpaceSlice) => {
                    slice.integrations.loading.isLogPreviewLoading = false;
                })
                .addCase(DownloadLogForPreviewAction.fulfilled, (slice: TAuditSpaceSlice, {payload: {resp, data}}) => {
                    slice.integrations.loading.isLogPreviewLoading = false;
                    if (slice.integrations.selectedApp){
                        slice.integrations.selectedApp.logsData.selectedLogResponse = resp;
                    }
                })

                .addCase(SEND_SHARED_FOR_AUDITORS_REPORT_ACTION.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    if (state.reports.organization?.id === payload.organizationId) {
                        state.reports.reports.unshift({...payload.report, workspaceId: ''})
                    }
                })
                .addCase(SEND_REPORT_STATUS_CHANGED_ACTION.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    {
                        const tmp = state.organizations.organizations;
                        const index = tmp.findIndex(e => e?.reports.includes(payload.reportId));
                        if (index > -1) {
                            tmp[index]!.reports.push(payload.reportId);
                            state.organizations.organizations = tmp;
                        }
                    }

                    {
                        const tmp = state.reports.reports;
                        const index = tmp.findIndex(e => e?.id === payload.reportId);
                        if (index > -1) {
                            tmp[index]!.status = payload.status;
                            state.reports.reports = tmp;
                        }
                    }

                    if (state.controls.report?.id === payload.reportId) {
                        state.controls.report!.status = payload.status
                    }
                })
                .addCase(SEND_CREATE_UPDATE_NCR_CONFIG_ACTION.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    if (state.controls.report?.id === payload.reportId) {
                        state.controls.report.reportNCR = payload.ncrReport;
                    }
                })
                .addCase(SEND_UPDATE_REVIEWED_CONTROL_ACTION.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    if (payload.reportId === state.controls.report?.id) {
                        if (payload.reviewed) {
                            state.controls.report.reviewedControls = [...new Set([...state.controls.report.reviewedControls, payload.controlId])]
                        } else {
                            state.controls.report.reviewedControls = state.controls.report.reviewedControls.filter(e => e !== payload.controlId)
                        }
                    }

                    {
                        const tmp = state.controls.controls;
                        const index = tmp.findIndex(e => e?.id === payload.controlId);
                        if (index > -1) {
                            tmp[index]!.reviewed = payload.reviewed;
                            state.controls.controls = tmp;
                        }
                    }

                    if (state.selectedControl.selectedControl?.id === payload.controlId) {
                        state.selectedControl.selectedControl.reviewed = payload.reviewed
                    }
                })
                .addCase(SEND_UPDATE_NCR_REPORT_ACTION.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    {
                        const tmp = state.controls.controls;
                        const index = tmp.findIndex(e => e?.id === payload.ncrControl.controlId);
                        if (index > -1) {
                            const controlIndex = tmp[index]!.smallNCRs.findIndex(e => e.id === payload.ncrControl.id);
                            const small = {id: payload.ncrControl.id, status: payload.ncrControl.status, type: payload.ncrControl.type};
                            if (controlIndex > -1) {
                                tmp[controlIndex]!.smallNCRs[controlIndex] = small;
                            } else {
                                tmp[controlIndex]!.smallNCRs.push(small);
                            }

                            state.controls.controls = tmp;
                        }
                    }

                    if (state.selectedControl.selectedControl?.id === payload.ncrControl.id) {
                        state.selectedControl.users.push(...payload.users);
                        const tmp = state.selectedControl.NCRs;
                        const index = tmp.findIndex(e => e.id === payload.ncrControl.id);
                        if (index > -1) {
                            tmp[index] = payload.ncrControl;
                        } else {
                            tmp.push(payload.ncrControl)
                        }

                        state.selectedControl.NCRs = tmp;
                    }
                })
                .addCase(SEND_DELETE_AUDITOR_ACTION.fulfilled, (state: TAuditSpaceSlice, {payload}) => {
                    {
                        const tmp = state.organizations.organizations;
                        const index = tmp.findIndex(e => e?.id === payload.organizationId);
                        if (index > -1) {
                            tmp[index]!.reports = tmp[index]!.reports.filter(e => e !== payload.reportId);
                            state.organizations.organizations = tmp;
                        }
                    }

                    {
                        const tmp = state.reports.reports;
                        const index = tmp.findIndex(e => e?.id === payload.reportId);
                        if (index > -1) {
                            state.reports.pageInfo.total--;
                            delete tmp[index];

                            state.reports.reports = tmp;
                        }
                    }
                })
        } 
    
})

export const AuditSpaceReducer = slice.reducer;
export const {
    setCollectorViewAction, setPreviewDialogAction, setStatementDialogAction, setRejectReportDialogAction, setAuditorsDialogAction, setChangeNcrStatusDialogAction,
    deselectCollectionObject, setSelectedCollectionObject, deselectApplicationAction, deselectLogAction, deselectLogFetchResultAction, selectLogAction, eraseCollectionDataBeforeFetch,

    clearState
} = slice.actions;

//selectors
const selectSelf = (state: AppState): TAuditSpaceSlice => state.auditSpace;

export const isLoadingSelector = createSelector(selectSelf, state => state.isLoading);

export const reportOrganizationsSelector = createSelector(selectSelf, state => state.organizations);
export const reportsSelector = createSelector(selectSelf, state => state.reports);
export const reportViewSelector = createSelector(selectSelf, state => state.controls);
export const selectedControlSelector = createSelector(selectSelf, state => state.selectedControl);

export const reportDialogSelector = createSelector(selectSelf, state => state.dialogs);


export const reportIntegrationsSelector = createSelector(selectSelf, state => state.integrations);
export const reportIntegrationSelectedSelector = createSelector(selectSelf, state => state.integrations.selectedApp);
export const reportIntegrationLogsSelector = createSelector(selectSelf, state => state.integrations.selectedApp.logsData);




