import {print} from "graphql";
import {gql} from "@apollo/react-hooks";

export const getUserProfileGQL = print(gql`
    query getUserProfileGQL {
        getCurrentProfile {
            lastName
            firstName
            email
            publicId
        }
    }
`)

export const changeUserProfileInfoGQL = print(gql`
    mutation changeUserProfileInfoGQL($data: ChangeNameInput!) {
        changeName(data: $data) {
            message
        }
    }
`)

export const changeUserProfilePassGQL = print(gql`
    mutation changeUserProfilePassGQL($data: ChangePasswordInput!) {
        changePassword(data: $data) {
            message
        }
    }
`)