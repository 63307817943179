import {useDispatch, useSelector} from "react-redux";
import {currentFilterSelector, erase} from "../../store/slice";

export const useGenericFiltersStorage = () => {
    const currentFilter = useSelector(currentFilterSelector);
    const dispatch = useDispatch();

    const clearFilters = () => {
        console.log(`useGenericFiltersStorage clearFilters`)
        dispatch(erase());
    };

    const isFilterEmpty = !Object.entries(currentFilter.filters).some(e => e[1].length > 0); //has any filter filled
    const isEmptySearch = !currentFilter.search.trim().length;
    return{
        clearFilters,
        isFilterEmpty,
        isEmptySearch,
        currentFiltersForFetch: currentFilter.filters,
        currentSearchForFetch: currentFilter.search,
        isSearchAndFilterEmpty: isFilterEmpty && isEmptySearch,
        originalSelectedValues: currentFilter.originalSelectedValues
    }
}