import {FC} from "react";
import {Box} from "@mui/material";
import {ReportRoutes} from "./routes";

export const AuditSpacePage: FC = () => {
    return (
        <Box width={'100%'} height={'100%'} overflow={"hidden"} p={'20px'}>
            <Box width={'100%'} height={'100%'} overflow={"hidden"}>
                <ReportRoutes/>
            </Box>
        </Box>
    )
}
