import React, {ReactElement, useState} from "react";
import {Flex} from "../Layouts";
import {Breadcrumbs} from "../../../newDomain/barsEnvironment/breadcrumbs";
import styled from "styled-components";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import colors from "../../theme/colors";
import EastIcon from "@mui/icons-material/East";
import {
    Box,
    Icon,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Skeleton,
    SvgIcon, Tooltip, tooltipClasses,
    TooltipProps,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory} from "react-router-dom";
import {useMedia} from "../../hooks/useMedia";
import {LoadingButton} from "@mui/lab";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {DataHasBeenUpdatedFlag} from "../dataHasBeenUpdatedFlag";
import {TypographyBody2} from "../Inputs/styled";
import { TypographyNew } from "../TypographyNew";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({

    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: colors.backgrounds.grey_light,
        borderRadius: '6px',
        padding: '8px 16px',
        color: colors.text.dark,
        fontWeight: 400,
        fontSize: '14px',
        marginTop: '0px !important',
    },

    [`& .${tooltipClasses.arrow}`]: {
        color: colors.backgrounds.grey_light,
    }
}));

export type actionType = {
    onClick: () => void;
    disabled?: boolean;
    variant?: "text" | "round" | "outlined" | "contained" | undefined;
    icon?: typeof Icon | typeof SvgIcon | React.ReactNode | undefined;
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | "lightPrimary" | "lightError" | "lightInfo" | "lightSuccess" | "lightSecondary" | "accent" | "accentDark" | undefined;
    loading?: boolean;
    size?: "small" | "medium" | "large" | undefined;
    title: string;
    dontShowWhen?: boolean;
    tooltip?: string

}

export type TPageTitleAndActionsProps = {
    title?: string | React.ReactNode | null | undefined; //if not string - skeleton
    titleMaxWidth?: string; //ellipsis
    subTitle?: string | React.ReactNode;
    handleTitleClick?: () => void;
    icon?: React.ReactNode | undefined;
    handleGoBack?: (() => void) | string | undefined, //if string - history.push / else execute func
    showMenuWhen?: boolean;
    showParticularMenuAfterElementIndex?: number | undefined; //hide part of elements after arr[elementIndex] into menu
    titleAndActionsBoxJC?: string | undefined; //flex-start
    titleAndActionsBoxDirection?: string | undefined; //row
    actions: actionType[];
    children?: React.ReactNode[] | React.ReactNode | Element[] | Element | undefined;
    disableBreadcrumbs?: boolean;
    customBreadcrumbs?: React.ReactNode | undefined;
    disableMenuOpenWhen?: boolean;
    isChildrenOnRight?: boolean;
    margin?: string,
    showDataHasBeenUpdated?: boolean;
    startMessage?: string,
    endMessage?:string,
    onReloadData?: () => void;
    firstElement?: ReactElement | null;
}

export const PageTitleAndActions = ({
    title,
    subTitle,
    titleMaxWidth,
    handleTitleClick,
    icon,
    handleGoBack,
    showMenuWhen = false,
    showParticularMenuAfterElementIndex,
    titleAndActionsBoxJC = 'flex-start',
    titleAndActionsBoxDirection = 'row',
    actions,
    disableBreadcrumbs = false,
    disableMenuOpenWhen = false,
    children,
    customBreadcrumbs,
    isChildrenOnRight = false,
    margin = undefined,
    showDataHasBeenUpdated = false,
    startMessage,
    endMessage,
    onReloadData = () => null,
    firstElement = null,
}: TPageTitleAndActionsProps) => {

    const history = useHistory();
    const {revDir} = useMainTranslation();
    const {matches_1920Up} = useMedia();

    const goBack = () => {
        if(handleGoBack !== undefined){
            if(typeof handleGoBack === 'string'){
                history.push(handleGoBack);
            }else{
                handleGoBack();
            }
        }
    }

    //MENU
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return(
        <Flex w={'100%'} direction={'column'} m={margin}>
            <DataHasBeenUpdatedFlag show={showDataHasBeenUpdated} onClick={onReloadData} startMessage={startMessage} endMessage={endMessage}/>

            {!disableBreadcrumbs &&
                <>
                    {customBreadcrumbs ? customBreadcrumbs : <Breadcrumbs/>}
                </>
            }

            <PageTopButtonContainer
                titleAndActionsBoxJC={showMenuWhen ? 'space-between' : titleAndActionsBoxJC}
                // when its time to show menu - making row (title)     [menuIcon]
                titleAndActionsBoxDirection={titleAndActionsBoxDirection}
            >
                {/*TITLE*/}
                <Flex ai={'center'} overflow={'hidden'}>
                    {handleGoBack !== undefined &&
                        <>
                            {!revDir ?
                                <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={goBack}/>
                                :
                                <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={goBack}/>
                            }
                        </>
                    }
                    {icon &&
                        <Flex m={handleGoBack !== undefined ? '0 8px' : (!revDir ? '0 8px 0 0' : '0 0 0 8px')}>
                            {icon}
                        </Flex>
                    }
                    <Flex
                        overflow={'hidden'}
                        maxw={titleMaxWidth}
                        m={(handleGoBack !== undefined && icon === undefined) ? (!revDir ? '0 0 0 12px' : '0 12px 0 0') : undefined}
                    >
                        {(typeof title === 'string' || !title) ?
                            <TypographyNew
                                variant={handleTitleClick ? 'h3' : matches_1920Up ? 'h1' : "h3"}
                                fontWeight={handleTitleClick ? '400' : matches_1920Up ? 700 : 400}
                                sx={{
                                    // margin: !revDir ? '0 32px 0 0' : '0 0 0 32px',
                                    color: colors.backgrounds.dark,
                                    // whiteSpace: 'nowrap',
                                    // textOverflow: 'ellipsis',
                                    // overflow: 'hidden',
                                    '&:hover': {
                                        color: handleTitleClick ? colors.primary.blue : undefined,
                                        cursor: handleTitleClick ? 'pointer' : undefined,
                                    },
                                }}
                                onClick={handleTitleClick}
                                noWrap
                            >
                                {title ?? <Skeleton width={200}/>}
                            </TypographyNew>
                            :
                            title
                        }
                    </Flex>
                </Flex>

                {subTitle && <TypographyBody2 sx={{ml: !revDir ? '12px' : undefined, mr: revDir ? '12px' : undefined }}>{subTitle}</TypographyBody2>}
                {firstElement && <Box sx={{ml: !revDir ? '12px' : undefined, mr: revDir ? '12px' : undefined }}>{firstElement}</Box>}

                <Flex ai={'center'} m={revDir ? '0 32px 0 0' : '0 0 0 32px'}>
                    {/*CUSTOM NODES*/}
                    {!isChildrenOnRight && children !== undefined && children}

                    {/*MENU*/}
                    {showParticularMenuAfterElementIndex !== undefined ?
                        <>
                            {actions.filter(e => !e.dontShowWhen).splice(0, showParticularMenuAfterElementIndex + 1).map(e => {
                                return(
                                    <LoadingButton
                                        key={e.title}
                                        color={e.color}
                                        loading={e.loading}
                                        startIcon={e.icon}
                                        disabled={e.disabled}
                                        variant={e.variant ?? 'contained'}
                                        // sx={{paddingLeft: !e.icon ? undefined : '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'  }}
                                        size={e.size ?? (matches_1920Up ? 'large' : 'small')}
                                        onClick={e.onClick}
                                    >
                                        <Typography fontWeight={600} fontSize={matches_1920Up ? '15px' : '13px'} noWrap>{e.title}</Typography>
                                    </LoadingButton>
                                )
                            })}

                            {actions.filter(e => !e.dontShowWhen).splice(showParticularMenuAfterElementIndex + 1).length > 0 ?
                                <>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        disabled={disableMenuOpenWhen}
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon sx={{color: disableMenuOpenWhen ? colors.primary.disable : colors.primary.blue}}/>
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{'aria-labelledby': 'long-button',}}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        {actions.filter(e => !e.dontShowWhen).splice(showParticularMenuAfterElementIndex + 1).map((e) => {
                                            return(
                                                <MenuItem key={e.title} onClick={e.onClick} disabled={e.disabled}>
                                                    {e.icon &&
                                                        <ListItemIcon>
                                                            {e.icon}
                                                        </ListItemIcon>
                                                    }
                                                    <Typography>{e.title}</Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Menu>
                                </>
                                :
                                <></>
                            }
                        </>
                        :
                        <>
                            {showMenuWhen ?
                                <>
                                    {actions.filter(e => !e.dontShowWhen).length > 0 ?
                                        <>
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={open ? 'long-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-haspopup="true"
                                                disabled={disableMenuOpenWhen}
                                                onClick={handleClick}
                                            >
                                                <MoreVertIcon sx={{color: disableMenuOpenWhen ? colors.primary.disable : colors.primary.blue}}/>
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{'aria-labelledby': 'long-button',}}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                {actions.filter(e => !e.dontShowWhen).map((e) => {
                                                    return(
                                                        <MenuItem key={e.title} onClick={() => {handleClose(); e.onClick();}} disabled={e.disabled}>
                                                            {e.icon &&
                                                                <ListItemIcon>
                                                                    {e.icon}
                                                                </ListItemIcon>
                                                            }
                                                            <Typography>{e.title}</Typography>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Menu>
                                        </>
                                        :
                                        <></>
                                    }
                                </>
                                :
                                <>
                                    {actions.filter(e => !e.dontShowWhen).map(e => {
                                        return e.tooltip ?
                                            <LightTooltip title={e.tooltip} key={e.title}>
                                                <span>
                                                    <LoadingButton
                                                        key={e.title}
                                                        disabled={e.disabled}
                                                        color={e.color}
                                                        loading={e.loading}
                                                        startIcon={e.icon}
                                                        variant={e.variant ?? 'contained'}
                                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', }}
                                                        // sx={{paddingLeft: !e.icon ? undefined : '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                        size={e.size}
                                                        onClick={e.onClick}
                                                    >
                                                        <Typography fontWeight={600} fontSize={matches_1920Up ? '15px' : '13px'} noWrap>{e.title}</Typography>
                                                    </LoadingButton>
                                                </span>
                                            </LightTooltip>
                                        :
                                            <LoadingButton
                                                key={e.title}
                                                disabled={e.disabled}
                                                color={e.color}
                                                loading={e.loading}
                                                startIcon={e.icon}
                                                variant={e.variant ?? 'contained'}
                                                sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px', }}
                                                // sx={{paddingLeft: !e.icon ? undefined : '35px', margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                size={e.size}
                                                onClick={e.onClick}
                                            >
                                                <Typography fontWeight={600} fontSize={matches_1920Up ? '15px' : '13px'} noWrap>{e.title}</Typography>
                                            </LoadingButton>
                                    })}
                                </>
                            }
                        </>
                    }

                    {/*CUSTOM NODES*/}
                    {isChildrenOnRight && children !== undefined && children}
                </Flex>
            </PageTopButtonContainer>
        </Flex>
    )
}

export const PageTopButtonContainer = styled.div<{titleAndActionsBoxJC: string, titleAndActionsBoxDirection: string}>`
    display: flex;
    margin: 8px 0 0 0;
    //if direction column - ai=center will ruin it - making flex-start to prevent
    align-items: ${props => props.titleAndActionsBoxDirection !== 'row' ? 'flex-start' : 'center'};
    direction: ${props => props.titleAndActionsBoxDirection};
    justify-content: ${props => props.titleAndActionsBoxJC};
    overflow: hidden;
`;
