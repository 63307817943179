import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/workspaces/hooks/useManageWorkspacesAndOrganizations";
import {changePasswordApi} from "../../api";
import {addSuccessfulSnack} from "../../../../barsEnvironment/snack/store/slice";
import * as yup from "yup";
import {useFormik} from "formik";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useChangeUserPassword = () => {
    const dispatch = useDispatch();

    const {t} = useMainTranslation('', {keyPrefix: 'UserProfile'})
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showPassword, setShowPassword] = useState<string[]>([]);
    const switchShowPassword = (str: string) => {
        setShowPassword(prev => prev.includes(str) ? prev.filter(e => e !== str) : [...prev, str])
    }

    const handleSubmit = (oldPass: string, newPass: string, publicId: string) => {
        setIsLoading(true);

        currentUser && changePasswordApi({data: {oldPass, newPass, publicId}})
            .then(res => {
                dispatch(addSuccessfulSnack(res.message));
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    useEffect(() => {
        return () => {
            formik.resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validationSchema = yup.object({
        oldPass: yup
            .string()
            .min(8, t('Password should be of minimum 8 characters length'))
            .required(t('Old password is required')),
        newPass: yup
            .string()
            .min(8, t('Password should be of minimum 8 characters length'))
            .matches(
                /(?=.*[A-Z])/,
                t("Must Contain one Uppercase character")
            )
            .matches(
                /(?=.*[a-z])/,
                t("Must Contain one Lowercase character")
            )
            .matches(
                /(?=.*[0-9])/,
                t("Must Contain one Number character")
            )
            .matches(
                /(?=.*[!@#$%^&*])/,
                t("Must contain one Special case character")
            )
            .required(t('New password is required')),
        confirmPass: yup
            .string()
            .oneOf([yup.ref("newPass"), null], t("Password does not match"))
            .required(t('Confirm password is required')),
    });

    const formik = useFormik<{oldPass: string, newPass: string, confirmPass: string}>({
        initialValues: {
            oldPass: '',
            newPass: '',
            confirmPass: '',
        },
        validationSchema: validationSchema,
        validateOnChange: true,
        onSubmit: ({oldPass, newPass}) => {
            currentUser?.publicId && handleSubmit(oldPass, newPass, currentUser.publicId)
        },
    });

    return {
        showPassword, switchShowPassword,
        formik,

        handleSubmit,
        isLoading,
    }
}