import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {TBreadcrumb} from "../types";

export type TBreadcrumbsSlice = {
    breadcrumbs: TBreadcrumb[],
    maxItems: number,
}

const initialState: TBreadcrumbsSlice = {
    breadcrumbs: [],
    maxItems: 10,
}

const Slice = createSlice({
  name: 'Breadcrumbs',
  initialState,
  reducers: {
      setBreadcrumbsAction(slice: TBreadcrumbsSlice, {payload}: {payload: TBreadcrumbsSlice["breadcrumbs"]}) {
          slice.breadcrumbs = payload
      },
      setBreadcrumbsSizeAction(slice: TBreadcrumbsSlice, {payload}: {payload: TBreadcrumbsSlice["maxItems"]}) {
          slice.maxItems = payload
      },
  },
  // extraReducers: builder => {
  //   builder
  //
  // }
});

export const breadcrumbsReducer = Slice.reducer;

export const {
    setBreadcrumbsAction,
    setBreadcrumbsSizeAction
} = Slice.actions;

const selectSelf = (state: AppState): TBreadcrumbsSlice => state.breadcrumbs;

export const breadcrumbsSelector = createSelector(selectSelf, state => state.breadcrumbs);
export const maxCountBreadcrumbsSelector = createSelector(selectSelf, state => state.maxItems);
