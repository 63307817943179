import {useDispatch, useSelector} from "react-redux";
import {
    breadcrumbsSelector,
    maxCountBreadcrumbsSelector,
    setBreadcrumbsAction,
    setBreadcrumbsSizeAction
} from "../../store/slice";
import {useEffect, useMemo} from "react";
import {TBreadcrumb} from "../../types";

export const useBreadcrumbs = () => {
    const dispatch = useDispatch();

    const breadcrumbs = useSelector(breadcrumbsSelector);
    const maxItems = useSelector(maxCountBreadcrumbsSelector);

    const setBreadcrumbs = (array: TBreadcrumb[]) => {dispatch(setBreadcrumbsAction(array))};
    const setBreadcrumbsMaxSize = (count: number) => {dispatch(setBreadcrumbsSizeAction(count))};

    return useMemo(() => ({
        breadcrumbs,
        maxItems,

        setBreadcrumbs,
        setBreadcrumbsMaxSize
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [breadcrumbs, maxItems]);
}

export const useSetBreadcrumbs = (titlesAndPaths: TBreadcrumb[], maxCount?: number) => {
    const {setBreadcrumbs, setBreadcrumbsMaxSize} = useBreadcrumbs();

    useEffect(() => {
        setBreadcrumbs(titlesAndPaths);
        maxCount !== undefined && setBreadcrumbsMaxSize(maxCount)
        return () => {
            setBreadcrumbs([])
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(titlesAndPaths)]);
}