import {Box, Flex} from "../../../../../../newShared/components/Layouts";
import {ReactComponent as DrawerLogo} from "../../../../../../newShared/images/login/logo.svg";
import {ReactComponent as DrawerLogoText} from "../../../../../../newShared/images/login/name.svg";
import React from "react";

export const LogoText = (props: { goToOverview: () => void }) => (
    <Flex jc="center" ai="center" onClick={props.goToOverview}
          cursor="pointer" m={'0 0 0 0'}>
        <Box p="0 10px 0 0 ">
            <DrawerLogo style={{width: "20px", height: "50px"}}/>
        </Box>
        <Box p="0 5px">
            <DrawerLogoText style={{width: "100px", height: "50px"}}/>
        </Box>
    </Flex>
)
