import {filterType, keyConfig} from "./types";

export const sortFilters = (filterA: keyConfig<any>, filterB: keyConfig<any>, defaults: string[], selectionSequence: string[]): number => {
    const isDefaultA = defaults.some(e => e === filterA.key);
    const isDefaultB = defaults.some(e => e === filterB.key);
    if(isDefaultA && isDefaultB){
        return 0;
    }else{
        if(isDefaultA && !isDefaultB){
            return 0;
        }else if(!isDefaultA && isDefaultB){
            return -1;
        }
        //returning original sorting if its not default filters (by added order)
        return selectionSequence.indexOf(filterA.key) - selectionSequence.indexOf(filterB.key)
    }
}

export const sortAddFiltersList = (filterA: filterType, filterB: filterType, disabledChangeOptions: string[]): number => {
    const isDisabledA = disabledChangeOptions.some(e => e === filterA.key);
    const isDisabledB = disabledChangeOptions.some(e => e === filterB.key);
    if(isDisabledA && isDisabledB){
        return 0;
    }else{
        if(isDisabledA && !isDisabledB){
            return 0;
        }else if(!isDisabledA && isDisabledB){
            return 1;
        }
        return 0;
    }
}