import React, {FC} from "react";
import {AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import {Menu as MenuIcon} from "@mui/icons-material";

import {useMainTranslation} from "../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    UseManageWorkspacesAndOrganizations
} from "../../authWorkspacesCookies/workspaces/hooks/useManageWorkspacesAndOrganizations";
import {useTopBar} from "./hooks/useTopBar";

import colors from "../../../newShared/theme/colors";
import {TopBarHelp} from "./components/topBarHelp";
import {TopBarAvatar} from "./components/topBarAvatar";
import {useLeftMenu} from "../leftMenu/hooks/useLeftMenu";
import {PATH_LOCAL_LOGIN, PATH_LOCAL_NEW_WORKSPACES} from "../../../newShared/constants";
import {useHistory} from "react-router";
import {ThreeDotsTopBar} from "./components/threeDotsTopBar";
import {useMedia} from "../../../newShared/hooks/useMedia";
import {TobBarLogo} from "../../../newShared/components/TobBarLogo";

type TProps = {
    isHidden: boolean,
}
export const TopBar: FC<TProps> = ({isHidden}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'TopBar'});
    const history = useHistory();

    const {currData: {
        currentUser, selectedWorkspace, selectedOrganization, is2FaAuthComplete
    }} = UseManageWorkspacesAndOrganizations();

    const {
        anchorPageEl,
        //handlePageOpen,
        handlePageClose,
        handleGoToMain,
        handleGoToPortal,
    } = useTopBar();

    const {isOpen, setOpen} = useLeftMenu();
    const {isDesktop} = useMedia();

    return !isHidden ? (
        <AppBar position={"static"} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                {currentUser?.publicId && (
                    ((selectedWorkspace && selectedOrganization) || true) ? (
                        <IconButton
                            disabled={history.location.pathname.includes(PATH_LOCAL_NEW_WORKSPACES)}
                            size={"large"}
                            sx={{color: isOpen ? colors.primary.blue : colors.text.grey_dark}}
                            onClick={() => {setOpen()}}
                        >
                            <MenuIcon/>
                        </IconButton>
                    ) : (
                        <IconButton
                            disabled={history.location.pathname.includes(PATH_LOCAL_NEW_WORKSPACES)}
                            size={"large"}
                            sx={{color: isOpen ? colors.primary.blue : colors.text.grey_dark}}
                            onClick={() => {history.push(PATH_LOCAL_NEW_WORKSPACES)}}
                        >
                            <MenuIcon/>
                        </IconButton>
                    )
                )}

                <TobBarLogo />

                {isDesktop ? (
                    <>
                        {currentUser?.publicId && (
                            <Typography variant={"body2"} color={colors.text.grey_dark}>{t('page.Reports')}</Typography>
                            // <Button variant={"text"} onClick={handlePageOpen} sx={{padding: '8px 4px', textTransform: 'none'}}>
                            //     <Box p={'0 0 2px 0'} borderBottom={'2px solid ' + Boolean(anchorPageEl) ? colors.primary.blue : colors.text.grey_dark}>
                            //         <Typography variant={"body2"} color={colors.text.grey_dark}>{t('page.Reports')}</Typography>
                            //     </Box>
                            //     <Apps sx={{
                            //         color: Boolean(anchorPageEl) ? colors.primary.blue : colors.text.grey_dark,
                            //         margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'
                            //     }}/>
                            // </Button>
                        )}
                        <Menu
                            keepMounted
                            anchorEl={anchorPageEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorPageEl)}
                            onClose={handlePageClose}
                        >
                            <MenuItem onClick={handleGoToMain}>{t('page.Main app')}</MenuItem>
                            {/*<MenuItem onClick={handleGoToReports}>{t('page.Reports')}</MenuItem>*/}
                            <MenuItem onClick={handleGoToPortal}>{t('page.Portal')}</MenuItem>
                        </Menu>

                        <Box height={'100%'} minWidth={'10px'} flexGrow={1}/>


                        {/*{is2FaAuthComplete && currentUser?.publicId && selectedWorkspace && selectedOrganization && <TopBarWorkspace/>}*/}
                        {/*{is2FaAuthComplete && currentUser?.publicId && <TopBarNotifications/>}*/}
                        <TopBarHelp/>
                        {/*{is2FaAuthComplete && currentUser?.publicId && <TopBarSettings/>}*/}
                        {is2FaAuthComplete && currentUser?.publicId && <TopBarAvatar/>}
                    </>
                ) : (
                    <>
                        <Box height={'100%'} minWidth={'10px'} flexGrow={1}/>
                        {is2FaAuthComplete && currentUser?.publicId && <ThreeDotsTopBar/>}
                    </>
                )}

                {!currentUser?.publicId && (
                    <Button onClick={() => {history.push(PATH_LOCAL_LOGIN)}}>{t('Login')}</Button>
                )}
            </Toolbar>
        </AppBar>
    ) : null
}