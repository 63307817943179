import {useHistory} from "react-router";
import {
    PATH_LOCAL_2FA,
    PATH_LOCAL_INITIAL_MFA_CONFIGURE,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_SIGNUP,
    PATH_LOCAL_SPACE,
    PATH_LOCAL_VERIFY_EMAIL
} from "../../../../../newShared/constants";

export const useRoutes = () => {
    const history = useHistory();

    const goToLogin = () => {history.push(PATH_LOCAL_LOGIN)};
    const goToVerifyEmail = () => {history.push(PATH_LOCAL_VERIFY_EMAIL)};
    const goToInitialMfa = () => {history.push(PATH_LOCAL_INITIAL_MFA_CONFIGURE)};
    const goToMfa = () => {history.push(PATH_LOCAL_2FA)};
    const goToSignUp = () => {history.push(PATH_LOCAL_SIGNUP)};
    const goToPassRestore = () => {history.push(PATH_LOCAL_PASSWORD_RESET)};
    const goToMfaRestore = () => {history.push(PATH_LOCAL_MFA_RESET)};
    const goToContactUs = () => {history.push(PATH_LOCAL_LOGIN_CONTACT_US)};

    const goToMain = () => {history.push(PATH_LOCAL_SPACE)};

    return {
        goToLogin,
        goToMfa,
        goToSignUp,
        goToPassRestore,
        goToMfaRestore,
        goToVerifyEmail,
        goToInitialMfa,
        goToContactUs,

        goToMain,
    }
}