import {useDispatch, useSelector} from "react-redux";
import {
    multiStoreDataType,
    TOrganizationWithWorkspaces,
    TUserInfo,
    TWorkspaceWithShortCollabsAndRequester
} from "../../types";
import {
    allMultiStoreData,
    eraseData,
    setSharedWorkspacesAndOrganizationsData
} from "../../store/sharedWorkspacesAndOrganizations/slice";
import {
    ORGANIZATION_ADMIN,
    ORGANIZATION_MEMBER,
    ORGANIZATION_OWNER,
    SELECT_WORKSPACES_PATH,
    WORKSPACE_ADMIN,
    WORKSPACE_EDITOR,
    WORKSPACE_OWNER,
    WORKSPACE_VIEWER
} from "../../constants";
import {useHistory} from "react-router-dom";
import {useCookies} from "../../../../../newShared/hooks/useCookies";
import {MAIN_DATA_COOKIE_NAME, PATH_LOCAL_LOGIN, PATH_LOCAL_NEW_WORKSPACES} from "../../../../../newShared/constants";
import {logoutAction} from "../../../signIn/store/actions";
import {generateNewToken} from "../../api";

export const UseManageWorkspacesAndOrganizations = () => {
    //this hooks is user to manage workspace/organizations/user data -> applies to another webs
    const dispatch = useDispatch();
    const history = useHistory();


    const {setCookieByName, getCookieByName, deleteCookieByName} = useCookies();

    const AllMultiStoreData = useSelector(allMultiStoreData) as multiStoreDataType;

    const setCurrentWorkspaceAndOrganization = async(workspace: TWorkspaceWithShortCollabsAndRequester, organization: TOrganizationWithWorkspaces, user?: TUserInfo) => {
        try {
            await generateNewToken(organization.id, workspace.id);
        }catch (ex: any){
            return;
        }
        const currentWorkspaceRoles: string[] = workspace.collaborators.filter(e => e.publicId === (user ? user.publicId : AllMultiStoreData.currentUser?.publicId)).map(e => e.role);
        const currentOrgRoles: string[] = organization.collaborators.filter(e => e.publicId === (user ? user.publicId : AllMultiStoreData.currentUser?.publicId)).map(e => e.role);

        const currData = {
            ...AllMultiStoreData,
            currentUser: user ? user : AllMultiStoreData.currentUser,
            selectedWorkspace: {...workspace, collaborators: [], requester: {}, organizationId: ''},
            selectedOrganization: {...organization, logo: '', workspaces: [], collaborators: [], invites: [], configuration: {...organization.configuration, availableWizards: [], availableTargetsVulnerabilityScan: organization.configuration.availableTargetsVulnerabilityScan ?? 1}, requester: {}, pageInfo: {}, blockedReason: ''}, //cookies can handle up to 4096bytes
            roles: {
                isWsViewer: currentWorkspaceRoles.some(e => e === WORKSPACE_VIEWER),
                isWsEditor: currentWorkspaceRoles.some(e => e === WORKSPACE_EDITOR),
                isWsAdmin: currentWorkspaceRoles.some(e => e === WORKSPACE_ADMIN),
                isWsOwner: currentWorkspaceRoles.some(e => e === WORKSPACE_OWNER),
                isOrgAdmin: currentOrgRoles.some(e => e === ORGANIZATION_ADMIN),
                isOrgMember: currentOrgRoles.some(e => e === ORGANIZATION_MEMBER),
                isOrgOwner: currentOrgRoles.some(e => e === ORGANIZATION_OWNER),
                isWsOutsideCollab: workspace.requester?.outsideCollaborator ?? false,
            }
        };

        setCookieData(currData);
        dispatch(setSharedWorkspacesAndOrganizationsData(currData));
    }

    const setUser = (user: TUserInfo) => {
        const currData = {...AllMultiStoreData, currentUser: user, is2FaAuthComplete: true};
        //getting user on 2fa page - so setting user and is2FaAuthComplete
        //if user updated - user already signed in - so is2FaAuthComplete already completed
        setCookieData(currData);
        dispatch(setSharedWorkspacesAndOrganizationsData(currData));
    }

    const setCookieData = (value: multiStoreDataType) => {
        setCookieByName(MAIN_DATA_COOKIE_NAME, JSON.stringify(value));
    }

    const getCookieData = (): multiStoreDataType | null => {
        const res = getCookieByName(MAIN_DATA_COOKIE_NAME);
        if(res){
            return JSON.parse(res) as multiStoreDataType;
        }else{
            return null;
        }
    }

    const logout = () => {
        dispatch(eraseData()); //clean inner store
        deleteCookieByName(MAIN_DATA_COOKIE_NAME); //clean cookies
        dispatch(logoutAction()); //send request to back to logout and clean auth cookie
        history.push(PATH_LOCAL_LOGIN); //go to login
    }

    const handle403Error = () => {
        //if user got 403 error - he have no access to do the action
        //or because he got wrong link or he got another role and it says he has to select w and o from zero to put new roles
        const newData = {
            ...AllMultiStoreData,
            selectedWorkspace: null,
            selectedOrganization: null,
            roles: {
                isOrgOwner: false,
                isOrgAdmin: false,
                isOrgMember: false,

                isWsOwner: false,
                isWsAdmin: false,
                isWsEditor: false,
                isWsViewer: false,
                isWsOutsideCollab: false,
            }
        };

        setCookieData(newData); //updating cookies
        dispatch(setSharedWorkspacesAndOrganizationsData(newData)); //updating inner store
        history.push(PATH_LOCAL_NEW_WORKSPACES + SELECT_WORKSPACES_PATH); //going to select w and o
    }

    const set2FaComplete = () => {
        const newData = {...AllMultiStoreData, is2FaAuthComplete: true};
        setCookieData(newData); //updating cookies
        dispatch(setSharedWorkspacesAndOrganizationsData(newData)); //updating inner store
    }

    const setAuthComplete = (isGoogleAuthConfigComplete: boolean, isEmailVerified: boolean) => {
        // console.log(`setAuthComplete -> isGoogleAuthConfigComplete: ${isGoogleAuthConfigComplete}`);
        const newData:multiStoreDataType = {...AllMultiStoreData, isAuthComplete: true, is2FaAuthComplete: false, isGoogleAuthConfigComplete, isEmailVerified};
        setCookieData(newData); //updating cookies
        dispatch(setSharedWorkspacesAndOrganizationsData(newData)); //updating inner store
    }

    const setGoogle2FaNotComplete = (flag: boolean) => {
        const newData:multiStoreDataType = {...AllMultiStoreData, isAuthComplete: true, is2FaAuthComplete: false, isGoogleAuthConfigComplete: flag};
        setCookieData(newData); //updating cookies
        dispatch(setSharedWorkspacesAndOrganizationsData(newData)); //updating inner store
    }


    return{
        setCurrentWorkspaceAndOrganization,
        setUser,
        currData: AllMultiStoreData,
        getCookieData,
        logout,
        setCookieData,
        handle403Error,
        roles: AllMultiStoreData.roles,
        user: AllMultiStoreData.currentUser,
        set2FaComplete,
        setAuthComplete,
        deleteCookieByName,
        workspaceId: AllMultiStoreData.selectedWorkspace?.id || null,
        organizationId: AllMultiStoreData.selectedOrganization?.id || null,
        subscription: {
            isFree: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_FREE' || false,
            isBusiness: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_BUSINESS' || false,
            isBusinessPlus: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_BUSINESS_PLUS' || false,
            isEnterprise: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_ENTERPRISE' || false,
            isConsultant: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_CONSULTANT' || false,
            isAuditor: AllMultiStoreData.selectedOrganization?.subscription === 'SUBSCRIPTION_AUDITOR' || false,
        },
        setGoogle2FaNotComplete,
        isGoogleAuthConfigComplete: AllMultiStoreData.isGoogleAuthConfigComplete
    }
}