import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    ChangeReviewedControlByAuditorMutationVariables,
    DeleteAuditorsInSnapshotMutationVariables,
    DownloadAppLogFileQueryVariables,
    DownloadAuditorEvidenceFileQueryVariables,
    DownloadReportCollectorAssetByAuditorQueryVariables,
    GetAppLogsToDateQueryVariables,
    GetAuditorEvidenceAssetQueryVariables,
    GetAuditorsVariantsByAuditorQueryVariables,
    GetFullNcrReportPreviewQueryVariables,
    GetIntegrationDataByCollectionReportsQueryVariables,
    GetIntegrationsReportByAuditorQueryVariables,
    GetNcRsByControlByAuditorQueryVariables,
    GetReportByIdByAuditorQueryVariables,
    GetReportControlByAuditorQueryVariables,
    GetReportControlsByAuditorQueryVariables,
    GetReportOrganizationsByAuditorQueryVariables,
    GetReportsByOrganizationIdByAuditorQueryVariables,
    UpdateAuditorsMutationVariables,
    UpdateReportInfoMutationVariables,
    UpdateReportStatusMutationVariables
} from "../../../../../newShared/GQLTypes";
import {
    changeReviewedControlByAuditorApi,
    deleteAuditorsInSnapshotApi,
    downloadAppLogFileApi,
    downloadAuditorEvidenceFileApi,
    downloadReportCollectorAssetByAuditorApi,
    getAppLogsToDateApi,
    getAuditorEvidenceAssetApi,
    getAuditorsVariantsByAuditorApi,
    getFullNcrReportPreviewApi,
    getIntegrationDataByCollectionReportsApi,
    getIntegrationsReportApi,
    getNCRsByControlApi,
    getReportByIdApi,
    getReportControlApi,
    getReportControlsApi,
    getReportOrganizationsApi,
    getReportsByOrganizationIdApi,
    updateAuditorsApi,
    updateReportConfigApi,
    updateReportStatusApi
} from "../../api";
import {TWithOptions} from "../../../../../newShared/types";
import {saveAs} from "file-saver";
import {TAuditor} from "../../types";
import {b64toBlob} from "../../../../../newShared/utils/base64/base64";
import {Buffer} from "buffer";

export const getReportOrganizationsAction = createAsyncThunk(
    'AuditSpace/getReportOrganizations',
    async (data: TWithOptions<GetReportOrganizationsByAuditorQueryVariables>) => {

        const resp = await getReportOrganizationsApi({...data.data}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp,
            isNewOnTop: false,
        };
    }
);

export const getReportsByOrganizationIdAction = createAsyncThunk(
    'AuditSpace/getReportsByOrganizationId',
    async (data: TWithOptions<GetReportsByOrganizationIdByAuditorQueryVariables>) => {

        const resp = await getReportsByOrganizationIdApi({...data.data, organizationId: data.data.organizationId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp,
            isNewOnTop: false
        };
    }
);

export const getReportByIdAction = createAsyncThunk(
    'AuditSpace/getReportById',
    async (data: TWithOptions<GetReportByIdByAuditorQueryVariables>) => {

        const resp = await getReportByIdApi({...data.data, organizationId: data.data.organizationId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const getReportControlsAction = createAsyncThunk(
    'AuditSpace/getReportControls',
    async (data: TWithOptions<GetReportControlsByAuditorQueryVariables>) => {
        
        const resp = await getReportControlsApi({...data.data, organizationId: data.data.organizationId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp,
            isNewOnTop: false
        };
    }
);

export const getReportControlAction = createAsyncThunk(
    'AuditSpace/getReportControl',
    async (data: TWithOptions<GetReportControlByAuditorQueryVariables>) => {

        const resp = await getReportControlApi({...data.data, organizationId: data.data.organizationId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const getNCRsByControlAction = createAsyncThunk(
    'AuditSpace/getNCRsByControl',
    async (data: TWithOptions<GetNcRsByControlByAuditorQueryVariables>) => {

        const resp = await getNCRsByControlApi({...data.data, organizationId: data.data.organizationId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const changeReviewedControlByAuditorAction = createAsyncThunk(
    'AuditSpace/changeReviewedControlByAuditor',
    async (data: TWithOptions<ChangeReviewedControlByAuditorMutationVariables>) => {

        const resp = await changeReviewedControlByAuditorApi({...data.data, workspaceId: data.data.workspaceId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const getAuditorsVariantsByAuditorAction = createAsyncThunk(
    'AuditSpace/getAuditorsVariantsByAuditor',
    async (data: TWithOptions<GetAuditorsVariantsByAuditorQueryVariables>) => {

        const resp = await getAuditorsVariantsByAuditorApi({...data.data}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const updateAuditorsApiAction = createAsyncThunk(
    'AuditSpace/updateAuditors',
    async (data: TWithOptions<UpdateAuditorsMutationVariables>) => {

        const resp = await updateAuditorsApi({...data.data, organizationId: data.data.organizationId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp,
            idNames: data.addictiveData as TAuditor[]
        };
    }
);

export const deleteAuditorsInSnapshotAction = createAsyncThunk(
    'AuditSpace/deleteAuditorsInSnapshot',
    async (data: TWithOptions<DeleteAuditorsInSnapshotMutationVariables>) => {

        const resp = await deleteAuditorsInSnapshotApi({...data.data}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const updateReportStatusAction = createAsyncThunk(
    'AuditSpace/updateReportStatus',
    async (data: TWithOptions<UpdateReportStatusMutationVariables>) => {

        const resp = await updateReportStatusApi({...data.data, workspaceId: data.data.workspaceId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const getFullNcrReportPreviewAction = createAsyncThunk(
    'AuditSpace/getFullNcrReportPreview',
    async (data: TWithOptions<GetFullNcrReportPreviewQueryVariables>) => {

        const resp = await getFullNcrReportPreviewApi({...data.data, workspaceId: data.data.workspaceId || '', organizationId: data.data.organizationId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const updateReportConfigAction = createAsyncThunk(
    'AuditSpace/updateReportConfig',
    async (data: TWithOptions<UpdateReportInfoMutationVariables>) => {

        const resp = await updateReportConfigApi({...data.data}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

//
//

export const downloadAuditorEvidenceFileAction = createAsyncThunk(
    'AuditSpace/downloadAuditorEvidenceFile',
    async (data: TWithOptions<DownloadAuditorEvidenceFileQueryVariables>) => {

        const resp = await downloadAuditorEvidenceFileApi({...data.data}, data.signal);
        saveAs(b64toBlob(resp.file), resp.filename)
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const downloadReportCollectorAssetByAuditorAction = createAsyncThunk(
    'AuditSpace/downloadReportCollectorAssetByAuditor',
    async (data: TWithOptions<DownloadReportCollectorAssetByAuditorQueryVariables>) => {

        const resp = await downloadReportCollectorAssetByAuditorApi({...data.data, workspaceId: data.data.workspaceId || '', organizationId: data.data.organizationId || ''}, data.signal);
        saveAs(b64toBlob(resp.file), resp.filename)
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const getAuditorEvidenceAssetTableAction = createAsyncThunk(
    'AuditSpace/getAuditorEvidenceAssetTable',
    async (data: TWithOptions<GetAuditorEvidenceAssetQueryVariables>) => {

        const resp = await getAuditorEvidenceAssetApi({...data.data, workspaceId: data.data.workspaceId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const getAuditorEvidenceAssetStatementAction = createAsyncThunk(
    'AuditSpace/getAuditorEvidenceStatementAsset',
    async (data: TWithOptions<GetAuditorEvidenceAssetQueryVariables>) => {

        const resp = await getAuditorEvidenceAssetApi({...data.data, workspaceId: data.data.workspaceId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        };
    }
);

export const getIntegrationsReportAction = createAsyncThunk(
    'ReportView/getIntegrationsReport',
    async (data: TWithOptions<GetIntegrationsReportByAuditorQueryVariables>, {dispatch, getState}) => {
        const resp = await getIntegrationsReportApi({...data.data, organizationId: data.data.organizationId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        }
    },
);

export const getIntegrationDataByCollectionReportsAction = createAsyncThunk(
    'ReportView/getIntegrationDataByCollectionReports',
    async (data: TWithOptions<GetIntegrationDataByCollectionReportsQueryVariables>, {dispatch, getState}) => {
        const resp = await getIntegrationDataByCollectionReportsApi({...data.data, workspaceId: data.data.workspaceId || ''}, data.signal);
        data.onSuccess?.(data.data, resp)
        return {
            data: data.data,
            resp
        }
    },
);

export const GetAppLogsAction = createAsyncThunk(
    'ReportView/getAppLogs',
    async (data: TWithOptions<GetAppLogsToDateQueryVariables>, {getState, dispatch}) => {
        const resp = await getAppLogsToDateApi({...data.data, workspaceId: data.data.workspaceId || ''}, data.signal);
        return {
            data: data.data,
            resp
        }
    }
);

export const DownloadLogAction = createAsyncThunk(
    'Reports/downloadLog',
    async (data: TWithOptions<DownloadAppLogFileQueryVariables & { fileName: string }>, {dispatch, getState}) => {
        const resp = await downloadAppLogFileApi({fileId: data.data.fileId, workspaceId: data.data.workspaceId || ''});
        saveAs(b64toBlob(resp.file), data.data.fileName)
        return {
            data: {},
            resp: {}
        }
    }
);

export const DownloadLogForPreviewAction = createAsyncThunk(
    'Reports/DownloadLogForPreview',
    async (data: TWithOptions<DownloadAppLogFileQueryVariables>, {dispatch, getState}) => {
        const resp = await downloadAppLogFileApi({fileId: data.data.fileId, workspaceId: data.data.workspaceId || ''});
        //receiving base64 plain text
        //decode base64 -> put in store
        const b = await Buffer.from(resp.file, 'base64').toString();

        // saveAs(b64toBlob(resp.file), resp.filename)
        return {
            data: data.data,
            resp: b
        };
    }
);

//
//
