import {
    Button,
    Dialog,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Radio,
    TextField,
    Typography
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {TransitionUp} from "../dialogTransaction";
import SearchIcon from "@mui/icons-material/Search";
import {MiniDialog} from "../miniDialog";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {DateFilterDialog} from "../dateFilterDialog";
import React, {ReactElement} from "react";
import {filterDateType, filterType, keyConfig} from "../../types";
import {useGenericFilter} from "../../hooks/useGenericFilter";
import {CheckboxOption} from "../checkboxOption";
import ClearIcon from '@mui/icons-material/Clear';
import {useMainTranslation} from "../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../hooks/useMedia";
import colors from "../../../../theme/colors";
import {Flex} from "../../../Layouts";
import {FilterButton} from "../styled";
import {isFutureDate, isPastDate, isSameDay, parseDateAuto} from "../../../../utils/dateTools";

interface IGenericFilterProps<T> {
    config: keyConfig<T>,
    updateFilterAndFetch: (filterKey: string, filterValues: string[]) => void,
    handleDeselectVisibleFilter: (filter: filterType) => void,
    dateFilterFormat: string
}
export function GenericFilter<T>({config, updateFilterAndFetch, handleDeselectVisibleFilter, dateFilterFormat}: IGenericFilterProps<T>): ReactElement {
    const {
        filterGeneral,
        search,
        options,
        bottomData,
        date
    } = useGenericFilter(config, updateFilterAndFetch, handleDeselectVisibleFilter);
    const {revDir, currentLanguage, t} = useMainTranslation('', {keyPrefix: 'genericFilter'});
    const {isMobile} = useMedia();

    return(
        <FilterButton
            onClick={filterGeneral.handleOpen}
            css={`
                max-width: ${config.type === 'filter' ? '250px' : '280px'};
                background-color: ${filterGeneral.chipBackgroundColor};
                color: ${filterGeneral.chipTextColor};
                & button svg{
                  fill: ${filterGeneral.chipTextColor};
                }
                ${!filterGeneral.isOpen && !filterGeneral.isUsedFilter && `
                    &:hover{
                      background-color: ${colors.chip.hover}; 
                      & p, & button svg{
                        fill: ${colors.grayText} !important;
                        color: ${colors.grayText} !important;
                      }
                    }
                `}
            `}
        >
            {/*FILTER*/}
            {config.type === 'filter' &&
                <>
                    <Flex>
                        <Typography variant={'body2'} noWrap>{config.name}{options.selectedOptions.length > 0 && `:`}</Typography>
                    </Flex>
                    {options.firstSelectedOption && (<Typography variant={'body2'} noWrap>{config.getOptionLabel ? config.getOptionLabel(options.firstSelectedOption) : options.firstSelectedOption}</Typography>)}
                    {options.selectedOptions.length - 1 > 0 &&
                        <Flex p={'2px 7.5px'} ai={'center'} jc={'center'} background={colors.backgrounds.blue_light_1} br={'30px'}>
                            <Typography variant={'bodySmall'} color={colors.grayText}>+{options.selectedOptions.length - 1}</Typography>
                        </Flex>
                    }

                    {!Boolean(config.default) &&
                        <IconButton size={'small'} sx={{padding: '0'}} onClick={filterGeneral.handleDeleteSelf}>
                            <ClearIcon sx={{width: '16px', height: '16px'}}/>
                        </IconButton>
                    }

                    <IconButton
                        size={'small'}
                        sx={{
                            padding: '0',
                            display: !config.default ? 'none': undefined
                        }}
                    >
                        {filterGeneral.isOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                    </IconButton>

                    {/*DIALOG*/}
                    <>
                        <Dialog
                            open={filterGeneral.isOpen && isMobile}
                            onClose={filterGeneral.handleClose}
                            fullScreen
                            TransitionComponent={TransitionUp}
                            sx={{'& .MuiPaper-root': {
                                    borderRadius: '40px 40px 0 0',
                                    height: '60vh',
                                    bottom: 0,
                                    position: 'absolute'
                                }}}
                        >
                            <Flex direction={'column'} onClick={(e) => e.stopPropagation()} grow={'1'}>
                                <OutlinedInput
                                    placeholder={'Search'}
                                    value={search.value}
                                    onChange={search.onChange}
                                    fullWidth
                                    sx={{
                                        margin: '16px auto 0',
                                        padding: '0',
                                        height: '40px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '& .MuiButtonBase-root': {
                                            padding: '13px 10px',
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            width: '88%',
                                            padding: '16px 10px',
                                            height: '14px'
                                        }}
                                    }
                                    size={'small'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <SearchIcon sx={{width: '16px', height: '16px', color: colors.text.grey_dark}}/>
                                        </InputAdornment>
                                    }
                                />

                                <Flex jc={'flex-end'} p={'0 16px'} style={{borderTop: `1px solid ${colors.stroke.grey}`}}>
                                    <Button variant={'text'} size={'large'} sx={{padding: '8px 2px', minWidth: '0'}} onClick={bottomData.handleResetSelection} disabled={!options.selectedOptions.length}>{t('Reset')}</Button>
                                </Flex>

                                <Flex
                                    w={'100%'}
                                    p={'16px 20px'}
                                    style={{borderTop: `1px solid ${colors.stroke.grey}`}}
                                    grow={'1'}
                                    overflowy={'auto'}
                                    direction={'column'}
                                    gap={'8px'}
                                >
                                    {search.isEmptySearch && options.options.selectedAndNotFoundInList.map((e, id) =>
                                        <CheckboxOption
                                            key={e.key}
                                            option={e}
                                            // margin={id > 0 ? '8px 0 0 0' : undefined}
                                            config={config}
                                            handleSelectOption={options.handleSelectOption}
                                            handleDeselectOption={options.handleDeselectOption}
                                        />
                                    )}
                                    {options.options.list.map((e, id) =>
                                        <CheckboxOption
                                            key={e.key}
                                            option={e}
                                            // margin={id > 0 ? '8px 0 0 0' : undefined}
                                            config={config}
                                            handleSelectOption={options.handleSelectOption}
                                            handleDeselectOption={options.handleDeselectOption}
                                        />
                                    )}
                                    {options.hasMore && !options.isLoading &&
                                        <Flex m={'8px 0 0 0'}>
                                            <Button variant={'text'} size={'small'} sx={{padding: '8px 2px', minWidth: '0'}} onClick={options.handleLoadMore}>{t('Show more')}</Button>
                                        </Flex>
                                    }
                                    {options.isLoading && <Typography variant={'body2'} color={colors.grayText} noWrap>{t('Loading...')}</Typography>}
                                    {!options.options.list.length && !options.options.selectedAndNotFoundInList.length && <Typography variant={'body2'} color={colors.grayText} noWrap>{t('No results')}</Typography>}
                                </Flex>
                            </Flex>
                        </Dialog>
                    </>

                    <MiniDialog isOpen={filterGeneral.isOpen && !isMobile} onClose={filterGeneral.handleClose} elAnchor={filterGeneral.anchorEl} id={filterGeneral.id}>
                        <OutlinedInput
                            placeholder={t('Search')}
                            value={search.value}
                            onChange={search.onChange}
                            sx={{
                                padding: '0',
                                height: '38px',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '& .MuiButtonBase-root': {
                                    padding: '13px 10px',
                                }
                            }}
                            inputProps={{
                                style: {
                                    width: '82%',
                                    padding: '10px 8px',
                                    height: '14px'
                                }}
                            }
                            size={'small'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon sx={{width: '16px', height: '16px', color: colors.text.grey_dark}}/>
                                </InputAdornment>
                            }
                        />

                        <Flex
                            w={'100%'}
                            p={'7px 5px'}
                            style={{borderTop: `1px solid ${colors.stroke.grey}`}}
                            maxh={'294px'}
                            overflowy={'auto'}
                            direction={'column'}
                        >
                            {search.isEmptySearch && options.options.selectedAndNotFoundInList.map((e, id) =>
                                <CheckboxOption
                                    key={e.key}
                                    option={e}
                                    // margin={id > 0 ? '8px 0 0 0' : undefined}
                                    config={config}
                                    handleSelectOption={options.handleSelectOption}
                                    handleDeselectOption={options.handleDeselectOption}
                                />
                            )}
                            {options.options.list.map((e, id) =>
                                <CheckboxOption
                                    key={e.key}
                                    option={e}
                                    // margin={(id > 0 && !options.options.selectedAndNotFoundInList.length) ? '8px 0 0 0' : undefined}
                                    config={config}
                                    handleSelectOption={options.handleSelectOption}
                                    handleDeselectOption={options.handleDeselectOption}
                                />
                            )}
                            {options.hasMore && !options.isLoading &&
                                <Flex m={'8px 0 0 0'}>
                                    <Button variant={'text'} size={'small'} sx={{padding: '8px 2px', minWidth: '0'}} onClick={options.handleLoadMore}>{t('Show more')}</Button>
                                </Flex>
                            }
                            {options.isLoading && <Typography variant={'body2'} color={colors.grayText} noWrap>{t('Loading...')}</Typography>}
                            {!options.options.list.length && !options.options.selectedAndNotFoundInList.length && <Typography variant={'body2'} color={colors.grayText} noWrap>{t('No results')}</Typography>}
                        </Flex>

                        <Flex w={'100%'} p={'8px'} style={{borderTop: `1px solid ${colors.stroke.grey}`}} ai={'center'} jc={'space-between'}>
                            <Button variant={'text'} size={'large'} sx={{padding: '8px 2px', minWidth: '0'}} onClick={bottomData.handleResetSelection} disabled={!options.selectedOptions.length}>{t('Reset')}</Button>
                            <Typography variant={'bodySmall'} color={colors.text.grey_dark}>{t('Total')}: {bottomData.total}</Typography>
                        </Flex>
                    </MiniDialog>
                </>
            }
            {/*DATE*/}
            {config.type === 'date' &&
                <>
                    {/*CHIP*/}
                    <>
                        <Flex>
                            <Typography variant={'body2'} noWrap>{config.name}{date.isSelectedAtLeastOne && `:`}</Typography>
                        </Flex>

                        {date.isSelectedAtLeastOne && <Typography variant={'body2'} noWrap>
                            {parseDateAuto(date.selectedDates[0]?.toISOString(), false, true, dateFilterFormat)}
                            {date.dateType === 'period' && ' - '}
                            {date.isSelectedAndFilledRange && parseDateAuto(date.selectedDates[1]?.toISOString(), false, true, dateFilterFormat)}
                        </Typography>}

                        {!Boolean(config.default) &&
                            <IconButton size={'small'} sx={{padding: '0'}} onClick={filterGeneral.handleDeleteSelf}>
                                <ClearIcon sx={{width: '16px', height: '16px'}}/>
                            </IconButton>
                        }

                        <IconButton
                            size={'small'}
                            sx={{
                                padding: '0',
                                display: !config.default ? 'none': undefined
                            }}
                        >
                            {filterGeneral.isOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                        </IconButton>
                    </>

                    <MiniDialog isOpen={filterGeneral.isOpen && !isMobile} onClose={filterGeneral.handleClose} elAnchor={filterGeneral.anchorEl} id={filterGeneral.id}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={date.dateType as filterDateType}
                                onChange={(e, value) => date.handleSelectDateType(value as filterDateType)}
                                sx={{
                                    '.MuiFormControlLabel-root': {margin: 0}
                                }}
                            >
                                <FormControlLabel value={'exact'} control={<Radio sx={{width: '24px', height: '24px', margin: !revDir ? '0 16px 0 8px' : '0 8px 0 16px'}}/>} label={<Typography variant={'body2'} color={colors.text.grey_dark}>{t('Exact date')}</Typography>} />

                                {date.dateType === 'exact' &&
                                    <>
                                        <DatePicker
                                            value={date.selectedDates[0] ?? null}
                                            onChange={(newValue) => {date.handleSelectExactDate(newValue)}}
                                            inputFormat={dateFilterFormat}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                size={'small'}
                                                sx={{'.MuiSvgIcon-root': {fill: colors.text.grey_dark}, margin: '6px 0'}}
                                            />}
                                        />
                                    </>
                                }

                                <FormControlLabel value={'period'} control={<Radio sx={{width: '24px', height: '24px', margin: !revDir ? '0 16px 0 8px' : '0 8px 0 16px'}}/>} label={<Typography variant={'body2'} color={colors.text.grey_dark}>{t('Period')}</Typography>} />

                                {date.dateType === 'period' &&
                                    <Flex ai={'center'} gap={'10px'} m={'6px 0 0 0'}>
                                        <DatePicker
                                            value={date.selectedDates[0] ?? null}
                                            inputFormat={dateFilterFormat}
                                            onChange={(newValue) => {date.handleSelectRangeDateA(newValue)}}
                                            shouldDisableDate={(_date) => {
                                                //if TO selected - disable selecting same as TO date or any date after
                                                if(_date && date.selectedDates[1] !== null && date.selectedDates[1] !== undefined){
                                                    return isSameDay(_date.toISOString(), date.selectedDates[1].toISOString(), currentLanguage.momentLocale) ||
                                                        isFutureDate(_date.toISOString(), currentLanguage.momentLocale, date.selectedDates[1].toISOString())
                                                }
                                                return false;
                                            }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                size={'small'}
                                                dir={revDir ? 'rtl' : 'ltr'}
                                                sx={{
                                                    '.MuiInputBase-root': {padding: !revDir ? '0 14px 0 0' : '0 6px 0 0'},
                                                    'button': {padding: '0 6px 0 0'},
                                                    '.MuiSvgIcon-root': {fill: colors.text.grey_dark, width: '24px', height: '24px'},
                                                    backgroundColor: colors.backgrounds.grey_light
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: t("From"),
                                                    style: {
                                                        padding: '10px 0 10px 6px',
                                                        height: '20px',
                                                        fontSize: '14px',
                                                        fontWeight: 400
                                                    }
                                                }}
                                            />}
                                        />

                                        <DatePicker
                                            value={date.selectedDates[1] ?? null}
                                            inputFormat={dateFilterFormat}
                                            shouldDisableDate={(_date) => {
                                                //disable selecting same as FROM date or any date before
                                                if(_date && date.selectedDates[0] !== null && date.selectedDates[0] !== undefined){
                                                    return isSameDay(_date.toISOString(), date.selectedDates[0]?.toISOString(), currentLanguage.momentLocale) ||
                                                        isPastDate(_date.toISOString(), currentLanguage.momentLocale, date.selectedDates[0]?.toISOString())
                                                }
                                                return false;
                                            }}
                                            onChange={(newValue) => {date.handleSelectRangeDateB(newValue)}}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                size={'small'}
                                                sx={{
                                                    '.MuiInputBase-root': {padding: !revDir ? '0 14px 0 0' : '0 6px 0 0'},
                                                    'button': {padding: '0 6px 0 0'},
                                                    '.MuiSvgIcon-root': {fill: colors.text.grey_dark, width: '24px', height: '24px'},
                                                    backgroundColor: colors.backgrounds.grey_light
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: t("To"),
                                                    style: {
                                                        padding: '10px 0 10px 6px',
                                                        height: '20px',
                                                        fontSize: '14px',
                                                        fontWeight: 400
                                                    }
                                                }}
                                            />}
                                        />

                                    </Flex>
                                }
                            </RadioGroup>
                        </FormControl>

                        <Flex w={'100%'} m={'6px 0 0 0'} p={'8px 0 0 0'} style={{borderTop: `1px solid ${colors.stroke.grey}`}}>
                            <Button fullWidth variant={'text'} size={'large'} sx={{padding: '8px 2px', minWidth: '0'}} onClick={date.handleResetDateFilter} disabled={date.isResetButtonDisabled}>{t('Reset')}</Button>
                        </Flex>
                    </MiniDialog>
                    <DateFilterDialog isOpen={filterGeneral.isOpen && isMobile} onClose={filterGeneral.handleClose} date={date}/>
                </>
            }
        </FilterButton>
    )
}
