import React, {FC, useEffect, useState} from "react";
import {Box, Collapse, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {useAuditSpace} from "../../hooks/useAuditSpace";
import {useRoutes} from "../../hooks/useRoutes";
import {Add} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {useNCR} from "../../hooks/useNCR";
import {NCRBlock} from "../ncrBlock";
import {ChangeNcrStatusDialog} from "../dialogs/changeNcrStatusDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/workspaces/hooks/useManageWorkspacesAndOrganizations";
import {NcrInput} from "../../../../../newShared/GQLTypes";
import {CustomInput} from "../../../../../newShared/components/Inputs";

export const NcrReport: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'AuditSpace'});
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();
    const {controlId} = useRoutes();

    const {
        controls: {report},
        selectedControl: {selectedControl, NCRs},
        isLoading: {isCreatingNcr},
    } = useAuditSpace();
    const {createNcr} = useNCR();

    const [selectedNcr, setSelectedNcr] = useState<string>(NCRs.length === 1 ? NCRs[0].id : '');
    const [creatingNCR, setCreatingNCR] = useState<NcrInput | null>(null);

    const IN_REVIEW = report?.status === 'IN_REVIEW';
    const RESPONSE = report?.status === 'RESPONSE';

    useEffect(() => {
        setCreatingNCR(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [NCRs.length, controlId, selectedNcr]);

    return (
        <Box width={'100%'} flexGrow={1} flexDirection={"column"} overflow={"auto"} sx={{overflowX: 'show'}}>

            {(IN_REVIEW || RESPONSE) && (
                <>
                    <LoadingButton variant={!creatingNCR ? "outlined" : 'contained'} disabled={!!creatingNCR && (creatingNCR.type?.trim() === '' || creatingNCR.observed?.trim() === '')}
                                   startIcon={<Add/>} loadingPosition={'start'} loading={isCreatingNcr}
                                   onClick={!creatingNCR
                                       ? () => {
                                           report && currentUser && selectedControl && setCreatingNCR({
                                               id: '',
                                               workspaceId: report.workspaceId,
                                               frameworkId: report.framework.id,
                                               reportId: report.id,
                                               controlId: selectedControl.id,
                                               createAuditorId: currentUser.publicId,
                                               status: 'OPEN',

                                               type: '',
                                               observed: '',

                                               createDate: new Date().toISOString(),
                                           })}
                                       : () => {
                                           creatingNCR && createNcr(creatingNCR)
                                       }}>
                        {t('Create NCR')}
                    </LoadingButton>
                    <Collapse in={!!creatingNCR}>
                        <Box>
                            <Typography variant={"subtitle2"} sx={{marginTop: '10px'}} >
                                {`${t('Type of Non-Conformity')}:`}
                            </Typography>
                            <RadioGroup row value={creatingNCR?.type || null} onChange={e => {
                                setCreatingNCR(prev => prev ? ({...prev, type: e.target.value}) : null)
                            }}>
                                <FormControlLabel value={'MAJOR'} control={<Radio size={"small"} />} label={'Major'} disabled={isCreatingNcr}/>
                                <FormControlLabel value={'MINOR'} control={<Radio size={"small"} />} label={'Minor'} disabled={isCreatingNcr}/>
                            </RadioGroup>

                            <Typography variant={"subtitle2"} sx={{marginTop: '10px'}} >
                                {`${t('Observed Non-Conformity')}:`}
                            </Typography>
                            <CustomInput multiline fullWidth minRows={3} maxRows={5}
                                         value={creatingNCR?.observed || ''}
                                         onChange={e => {setCreatingNCR(prev => prev ? ({...prev, observed: e.target.value}) : null)}}
                                         sx={{marginTop: '10px'}} disabled={isCreatingNcr}
                            />
                        </Box>
                    </Collapse>
                </>
            )}

            {NCRs.map(ncr => (
                <NCRBlock key={ncr.id} ncr={ncr} isOpen={selectedNcr === ncr.id}
                          handleOpen={() => {setSelectedNcr(prevState => ncr.id === prevState ? '' : ncr.id)}}/>
            ))}

            <ChangeNcrStatusDialog/>
        </Box>
    )
}
