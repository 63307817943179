import styled from 'styled-components'
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    SxProps,
    TextField,
    Theme
} from '@mui/material'
import {Search, Visibility, VisibilityOff} from '@mui/icons-material'
import React, {ChangeEvent, FC, useState} from 'react'


export const CustomInput = styled(TextField).attrs({
    variant: 'outlined',
    fullWidth: true
})``

type TSearchInput = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    label: string,
    size?: "small" | "medium",
    value?: string,
    dir?: 'ltr' | 'rtl',
    onClick?: () => void,
    variant?: "outlined" | "standard" | "filled" | undefined,
    sx?: any,
}

export const SearchInput:FC<TSearchInput> = (props: TSearchInput) => {

    return (
        <TextField
            onChange={props.onChange}
            label={props.label}
            variant = {props.variant ? props.variant : 'outlined'}
            value={props.value}
            fullWidth
            size={props.size}
            sx={props.sx}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={() => props.onClick ? props.onClick() : () => null}>
                    <Search style={{cursor:  props.onClick ? 'pointer' : 'default'}}/>
                  </InputAdornment>
                ),
              }}
        />
    )
}

export const TextArea = styled(TextField).attrs((props) => ({
    multiline: true,
    minRows: props.rows || 10,
    rows: props.rows,
    fullWidth: true
}))``


type TPasswordField = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    name?: string,
    label?: string,
    m?: string,
    value?: string,
    onKeyDown?:  React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
    sx?:  SxProps<Theme> | undefined,
    error?: boolean,
    onFocus?: () => void,
    onBlur?: () => void,
    nonrequired?: boolean,
}

export const PasswordField: FC<TPasswordField> = React.forwardRef((props, ref) => {
    const [isVisible, changeVisibility] = useState<boolean>(false);

    const changeVisiblePassword = () => {
        changeVisibility(prevState => !prevState);
    }

    return (
        <FormControl variant={'outlined'} sx={{direction: 'ltr', margin: props.m ?? '0'}} required={!props.nonrequired}>
            <InputLabel htmlFor="filled-adornment-password">{props.label ?? 'Password'}</InputLabel>
            <OutlinedInput
                {...props}
                ref={ref}
                id="filled-adornment-password"
                type={isVisible ? 'text' : 'password'}
                onChange={props.onChange}
                label={props.label ?? 'Password'}
                name={props.name ?? 'password'}
                // value={props.value}

                error={props.error}
                onFocus={props.onFocus}
                onBlur={props.onBlur}

                endAdornment={
                    <InputAdornment position={'end'}>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={changeVisiblePassword}
                            onMouseDown={changeVisiblePassword}
                            edge="end"
                        >
                            {isVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
})