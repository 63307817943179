

export const DEFAULT_RAW_DATA_PAGING = 50;
export const DEFAULT_APP_HISTORY_PAGING = 15;
export const DEFAULT_APP_LOGS_PAGING = 15;
export const APP_HISTORY_DATE_FORMAT= 'D/M/YYYY';

export const integrationGodModeKeys: string[] = ["u", "z", "u", "m", "y", "m", "w"];
export const IN_PROGRESS_STATUS = 'IN_PROGRESS';
export const resetEggMs = 7000;
//endpointMappingNames
export const GWORKSPACE_ENDPOINT_MAPPING_NAME = 'gworkspace';
export type ENDPOINT_MAPPING_NAMES_TYPE = typeof GWORKSPACE_ENDPOINT_MAPPING_NAME;

export const MAX_LOG_SIZE_PREVIEW = 10000000;
export const LOG_DATE_FORMAT = 'D/M/YYYY hh:mm';

export const ORGANIZATION_REPORTS_PAGING = 20;
export const ORGANIZATION_PAGING = 20;

