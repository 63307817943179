import {FC} from "react";
import {useContactUs} from "./hooks/useContactUs";
import {useMainTranslation} from "../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Box, FormControl, Icon, MenuItem, TextField, Typography} from "@mui/material";
import {TYPE_VARIANTS} from "./constants";
import {LoadingButton} from "@mui/lab";

export const ContactUs: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'ContactUs'});
    const {
        type, handleChangeType,
        subject, handleChangeSubject,
        message, handleChangeMessage,

        handleSubmit, isLoading
    } = useContactUs();

    return (
        <Box p={'40px 20px 20px'} width={'100%'} height={'100%'} overflow={"auto"}>
            <FormControl sx={{width: '100%', maxWidth: '320px'}}>
                <Typography variant={"h1"}>{t('Contact us')}</Typography>

                <TextField fullWidth select label={t('Type')} placeholder={t('Choose type')}
                           value={type} onChange={handleChangeType}
                           sx={{marginTop: '16px'}}
                           disabled={isLoading}
                >
                    {TYPE_VARIANTS.map(value => (
                        <MenuItem key={value} value={value}>
                            {t(value)}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField fullWidth label={t('Subject')} placeholder={t('Subject')}
                           value={subject} onChange={handleChangeSubject}
                           sx={{marginTop: '16px'}}
                           disabled={isLoading}
                />

                <TextField fullWidth label={t('Problem')} placeholder={t('Describe your problem')}
                           multiline minRows={3}
                           value={message} onChange={handleChangeMessage}
                           sx={{marginTop: '16px'}}
                           disabled={isLoading}
                />


                <LoadingButton onSubmit={handleSubmit} onClick={handleSubmit} loading={isLoading}
                               disabled={type.trim() === '' || subject.trim() === '' || message.trim() === ''}
                               variant={"contained"} loadingPosition={"start"} startIcon={isLoading ? <Icon/> : undefined}
                               sx={{marginTop: '16px', width: 'fit-content'}}
                >
                    {t('Send')}
                </LoadingButton>
            </FormControl>
        </Box>
    )
}