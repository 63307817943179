import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useParams} from "react-router-dom";
import {useRoutes} from "../useRoutes";
import {useAuditSpace} from "../useAuditSpace";
import {useEffect} from "react";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getReportStatus} from "../../helpers";
import {Typography} from "@mui/material";
import {ORGANIZATION_REPORTS_PAGING} from "../../constants";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";

export const useOrganizationReports = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'AuditSpace'});
    const {organizationId} = useParams<{organizationId?: string}>();
    const {currentFiltersForFetch, currentSearchForFetch} = useGenericFiltersStorage();

    //
    const controller = new AbortController();
    const routes = useRoutes();
    const {
        reports: {organization, reports, pageInfo},
        isLoading: {isLoadingReports},
        getReports,
    } = useAuditSpace();


    useEffect(() => {
        getReports(organizationId ?? '', {page: 0, count: ORGANIZATION_REPORTS_PAGING, filters: {}, sort: '', groupBy: ''}, controller.signal, false);

        return () => {
            controller.abort();
        }
        //eslint-disable-next-line
    }, []);

    //filters
    const statusConfig: keyConfig<string> = {
        type: 'filter',
        key: 'status',
        name: ('Status'),
        options: ['IN_REVIEW', 'PUBLISHED', 'RESPONSE', 'COMPLETED', 'REJECTED'],
        OptionRenderer: (option) => {
            const {color, status} = getReportStatus(t, option);
            return <Typography color={color} variant={'body2'}>{status}</Typography>
        },
        getOptionLabel: (option) => getReportStatus(t, option).status,
        default: true
    }

    // const typeConfig: keyConfig<string> = {
    //     type: 'filter',
    //     key: 'type',
    //     name: ('Type'),
    //     options: ['SNAPSHOT', 'LIVE_LITE', 'LIVE_FULL'],
    //     getOptionLabel: (option) => getReportType(t, option),
    //     default: true
    // }

    const fetchWithFilters = (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
        getReports(organizationId ?? '', {page: 0, count: ORGANIZATION_REPORTS_PAGING, filters: {...filters, input: [search]}, sort: '', groupBy: ''}, controller.signal, true);
    }

    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            getReports(organizationId ?? '', {page: page, count: ORGANIZATION_REPORTS_PAGING, filters: {...currentFiltersForFetch, input: [currentSearchForFetch]}, sort: '', groupBy: ''});
        },
        arrayLength: reports.length,
        isLoading: isLoadingReports,
        pageInfo: pageInfo,
        // cleanData: () => dispatch(eraseEmployees()),
        // controller: controller,
        // isOnTop: true,
        isNoMoreThanTotal: true,
        customDependencies: [],
        is0PageAddictive: false,
        disableInitFetch: true,
        // disableClearOnUnmount: true
    })

    const onReportClick = (id: string) => {
        organization && routes.goToReport(organization.id, id)
    }

    return{
        routes,
        organization,
        configs: [statusConfig],
        fetchWithFilters,
        scroll: {
            bottomLoader,
            onScroll,
            scrollRef,
            hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
            total: pageInfo.total
        },
        reports,
        onReportClick,
        isLoading: isLoadingReports
    }
}