import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {FC, MouseEvent,} from "react";
import {Typo} from "../Typography";
import colors from "../../theme/colors";
import {Flex} from "../Layouts"

export const ignoreCells: string[] = ['id', 'indexKey'];
export const prettyNames: {[key: string]: string} = {
    name: 'Name',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    id: 'ID',
    account: 'Account',
    email: 'Email',
    date: 'Date',
    createDate: 'Create Date',
    user: 'User',
    identity: 'Identity',
    hostname: 'Hostname',
    model: 'Model',
    serialNumber: 'Serial Number',
    mfa: 'MFA',
    enforcedMfa: 'Enforced MFA',
    applicationName: 'Application',
    applicationStatus: 'Application Status',
    applicationOwnerEmail: 'Application Owner Email',
    roleName: 'Role',
    roleDescription: 'Role Description',
    roleType: 'Role Type',
    assetName: 'Asset Name',
    assetType: 'Asset Type',
    assetOriginId: 'Asset Origin Id',
    assetPath: 'Asset Path',
    assetRoot: 'Asset Root',

    type: 'Type',
    description: 'Description',

    initialProbabilityQuantitative: 'Initial probability (quantitative)',
    initialProbabilityQuality: 'Initial probability quality',
    initialProbabilityDescription: 'Initial probability description',
    initialImpactQuantitative: 'Initial impact (quantitative)',
    initialImpactQuality: 'Initial impact quality',
    initialImpactDescription: 'Initial impact description',
    initialRating: 'Initial rating',

    controlType: 'Control Type',
    controlOwners: 'Control Owners',
    controlActions: 'Control Actions',
    controlScheduler: 'Control Scheduler',

    residualProbabilityQuantitative: 'Residual probability (quantitative)',
    residualProbabilityQuality: 'Residual probability quality',
    residualProbabilityDescription: 'Residual probability description',
    residualImpactQuantitative: 'Residual impact (quantitative)',
    residualImpactQuality: 'Residual impact quality',
    residualImpactDescription: 'Residual impact description',
    residualRating: 'Residual rating',

    workEmail: 'Work email',
    personalEmail: 'Personal Email',

    birthDay: 'Birthday',
    hireDate: 'Hire date',
    activeStatus: 'Active status',

    addressCountry: 'Country',
    addressState: 'State',
    addressCity: 'City',
    addressStreet: 'Street',
    addressZipcode: 'Zipcode',

    phone: 'Phone',
    employmentStatus: 'Employment status',
    jobTitle: 'Job title',
    department: 'Department',
    manager: 'Manager',

    documentStatus: 'Document status',
    serviceProvided: 'Service provided',
    owner: 'Owner',
    contactPersons: 'Contact Persons',
    link: 'Link',
    riskLevel: 'Risk level',
    evaluator: 'Evaluator',
    qualityLevel: 'Quality level',
    priceLevel: 'Price level',
    serviceLevel: 'Service level',
    averageLevel: 'Average level',
    notesAndRecommendations: 'Notes and recommendations',
};

type TTransformProps = {name: string, logo?: string};
const AppCell: FC<TTransformProps> = ({name, logo}: TTransformProps) => {
    return (
        <Flex ai={"center"}>
            {logo && <Flex ai={"center"} jc={"center"} w={'24px'} h={'24px'}>
                <img style={{maxHeight: 20, maxWidth: 20}} src={logo} alt={name}/>
            </Flex>}
            <Typo fontSize={'16px'} fontWeight={300} style={{marginLeft: 5}}>{name}</Typo>
        </Flex>
    )
}

export const strToPrettyName = (str: string): string => {
    return prettyNames[str] || str
}

const toStr = (str: string | string[]): string => {
    return Array.isArray(str) ? str.join(', ') : str
}

type PropsType = {
    data: {[key: string]: string | string[]}[],
    keys: string[]
}
export const TextTable: FC<PropsType> = ({data, keys}) => {

    if (data.length === 0) return <Typo fontSize={'16px'}>No Data</Typo>;

    return (
        <>
            <TableContainer style={{boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)', borderRadius: 15, marginTop: 7}}
                            onClick={(e: MouseEvent<HTMLDivElement>) => {e.stopPropagation()}}
            >
                <Table size={"small"} stickyHeader
                       sx={{
                           '& .MuiTableCell-root': {
                               borderRight: "1px solid rgba(224, 224, 224, 1)"
                           }
                       }}
                >
                    <TableHead >
                        <TableRow>
                            {keys.map(key =>
                                    (ignoreCells.findIndex(el => el === key) < 0) && (
                                        <TableCell key={key} sx={{borderBottom: `2px solid ${colors.yellow}`}}>
                                            <Typo color={colors.table.head}
                                                  fontSize={'16px'} fontWeight={400}
                                            >
                                                {strToPrettyName(key)}
                                            </Typo>
                                        </TableCell>
                                    )
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .map((row) => (
                                <TableRow key={row['indexKey'] as string || ''}>
                                    {
                                        keys.map((key, cellIndex) =>
                                            ignoreCells.findIndex(el => el === key) < 0 && (
                                                <TableCell key={row['indexKey'] as string + ' column ' + cellIndex}
                                                           style={{
                                                               minWidth: (toStr(row[key] || '').length > 30 ? Math.floor(toStr(row[key] || '').length * 16 / 8) : Math.floor(toStr(row[key] || '').length * 16)) + 'px',
                                                               // minWidth: 400 + 'px',
                                                               backgroundColor: key.toLowerCase().includes('rating')
                                                                   ? row[key]?.toString().toLowerCase() === 'high'
                                                                       ? colors.red
                                                                       : row[key]?.toString().toLowerCase() === 'medium'
                                                                           ? 'yellow'
                                                                           : row[key]?.toString().toLowerCase() === 'low'
                                                                               ? colors.green
                                                                               : undefined
                                                                   : undefined
                                                           }}
                                                >
                                                    {
                                                        key === 'applicationName'
                                                            ? <AppCell name={toStr(row[key] || '')} logo={toStr(row.appLogo)}/>
                                                            : (
                                                                <Typo color={'black'}
                                                                      fontSize={'16px'} fontWeight={300}>
                                                                    {toStr(row[key] || '')}
                                                                    {/*{isValid(parseCustom(toStr(row[key])))*/}
                                                                    {/*    ? autoDateToString(toStr(row[key]))*/}
                                                                    {/*    : toStr(row[key] || '')}*/}
                                                                </Typo>
                                                            )
                                                    }
                                                </TableCell>
                                            ))
                                    }
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}