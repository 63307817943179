//core
import React, {FC, useEffect, useState} from "react";

//shared components
//material components
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ClearIcon from "@mui/icons-material/Clear";

//other
import {Delete} from "@mui/icons-material";
import {useAuditSpace} from "../../../hooks/useAuditSpace";
import {useReportDialogs} from "../../../hooks/useReportsDialogs";
import {LoadingButton} from "@mui/lab";
import {useRoutes} from "../../../hooks/useRoutes";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {TAuditorVariant} from "../../../types";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";
import colors from "../../../../../../newShared/theme/colors";

export const AddAuditorDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'AuditSpace'});

    const {
        auditors: {
            isLoading, isLoadingVariants, isOpen,
            auditors, auditorVariants, setAuditorsDialog
        },
    } = useReportDialogs();
    const {
        controls: {report},

        getAuditorsVariants,
        updateAuditorsInfo, deleteAuditorsInfo,
    } = useAuditSpace();
    const {organizationId} = useRoutes();

    const [organization, setOrganization] = useState<TAuditorVariant | null>(null);
    const closeDialog = () => {setAuditorsDialog({clear: true})};

    useEffect(() => {
        isOpen && getAuditorsVariants()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);


    const [auditorsList, setAuditorsList] = useState<string[]>(auditors.map(e => e.id));
    useEffect(() => {
        setAuditorsList(auditors.map(e => e.id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auditors]);

    const handleDel = (id:string) => {
        setAuditorsList(prev => prev.filter(e => e !== id))
    }
    const handleAdd = (id:string) => {
        setAuditorsList(prev => [...prev, id])
    }

    const allVariants = auditorVariants.flatMap(e => e.auditors);

    return (
        <Dialog open={isOpen} onClose={closeDialog}
                sx={{
                    '& .MuiPaper-root': {width: '500px', maxHeight: '70vh'}
                }}>
            <DialogTitle>
                <Flex ai={"center"} jc={"space-between"}>
                    <Typo letterSpacing={'1.53px'} fontSize={'20px'} fontWeight={700}>{t('Add auditors')}</Typo>
                    <IconButton size={"small"} onClick={closeDialog}>
                        <ClearIcon/>
                    </IconButton>
                </Flex>
            </DialogTitle>

    <DialogContent>
        <Autocomplete options={auditorVariants}
                      getOptionLabel={(variant) => variant.organization.name}
                      fullWidth loading={isLoadingVariants}
                      sx={{margin: '20px 0'}}
                      onChange={(e, value: TAuditorVariant | null) => {
                          value && setOrganization(value)
                      }}
                      value={organization || null}
                      renderInput={(params) => <TextField {...params} variant={"outlined"} label={t("Select Organization")} />}
        />

        <Autocomplete options={organization?.auditors.filter(e => !auditorsList.includes(e.id)) || []}
                      fullWidth loading={isLoadingVariants}
                      sx={{margin: '20px 0'}}
                      getOptionLabel={(variant) => variant.name}
                      disabled={organization?.organization.name === ''}
                      onChange={(e, value: TAuditorVariant["auditors"][number] | null) => {
                          value && handleAdd(value.id)
                      }}
                      renderInput={(params) => <TextField {...params} variant={"outlined"} label={t("Select Email")} />}
        />
        {auditorsList.map((id) =>
            (
                <Flex ai={"center"} key={id}>
                    <Typo shrink fontWeight={500} fontSize={'14px'} letterSpacing={'1.25px'} style={{flexGrow: 1}}>
                        {allVariants.find((auditor) => id === auditor.id)?.name}</Typo>

                    {auditors.some(e => e.id === id && e.type !== 'LEADER') && (
                        <IconButton size={"small"} onClick={e => {
                            e.stopPropagation();
                            report && deleteAuditorsInfo(id, report.id)
                        }}>
                            <Delete/>
                        </IconButton>
                    )}

                    {!auditors.some(e => e.id === id) && (
                        <IconButton size={"small"} onClick={e => {
                            e.stopPropagation();
                            handleDel(id)
                        }}>
                            <Delete/>
                        </IconButton>
                    )}
                </Flex>
            ))}
        <Divider flexItem sx={{backgroundColor: colors.grey, marginTop: '7px', marginBottom: '20px'}}/>

    </DialogContent>
            <DialogActions>
                <Button variant={"outlined"}
                        onClick={closeDialog}>
                    {t('Cancel')}
                </Button>

                <LoadingButton variant={"contained"}
                               loading={isLoading}
                               onClick={() => {
                                   report && organizationId && updateAuditorsInfo({
                                           organizationId,
                                           reportId: report.id,
                                           data: {ids: auditorsList.filter(aud => !auditors.some(e => e.type === 'LEADER' && e.id === aud))}
                                       },
                                       auditorsList.map(aud => {
                                           const a = allVariants.find(a => a.id === aud);
                                           return {id: a?.id || aud, name: a?.name || '', type: 'TEAM'}
                                       })
                                   )}}>
                    {t('Save')}
                </LoadingButton>
            </DialogActions>
    </Dialog>
)
}
