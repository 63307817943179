import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    SEND_CREATE_UPDATE_NCR_CONFIG_payload,
    SEND_DELETE_AUDITOR_payload,
    SEND_REPORT_STATUS_CHANGED_payload,
    SEND_SHARED_FOR_AUDITORS_REPORT_payload,
    SEND_UPDATE_NCR_REPORT_payload,
    SEND_UPDATE_REVIEWED_CONTROL_payload
} from "./types";
import {AppState} from "../../../newShared/redux/rootReducer";
import {getReportByIdAction} from "./store/auditSpace/actions";


export const SEND_SHARED_FOR_AUDITORS_REPORT_ACTION = createAsyncThunk(
    'PubSub/SEND_SHARED_FOR_AUDITORS_REPORT_ACTION',
    async (data: SEND_SHARED_FOR_AUDITORS_REPORT_payload, {dispatch, getState}) => {
        return data
    },
);

export const SEND_REPORT_STATUS_CHANGED_ACTION = createAsyncThunk(
    'PubSub/SEND_REPORT_STATUS_CHANGED_ACTION',
    async (data: SEND_REPORT_STATUS_CHANGED_payload, {dispatch, getState}) => {
        const state = getState() as AppState;
        if (state.auditSpace.controls.report?.id === data.reportId && state.auditSpace.controls.organization?.id) {
            dispatch(getReportByIdAction({data: {organizationId: state.auditSpace.controls.organization?.id, reportId: data.reportId}}))
        }
        return data
    },
);

export const SEND_CREATE_UPDATE_NCR_CONFIG_ACTION = createAsyncThunk(
    'PubSub/SEND_CREATE_UPDATE_NCR_CONFIG_ACTION',
    async (data: SEND_CREATE_UPDATE_NCR_CONFIG_payload, {dispatch, getState}) => {
        return data
    },
);

export const SEND_UPDATE_REVIEWED_CONTROL_ACTION = createAsyncThunk(
    'PubSub/SEND_UPDATE_REVIEWED_CONTROL_ACTION',
    async (data: SEND_UPDATE_REVIEWED_CONTROL_payload, {dispatch, getState}) => {
        return data
    },
);

export const SEND_UPDATE_NCR_REPORT_ACTION = createAsyncThunk(
    'PubSub/SEND_UPDATE_NCR_REPORT_ACTION',
    async (data: SEND_UPDATE_NCR_REPORT_payload, {dispatch, getState}) => {
        switch (data.ncrControl.status) {
            case "REVIEW": {
                dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION({controlId: data.ncrControl.controlId, reportId: data.ncrControl.reportId, reviewed: false}));
                break;
            }
            case "OPEN": {
                dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION({controlId: data.ncrControl.controlId, reportId: data.ncrControl.reportId, reviewed: true}));
                break;
            }
            case "REVOKE": {
                dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION({controlId: data.ncrControl.controlId, reportId: data.ncrControl.reportId, reviewed: true}));
                break;
            }
            case "APPROVED": {
                dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION({controlId: data.ncrControl.controlId, reportId: data.ncrControl.reportId, reviewed: true}));
                break;
            }
            case "REJECTED": {
                dispatch(SEND_UPDATE_REVIEWED_CONTROL_ACTION({controlId: data.ncrControl.controlId, reportId: data.ncrControl.reportId, reviewed: true}));
                break;
            }
        }

        return data
    },
);

export const SEND_DELETE_AUDITOR_ACTION = createAsyncThunk(
    'PubSub/SEND_DELETE_AUDITOR_ACTION',
    async (data: SEND_DELETE_AUDITOR_payload, {dispatch, getState}) => {
        return data
    },
);