export const SETTING_ROOT_PATH = '/settings';

//ORGANIZATIONS
export const SETTINGS_ORGANIZATIONS_PATH = `${SETTING_ROOT_PATH}/organizations`;
export const SETTINGS_ORGANIZATIONS_CREATE_PATH = `${SETTINGS_ORGANIZATIONS_PATH}/new`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH = `${SETTINGS_ORGANIZATIONS_PATH}/exact/:orgId`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_USERS_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/users`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_ROLES_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/roles`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_PLANS_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/plans`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_NOTIFICATIONS_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/notifications`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/billing`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_BILLING_HISTORY_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/billingHistory`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_WORKSPACES_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/workspaces`;

export const SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_PATH}/workspaces/exact/:wsId`;

export const SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_USERS_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH}/users`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_ROLES_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH}/roles`;
export const SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_NOTIFICATIONS_PATH = `${SETTINGS_ORGANIZATIONS_EXACT_ORG_EXACT_WORKSPACE_PATH}/notifications`;

//INVITES
export const SETTINGS_INVITES_PATH = `${SETTING_ROOT_PATH}/invites`;
//TODO add invite selector logic

//PROFILE
export const SETTINGS_PROFILE_PATH = `${SETTING_ROOT_PATH}/profile`;

//LANGUAGE
export const SETTINGS_LANGUAGE_PATH = `${SETTING_ROOT_PATH}/language`;

//SECURITY
export const SETTINGS_SECURITY_PATH = `${SETTING_ROOT_PATH}/security`;

//SELECTOR
export const SETTINGS_SELECTOR_PATH = `${SETTING_ROOT_PATH}/selector/:orgId/:wsId`;

