import {
    downloadAuditorEvidenceFileAction,
    downloadReportCollectorAssetByAuditorAction,
    getAuditorEvidenceAssetStatementAction,
    getAuditorEvidenceAssetTableAction
} from "../../store/auditSpace/actions";
import {useDispatch} from "react-redux";
import {
    DownloadReportCollectorAssetByAuditorQueryVariables,
    GetAuditorEvidenceAssetQueryVariables
} from "../../../../../newShared/GQLTypes";

export const useEvidence = () => {
    const dispatch = useDispatch();

    const downloadEvidenceFile = (fileId: string, filename: string) => {
        dispatch(downloadAuditorEvidenceFileAction({data: {fileId, filename}}));
    }

    const downloadEvidenceCollector = (data: DownloadReportCollectorAssetByAuditorQueryVariables) => {
        dispatch(downloadReportCollectorAssetByAuditorAction({data}));
    }

    const getStatement = (data: GetAuditorEvidenceAssetQueryVariables) => {
        dispatch(getAuditorEvidenceAssetStatementAction({data}))
    }

    const getCollector = (data: GetAuditorEvidenceAssetQueryVariables) => {
        dispatch(getAuditorEvidenceAssetTableAction({data}))
    }

    return {
        downloadEvidenceFile,
        downloadEvidenceCollector,
        getStatement,
        getCollector,
    }
}