import {useHistory, useParams} from "react-router-dom";
import {PATH_LOCAL_SPACE} from "../../../../../newShared/constants";

export const useRoutes = () => {
    const history = useHistory();

    const goToOrganizations = () => {history.push(`${PATH_LOCAL_SPACE}`)};
    const goToOrganization = (orgId: string) => {history.push(`${PATH_LOCAL_SPACE}/${orgId}`)};
    const goToReport = (orgId: string, reportId: string) => {history.push(`${PATH_LOCAL_SPACE}/${orgId}/${reportId}`)};
    const goToControl = (orgId: string, reportId: string, controlId: string) => {history.push(`${PATH_LOCAL_SPACE}/${orgId}/${reportId}/${controlId}`)};

    const goToReportApp = (orgId: string, reportId: string, appId: string) => {history.push(`${PATH_LOCAL_SPACE}/${orgId}/${reportId}/integrations/${appId}`)};


    const {organizationId, reportId, controlId, appId} = useParams<{organizationId?: string, reportId?: string, controlId?: string, appId?: string}>();

    return {
        goToOrganizations,
        goToOrganization,
        goToReport,
        goToControl,

        goToReportApp,

        organizationId,
        reportId,
        controlId,
        appId,
    }
}