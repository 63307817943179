import {FC} from "react";
import {
    FormControl,
    FormHelperText,
    Icon,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useChangeUserPassword} from "../../hooks/useChangeUserPassword";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export const ChangePassword: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'UserProfile'});

    const {
        showPassword, switchShowPassword,
        formik: {handleSubmit, handleChange, errors, values},

        isLoading,
    } = useChangeUserPassword();

    return (
        <FormControl sx={{width: '100%', maxWidth: '320px'}} component={"form"} onSubmit={handleSubmit} >

            <Typography variant={"h1"}>{t('Change password')}</Typography>

            <FormControl variant="outlined" error={values.oldPass.length > 0 && Boolean(errors.oldPass)}>
                <InputLabel htmlFor={'Previous password'} className={'customLabel'}>{t('Previous password')}</InputLabel>
                <OutlinedInput fullWidth id={'Previous password'} name={'oldPass'}
                               label={t('Previous password')} placeholder={t('Input previous password')}
                               value={values.oldPass} onChange={handleChange}
                               sx={{marginTop: '16px'}}
                               disabled={isLoading}
                               type={showPassword.includes('old') ? 'text' : 'password'}
                               endAdornment={
                                   <InputAdornment position="end">
                                       <IconButton
                                           aria-label={"toggle password visibility"}
                                           onClick={() => {switchShowPassword('old')}}
                                           onMouseDown={(e) => {e.preventDefault()}}
                                           edge={"end"}
                                       >
                                           {!showPassword.includes('old') ? <VisibilityOff /> : <Visibility />}
                                       </IconButton>
                                   </InputAdornment>
                               }
                />
                {errors.oldPass && <FormHelperText >{t(errors.oldPass)}</FormHelperText>}
            </FormControl>

            <FormControl variant="outlined" error={values.newPass.length > 0 && Boolean(errors.newPass)}>
                <InputLabel htmlFor={'New password'} className={'customLabel'}>{t('New password')}</InputLabel>
                <OutlinedInput fullWidth id={'New password'} name={'newPass'}
                               label={t('New password')} placeholder={t('Input new password')}
                               value={values.newPass} onChange={handleChange}
                               sx={{marginTop: '16px'}}
                               disabled={isLoading}
                               type={showPassword.includes('new') ? 'text' : 'password'}
                               endAdornment={
                                   <InputAdornment position="end">
                                       <IconButton
                                           aria-label={"toggle password visibility"}
                                           onClick={() => {switchShowPassword('new')}}
                                           onMouseDown={(e) => {e.preventDefault()}}
                                           edge={"end"}
                                       >
                                           {!showPassword.includes('new') ? <VisibilityOff /> : <Visibility />}
                                       </IconButton>
                                   </InputAdornment>
                               }
                />
                {errors.newPass && <FormHelperText>{t(errors.newPass)}</FormHelperText>}
            </FormControl>

            <FormControl variant="outlined" error={values.confirmPass.length > 0 && Boolean(errors.confirmPass)}>
                <InputLabel htmlFor={'Confirm new password'} className={'customLabel'}>{t('Confirm new password')}</InputLabel>
                <OutlinedInput fullWidth id={'Confirm new password'} name={'confirmPass'}
                               label={t('Confirm new password')} placeholder={t('Confirm new password')}
                               value={values.confirmPass} onChange={handleChange}
                               sx={{marginTop: '16px'}}
                               disabled={isLoading}
                               type={showPassword.includes('confirm') ? 'text' : 'password'}
                               endAdornment={
                                   <InputAdornment position="end">
                                       <IconButton
                                           aria-label={"toggle password visibility"}
                                           onClick={() => {switchShowPassword('confirm')}}
                                           onMouseDown={(e) => {e.preventDefault()}}
                                           edge={"end"}
                                       >
                                           {!showPassword.includes('confirm') ? <VisibilityOff /> : <Visibility />}
                                       </IconButton>
                                   </InputAdornment>
                               }
                />
                {errors.confirmPass && <FormHelperText>{t(errors.confirmPass)}</FormHelperText>}
            </FormControl>

            <LoadingButton loading={isLoading} type={"submit"}
                           disabled={Boolean(errors.oldPass || errors.newPass || errors.confirmPass)}
                           variant={"contained"} loadingPosition={"start"} startIcon={isLoading ? <Icon/> : undefined}
                           sx={{marginTop: '16px', width: 'fit-content'}}
            >
                {t('Submit')}
            </LoadingButton>

        </FormControl>
    )
}