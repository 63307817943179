
//getInvites
import {createAsyncThunk} from "@reduxjs/toolkit";
import {TInviteExtended} from "../../types";
import {acceptInvite, declineInvite, getInvites} from "../../api";
import { addSuccessfulSnack } from "../../../../barsEnvironment/snack/store/slice";

export const GetInvites = createAsyncThunk(
    'Invites/GetInvites',
    async (): Promise<TInviteExtended[]> => {
        return await getInvites();
    }
);

export const AcceptInvite = createAsyncThunk(
    'Invites/acceptInvite',
    async (data: {inviteId: string, type: string}, {dispatch}): Promise<string> => {
        const mes =  await acceptInvite(data.inviteId, data.type);
        dispatch(addSuccessfulSnack(mes));
        return data.inviteId;
    }
);

export const DeclineInvite = createAsyncThunk(
    'Invites/declineInvite',
    async (data: {inviteId: string, type: string}, {dispatch}): Promise<string> => {
        const mes =  await declineInvite(data.inviteId, data.type);
        dispatch(addSuccessfulSnack(mes));
        return data.inviteId;
    }
);