import {useLanguage} from "../../hooks/language/useLanguage";
import {Breadcrumbs} from "../../../../barsEnvironment/breadcrumbs";
import * as S from "../../components/styled";
import {FormControlLabel, List, ListItem, ListItemAvatar, ListItemIcon, Radio, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import React from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import ReactCountryFlag from "react-country-flag";

export const Language = () => {
    //reusing hook for dev language dialog
    const {setLanguage, currentLanguage} = useLanguage();
    const {revDir} = useMainTranslation('', {keyPrefix: 'settings.profile'});
    const {matches_1440Down} = useMedia(858);

    return(
        <Flex w={'100%'} h={'100%'} direction={'column'}>
            <Breadcrumbs/>
            <S.ListPageTopButtonContainer>
                <Typography variant={matches_1440Down ? 'h3' : "h1"} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 0 32px', color: colors.backgrounds.dark}}>{('Language')}</Typography>
            </S.ListPageTopButtonContainer>

            <S.ProfileSettingWrapper>
                <List sx={{ pt: 0 }}>
                    {SUPPORTED_LANGUAGES.map((e) => (
                        <ListItem onClick={() => setLanguage(e)} key={e.code}>
                            <ListItemIcon>
                                <FormControlLabel value={e.code === currentLanguage.code} control={<Radio />} label={e.name} checked={currentLanguage.code === e.code} />
                            </ListItemIcon>
                            <ListItemAvatar>
                                <ReactCountryFlag countryCode={e.countryCode.toUpperCase()} svg style={{fontSize: '36px', lineHeight: '36px'}}/>
                            </ListItemAvatar>
                        </ListItem>
                    ))}
                </List>
            </S.ProfileSettingWrapper>
        </Flex>
    )
}