import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    CircularProgress,
    Collapse,
    Divider,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import {getNCRStatus} from "../../helpers";
import {Cancel, Delete, Edit, ExpandLess, ExpandMore, Save} from "@mui/icons-material";
import {useAuditSpace} from "../../hooks/useAuditSpace";
import {useNCR} from "../../hooks/useNCR";
import {AdditionalAssets} from "../additionalAssets";
import {LoadingButton} from "@mui/lab";
import {useReportDialogs} from "../../hooks/useReportsDialogs";
import {NcrModel, RequestHistoryModel} from "../../../../../newShared/GQLTypes";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/workspaces/hooks/useManageWorkspacesAndOrganizations";
import colors from "../../../../../newShared/theme/colors";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {CustomInput} from "../../../../../newShared/components/Inputs";

type TProps = {
    ncr: NcrModel,
    isOpen: boolean,
    handleOpen: () => void
}
export const NCRBlock: FC<TProps> = ({ncr: oldNcr, isOpen, handleOpen}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'AuditSpace'});
    const {currData: {currentUser}} = UseManageWorkspacesAndOrganizations();

    const {
        controls: {report}, selectedControl: {users, NCRs},
        isLoading: {isUpdatingNcr, isChangingStatusNcr, isDeletingNcr}
    } = useAuditSpace();
    const {updateNcr, changeStatusNcr, deleteNcr} = useNCR();
    const {
        changeStatusNcr: {setChangeNcrStatus}
    } = useReportDialogs();

    const [ncr, setNcr] = useState<NcrModel>(oldNcr);
    const [editMode, setEditMode] = useState<boolean>(false);

    const OPEN = ncr.status === 'OPEN';
    const REVIEW = ncr.status === 'REVIEW';
    const REVOKED = ncr.status === 'REVOKE';
    const REJECTED = ncr.status === 'REJECTED';
    const APPROVED = ncr.status === 'APPROVED';

    const IN_REVIEW = report?.status === 'IN_REVIEW';
    const RESPONSE = report?.status === 'RESPONSE';

    const CAN_REDACT = OPEN && (IN_REVIEW || RESPONSE);

    const [history, setHistory] = useState<number>(-1);
    useEffect(() => {
        setHistory(-1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        setEditMode(false);
        setNcr(oldNcr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oldNcr]);

    const lastHistory = ncr.requestHistory?.[ncr.requestHistory?.length - 1];

    const isLoading = isDeletingNcr.includes(ncr.id) || isUpdatingNcr.includes(ncr.id) || isChangingStatusNcr.some(e => e.id === ncr.id);
    return (
        <Box width={'100%'} display={"flex"} flexDirection={"column"}>

            <Box display={"flex"} p={'5px 0'} onClick={handleOpen} alignItems={"center"} sx={{
                '&:hover': {
                    cursor: 'pointer',
                    // backgroundColor: colors.backgrounds.grey_light,
                }
            }}>
                <Typography variant={"h3"} color={colors.text.dark}>{`Created at ${parseDateAuto(ncr.createDate)}`}</Typography>
                <Typography variant={"h3"} color={getNCRStatus(t, ncr.status).color} margin={!revDir ? '0 auto 0 8px' : '0 8px 0 auto'}>{getNCRStatus(t, ncr.status).status}</Typography>

                {CAN_REDACT && (
                    editMode
                        ? (
                            <>
                                <IconButton sx={{margin: !revDir ? '0 0 0 auto' : '0 auto 0 0'}} disabled={isLoading}
                                            onClick={(e) => {e.stopPropagation(); setEditMode(false); setNcr(oldNcr)}}>
                                    <Cancel/>
                                </IconButton>
                                <IconButton onClick={(e) => {e.stopPropagation(); updateNcr(ncr)}} disabled={isLoading}>
                                    {isUpdatingNcr.includes(ncr.id) ? <CircularProgress size={24}/> : <Save/>}
                                </IconButton>
                            </>
                        )
                        : (
                            <>
                                <IconButton sx={{margin: !revDir ? '0 0 0 auto' : '0 auto 0 0'}}
                                            onClick={(e) => {e.stopPropagation(); setEditMode(true)}}>
                                    <Edit/>
                                </IconButton>
                            </>
                        )
                )}
                {CAN_REDACT && (
                    <IconButton disabled={isLoading}
                                onClick={(e) => {e.stopPropagation(); deleteNcr({ncrControlId: ncr.id})}}>
                        {isDeletingNcr.includes(ncr.id) ? <CircularProgress size={24}/> : <Delete/>}
                    </IconButton>
                )}
                <IconButton>{isOpen ? (<ExpandLess/>) : (<ExpandMore/>)}</IconButton>
            </Box>

            <Collapse in={isOpen || editMode} sx={{'& .MuiCollapse-wrapperInner': {width: '100%', display: "flex", flexDirection: 'column'}}}>
                {!editMode ? (
                    <>
                        <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Type of Non-Conformity')}</Typography>
                        <Typography variant={"body1"} color={colors.text.dark} sx={{marginBottom: '18px', marginTop: '5px'}}>
                            {ncr.type ? ncr.type.charAt(0).toUpperCase() + ncr.type.slice(1).toLowerCase() : '- - -'}</Typography>

                        <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Observed Non-Conformity')}</Typography>
                        {(ncr.observed || '- - -').split('\n').map((text, index, array) => (
                            <Typography variant={"body1"} color={colors.text.dark} key={ncr.id + text} sx={{
                                marginBottom: index === array.length - 1 ? '18px' : undefined,
                                marginTop: index === 0 ? '5px' : undefined,
                            }}>{text}</Typography>
                        ))}
                    </>
                ) : (
                    <>
                        <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Type of Non-Conformity')}</Typography>
                        <RadioGroup row value={ncr?.type || null} onChange={e => {
                            setNcr(prev => ({...prev, type: e.target.value}))
                        }}>
                            <FormControlLabel value={'MAJOR'} control={<Radio size={"small"} />} label={t('Major')} disabled={isLoading}/>
                            <FormControlLabel value={'MINOR'} control={<Radio size={"small"} />} label={t('Minor')} disabled={isLoading}/>
                        </RadioGroup>

                        <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Observed Non-Conformity')}</Typography>
                        <CustomInput multiline fullWidth minRows={3} maxRows={5}
                                     value={ncr?.observed || ''}
                                     onChange={e => {setNcr(prev => ({...prev, observed: e.target.value}))}}
                                     sx={{marginTop: '10px'}} disabled={isLoading}
                        />
                    </>
                )}

                {lastHistory && (
                    <>
                        <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Root Cause Analysis')}</Typography>
                        <Typography variant={"body1"} color={colors.text.dark} sx={{marginBottom: '18px', marginTop: '5px'}}>
                            {lastHistory.causeAnalysis || '- - -'}</Typography>

                        <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Corrective action')}</Typography>
                        {(lastHistory.correctiveAction?.description || '- - -').split('\n').map((text, index, array) => (
                            <Typography variant={"body1"} color={colors.text.dark} key={ncr.id + ' ' + index + ' ' + text} sx={{
                                marginBottom: index === array.length - 1 ? '18px' : undefined,
                                marginTop: index === 0 ? '5px' : undefined,
                            }}>{text}</Typography>
                        ))}

                        <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Proposed completion date')}</Typography>
                        <Typography variant={"body1"} color={colors.text.dark} sx={{marginBottom: '18px', marginTop: '5px'}}>
                            {parseDateAuto(lastHistory.correctiveAction?.proposedCompletionDate)}</Typography>

                        {(lastHistory.evidenceAssets?.length ?? 0) > 0 && (
                            <>
                                <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Additional evidences')}</Typography>
                                <AdditionalAssets assets={lastHistory.evidenceAssets || []}/>
                            </>
                        )}
                    </>
                )}

                {(ncr.requestHistory?.filter(e => e.correctiveActionAccepted === false).length ?? 0) > 0 && <Typography margin={'16px 0 8px 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Previous NCRs')}</Typography>}
                {(ncr.requestHistory || []).filter((e: RequestHistoryModel) => e.correctiveActionAccepted === false).map((h, index) => {
                    // const date = parseDate(h.approveDate, momentLocale, 'DD/MM/YYYY hh:mm');
                    // const isDate = date !== '- - -';

                    return (
                        <Accordion expanded={history === index} onChange={() => {setHistory(prev => prev === index ? -1 : index)}} key={JSON.stringify(h)} >
                            <AccordionSummary expandIcon={<ExpandMore/>}>
                                <Typography variant={"body2"}>
                                    {/*{`${t('Added at')} ${date} ${*/}
                                    {/*    currentUser?.publicId === h.correctiveAction?.authorPublicId*/}
                                    {/*        ? t('You')*/}
                                    {/*        : users.find(e => e.id === h.correctiveAction?.authorPublicId)?.name}`}*/}

                                    {`${t('Added by')} ${
                                        currentUser?.publicId === h.correctiveAction?.authorPublicId
                                            ? t('You')
                                            : users.find(e => e.id === h.correctiveAction?.authorPublicId)?.name}
                                `}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant={"subtitle2"} color={colors.text.dark}>{t('Root cause analysis')}</Typography>
                                <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{h.causeAnalysis}</Typography>

                                <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Corrective action')}</Typography>
                                <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{h.correctiveAction?.description || ''}</Typography>

                                <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Proposed completion date')}</Typography>
                                <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{parseDateAuto(h.correctiveAction?.proposedCompletionDate)}</Typography>

                                {(h.evidenceAssets?.length || 0) > 0 && <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Additional evidences')}</Typography>}
                                <AdditionalAssets assets={h.evidenceAssets || []}/>

                                {h.comment && (
                                    <>
                                        <Typography margin={'16px 0 0 0'} variant={"subtitle2"} color={colors.text.dark}>{t('Reject comment')}</Typography>
                                        <Typography margin={'8px 0 0 0'} variant={"body1"} color={colors.text.dark}>{h.comment || ''}</Typography>
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    )
                })}

                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} mt={'10px'}>
                    {!editMode && REVIEW && !REVOKED && !REJECTED && !APPROVED && RESPONSE && (
                        <>
                            <LoadingButton variant={"contained"} disabled={isLoading} loading={isChangingStatusNcr.some(e => e.id === ncr.id && e.status === 'REVOKE')}
                                           onClick={() => {changeStatusNcr({workspaceId: ncr.workspaceId, reportId: ncr.reportId, frameworkId: ncr.frameworkId, controlId: ncr.controlId, ncrId: ncr.id, status: 'REVOKE'})}}
                                           // onClick={() => {setChangeNcrStatus({isOpen: true, data: {workspaceId: ncr.workspaceId, reportId: ncr.reportId, frameworkId: ncr.frameworkId, controlId: ncr.controlId, ncrId: ncr.id, status: 'REVOKE'}})}}
                            >
                                {t('Revoke')}
                            </LoadingButton>

                            <LoadingButton variant={"contained"} disabled={isLoading} loading={isChangingStatusNcr.some(e => e.id === ncr.id && e.status === 'REJECTED')}
                                           // onClick={() => {changeStatusNcr({workspaceId: ncr.workspaceId, reportId: ncr.reportId, frameworkId: ncr.frameworkId, controlId: ncr.controlId, ncrId: ncr.id, status: 'REJECTED'})}}
                                           onClick={() => {setChangeNcrStatus({isOpen: true, data: {workspaceId: ncr.workspaceId, reportId: ncr.reportId, frameworkId: ncr.frameworkId, controlId: ncr.controlId, ncrId: ncr.id, status: 'REJECTED'}})}}
                                           sx={{margin: '0 10px'}}>
                                {t('Reject')}
                            </LoadingButton>

                            <LoadingButton variant={"contained"} disabled={isLoading} loading={isChangingStatusNcr.some(e => e.id === ncr.id && e.status === 'APPROVED')}
                                           onClick={() => {changeStatusNcr({workspaceId: ncr.workspaceId, reportId: ncr.reportId, frameworkId: ncr.frameworkId, controlId: ncr.controlId, ncrId: ncr.id, status: 'APPROVED'})}}
                                           // onClick={() => {setChangeNcrStatus({isOpen: true, data: {workspaceId: ncr.workspaceId, reportId: ncr.reportId, frameworkId: ncr.frameworkId, controlId: ncr.controlId, ncrId: ncr.id, status: 'APPROVED'}})}}
                            >
                                {t('Approve')}
                            </LoadingButton>
                        </>
                    )}
                </Box>

                {NCRs.length > 1 && <Divider flexItem sx={{marginTop: '10px'}}/>}
            </Collapse>
        </Box>
    )
}