import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";

export type TLeftMenuSlice = {
    isOpen: boolean,

    pinnedItems: string[] | null,
}

const initialState: TLeftMenuSlice = {
    isOpen: false,

    pinnedItems: null,
}

const Slice = createSlice({
  name: 'leftMenu',
  initialState,
  reducers: {
      setOpenMenuAction(slice: TLeftMenuSlice, {payload}: {payload: TLeftMenuSlice["isOpen"]}) {
          slice.isOpen = payload
      },
      setPinnedMenuItemsAction(slice: TLeftMenuSlice, {payload}: {payload: TLeftMenuSlice["pinnedItems"]}) {
          slice.pinnedItems = payload
      },
  },
  // extraReducers: builder => {
  //   builder
  //
  // }
});

export const leftMenuReducerReducer = Slice.reducer;

export const {
    setOpenMenuAction,
    setPinnedMenuItemsAction,
} = Slice.actions;

const selectSelf = (state: AppState): TLeftMenuSlice => state.leftMenu;

export const leftMenuIsOpenSelector = createSelector(selectSelf, state => state.isOpen);
export const leftMenuPinnedItemsSelector = createSelector(selectSelf, state => state.pinnedItems);
