import {FC} from "react";
import {FormControl, Icon, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useChangeUserName} from "../../hooks/useChangeUserName";

export const ChangeName: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'UserProfile'});

    const {
        firstName, handleChangeFirstName,
        lastName, handleChangeLastName,

        handleSubmit,
        isLoading,
    } = useChangeUserName();

    return (
        <FormControl sx={{width: '100%', maxWidth: '320px'}}>

            <Typography variant={"h1"}>{t('Change user info')}</Typography>

            <TextField fullWidth label={t('First name')}
                       value={firstName} onChange={handleChangeFirstName}
                       sx={{marginTop: '16px'}}
                       disabled={isLoading}
            />

            <TextField fullWidth label={t('Last name')}
                       value={lastName} onChange={handleChangeLastName}
                       sx={{marginTop: '16px'}}
                       disabled={isLoading}
            />

            <LoadingButton onSubmit={handleSubmit} onClick={handleSubmit} loading={isLoading}
                           disabled={firstName.trim() === '' || lastName.trim() === ''}
                           variant={"contained"} loadingPosition={"start"} startIcon={isLoading ? <Icon/> : undefined}
                           sx={{marginTop: '16px', width: 'fit-content'}}
            >
                {t('Submit')}
            </LoadingButton>

        </FormControl>
    )
}