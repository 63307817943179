import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Typography} from "@mui/material";
import React, {FC} from "react";
import {useReportDialogs} from "../../../hooks/useReportsDialogs";
import {TAssetDataCUSTOM} from "../../../types";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const StatementReportDialog: FC = () => {
    const {t: tCommon} = useMainTranslation('', {keyPrefix: 'AuditSpace'});

    const {statement: {
        isLoading, isOpen, setStatementDialog, data,
    }} = useReportDialogs();
    const handleClose = () => {setStatementDialog({clear: true})};

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                {isLoading ? <Skeleton/> : data?.[0]?.name || 'Statement'}
            </DialogTitle>

            <DialogContent>
                <Typography variant={"body2"}>
                    {isLoading ? <Skeleton/> : (data as TAssetDataCUSTOM | undefined)?.[0]?.text || ''}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => {handleClose()}} variant={"text"}>
                    {tCommon('Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}