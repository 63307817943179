import styled from "styled-components";
import {device} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";
import {TextField} from "@mui/material";

export const ListPageTopButtonContainer = styled.div`
    display: flex;
    align-items: center;
    ${device.generateMax(1049)} {
        width: 100%;
        justify-content: space-between;
    }
    ${device.generateMax(501)} {
        margin: 11px 0 0 0;
    }
`;

export const FilterContainer = styled.div`
    display: flex;
    width: 20%;
    margin: 30px 0 0 0;
    & div{
        margin: 0 10px 0 0;
    }
    ${device.tablet} {
        width: 100%;
    }
`;

export const IntegrationCardsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    padding: 16px;
    margin: 8px 0 0 0;
`;

export const IntegrationCard = styled.div<{disableHover?: boolean}>`
    width: 200px;
    min-height: 160px;
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 2px 6px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 2px 6px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin: 8px;
    ${device.tablet}{
        width: calc(50% - 24px);
    }
    // ${device.desktopNarrow}{
    //     width: 100%;
    //     margin: 8px 0 0 0;
    //     & div img{
    //         width: 20%;
    //     }
    // }
    ${props => props.disableHover ? '' : 
    `
    &:hover{
        background-color: ${colors.backgrounds.blue_light_2};
        cursor: pointer;
    }
    `}
`;


export const ExactAppCollectionsListContainer = styled.div`
    width: 20%;
    display: flex;
    ${device.desktopNarrow}{
       display: none;
    }
`;

export const ExactAppItemsListContainer = styled.div<{rightSideOpened: boolean}>`
    padding: 8px 16px;
    height: 100%;
   width: ${props => props.rightSideOpened ? '40%' : '80%'};
   ${device.desktopNarrow}{
       ${props => props.rightSideOpened ? 'display: none' : 'width: 100%'};
   }
`;

export const ExactAppItemsListSearchContainer = styled.div<{rightSideOpened: boolean}>`
    ${props => props.rightSideOpened && `
        width: 50%;
    `}
    ${props => !props.rightSideOpened && `
        width: 25%;
        ${device.desktopNarrow}{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    `}
`;

export const ExactAppExactItemContainer = styled.div`
    // border: 1px solid blue;
    height: 100%;
    width: 40%;
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const ExactAppObjListRow = styled.div<{selected: boolean}>`
    width: 100%;
    padding: 16px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${props => props.selected && `background-color: ${colors.backgrounds.blue_light_2};`}
    &: hover{
        background-color: ${colors.backgrounds.grey_light};
        cursor: pointer;
    }
`;

export const MainTableScrollWrapper = styled.div`
    overflow: auto;
    width: 100%;
    max-height: calc(100% - 60px);
    height: calc(100% - 60px);
`;


export const AppHistoryTableHeaderWrapper = styled.div<{hoverable: boolean}>`
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 24px 0;
    ${device.tablet} {
        width: 100%;
        margin: 25px 0 24px 0;
    }
    &:hover{
        ${props => props.hoverable && `
        background-color: ${colors.backgrounds.grey_light}; 
        cursor: pointer;
        `}
    }
`;

export const AppHistoryTableHeaderItemWrapper = styled.div<{ width?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: center;
`;

export const AppHistoryTableMainWrapper = styled.div`
    overflow: auto;
    width: 100%;
`;

export const AppLogsTableMainWrapper = styled.div`
    overflow: auto;
    width: 100%;
`;

export const TextFieldCustom = styled(TextField)`
  & .MuiInputBase-input {
    height: 16px;
  }
  & label{
    top: 0 !important;
  }
`;

export const AppConfigContainer = styled.div`
    padding: 16px 8px;
    width: 33%;
    display: flex;
    flex-direction: column;
    ${device.desktopM}{
        width: 33%;
    }
    ${device.desktop}{
       width: 25%;
    }
    ${device.tablet}{
        width: 50%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const DialogContentWrapper = styled.div<{margin?: string}>`
    width: 60%;
    ${props => props.margin && `margin: ${props.margin};`}

    ${device.tablet}{
        width: 60%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const ExactAppLogsLeftSide = styled.div<{isRightSideOpened: boolean}>`
    // border: 1px solid red;
    width: ${props => props.isRightSideOpened ? '40%' : '100%'};
    overflow: auto;
    ${device.tablet}{
        width: ${props => props.isRightSideOpened ? '50%' : '100%'};
    }
    ${device.desktopNarrow}{
        width: ${props => props.isRightSideOpened ? '0' : '100%'};
    }
`;

export const ExactAppLogsRightSide = styled.div<{isRightSideOpened: boolean}>`
    // border: 1px solid blue;
    width: ${props => props.isRightSideOpened ? '60%' : '0'};
    ${device.tablet}{
        width: ${props => props.isRightSideOpened ? '50%' : '0'};
    }
    overflow: auto;
    ${device.desktopNarrow}{
        width: ${props => props.isRightSideOpened ? '100%' : '0'};
    }
`;

export const ExactAppLogsTableHeaderWrapper = styled.div<{isRightSideOpened: boolean}>`
    margin: 25px 0 8px 0;
    ${props => props.isRightSideOpened ? `
        width: 100%;
        display: flex;
        justify-content: space-between;
    ` : `
        width: 40%;
        display: flex;
        justify-content: space-between;
        ${device.tablet}{
            width: 50%;
        }
        ${device.desktopNarrow}{
            width: 100%;
        }
    `}
`;

export const ExactAppLogsTableRowWrapper = styled.div<{isRightSideOpened: boolean, hoverable: boolean, selected: boolean}>`
    padding: 16px 0;
    ${props => props.selected && `background-color: ${colors.backgrounds.blue_light_2};`}
    ${props => props.isRightSideOpened ? `
        width: 100%;
        display: flex;
        justify-content: space-between;
    ` : `
        width: 40%;
        display: flex;
        justify-content: space-between;
        ${device.tablet}{
            width: 50%;
        }
        ${device.desktopNarrow}{
            width: 100%;
        }
    `}
    
    &:hover{
        ${props => props.hoverable && `
        background-color: ${colors.backgrounds.grey_light}; 
        cursor: pointer;
        `}
    }
`;