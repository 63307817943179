import React, {FC, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSignIn} from "../../hooks/useSignIn";
import {useRoutes} from "../../hooks/useRoutes";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";
import {Box, Link, Menu, MenuItem, TextField, Typography} from "@mui/material";
import {DropdownButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {Language} from "@mui/icons-material";
import {SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";

export const BlockSignInRestoreMfa: FC = () => {
    const {t, currentLanguage: {name, code}, handleChooseLanguage} = useMainTranslation('', {keyPrefix: ''});
    // const {user, isGoogleAuthConfigComplete} = UseManageWorkspacesAndOrganizations();

    const {
        handleLoginByRecoveryCode,
        isLoading: {isCheckingMfa},
    } = useSignIn();
    const {goToMfa, goToLogin} = useRoutes();

    const {anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose} = useThreeDotsMenu();
    const [mfaCode, setMfaCode] = useState<string>('');

    const handleContactUs = () => {};

    // useEffect(() => {
    //     // user && !isGoogleAuthConfigComplete && goToLogin();
    //     console.log(!!user);
    // }, [user, isGoogleAuthConfigComplete])

    return (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Two-Factor Authentication')}</Typography>
            <Typography variant={"body1"} textAlign={"center"} mt={'8px'}>{t('If you are unable to access your mobile device, enter one of your recovery codes to verify your identity.')}</Typography>

            <DropdownButton value={code} sx={{m: '16px auto 0', alignSelf: 'center'}} variant={"text"}
                            icon={<Language/>} open={!!anchorThreeDotsEl} onClick={handleThreeDotsOpen}>
                <Typography variant={"body1"}>{name}</Typography>
            </DropdownButton>
            <Menu open={!!anchorThreeDotsEl} anchorEl={anchorThreeDotsEl} onClose={handleThreeDotsClose}>
                {SUPPORTED_LANGUAGES.map((e) => (
                    <MenuItem value={e.code} key={e.code}
                              sx={{width: anchorThreeDotsEl?.offsetWidth}}
                              onClick={() => {
                                  handleChooseLanguage(e.code);
                                  handleThreeDotsClose();
                              }}>
                        {e.name}
                    </MenuItem>
                ))}
            </Menu>

            <TextField label={t('Recovery 2FA code')} value={mfaCode} onChange={e => setMfaCode(e.target.value)}
                       sx={{mt: '16px'}}/>

            <Link onClick={goToMfa}
                  sx={{
                      alignSelf: 'flex-end', cursor: 'pointer',
                      fontWeight: 500, fontSize: '14px', lineHeight: '24px'
                  }}>
                {t('Use 2FA code?')}
            </Link>

            <Box display={"flex"} alignItems={"center"} gap={'16px'} mt={'16px'} width={'100%'}>
                <LoadingButton loading={isCheckingMfa} onClick={() => handleLoginByRecoveryCode(mfaCode)}
                               disabled={mfaCode.trim() === ''}>
                    {t('Continue')}
                </LoadingButton>
            </Box>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('Back to')} `}<span onClick={goToLogin} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Log in')}</span>
            </Typography>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('If you have a problem')} `}<span onClick={handleContactUs} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Contact us')}</span>
            </Typography>
        </>
    )
}