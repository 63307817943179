import React, {FC, useEffect, useState} from "react";
import {useAuditSpace} from "../../hooks/useAuditSpace";
import {useRoutes} from "../../hooks/useRoutes";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {getNCRStatus} from "../../helpers";
import {ReportControlView} from "../reportControlView";
import {Block, Visibility} from "@mui/icons-material";
import {PreviewFullReportNcrDialog} from "../../components/dialogs/previewFullReportNcrDialog";
import {ChangeReportConfig} from "../changeReportConfig";
import {ReportViewInfo} from "../../components/reportViewInfo";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useParameters} from "../../../../../newShared/hooks/useParameters";
import {useScroll} from "../../../../../newShared/hooks/useScroll";
import colors from "../../../../../newShared/theme/colors";
import {MinimizeInfoWrapper} from "../../../../../newShared/components/minimizeInfoWrapper";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";
import {useReportFilter} from "../../hooks/useReportFilter";
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";

export const ReportView: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'AuditSpace'});
    // const history = useHistory();
    const {filter} = useParameters();

    const {
        controls: {report, controls, pageInfo},
        isLoading: {isLoadingControls, isChangingStatusReport, isRejectingReport, isLoadingPreview},
        getControls, getReportById, getReportPreview,
        changeReportStatus,
        cleanReport, cleanControls,
    } = useAuditSpace();
    const {
        goToControl, goToOrganization, goToReportApp,
        reportId, controlId, organizationId
    } = useRoutes();

    const {configs, applyFilter} = useReportFilter();

    const controller = new AbortController();

    const {bottomLoader, isBottom, isFirst, onScroll, scrollRef} = useScroll();
    useEffect(() => {
        const isTop = isFirst;
        const {page, count, total} = pageInfo;
        (total === 0 || controls.length < total) &&
        (isTop || isBottom) && !isLoadingControls && organizationId && reportId && getControls(organizationId, reportId,{
            page: isTop
                ? 0
                : controls.length < (page + 1) * count
                    ? page
                    : page + 1,
            count,
            filters: filter
        }, controller.signal, isFirst);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirst, isBottom]);

    useEffect(() => {
        organizationId && reportId && getReportById(organizationId, reportId, controller.signal)
        return () => {
            cleanControls();
            cleanReport();
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const IN_REVIEW = report?.status === 'IN_REVIEW';
    const PUBLISHED = report?.status === 'PUBLISHED';
    const RESPONSE = report?.status === 'RESPONSE';
    const REJECTED = report?.status === 'REJECTED';

    const isLoading = isLoadingControls || !!isChangingStatusReport || isRejectingReport || isLoadingPreview;

    const [showEditConfig, setShowEditConfig] = useState<boolean>(false);

    const {isTablet} = useMedia();
    return (
        <>
            <Box display={!showEditConfig ? "flex" : "none"} width={'100%'} height={'100%'} overflow={"hidden"}>
                <Box flexDirection={"column"} height={'100%'} overflow={'hidden'}
                     flexBasis={"100%"}
                     display={((controlId)) ? 'none' : "flex"}>
                    {/*<Breadcrumbs/>*/}

                    <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                        <PageTitleAndActions
                            title={report?.name}
                            showMenuWhen={isTablet}
                            handleGoBack={() => organizationId && goToOrganization(organizationId)}
                            showParticularMenuAfterElementIndex={isTablet ? 0 : undefined}
                            actions={[
                                {
                                    dontShowWhen: !(report && (IN_REVIEW || RESPONSE) && report.smallNCRs?.some(e => e.status === 'OPEN' || e.status === 'REJECTED' || e.status === 'REVIEW')),
                                    title: t('Publish'),
                                    onClick: () => report && changeReportStatus(report.workspaceId, report.id, 'PUBLISHED'),
                                    disabled: (
                                        isLoading || !report || !report.reportNCR?.title
                                        || report.smallNCRs?.some(e => e.status === 'REVIEW')
                                        || report.reviewedControls.length !== report.totalControls
                                    ),
                                    loading: isChangingStatusReport === 'PUBLISHED',
                                    size: 'small',
                                },
                                {
                                    dontShowWhen: !(report && (IN_REVIEW || RESPONSE) && report.smallNCRs?.every(e => e.status === 'REVOKE' || e.status === 'APPROVED')),
                                    title: t('Complete'),
                                    onClick: () => report && changeReportStatus(report.workspaceId, report.id, 'COMPLETED'),
                                    disabled: isLoading || !report || !report.reportNCR?.title || (report.reviewedControls.length < report.totalControls),
                                    loading: isChangingStatusReport === 'COMPLETED',
                                    size: 'small'
                                },
                                {
                                    dontShowWhen: !(report && (IN_REVIEW) && !report.reportNCR?.title),
                                    title: t('Edit report information'),
                                    onClick: () => {setShowEditConfig(true)},
                                    disabled: isLoading,
                                    size: 'small'
                                },
                                {
                                    dontShowWhen: !IN_REVIEW || !reportId,
                                    variant: 'contained',
                                    icon: <Block/>,
                                    title: t('Reject report'),
                                    onClick: () => report && changeReportStatus(report.workspaceId, report.id, 'REJECTED'),
                                    disabled: isLoading,
                                    loading: isChangingStatusReport === 'REJECTED',
                                    size: 'small'
                                },
                                {
                                    dontShowWhen: !((PUBLISHED || RESPONSE) && report?.reportNCR?.title) || !reportId,
                                    variant: 'contained',
                                    title: t('Edit report information'),
                                    onClick: () => {setShowEditConfig(true)},
                                    disabled: isLoading,
                                    size: 'small'
                                },
                                {
                                    dontShowWhen: !(!REJECTED && report?.reportNCR?.title) || !reportId,
                                    variant: 'contained',
                                    icon: <Visibility/>,
                                    title: t('Preview report'),
                                    onClick: () => report && report.reportNCR?.title && organizationId && getReportPreview(organizationId, report.workspaceId, report.id),
                                    disabled: isLoading,
                                    loading: isLoadingPreview,
                                    size: 'small'
                                },
                                {
                                    dontShowWhen: !report || !reportId,
                                    variant: 'outlined',
                                    icon: <Visibility/>,
                                    title: t('Integration details'),
                                    onClick: () => organizationId && reportId && goToReportApp(organizationId, reportId, ''),
                                    disabled: isLoading,
                                    loading: isLoadingPreview,
                                    size: 'small'
                                },
                            ]}
                        />
                    </Box>

                    <MinimizeInfoWrapper>
                        <ReportViewInfo/>

                        {/*{isLessThen1050 && <ReportViewFilters controller={controller}/>}*/}
                    </MinimizeInfoWrapper>

                    {/*{!isLessThen1050 && <ReportViewFilters controller={controller}/>}*/}

                    <GenericFiltersArray
                        configs={configs}
                        fetchResultWithSelectedFilters={applyFilter}
                        isAddFilters={false}
                    />


                    {controls.length === 0 && !isLoadingControls ?
                        <EmptyArrayImage type={"organizationReports"} isShowing={controls.length === 0 && !isLoadingControls} text={'No controls found'} withoutAdd/>
                        :
                        <TableContainer onScroll={onScroll} ref={scrollRef} sx={{marginTop: '24px'}}>
                            <Table stickyHeader size={"medium"}>
                                <TableHead>
                                    <TableRow sx={{'& .MuiTableCell-root': {backgroundColor: colors.backgrounds.grey_light, width: '100%', paddingTop: '6px', paddingBottom: '6px'}}}>
                                        {/*Column actions is not in columnConfig - its generated by default at the end of row*/}
                                        <TableCell colSpan={5}>
                                            <Typography variant={'subtitle1'} color={colors.text.grey}>{`${('Total controls')}: ${pageInfo.total}`}</Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>{t('Control name')}</TableCell>
                                        <TableCell>{t('NCR status')}</TableCell>
                                        <TableCell>{t('Review status')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {controls.map((control, index) => control ? (
                                        <TableRow key={control.id} hover selected={controlId === control.id}
                                                  onClick={() => {organizationId && reportId && goToControl(organizationId, reportId, control.id)}}>
                                            <TableCell>{control.name}</TableCell>
                                            <TableCell style={{color: getNCRStatus(t, control.smallNCRs?.map(e => e.status)).color}}>{getNCRStatus(t, control.smallNCRs?.map(e => e.status)).status}</TableCell>
                                            <TableCell>{control.reviewed ? t('Reviewed') : t('Unreviewed')}</TableCell>
                                        </TableRow>
                                    ) : <TableRowSkeleton key={'loader' + index} columns={3}/>)}

                                    {(bottomLoader || isLoadingControls) && <TableRowSkeleton columns={3}/>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Box>

                {/*RightSide*/}
                {(controlId) && (
                    <>
                        {/*{!isLessThen1050 && <Divider flexItem orientation={"vertical"} variant={"fullWidth"} sx={{margin: '0 40px', borderColor: colors.backgrounds.grey_dark}}/>}*/}
                        <ReportControlView/>
                    </>
                )}

                <PreviewFullReportNcrDialog/>
            </Box>

            <ChangeReportConfig show={showEditConfig} close={() => {setShowEditConfig(false)}}/>
        </>
    )
}