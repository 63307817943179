import {FC, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight, PushPin} from "@mui/icons-material";

import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useLeftMenu} from "../../hooks/useLeftMenu";

import colors from "../../../../../newShared/theme/colors";
import {TMenuItem} from "../../types";

type TProps = {
    item: TMenuItem,
    pinned?: boolean,
    second?: boolean,
    secondTitle?: boolean,
}
export const LeftMenuItem: FC<TProps> = ({item, pinned, second, secondTitle}) => {
    const {title, icon: Icon, path, child} = item;

    const history = useHistory();
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'LeftMenu'});

    const {
        pinnedItems, setPinnedItems,
        setOpen,
    } = useLeftMenu();

    const [isHovered, setIsHovered] = useState<boolean>(false);

    const [isSelected, setIsSelected] = useState<boolean>(false);
    useEffect(() => {
        setIsSelected(history.location.pathname.startsWith(path) || child?.some(e => history.location.pathname.startsWith(e.path)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.pathname]);

    const handleSelect = (p?: string) => {
        setOpen(false);
        history.push(p || path)
    }

    const anchorSubMenuEl = useRef<HTMLElement>(null);
    const [isSubMenuHovered, setIsSubMenuHovered] = useState<boolean>(false);
    const [isSubManuOpen, setIsSubManuOpen] = useState<boolean>(false);

    return (
        <ListItem disablePadding
                  onMouseOver={() => {setIsHovered(true)}}
                  onMouseLeave={() => {setIsHovered(false)}}
                  sx={{
                      padding: '6px 0',
                      pointerEvents: secondTitle ? 'none' : undefined,
                      textTransform: "uppercase",
                      lineHeight: '20px',
                      '& *': {
                          backgroundColor: 'transparent !important',
                          '.MuiListItemText-root, .MuiListItemIcon-root': {
                              color: isSelected ? colors.primary.blue : colors.text.grey_dark,
                          },
                          '& div.menu-icon .svg-fill path, & div.menu-icon .svg-fill circle': {
                              fill: isSelected ? colors.primary.blue : colors.text.grey_dark + ' !important',
                          },
                          '& div.menu-icon .svg-stroke path, & div.menu-icon .svg-stroke circle': {
                              stroke: isSelected ? colors.primary.blue : colors.text.grey_dark + ' !important',
                          },
                      },
                      '&:hover *': {
                          backgroundColor: 'transparent !important',
                          '.MuiListItemText-root, .MuiListItemIcon-root': {
                              color: colors.text.dark,
                          },
                          '& div.menu-icon .svg-fill path, & div.menu-icon .svg-fill circle': {
                              fill: colors.text.dark + ' !important',
                          },
                          '& div.menu-icon .svg-stroke path, & div.menu-icon .svg-stroke circle': {
                              stroke: colors.text.dark + ' !important',
                          },
                      },
                      backgroundColor: second && isSelected ? colors.backgrounds.blue_light_1 : 'transparent !important',
                  }}
        >
            <ListItemButton selected={isSelected}
                            onClick={() => {handleSelect()}}
            >
                {Icon && (
                    <ListItemIcon sx={{width: '36px', minWidth: '36px'}} className={'menu-icon'}>
                        <Icon style={{maxHeight: '24px'}}/>
                    </ListItemIcon>
                )}
                <ListItemText primary={t(title)} primaryTypographyProps={{variant: !second ? 'subtitle2' : 'body1'}}/>

                {!second && !secondTitle && (
                    <ListItemIcon sx={{width: '25px', minWidth: '25px'}}
                                  onClick={(isHovered || pinned) ? (e) => {
                                      e.stopPropagation();
                                      if (pinned) setPinnedItems(pinnedItems ? pinnedItems.filter(e => e !== title) : [])
                                      else setPinnedItems(pinnedItems ? [...pinnedItems, title] : [title])
                                  } : undefined}
                    >
                        {(isHovered || pinned) && (
                            <PushPin sx={{
                                margin: '0 auto', borderRadius: '100%',
                                '&:hover': {color: (pinned ? colors.text.grey : colors.text.grey_dark) + ' !important' },
                            }}/>
                        )}
                    </ListItemIcon>
                )}

                {child && child.length > 1 && !secondTitle ? (
                    <>
                        <ListItemIcon sx={{width: '24px', minWidth: '24px'}}
                                      ref={anchorSubMenuEl}
                                      onMouseOver={() => {setIsSubManuOpen(true)}}
                                      onMouseLeave={() => {setIsSubManuOpen(false)}}
                        >
                            {!revDir ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                        </ListItemIcon>
                        <Menu
                            sx={{pointerEvents: 'none'}}
                            keepMounted
                            anchorEl={anchorSubMenuEl.current}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            open={isSubManuOpen || isSubMenuHovered}
                            onClose={() => {setIsSubManuOpen(false); setIsSubMenuHovered(false)}}
                            PaperProps={{
                                sx: {pointerEvents: 'auto'},
                                onMouseOver: () => {setIsSubMenuHovered(true)},
                                onMouseLeave: () => {setIsSubMenuHovered(false)},
                            }}
                        >
                            {child.map(e => (
                                <MenuItem
                                    key={e.title}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleSelect(e.path);
                                        setIsSubManuOpen(false);
                                        setIsSubMenuHovered(false);
                                    }}>
                                    {t(e.title)}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                ) : <Box height={'100%'} width={'24px'}/> }
            </ListItemButton>
        </ListItem>
    )
}