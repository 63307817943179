import {gql} from "@apollo/react-hooks";
import {NCRFragment, NcrReportConfigFragment, reportAppFragment, reportFragment} from "./fragments";

export const getReportOrganizationsByAuditor = gql`
    query getReportOrganizationsByAuditor($pageInfo: PaginationInput!) {
        getReportOrganizationsByAuditor(pageInfo: $pageInfo) {
            organizations {
                id
                name
                logo
                reports
                lastUpdate
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
`

export const getReportsByOrganizationIdByAuditor = gql`
    query getReportsByOrganizationIdByAuditor($organizationId: String!, $pageInfo: PaginationInput!) {
        getReportsByOrganizationIdByAuditor(organizationId: $organizationId, pageInfo: $pageInfo) {
            organization {
                id
                name
                reports
                lastUpdate
                logo
            }
            reports {
                id
                name
                status
                type
                lastUpdate
                framework {
                    id
                    name
                    logo
                }
                workspaceId
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
`

export const getReportByIdByAuditor = gql`
    query getReportByIdByAuditor($organizationId: String!, $reportId: String!) {
        getReportByIdByAuditor(organizationId: $organizationId, reportId: $reportId) {
            report {
                ...reportFragment
            }
            
            organization {
                id
                name
                reports
                lastUpdate
            }
        }
    }
    ${reportFragment}
`

export const getReportControlsByAuditor = gql`
    query getReportControlsByAuditor($organizationId: String!, $reportId: String!, $pageInfo: PaginationInput!) {
        getReportControlsByAuditor(organizationId: $organizationId, reportId: $reportId, pageInfo: $pageInfo) {
            controls {
                id
                name
                reviewed
                smallNCRs {
                    id
                    status
                    type
                }
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
`

export const getReportControlByAuditor = gql`
    query getReportControlByAuditor($organizationId: String!, $reportId: String!, $controlId: String!) {
        getReportControlByAuditor(organizationId: $organizationId, reportId: $reportId, controlId: $controlId) {
            id
            name
            reviewed
            smallNCRs {
                id
                status
                type
            }
            description
            evidences {
                evidenceId
                id
                name
                lastUpdate
                type
                data
            }
        }
    }
`

export const changeReviewedControlByAuditor = gql`
    mutation changeReviewedControlByAuditor($controlId: String!, $frameworkId: String!, $reportId: String!, $workspaceId: String!, $reviewed: Boolean!) {
        changeReviewedControlByAuditor(controlId: $controlId, frameworkId: $frameworkId, reportId: $reportId, workspaceId: $workspaceId, reviewed: $reviewed) {
            reportId
            reviewed
            workspaceId
            controlId
        }
    }
`


// //NCR
export const getNCRsByControlByAuditor = gql`
    query getNCRsByControlByAuditor($organizationId: String!, $reportId: String!, $controlId: String!) {
        getNCRsByControlByAuditor(organizationId: $organizationId, reportId: $reportId, controlId: $controlId) {
            NCRs {
                ...NCRFragment
            }
            users {
                id
                name
                email
            }
        }
    }
    ${NCRFragment}
`

export const createNcr = gql`
    mutation createNcr($ncrControl: NCRInput!) {
        createNcr(ncrControl: $ncrControl) {
            ...NCRFragment
        }
    }
    ${NCRFragment}
`

export const updateNCR = gql`
    mutation updateNCR($ncrControl: NCRInput!) {
        updateNCR(ncrControl: $ncrControl) {
            ...NCRFragment
        }
    }
    ${NCRFragment}
`

export const deleteNCR = gql`
    mutation deleteNCR($ncrControlId: String!) {
        deleteNCR(ncrControlId: $ncrControlId) {
            message
        }
    }
`

export const changeStatusNCRByAuditor = gql`
    mutation changeStatusNCRByAuditor($controlId: String!, $frameworkId: String!, $ncrId: String!, $reportId: String!, $workspaceId: String!, $status: String!, $comment: String) {
        changeStatusNCRByAuditor(controlId: $controlId, frameworkId: $frameworkId, ncrId: $ncrId, reportId: $reportId, workspaceId: $workspaceId, status: $status, comment: $comment) {
            ...NCRFragment
        }
    }
    ${NCRFragment}
`

export const updateReportStatus = gql`
    mutation updateReportStatus($workspaceId: String!, $reportId: String!, $status: String!){
        updateReportStatus(workspaceId: $workspaceId, reportId: $reportId, status: $status){
            message
        }
    }
`

export const getAuditorsVariantsByAuditor = gql`
    query getAuditorsVariantsByAuditor {
        getAuditorsVariantsByAuditor {
            auditors {
                id
                email
                name
            }
            organization {
                id
                name
            }
        }
    }
`

export const updateAuditors = gql`
    mutation updateAuditors($organizationId: String!, $reportId: String!, $data:UpdateAuditorsInput! ){
        updateAuditors(organizationId: $organizationId, reportId: $reportId, data: $data, ){
            message
        }
    }
`

export const deleteAuditorsInSnapshot = gql`
    mutation deleteAuditorsInSnapshot($id: String!, $reportId: String!){
        deleteAuditorsInSnapshot(id: $id, reportId: $reportId) {
            message
        }
    }
`

export const updateReportInfo = gql`
    mutation updateReportInfo($data: NcrReportConfigInput!, $reportId: String!){
        updateReportInfo(data: $data, reportId: $reportId){
            ...NcrReportConfigFragment
        }
    }
    ${NcrReportConfigFragment}
`

export const getFullNcrReportPreview = gql`
    query getFullNcrReportPreview($organizationId: String!, $workspaceId: String!, $reportId: String!){
        getFullNcrReportPreview(organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId ){
            header {
                title
                owner
                classification
                auditCommittee
                status
                
                approver
                approvalDate
                version
                logo
            }
            firstPage {
                organization
                address
                clientID
                auditeeRepresentative
                auditStandard
                auditType
                auditLead
                auditTeam
                datesOfAudit
                reportDate
                
                majorNcr
                minorNcr
            }
            ncrList {
                ...NCRFragment
            }
        }
    }
    ${NCRFragment}
`

export const downloadAuditorEvidenceFile = gql`
    query downloadAuditorEvidenceFile($fileId: String!, $filename: String!){
        downloadAuditorEvidenceFile (fileId: $fileId, filename: $filename) {
            filename
            filetype
            file
        }
    }
`

export const getAuditorEvidenceAsset = gql`
    query getAuditorEvidenceAsset($id: String!, $pageInfo: SharedPaginationInput!, $workspaceId: String!, $reportId: String!, $evidenceId: String!){
        getAuditorTableEvidence(id: $id, pageInfo: $pageInfo, workspaceId: $workspaceId, reportId: $reportId, evidenceId: $evidenceId) {
            data
            tableFields
            pageInfo {
                page
                count
                sort
                total
            }
        }
    }
`

export const downloadReportCollectorAssetByAuditor = gql`
    query downloadReportCollectorAssetByAuditor($assetId: String!, $organizationId: String!, $workspaceId: String!, $reportId: String!, $evidenceId: String!){
        downloadReportCollectorAssetByAuditor(assetId: $assetId, organizationId: $organizationId, workspaceId: $workspaceId, reportId: $reportId, evidenceId: $evidenceId) {
            file
            filename
        }
    }
`

export const getIntegrationsReport = gql`
    query getIntegrationsReportByAuditor($organizationId: String!, $reportId: String!){
        getIntegrationsReportByAuditor(organizationId: $organizationId, reportId: $reportId) {
            report {
                id
                name
                status
                type
                lastUpdate
                framework {
                    id
                    name
                    logo
                }
                workspaceId
            }

            organization {
                id
                name
                reports
                lastUpdate
            }

            apps {
                ...reportAppFragment
            }
        }
    }
    ${reportAppFragment}
`

export const getIntegrationDataByCollectionReports = gql`
    query getIntegrationDataByCollectionReports($workspaceId: String!, $reportId: String!, $appId: String!, $collectionAlias: String!, $pageInfo: PaginationInput!){
        getIntegrationDataByCollectionReports(workspaceId: $workspaceId, reportId: $reportId, appId: $appId, collectionAlias: $collectionAlias, pageInfo: $pageInfo) {
            data
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
`

export const getAppLogsToDate = gql`
    query getAppLogsToDate($workspaceId: String!, $appId: String!, $pageInfo: PaginationInput!, $date: String!){
        getAppLogsToDate(workspaceId: $workspaceId, appId: $appId, pageInfo: $pageInfo, date: $date) {
            logs {
                id
                applicationId
                workspaceId
                fileId
                size
                timestamp
                prefix
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
`