import {useDispatch, useSelector} from "react-redux";
import {selectedLanguage, selectLanguage} from "../slice";
import {TLanguage} from "../types";
import {useTranslation, UseTranslationOptions} from "react-i18next";
import {SUPPORTED_LANGUAGES} from "../../../constants";
import i18next from "i18next";

// having resources like this:
/*{
  "translation": {
    "very": {
      "deeply": {
        "nested": {
          "key": "here"
        }
      }
    }
  }
}*/
// you can define a keyPrefix to be used for the resulting t function
// const { t } = useTranslation('translation', { keyPrefix: 'very.deeply.nested' });
// const text = t('key'); // "here"

export const useMainTranslation = (ns?: string | string[] | readonly string[] | undefined, options?: UseTranslationOptions<string>) => {
    const dispatch = useDispatch();
    const _selectedLanguage: TLanguage = useSelector(selectedLanguage);
    const setLanguage = (language: TLanguage) => dispatch(selectLanguage(language));
    const handleChooseLanguage = (languageCode: string) => {
        const currLanguage: TLanguage | undefined = SUPPORTED_LANGUAGES.find((e) => e.code === languageCode);
        if (currLanguage) {
            setLanguage(currLanguage);
            i18next.changeLanguage(currLanguage.code);
        }
    }
    const {t} = useTranslation(ns, options);
    const {t: tCommon} = useTranslation(ns, {keyPrefix: 'CommonComponents'});
    const {t: tMenu} = useTranslation(ns, {keyPrefix: 'LeftMenu'});

    return{
        setLanguage,
        handleChooseLanguage,
        currentLanguage: _selectedLanguage,
        revDir: _selectedLanguage.dir === 'rtl',
        t,
        tCommon,
        tMenu
    }
}