const colors = {
    primary: {
        blue_light: '#90CAF9',
        blue: '#2962FF',
        blue_dark: '#1A237E',
        hovered: '#448AFF',
        disable: '#E0E0E0',
    },
    secondary: {
        green_light: '#A5D6A7',
        green: '#2E7D32',
        green_dark: '#1B5E20',
        hovered: '#4CAF50',
        disable: '#E8F5E9',
    },
    text: {
        dark: '#202223',
        grey: '#A19F9D',
        grey_dark: '#605E5C',
        grey_light_2: '#D2D0CE',
        white: '#FFFFFF',
        blue: '#106EBE',
        grey_light_1: '#E1DFDD',
        critical: '#D72C0D',
        warning: '#916A00',
        success: '#008060',
        red: '#ED0202'
    },
    backgrounds: {
        white: '#FFFFFF',
        grey_light: '#F7F7F7',
        grey_light2: '#FCFCFC',
        blue_light_1: '#E3F2FD',
        blue_light_2: '#E8EAF6',
        blue_light_3: '#F9FAFB',
        blue_light_4: '#FAFFFF',
        blue_light_5: '#F5F6F8',
        dark: '#111827',
        dark_blue: '#192745',
        green: '#E0F2F1',
        red: '#FFCDD2',
        grey_dark: '#EDEBE9',
        orange: '#FFF9C4',
        warning: '#FFFCDB',
        optional: '#F8FCFF',
    },
    chip:{
        default: '#E9F1FA',
        pressed: '#23439A',
        hover: '#D4E1F0'
    },
    stroke: {
        grey: '#E0E0E0',
        blue: '#436FC0',
        green: '#00A47C',
        red: '#DD2C00',
        dark_grey: '#616161',
        dark: '#212121',
        white: '#FFFFFF',
    },
    warningActions: {
        green: '#006D41',
        red: '#EA3C1D',
    },
    decorative: {
        brown: '#7E5700',
        bright_green: '#12A778',
        bright_red: '#FF2098',
        green_dark: '#4D8063',
        orange: '#ED6C02',
        violet: '#4968D3',
        blue_light: '#5BCDDA',
        lilac: '#C886D3',
        additional: '#3B47B9',
        additional_2: '#7A4ADF',
        additional_3: '#55897C',
        background_red: '#FAD9D9',
        background_1: '#D9E1FA',
        background_2: '#FFE7F4',
        background_3: '#ECFDF3',
        background_4: '#D0D8E3',
    },
    divider: '#79747e',





    leftbar:"#23273F",
    background: "#F5F5F5",
    background2: "#FBFBFB",
    yellow: "#FAC000",
    yellowWarning: "#FFA000",
    orange: "#FFA000",
    green: "#3BCC89",
    red: "#FF5959",
    darkRed: '#A64153',
    grey: "#878BA1",
    greyRGBA: 'rgba(0, 0, 0, 0.53)',
    lightGrey: "#9F9F9F",
    c3c3c3: "#C3C3C3",
    white:"#f5f5f5",
    trueWhite: "white",
    pwhite: "white",
    skip: "#A0A2B1",
    checked:"#353536",
    whiteA:"#FFFFFF",
    active:"#1CCBFCFF",
    blue:"#14CBFD",
    purple: '#663399',
    circles:{
        red:'#FCE7E7',
        yellow:'#FFF5E5',
        blue:'#E3F2FD',
        text:{
            red:'#FF5959',
            yellow:'#FFA000',
            blue:'#A0A2B1'
        }
    },
    d8: '#D8D8D8',

    navButtonActive:"#E5E5E5",

    //LEFT SIDE BAR STYLES
    leftSideBarBackgroundColor: "#23273f",
    leftSideBarActiveTabBackgroundColor: "#2B3648",
    leftSideBarHoverColor: "#2B3648",
    leftSideBarIconColor: "white",
    leftSideBarActiveIconColor: "white",
    leftSideBarDeviderColor: "white",

    logo: '#2196F3',

    table: {
        head: "#80859F"
    },
    chips: 'rgb(59, 81, 204)',
    linkTextColor: '#2962FF',
    grayText: '#343440',
    lightGrayText: '#747474',
    errorText: '#D72C0D',
    darkBlueText: '#172B4D',
    darkText: '#161616',
    darkOrange: '#ED6C02',
}

export default colors;
