import {ManageWorkspaceInitialState, TExtendedCollaborator} from "../../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {
    CancelWorkspaceInvite,
    ChangeWSInviteRole, DeleteWorkspace, DeleteWorkspaceCollaborator,
    GetWorkspaceById,
    InviteMemberIntoWorkspace, ResendWorkspaceInvite,
    UpdateWorkspaceCollaboratorRole,
    UpdateWorkspaceName
} from "./actions";
import { AppState } from "../../../../../newShared/redux/rootReducer";

export const initialState: ManageWorkspaceInitialState = {
    workspace: null,
    isLoadingWorkspace: true,
    otherLoadings: {
        isLoadingChangeName: false,
        isLoadingInviteUser: false,
        isLoadingChangeCollabRole: {
            isLoading: false,
            prevRole: null,
            publicId: null,
        },
        isLoadingChangeInviteRole: {
            isLoading: false,
            prevRole: null,
            email: null,
        },
        isLoadingResendInvite: {
            isLoading: false,
            email: null,
        },
        isLoadingCancelInvite: {
            isLoading: false,
            email: null,
        },
        isLoadingSendInvite: false,
        isLoadingDeleteCollab: false,
        isLoadingDeleteWorkspace: false,
    },
    dialogs: {
        deleteWorkspace: {
            isOpen: false,
        },
        deleteCollaboratorDialog: {
            collab: null,
            isOpen: false,
        },
        userNotExistsDialog: {
            isOpen: false,
            organizationId: null,
            email: null,
        }
    }
}

export const ManageWorkspaceSlice = createSlice({
    name: 'ManageWorkspace',
    initialState,
    reducers: {
        setPrevUserRole: (slice, {payload}: {payload: {prevRole: string, publicId: string, newRole: string}}) => {
            //actions to set it before changing user role
            //to change it on ui and silently send request
            //if reqeust fails - return back old role
            if(slice.workspace){
                slice.workspace.collaborators = slice.workspace.collaborators.map(e => e.publicId === payload.publicId ? {...e, role: payload.newRole} : e);
                slice.otherLoadings.isLoadingChangeCollabRole = {
                    isLoading: false,
                    publicId: payload.publicId,
                    prevRole: payload.prevRole
                }
            }
        },
        prevUserRoleRollback: (slice) => {
            if(slice.workspace && slice.otherLoadings.isLoadingChangeCollabRole.publicId && slice.otherLoadings.isLoadingChangeCollabRole.prevRole){
                slice.workspace.collaborators = slice.workspace.collaborators.map(e => e.publicId === slice.otherLoadings.isLoadingChangeCollabRole.publicId  ? {...e, role: slice.otherLoadings.isLoadingChangeCollabRole.prevRole! } : e);
                slice.otherLoadings.isLoadingChangeCollabRole = {
                    isLoading: false,
                    publicId: null,
                    prevRole: null
                }
            }
        },
        setPrevInviteRole: (slice, {payload}: {payload: {prevRole: string, email: string, newRole: string}}) => {
            //actions to set it before changing invite role
            //to change it on ui and silently send request
            //if reqeust fails - return back old role
            if(slice.workspace){
                slice.workspace.invites = slice.workspace.invites.map(e => e.emailAddress === payload.email ? {...e, role: payload.newRole} : e);
                slice.otherLoadings.isLoadingChangeInviteRole = {
                    isLoading: false,
                    email: payload.email,
                    prevRole: payload.prevRole
                }
            }
        },
        prevInviteRoleRollback: (slice) => {
            if(slice.workspace && slice.otherLoadings.isLoadingChangeInviteRole.email && slice.otherLoadings.isLoadingChangeInviteRole.prevRole){
                slice.workspace.invites = slice.workspace.invites.map(e => e.emailAddress === slice.otherLoadings.isLoadingChangeInviteRole.email  ? {...e, role: slice.otherLoadings.isLoadingChangeInviteRole.prevRole! } : e);
                slice.otherLoadings.isLoadingChangeInviteRole = {
                    isLoading: false,
                    email: null,
                    prevRole: null
                }
            }
        },
        setIsLoadingResendInvite: (slice, {payload}: {payload: {email: string}}) => {
            slice.otherLoadings.isLoadingResendInvite = {isLoading: true, email: payload.email};
        },
        setIsLoadingCancelInvite: (slice, {payload}: {payload: {email: string}}) => {
            slice.otherLoadings.isLoadingCancelInvite = {isLoading: true, email: payload.email};
        },
        openDeleteCollaboratorDialog: (slice, {payload} : {payload: TExtendedCollaborator}) => {
            slice.dialogs.deleteCollaboratorDialog = {
                isOpen: true,
                collab: payload,
            }
        },
        hideDeleteCollaboratorDialog: (slice) => {
            slice.dialogs.deleteCollaboratorDialog = {
                isOpen: false,
                collab: null,
            }
        },
        openDeleteWorkspace: (slice) => {slice.dialogs.deleteWorkspace.isOpen = true},
        hideDeleteWorkspace: (slice) => {slice.dialogs.deleteWorkspace.isOpen = false},

        openUserNotExistsDialog: (slice, {payload} : {payload: {organizationId: string, email: string}}) => {
            slice.dialogs.userNotExistsDialog = {isOpen: true, organizationId : payload.organizationId, email: payload.email};
        },
        hideUserNotExistsDialog: (slice) => {slice.dialogs.userNotExistsDialog = {isOpen: false, organizationId: null, email: null}},

        cleanUp: () => initialState,
    },
    extraReducers: builder => {
        builder
            .addCase(GetWorkspaceById.pending, (slice) => {
                slice.isLoadingWorkspace = true;
            })
            .addCase(GetWorkspaceById.rejected, (slice, {payload}) => {
                slice.isLoadingWorkspace = false;
            })
            .addCase(GetWorkspaceById.fulfilled, (slice, {payload}) => {
                slice.isLoadingWorkspace = false;
                slice.workspace = payload;
            })
        //UpdateWorkspaceName
            .addCase(UpdateWorkspaceName.pending, (slice) => {
                slice.otherLoadings.isLoadingChangeName = true;
            })
            .addCase(UpdateWorkspaceName.rejected, (slice, {payload}) => {
                slice.otherLoadings.isLoadingChangeName = false;
            })
            .addCase(UpdateWorkspaceName.fulfilled, (slice, {payload}) => {
                slice.otherLoadings.isLoadingChangeName = false;
                if(slice.workspace){
                    slice.workspace.name = payload.newName;
                }
            })
            .addCase(InviteMemberIntoWorkspace.pending, (slice) => {
                slice.otherLoadings.isLoadingSendInvite = true;
            })
            .addCase(InviteMemberIntoWorkspace.rejected, (slice, {payload}) => {
                slice.otherLoadings.isLoadingSendInvite = false;
            })
            .addCase(InviteMemberIntoWorkspace.fulfilled, (slice, {payload}) => {
                slice.otherLoadings.isLoadingSendInvite = false;
                if(slice.workspace && payload){
                    payload.invite && slice.workspace.invites.push(payload.invite);
                    payload.collaborator && slice.workspace.collaborators.push(payload.collaborator);
                }
            })
        //UpdateWorkspaceCollaboratorRole
            .addCase(UpdateWorkspaceCollaboratorRole.pending, (slice) => {
                slice.otherLoadings.isLoadingChangeCollabRole.isLoading = true;
            })
            .addCase(UpdateWorkspaceCollaboratorRole.rejected, (slice, {payload}) => {
                slice.otherLoadings.isLoadingChangeCollabRole.isLoading = false;
            })
            .addCase(UpdateWorkspaceCollaboratorRole.fulfilled, (slice, {payload}) => {
                slice.otherLoadings.isLoadingChangeCollabRole = {
                    isLoading: false,
                    prevRole: null,
                    publicId: null,
                };
            })
        //ChangeWSInviteRole
            .addCase(ChangeWSInviteRole.pending, (slice) => {
                slice.otherLoadings.isLoadingChangeInviteRole.isLoading = true;
            })
            .addCase(ChangeWSInviteRole.rejected, (slice, {payload}) => {
                slice.otherLoadings.isLoadingChangeInviteRole.isLoading = false;
            })
            .addCase(ChangeWSInviteRole.fulfilled, (slice, {payload}) => {
                slice.otherLoadings.isLoadingChangeInviteRole = {
                    isLoading: false,
                    prevRole: null,
                    email: null,
                };
            })
        //CancelWorkspaceInvite
            .addCase(CancelWorkspaceInvite.pending, (slice) => {
                slice.otherLoadings.isLoadingResendInvite.isLoading = true;
            })
            .addCase(CancelWorkspaceInvite.rejected, (slice, {payload}) => {
                slice.otherLoadings.isLoadingResendInvite.isLoading = false;
            })
            .addCase(CancelWorkspaceInvite.fulfilled, (slice, {payload}) => {
                slice.otherLoadings.isLoadingResendInvite = {
                    isLoading: false,
                    email: null,
                };
                if(slice.workspace){
                    slice.workspace.invites = slice.workspace.invites.filter(e => e.id !== payload.inviteId);
                }
            })
        //ResendWorkspaceInvite
            .addCase(ResendWorkspaceInvite.pending, (slice) => {
                slice.otherLoadings.isLoadingResendInvite.isLoading = true;
            })
            .addCase(ResendWorkspaceInvite.rejected, (slice, {payload}) => {
                slice.otherLoadings.isLoadingResendInvite.isLoading = false;
            })
            .addCase(ResendWorkspaceInvite.fulfilled, (slice, {payload}) => {
                slice.otherLoadings.isLoadingResendInvite = {
                    isLoading: false,
                    email: null,
                };
                //TODO update invite (if needed) - now sentDate is not visible so not updating
            })
        //DeleteWorkspace
            .addCase(DeleteWorkspace.pending, (slice) => {
                slice.otherLoadings.isLoadingDeleteWorkspace = true;
            })
            .addCase(DeleteWorkspace.rejected, (slice, {payload}) => {
                slice.otherLoadings.isLoadingDeleteWorkspace = false;
            })
            .addCase(DeleteWorkspace.fulfilled, (slice, {payload}) => {
                slice.otherLoadings.isLoadingDeleteWorkspace = false;
            })
        //DeleteWorkspaceCollaborator
            .addCase(DeleteWorkspaceCollaborator.pending, (slice) => {
                slice.otherLoadings.isLoadingDeleteCollab = true;
            })
            .addCase(DeleteWorkspaceCollaborator.rejected, (slice, {payload}) => {
                slice.otherLoadings.isLoadingDeleteCollab = false;
            })
            .addCase(DeleteWorkspaceCollaborator.fulfilled, (slice, {payload}) => {
                slice.otherLoadings.isLoadingDeleteCollab = false;
                slice.dialogs.deleteCollaboratorDialog = {isOpen: false, collab: null};
                if(slice.workspace){
                    slice.workspace = {...slice.workspace, collaborators: slice.workspace.collaborators.filter(e => e.publicId !== payload)};
                }
            })
    }
});

export const {
    setPrevUserRole,
    prevUserRoleRollback,
    setPrevInviteRole,
    prevInviteRoleRollback,
    cleanUp,
    setIsLoadingResendInvite,
    setIsLoadingCancelInvite,

    openDeleteCollaboratorDialog,
    hideDeleteCollaboratorDialog,

    openDeleteWorkspace,
    hideDeleteWorkspace,

    openUserNotExistsDialog,
    hideUserNotExistsDialog,
} = ManageWorkspaceSlice.actions;

export const ManageNewWorkspaceReducer = ManageWorkspaceSlice.reducer;

const selectSelf = (state: AppState): ManageWorkspaceInitialState => state.ManageNewWorkspaceReducer as ManageWorkspaceInitialState;

export const isLoadingWorkspace = createSelector(selectSelf, state => state.isLoadingWorkspace);
export const workspace = createSelector(selectSelf, state => state.workspace);
export const otherLoadings = createSelector(selectSelf, state => state.otherLoadings);
export const dialogs = createSelector(selectSelf, state => state.dialogs);






