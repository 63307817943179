import React, {FC, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useReportDialogs} from "../../../hooks/useReportsDialogs";
import {LoadingButton} from "@mui/lab";
import {useAuditSpace} from "../../../hooks/useAuditSpace";
import {useNCR} from "../../../hooks/useNCR";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const ChangeNcrStatusDialog: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'AuditSpace'});

    const {
        changeStatusNcr: {isOpen, isLoading, data, setChangeNcrStatus}
    } = useReportDialogs();
    const {isLoading: {isChangingStatusNcr}} = useAuditSpace();
    const {changeStatusNcr} = useNCR();

    const [text, setText] = useState<string>('');

    const handleClose = () => {
        setChangeNcrStatus({clear: true});
        setText('');
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                {t('Change NCR status')}
            </DialogTitle>

            <DialogContent>
                {data?.status === 'REJECTED' && (
                    <TextField multiline fullWidth minRows={3} maxRows={5} label={t('Enter reject comment')}
                               sx={{marginTop: '10px'}}
                               onChange={e => {setText(e.target.value)}} value={text}/>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={"text"}>
                    {t('Cancel')}
                </Button>

                {data?.status === 'REVOKE' && (
                    <LoadingButton variant={"contained"} disabled={isLoading}
                                loading={isChangingStatusNcr.some(e => e.id === data?.ncrId && e.status === 'REVOKE')}
                                onClick={() => {
                                    changeStatusNcr(data)
                                }}>
                    {t('Revoke')}
                </LoadingButton>
                )}

                {data?.status === 'REJECTED' && (
                    <LoadingButton variant={"contained"} disabled={isLoading || text.trim() === ''}
                                loading={isChangingStatusNcr.some(e => e.id === data?.ncrId && e.status === 'REJECTED')}
                                onClick={() => {
                                    changeStatusNcr({
                                        ...data,
                                        comment: text.trim(),
                                    })
                                }}>
                    {t('Reject')}
                </LoadingButton>
                )}

                {data?.status === 'APPROVED' && (
                    <LoadingButton variant={"contained"} disabled={isLoading}
                                loading={isChangingStatusNcr.some(e => e.id === data?.ncrId && e.status === 'APPROVED')}
                                onClick={() => {
                                    changeStatusNcr(data)
                                }}>
                    {t('Approve')}
                </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    )
}