import React, {FC, Fragment} from "react";
import {Dialog, DialogContent, Grid, IconButton, Table, TableCell, TableRow as tableRow} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../../newShared/components/Typography";
import {CSSProperties, styled} from "@mui/styles";
import {useReportDialogs} from "../../../hooks/useReportsDialogs";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";

export const HeaderSpan = styled('span')({
    fontSize: '12px',
    // whiteSpace: "nowrap"
})

const tableStyle: CSSProperties = {width: '80%'};
const TableRow = styled(tableRow)({
    minHeight: '30px',
    '&:last-child td, &:last-child th': { borderBottom: '1px solid black' },
    '& td': {
        border: '1px solid black'
    }
})

export const PreviewFullReportNcrDialog: FC = () => {
    const {preview: {isOpen, data, setPreviewDialog}} = useReportDialogs();
    const closeDialog = () => {setPreviewDialog({clear: true})};

    return (
        <Dialog open={isOpen} onClose={closeDialog} sx={{'& .MuiPaper-root': {
                // minWidth: 'min(90vw, calc(90vh / 1.414))',
                // maxWidth: 'calc(90vh / 1.414)',
                minWidth: 'min(750px, 90vw)',
                maxWidth: 'min(750px, 90vw)',
                height: '90vh',
                position: 'relative'
            }}}>
            <IconButton size={"small"} onClick={closeDialog} style={{position: "absolute", right: '20px', top: '20px'}}>
                <ClearIcon/>
            </IconButton>

            <DialogContent>
                {data ? (
                        <Flex direction={"column"} w={'100%'} p={'15px'} ai={"center"}>

                            {/*Header*/}
                            <Grid container spacing={2} sx={{flexGrow: 1}}>
                                <Grid item xs={6} sx={{borderLeft: '2px solid black'}} direction={"column"}>
                                    <HeaderSpan style={{fontWeight: 600}}>{data.header.title}</HeaderSpan><br/>
                                    <HeaderSpan>{`Owner: ${data.header.owner}`}</HeaderSpan><br/>
                                    <HeaderSpan>{`Classification: ${data.header.classification} | Audit Committee: ${data.header.auditCommittee}`}</HeaderSpan><br/>
                                    <HeaderSpan>{`Status: ${data.header.status}`}</HeaderSpan><br/>
                                </Grid>

                                <Grid item xs={4} sx={{borderLeft: '2px solid black'}} direction={"column"}>
                                    <HeaderSpan>{`Approver: ${data.header.approver}`}</HeaderSpan><br/>
                                    <HeaderSpan>{`Approval Date: ${data.header.approvalDate}`}</HeaderSpan><br/>
                                    <HeaderSpan>{`Version: ${data.header.version}`}</HeaderSpan><br/>
                                </Grid>

                                <Grid item xs={2}>
                                    {data.header.logo && <img src={data.header.logo} style={{maxWidth: '80px', maxHeight: '80px'}} alt={'LOGO'}/>}
                                </Grid>
                            </Grid>

                            <Typo fontWeight={600} fontSize={'20px'} margin={'20px 0'}>
                                Corrective Action and Response Form
                            </Typo>

                            <Table size={"small"} style={{...tableStyle}}>
                                <TableRow>
                                    <TableCell>Organization:</TableCell>
                                    <TableCell>{data.firstPage.organization}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Address:</TableCell>
                                    <TableCell>{data.firstPage.address}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Client ID:</TableCell>
                                    <TableCell>{data.firstPage.clientID}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Auditee representative:</TableCell>
                                    <TableCell>{data.firstPage.auditeeRepresentative}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Standard (s) audited:</TableCell>
                                    <TableCell>{data.firstPage.auditStandard}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Type of audit:</TableCell>
                                    <TableCell>{data.firstPage.auditType}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lead auditor:</TableCell>
                                    <TableCell>{data.firstPage.auditLead}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Other team member(s):</TableCell>
                                    <TableCell>{data.firstPage.auditTeam.join(', ')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Date(s) of audit:</TableCell>
                                    <TableCell>{data.firstPage.datesOfAudit.map(e => parseDateAuto(e)).join(', ')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Report Date:</TableCell>
                                    <TableCell>{parseDateAuto(data.firstPage.reportDate)}</TableCell>
                                </TableRow>
                            </Table>

                            {/**/}
                            <Typo fontWeight={600} fontSize={'20px'} margin={'20px 0'}>
                                Findings
                            </Typo>
                            <Table size={"small"} style={tableStyle}>
                                <TableRow>
                                    <TableCell>Number of Major Nonconformities uncovered:</TableCell>
                                    <TableCell>{data.firstPage.majorNcr}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Number of Minor Nonconformities uncovered:</TableCell>
                                    <TableCell>{data.firstPage.minorNcr}</TableCell>
                                </TableRow>
                            </Table>

                            {/**/}
                            {data.ncrList.length > 0 && (
                                <Typo fontWeight={600} fontSize={'20px'} margin={'20px 0'}>
                                    NON-CONFORMITY (1 PER NON-CONFORMITY)
                                </Typo>
                            )}

                            {data.ncrList.map((ncr, index) => (
                                <Table size={"small"} key={ncr.id} style={{...tableStyle, marginTop: index !== 0 ? '40px' : undefined}}>
                                    <TableRow>
                                        <TableCell align={"center"} sx={{backgroundColor: 'black', color: 'white', fontSize: '14px', fontWeight: 600}} colSpan={2}>
                                            Requirement of the Audit Standard</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Clause:</TableCell>
                                        <TableCell>Clause description:</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{ncr.clause}</TableCell>
                                        <TableCell>{ncr.clauseDescription}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Type of Non-Conformity</TableCell>
                                        <TableCell>{ncr.type}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align={"center"} sx={{backgroundColor: 'black', color: 'white', fontSize: '14px', fontWeight: 600}} colSpan={2}>
                                            Observed Non-Conformity</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>{ncr.observed}</TableCell>
                                    </TableRow>
                                    {ncr?.requestHistory?.map(e => (
                                        <Fragment key={JSON.stringify(e)}>
                                            <TableRow>
                                                <TableCell align={"center"} sx={{backgroundColor: 'black', color: 'white', fontSize: '14px', fontWeight: 600}} colSpan={2}>
                                                    Root Cause Analysis</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>{e.causeAnalysis}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align={"center"} sx={{backgroundColor: 'black', color: 'white', fontSize: '14px', fontWeight: 600}} colSpan={2}>
                                                    Corrective Action</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}><span style={{fontWeight: 600}}>Description: </span>{e.correctiveAction?.description || ''}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Proposed completion date:</TableCell>
                                                <TableCell>{parseDateAuto(e.correctiveAction?.proposedCompletionDate)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Company representative:</TableCell>
                                                <TableCell>{e.correctiveAction?.authorPublicId || ''}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align={"center"} sx={{backgroundColor: 'black', color: 'white', fontSize: '14px', fontWeight: 600}} colSpan={2}>
                                                    Clearance (to be filled by auditor only)</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Corrective action accepted:</TableCell>
                                                <TableCell>{e.correctiveActionAccepted === null ? '' : e.correctiveActionAccepted ? 'Yes' : 'No'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Auditor:</TableCell>
                                                <TableCell>{e.approveAuditor || ''}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Date:</TableCell>
                                                <TableCell>{parseDateAuto(e.approveDate)}</TableCell>
                                            </TableRow>
                                        </Fragment>
                                    ))}
                                </Table>
                            ))}
                        </Flex>
                    ) : null
                }
            </DialogContent>
        </Dialog>
    )
}