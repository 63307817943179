import {useDispatch, useSelector} from "react-redux";
import {cleanDataAction, cleanMessageAction, isErrorSelector, isLoadingSelector, messageSelector,} from "../../reducer";
import {changeCurrentUserInfoAction, changeCurrentUserPassAction} from "../../actions";
import {UseManageWorkspacesAndOrganizations} from "../../../workspaces/hooks/useManageWorkspacesAndOrganizations";

export const useSettingsProfile = () => {

    const dispatch = useDispatch();
    const {user} = UseManageWorkspacesAndOrganizations();


    const loadData = () => {
        // dispatch(getCurrentUserInfoAction())
    }

    const cleanData = () => {
        dispatch(cleanDataAction());
        dispatch(cleanMessageAction())
    }

    const cleanMessage = () => {
        dispatch(cleanMessageAction())
    }

    const submitName = (firstName: string, lastName: string,
                        // email: string, password: string,
                        publicId: string) => {
        dispatch(changeCurrentUserInfoAction({firstName, lastName,
            // email, password,
            publicId,}))
    }

    const submitPass = (publicId: string, oldPass: string, newPass: string) => {
        dispatch(changeCurrentUserPassAction({publicId, oldPass, newPass}))
    }

    return {
        loadData,
        cleanData,
        cleanMessage,

        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        email: user?.email ?? '',
        publicId: user?.publicId ?? '',
        submitName,

        submitPass,

        isLoading: useSelector(isLoadingSelector),
        isError: useSelector(isErrorSelector),
        message: useSelector(messageSelector),
    }
}
