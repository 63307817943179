import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {ORGANIZATION_PAGING, ORGANIZATION_REPORTS_PAGING} from "../../constants";
import {useAuditSpace} from "../useAuditSpace";
import {useEffect} from "react";
import {useRoutes} from "../useRoutes";

export const useReportOrganizationsListPage = () => {
    // const {t, revDir} = useMainTranslation('', {keyPrefix: 'AuditSpace'});
    const {currentSearchForFetch} = useGenericFiltersStorage();
    const {goToOrganization} = useRoutes();

    const {
        organizations: {organizations, pageInfo},
        getOrganizations,
        isLoading: {isLoadingOrganizations},
        cleanOrganizations
    } = useAuditSpace();

    useEffect(() => {
        getOrganizations({page: 0, count: ORGANIZATION_PAGING, filters: {input: [currentSearchForFetch]}, sort: '', groupBy: ''});

        return () => {
            cleanOrganizations()
        }
        //eslint-disable-next-line
    }, []);

    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            getOrganizations({page: page, count: ORGANIZATION_REPORTS_PAGING, filters: {input: [currentSearchForFetch]}, sort: '', groupBy: ''});
        },
        arrayLength: organizations.length,
        isLoading: isLoadingOrganizations,
        pageInfo: pageInfo,
        // cleanData: () => dispatch(eraseEmployees()),
        // controller: controller,
        // isOnTop: true,
        isNoMoreThanTotal: true,
        customDependencies: [],
        is0PageAddictive: false,
        disableInitFetch: true,
        // disableClearOnUnmount: true
    })

    const fetchWithFilters = (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
        getOrganizations({page: 0, count: ORGANIZATION_REPORTS_PAGING, filters: {input: [search]}, sort: '', groupBy: ''});
    }

    return{
        scroll: {
            bottomLoader, onScroll, scrollRef,
            total: pageInfo.total,
            hasMore: pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
        },
        fetchWithFilters,
        organizations,
        isLoadingOrganizations,
        goToOrganization
    }
}