import React, {FC, useEffect, useState} from "react";
import {useAuditSpace} from "../../hooks/useAuditSpace";
import {Box, Button, IconButton, Skeleton, Typography} from "@mui/material";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {NcrReportConfigInput} from "../../../../../newShared/GQLTypes";
import {toBase64} from "../../../../../newShared/utils/base64/base64";
import colors from "../../../../../newShared/theme/colors";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../newShared/components/Typography";
import {useMedia} from "../../../../../newShared/hooks/useMedia";

type TProps = {
    show: boolean;
    close: () => void;
}
export const ChangeReportConfig: FC<TProps> = ({show, close}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'AuditSpace'});

    const {
        controls: {report},
        isLoading: {isUpdatingReportConfig},
        setReportConfig,
    } = useAuditSpace();

    const [data, setData] = useState<NcrReportConfigInput | null>(null);
    useEffect(() => {
        show && setData({
            title: report?.reportNCR?.title || '',
            owner: report?.reportNCR?.owner || '',
            classification: report?.reportNCR?.classification || '',
            auditCommittee: report?.reportNCR?.auditCommittee || '',
            status: report?.reportNCR?.status || '',
            approver: report?.reportNCR?.approver || '',
            approvalDate: report?.reportNCR?.approvalDate || '',
            version: report?.reportNCR?.version || '',
            logo: report?.reportNCR?.logo || null,
            auditType: report?.reportNCR?.typeOfAudit || '',
            // notes: report?.reportNCR?.notes || {},

            address: '',
            auditeeRepresentative: '',
        })
        return () => {
            setData(null)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        close()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report?.reportNCR]);

    const setField = (field: string, data: string) => {setData(prev => prev ? ({...prev, [field]: data}) : null)};
    const setFile = async (file: any) => {
        const fileBase64: string = await toBase64(file) as string;
        setField('logo', fileBase64);
    }

    const disable = report?.status === 'COMPLETED'
        || report?.status === 'REJECTED'
        || report?.status === 'ARCHIVED'
        || isUpdatingReportConfig;

    const {isDesktop, isLessThen1050} = useMedia();
    return (
        <Box flexDirection={"column"} height={'100%'} overflow={'auto'}
             display={!show ? 'none' : "flex"}>
            {/*<Breadcrumbs/>*/}

            <Typography variant={isDesktop ? "h1" : "h3"}>
                <IconButton sx={{color: colors.text.blue, margin: !revDir ? `0 12px ${!isLessThen1050 ? 4 : 2}px 0` : `0 0 ${!isLessThen1050 ? 4 : 2}px 12px`, padding: '0'}}
                            onClick={close}>
                    {!revDir ? <ArrowBack/> : <ArrowForward/>}
                </IconButton>

                {report?.name || <Skeleton width={'200px'}/>}
            </Typography>

            {data && (
                <Box display={"flex"} flexDirection={"column"} maxWidth={'400px'}>
                    <Box display={"flex"} width={'100%'} alignItems={'center'} justifyContent={'center'} flexDirection={"column"} mt={'10px'}>
                        {data.logo && data.logo !== '' ? (
                            <img src={data.logo || undefined} alt={'LOGO'} style={{maxWidth: '70px', maxHeight: '70px'}}/>
                        ) : (
                            <Flex w={'70px'} h={'70px'} ai={"center"} jc={"center"} border={'1px solid black'}>
                                <span>No logo</span>
                            </Flex>
                        )}
                        {!disable && (
                            <>
                                <input
                                    style={{display: 'none'}}
                                    id={"contained-button-file-logoOrg"}
                                    type="file"
                                    accept={''}
                                    onChange={async e => {
                                        await setFile(e.currentTarget.files![0]);
                                    }}
                                />
                                <label htmlFor={"contained-button-file-logoOrg"}>
                                    <Typo cursor={'pointer'} fontSize={'12px'} fontWeight={500} color={'#9E9E9E'} letterSpacing={'1.25px'}>{t('Change Picture')}</Typo>
                                </label>
                            </>
                        )}
                    </Box>

                    <TextField fullWidth sx={{marginTop: '10px'}}
                               value={data.title}
                               onChange={e => {setField('title', e.target.value)}}
                               variant={'outlined'}
                               label={t('Title')} disabled={disable}
                    />
                    <TextField fullWidth sx={{marginTop: '10px'}}
                               value={data.owner}
                               onChange={e => {setField('owner', e.target.value)}}
                               variant={'outlined'}
                               label={t('Owner')} disabled={disable}
                    />
                    <TextField fullWidth sx={{marginTop: '10px'}}
                               value={data.classification}
                               onChange={e => {setField('classification', e.target.value)}}
                               variant={'outlined'}
                               label={t('Classification')} disabled={disable}
                    />
                    <TextField fullWidth sx={{marginTop: '10px'}}
                               value={data.auditCommittee}
                               onChange={e => {setField('auditCommittee', e.target.value)}}
                               variant={'outlined'}
                               label={t('Audit Committee')} disabled={disable}
                    />
                    <TextField fullWidth sx={{marginTop: '10px'}}
                               value={data.status}
                               onChange={e => {setField('status', e.target.value)}}
                               variant={'outlined'}
                               label={t('Status')} disabled={disable}
                    />
                    <TextField fullWidth sx={{marginTop: '10px'}}
                               value={data.approver}
                               onChange={e => {setField('approver', e.target.value)}}
                               variant={'outlined'}
                               label={t('Approver')} disabled={disable}
                    />
                    <TextField fullWidth sx={{marginTop: '10px'}}
                               value={data.auditType}
                               onChange={e => {setField('auditType', e.target.value)}}
                               variant={'outlined'}
                               label={t('Type of audit')} disabled={disable}
                    />

                    <Box display={"flex"} alignItems={"center"} mt={'10px'}>
                        <Button variant={"outlined"}
                                onClick={close}>
                            {t('Cancel')}
                        </Button>

                        <Box width={'10px'} height={'10px'}/>

                        <LoadingButton loading={isUpdatingReportConfig} disabled={disable}
                                       variant={"contained"}
                                       onClick={() => {
                                           data && report && setReportConfig(report.id, data)
                                       }}>
                            {t('Save')}
                        </LoadingButton>
                    </Box>
                </Box>
            )}
        </Box>
    )
}