import {print} from "graphql";
import {gql} from "@apollo/react-hooks";

export const NcrReportConfigFragment = print(gql`
    fragment NcrReportConfigFragment on NcrReportConfigModel {
        title
        owner
        classification
        auditCommittee
        status
        approver
        approvalDate
        logo
        version

        organization
        address
        clientID
        auditeeRepresentative
        auditStandard
        auditType
    }
`)


export const NCRFragment = print(gql`
    fragment NCRFragment on NcrModel {
        id
        workspaceId
        reportId
        frameworkId
        controlId
        status
        createDate
        createAuditorId
        type
        observed
        clause
        clauseDescription
        requestHistory {
            causeAnalysis
            correctiveActionAccepted
            correctiveAction {
                description
                proposedCompletionDate
                authorPublicId
            }
            evidenceAssets {
                fileName
                assetId
            }
            approveAuditor
            approveDate
            comment
        }
    }
`)

export const reportAppFragment = gql`
    fragment reportAppFragment on ReportAppModel {
        id
        name
        logo
        description
        config {
            collectionPrefix
            collectionConfigs {
                name
                alias
                headers
                data
                mappingFieldsSchema {
                    sourceName
                    name
                    representationName
                }
            }
        }
    }
`


export const reportFragment = gql`
    fragment reportFragment on ReportModel {
        id
        name
        framework {
            id
            name
            logo
        }
        ncrEnabled
        type
        status
        reportNCR {
            title
            owner
            classification
            auditCommittee
            status
            approver
            approvalDate
            version
            logo
            typeOfAudit
            notes
        }
        createDate
        auditors {
            id
            name
            type
        }
        reviewedControls
        totalControls
        smallNCRs {
            id
            status
            type
        }
        workspaceId
    }
`

