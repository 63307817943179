// Core
import {configureStore} from '@reduxjs/toolkit'

// Instruments
import {AppState, rootReducer} from './rootReducer';
import {middleware} from './middleware';
import {useDispatch} from "react-redux";

const devEnvironment = window.config?.NODE_ENV === 'development';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(middleware)
    ,
    devTools:devEnvironment
});

export {store};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch<AppDispatch>() ;

export type getActionsDataType = {
    workspaceId: string,
    workspaceName: string,
    organizationId: string,
    publicId: string,
    language: string
};

export const getActionsData = (state: AppState): getActionsDataType => {
    return {
        workspaceId: state.SharedWorkspacesAndOrganizations.multistore.selectedWorkspace?.id || '',
        workspaceName: state.SharedWorkspacesAndOrganizations.multistore.selectedWorkspace?.name || '',
        organizationId: state.SharedWorkspacesAndOrganizations.multistore.selectedOrganization?.id || '',
        publicId: state.SharedWorkspacesAndOrganizations.multistore.currentUser?.publicId || '',
        language: state.I18Next.selectedLanguage.code || ''
    }
}



