import {
    deselectApplicationAction,
    deselectLogAction,
    deselectLogFetchResultAction,
    reportIntegrationLogsSelector,
    reportIntegrationsSelector,
    selectLogAction,
    TAuditSpaceSlice
} from "../../store/auditSpace/slice";
import {useDispatch, useSelector} from "react-redux";
import {TReportIntegrationLog} from "../../types";
import {
    DownloadLogAction,
    DownloadLogForPreviewAction,
    GetAppLogsAction,
    getIntegrationDataByCollectionReportsAction,
    getIntegrationsReportAction
} from "../../store/auditSpace/actions";
import {
    GetAppLogsToDateQueryVariables,
    GetIntegrationDataByCollectionReportsQueryVariables,
    GetIntegrationsReportByAuditorQueryVariables
} from "../../../../../newShared/GQLTypes";
import {useRoutes} from "../useRoutes";

type TResponse = {
    integrations: TAuditSpaceSlice["integrations"],
    // selectedApp: TAuditSpaceSlice["integrations"]["selectedApp"],
    logsData: TAuditSpaceSlice["integrations"]["selectedApp"]["logsData"],

    getReportIntegrations: (data: GetIntegrationsReportByAuditorQueryVariables) => void,
    getIntegrationData: (data: GetIntegrationDataByCollectionReportsQueryVariables) => void,

    getAppLogs: (data: GetAppLogsToDateQueryVariables) => void,
    downloadLog: (data: { fileId: string, fileName: string, workspaceId: string }) => void,
    downloadPreviewLog: (data: { fileId: string, workspaceId: string }) => void,

    deselectApplication: () => void,
    deselectLog: () => void,
    selectLog: (data: TReportIntegrationLog) => void,
    deselectLogFetchResult: () => void,
}
export const useReportsIntegrations = (): TResponse => {
    const dispatch = useDispatch();
    const controller = new AbortController();

    const {appId} = useRoutes();

    const integrations = useSelector(reportIntegrationsSelector);
    // const selectedApp = useSelector(reportIntegrationSelectedSelector);
    const logsData = useSelector(reportIntegrationLogsSelector);

    const getAppLogs: TResponse["getAppLogs"] = (data) => {
        dispatch(GetAppLogsAction({data: data, signal: controller.signal}))
    }
    const downloadLog: TResponse["downloadLog"] = (data) => {
        dispatch(DownloadLogAction({data: data, signal: controller.signal}))
    }
    const downloadPreviewLog: TResponse["downloadPreviewLog"] = (data) => {
        dispatch(DownloadLogForPreviewAction({data: data, signal: controller.signal}))
    }

    const getReportIntegrations: TResponse["getReportIntegrations"] = (data) => {
        dispatch(getIntegrationsReportAction({data: data, signal: controller.signal}))
    }
    const getIntegrationData: TResponse["getIntegrationData"] = (data) => {
        dispatch(getIntegrationDataByCollectionReportsAction({data: data, signal: controller.signal}))
    }

    const deselectApplication: TResponse["deselectLogFetchResult"] = () => {
        dispatch(deselectApplicationAction())
    }
    const deselectLog: TResponse["deselectLog"] = () => {
        dispatch(deselectLogAction())
    }
    const selectLog: TResponse["selectLog"] = (data) => {
        dispatch(selectLogAction(data))
    }
    const deselectLogFetchResult: TResponse["deselectLogFetchResult"] = () => {
        dispatch(deselectLogFetchResultAction())
    }

    return {
        integrations: {
            ...integrations,
            selectedApp: {
                ...integrations.selectedApp,
                app: integrations.integrations.find(e => e.id === appId) || null
            }
        },
        logsData,

        getReportIntegrations,
        getIntegrationData,

        getAppLogs,
        downloadLog,
        downloadPreviewLog,

        deselectApplication,
        deselectLog,
        selectLog,
        deselectLogFetchResult,
    }
}