import {Flex} from "../../../../../newShared/components/Layouts"
import {useOrganizationReports} from "../../hooks/useOrganizationReports";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";
import {
    Avatar,
    Box,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {TReportSmall} from "../../types";
import {getReportStatus, getReportType} from "../../helpers";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import React from "react";
import {getAvatarInitials} from "../../../../../newShared/utils/text";

export const OrganizationReportsRefactor = () => {
    const {routes, organization, configs, fetchWithFilters, scroll, reports, onReportClick, isLoading} = useOrganizationReports();

    return(
        <Flex w={'100%'} h={'100%'} overflow={'hidden'} direction={'column'}>
            <PageTitleAndActions
                icon={
                    <Avatar src={organization?.logo ?? ''} sx={{width: '25px', height: '25px', backgroundColor: 'inherit'}}>
                        <Typography fontSize={'10px'} fontWeight={300}>{getAvatarInitials(organization?.name ?? '')}</Typography>
                    </Avatar>
                }
                title={organization?.name}
                actions={[]}
                handleGoBack={routes.goToOrganizations}
            />

            <GenericFiltersArray
                isAddFilters={false}
                configs={configs}
                fetchResultWithSelectedFilters={fetchWithFilters}
            />

            <TableContainer onScroll={scroll.onScroll} ref={scroll.scrollRef}>
                <Table stickyHeader aria-label="sticky table">
                    <OrganizationReportsTableHeader total={scroll.total}/>

                    <TableBody sx={{
                        '& .MuiTableRow-root:last-child td': {
                            borderBottom: `1px solid ${colors.primary.disable}`
                        }
                    }}>
                        {(reports.filter(e => e !== null) as TReportSmall[]).map((e) => <OrganizationReportsTableRow
                            key={e.id}
                            row={e}
                            onClick={() => onReportClick(e.id)}/>
                        )}

                        {(scroll.hasMore || isLoading) && <OrganizationReportsTableRowSkeleton />}
                    </TableBody>
                </Table>
            </TableContainer>
        </Flex>
    )
}

export const OrganizationReportsTableHeader = ({total}: {total: number}) => {
    return(
        <TableHead sx={{backgroundColor: colors.backgrounds.grey_light}}>
            <TableRow sx={{'& .MuiTableCell-root': {backgroundColor: colors.backgrounds.grey_light, width: '100%', paddingTop: '6px', paddingBottom: '6px'}}}>
                {/*Column actions is not in columnConfig - its generated by default at the end of row*/}
                <TableCell colSpan={5}>
                    <Typography variant={'subtitle1'} color={colors.text.grey}>{`${('Total reports')}: ${total}`}</Typography>
                </TableCell>
            </TableRow>

            <TableRow sx={{'& .MuiTableCell-root': {backgroundColor: colors.backgrounds.grey_light}}}>
                <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                    <Typography variant={'body2'} color={colors.grayText}>{'Report name'}</Typography>
                </TableCell>

                <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                    <Typography variant={'body2'} color={colors.grayText}>{'Report status'}</Typography>
                </TableCell>

                <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                    <Typography variant={'body2'} color={colors.grayText}>{'Report type'}</Typography>
                </TableCell>

                <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                    <Typography variant={'body2'} color={colors.grayText}>{'Last update'}</Typography>
                </TableCell>

                <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                    <Typography variant={'body2'} color={colors.grayText}>{'Framework'}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}


export const OrganizationReportsTableRow = ({row, onClick}: {row: TReportSmall, onClick: () => void}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'AuditSpace'});
    const reportStatus = getReportStatus(t, row.status);
    const reportType = getReportType(t, row.type);

    return(
        <TableRow key={row.id} hover onClick={onClick}>
            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}>{row.name}</Typography>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={reportStatus.color}>{reportStatus.status}</Typography>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}>{reportType}</Typography>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}>{parseDateAuto(row.lastUpdate)}</Typography>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}, padding: '4px' }}>
                <Box display={"flex"} alignItems={"center"}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={'40px'} height={'40px'} m={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>
                        {row.framework.logo && <img src={row.framework.logo} alt={''} style={{maxWidth: '40px', maxHeight: '40px'}}/>}
                    </Box>
                    <Typography variant={'body2'} color={colors.grayText}>{row.framework.name || ''}</Typography>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export const OrganizationReportsTableRowSkeleton = () => {
    return(
        <TableRow>
            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}>
                    <Skeleton width={100}/>
                </Typography>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}>
                    <Skeleton width={100}/>
                </Typography>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}>
                    <Skeleton width={100}/>
                </Typography>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}>
                    <Skeleton width={100}/>
                </Typography>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Skeleton width={100} height={40}/>
            </TableCell>
        </TableRow>
    )
}