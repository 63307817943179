//Core
import React, {FC} from "react";

//Router
import {Redirect, Route, Switch} from 'react-router';
import {
    LICENSE_PATH,
    PATH_LOCAL_2FA,
    PATH_LOCAL_HELP,
    PATH_LOCAL_INITIAL_MFA_CONFIGURE,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_LOGOUT,
    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_SIGNUP,
    PATH_LOCAL_SPACE,
    PATH_LOCAL_USER_PROFILE,
    PATH_LOCAL_VERIFY_EMAIL
} from "../../../newShared/constants";

import {SignIn} from "../../authWorkspacesCookies/signIn";
import {ContactUs} from "../../components/contactUs";
import {UserProfile} from "../../components/profile";
import {AuditSpacePage} from "../../components/auditSpaceNew";
import {LicensePage} from "../../components/license_page";
import {SETTINGS_LANGUAGE_PATH} from "../../authWorkspacesCookies/settings/constants";
import {Language} from "../../authWorkspacesCookies/settings/pages/language";


export const Routes: FC = () => {
    return <Switch>
        <Route path="/" exact>
            <Redirect to={PATH_LOCAL_SPACE}/>
        </Route>

        {/*INIT*/}
        <Route exact path={[
            PATH_LOCAL_LOGIN,
            PATH_LOCAL_2FA,

            PATH_LOCAL_MFA_RESET,
            PATH_LOCAL_PASSWORD_RESET,

            PATH_LOCAL_VERIFY_EMAIL,
            PATH_LOCAL_SIGNUP,
            PATH_LOCAL_INITIAL_MFA_CONFIGURE,

            PATH_LOCAL_LOGIN_CONTACT_US,
        ]} component={SignIn}/>

        <Route exact path={`${LICENSE_PATH}`} component={LicensePage}/>
        {/*<Route path={SETTING_ROOT_PATH} component={Settings}/>*/}

        <Route exact path={PATH_LOCAL_LOGOUT}>
            <Redirect to={PATH_LOCAL_LOGIN}/>
        </Route>

        {/*Settings*/}
        <Route exact path={PATH_LOCAL_HELP} component={ContactUs}/>
        <Route exact path={PATH_LOCAL_USER_PROFILE} component={UserProfile}/>
        <Route exact path={SETTINGS_LANGUAGE_PATH} component={Language}/>

        {/*INNER PAGES*/}
        <Route path={PATH_LOCAL_SPACE} component={AuditSpacePage}/>

        {/*REDIRECT*/}
        <Redirect to={PATH_LOCAL_SPACE}/>
    </Switch>;
}
