import React, {FC} from "react";
import {
    Avatar,
    Box,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";
import colors from "../../../../../newShared/theme/colors";
import {TReportOrganization} from "../../types";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {useReportOrganizationsListPage} from "../../hooks/useReportOrganizationsListPage";
import {Flex} from "../../../../../newShared/components/Layouts";
import {getAvatarInitials} from "../../../../../newShared/utils/text";

export const ReportOrganizations: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'AuditSpace'});
    const {scroll, fetchWithFilters, organizations, isLoadingOrganizations, goToOrganization} = useReportOrganizationsListPage();

    return (
        <Box display={"flex"} flexDirection={"column"} width={'100%'} height={'100%'} overflow={"hidden"}>
            <PageTitleAndActions
                title={t('Organizations')}
                actions={[]}
            />

            <GenericFiltersArray
                isAddFilters={false}
                configs={[]}
                fetchResultWithSelectedFilters={fetchWithFilters}
            />


            {organizations.length === 0 && !isLoadingOrganizations ?
                <EmptyArrayImage type={"reports"} isShowing={organizations.length === 0 && !isLoadingOrganizations}
                                 text={'You have not any shared reports yet'} withoutAdd/>
                :
                <TableContainer onScroll={scroll.onScroll} ref={scroll.scrollRef}>
                    <Table stickyHeader aria-label="sticky table">
                        <OrganizationsTableHeader total={scroll.total}/>

                        <TableBody sx={{
                            '& .MuiTableRow-root:last-child td': {
                                borderBottom: `1px solid ${colors.primary.disable}`
                            }
                        }}>
                            {organizations.map((e) => e && <OrganizationsTableRow
                                key={e.id}
                                row={e}
                                onClick={() => goToOrganization(e.id)}/>
                            )}

                            {(scroll.hasMore || isLoadingOrganizations) && <OrganizationsTableRowSkeleton/>}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>
    )
}


export const OrganizationsTableHeader = ({total}: {total: number}) => {
    return(
        <TableHead sx={{backgroundColor: colors.backgrounds.grey_light}}>
            <TableRow sx={{'& .MuiTableCell-root': {backgroundColor: colors.backgrounds.grey_light, width: '100%', paddingTop: '6px', paddingBottom: '6px'}}}>
                {/*Column actions is not in columnConfig - its generated by default at the end of row*/}
                <TableCell colSpan={5}>
                    <Typography variant={'subtitle1'} color={colors.text.grey}>{`${('Total organizations')}: ${total}`}</Typography>
                </TableCell>
            </TableRow>

            <TableRow sx={{'& .MuiTableCell-root': {backgroundColor: colors.backgrounds.grey_light}}}>
                <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                    <Typography variant={'body2'} color={colors.grayText}>{'Organization name'}</Typography>
                </TableCell>

                <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                    <Typography variant={'body2'} color={colors.grayText}>{'Report count'}</Typography>
                </TableCell>

                <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                    <Typography variant={'body2'} color={colors.grayText}>{'Last modified'}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}


export const OrganizationsTableRow = ({row, onClick}: {row: TReportOrganization, onClick: () => void}) => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'AuditSpace'});

    return(
        <TableRow key={row.id} hover onClick={onClick}>
            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Flex w={'100%'} ai={'center'}>
                    <Avatar src={row.logo ?? ''} sx={{width: '25px', height: '25px', backgroundColor: 'inherit'}}>
                        <Typography fontSize={'10px'} fontWeight={300}>{getAvatarInitials(row.name)}</Typography>
                    </Avatar>
                    <Typography
                        variant={'body1'}
                        color={colors.darkText}
                        sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}}
                    >
                        {row.name}
                    </Typography>
                </Flex>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}>{row.reports.length}</Typography>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}>{parseDateAuto(row.lastUpdate)}</Typography>
            </TableCell>
        </TableRow>
    )
}

export const OrganizationsTableRowSkeleton = () => {
    const {revDir} = useMainTranslation();
    return(
        <TableRow>
            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Flex w={'100%'} ai={'center'}>
                    <Skeleton variant={'circular'} width={'25px'} height={'25px'}/>
                    <Typography
                        variant={'body1'}
                        color={colors.darkText}
                        sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', margin: !revDir ? '0 0 0 8px' : '0 8px 0 0'}}
                    >
                        <Skeleton  width={100}/>
                    </Typography>
                </Flex>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}><Skeleton  width={100}/></Typography>
            </TableCell>

            <TableCell sx={{'& p': {whiteSpace: 'nowrap'}}}>
                <Typography variant={'body2'} color={colors.grayText}><Skeleton  width={100}/></Typography>
            </TableCell>
        </TableRow>
    )
}