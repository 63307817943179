import {FC} from 'react';
import {useGetToken} from "./hooks/useGetToken";
import {
    UseManageWorkspacesAndOrganizations
} from "../../authWorkspacesCookies/workspaces/hooks/useManageWorkspacesAndOrganizations";
import {usePublicIdSubscription} from "./hooks/usePublicIdSubscription";


const WorkspaceSubscriptionWS:FC<{workspaceId: string, token: string}> = ({workspaceId, token}: {workspaceId: string, token: string}) => {
    // useWorkspaceSubscription(workspaceId, token);
    usePublicIdSubscription(workspaceId, token);
    return null;
}

export const SubscriptionHandler: FC = () => {
    const {workspaceId} = UseManageWorkspacesAndOrganizations();
    const token = useGetToken();
    return(
        <>
            {(workspaceId && token) ? <WorkspaceSubscriptionWS workspaceId={workspaceId} token={token} key={workspaceId} /> : null}
        </>
    )
}
