import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {SHOW_EXTENDED_ERRORS} from "../../constants";
import {Logging} from "../logs/log";
import {snackError} from "../snacks/snacks";
import {Handle403, LogOut} from "./helpers";
import {GraphQLErrors} from "@apollo/client/errors";
import {getErrorsMap} from "../asyncThunk/helpers";
import {GraphQLError} from "graphql";

export default import("../../redux").then(({store}) => {
    axios.interceptors.request.use(
        (req:  AxiosRequestConfig<any>) => {
            Logging.log('-------RE: ', req.data);
            return ({
                ...req,
                headers: {
                    ...req.headers,
                },
                withCredentials: true
            })
        }
    );
    axios.interceptors.response.use(
        (req:  AxiosResponse<any, any>) => {
            const disableSnackOnError = req.config?.params?.disableSnackOnError || false;
            const _errors = getErrorsMap(req.data?.errors as GraphQLErrors | Error[]);

            if (_errors.all.length) {
                const errors = (_errors.all as GraphQLErrors).filter(e => e.extensions) as GraphQLError[];
                Logging.log('ERRORS: ', errors);

                const done: string[] = [];
                if (errors) {
                    errors
                        .filter((x)=> x.extensions?.exception?.status !== 401)
                        .filter((x)=> x.extensions?.exception?.status !== 403)
                        .filter((x)=> x.extensions?.exception?.status !== 409)
                        .forEach(({message = '', path = ''}) => {
                            const error = SHOW_EXTENDED_ERRORS ? `${path} ${message}` : `${message}`;
                            if(!done.some(d => d === error)) {
                                done.push(error)

                                if (!disableSnackOnError) {
                                    snackError(error);
                                }
                            }
                        });
                }

                if (_errors.e401?.length) {
                    snackError({message: _errors.e401[0] || 'Unauthorized. Please login', invalidToken: true});
                    Logging.log('Got 401 error - executing LogOut()');
                    LogOut();
                }

                if (_errors.e403?.length) {
                    Handle403(_errors.e403[0] || _errors.e403[1] || '');

                    const loginError = _errors.all.find(e => (e as GraphQLError)?.path?.includes('login'));
                    if (loginError) {
                        snackError({message: loginError.message || 'Access is denied', invalidToken: true});
                    }
                }

            }
            return req
        },
        res => {
            Logging.log('-------RES-REJ: ', res?.toJSON().message, res?.toJSON().code);
            snackError(res?.toJSON().message || 'res not found');
            return res;
        }
    )
    return axios;
});
