import {Box, Typography} from "@mui/material";
import React, {FC, useEffect} from "react";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import colors from "../../../../../newShared/theme/colors";
import {useReportsIntegrations} from "../../hooks/useReportsIntegrations";
import {ReportIntegrationCard} from "../../components/ReportIntegrationCard";
import {useRoutes} from "../../hooks/useRoutes";
import {AppCardSkeleton} from "../../components/appCardSkeleton";
import * as S from "../../components/styled"
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";

export const ReportIntegrationsDetails: FC = () => {
    const {
        integrations: {integrations, report, loading: {isAppListLoading}},
        getReportIntegrations,
    } = useReportsIntegrations();
    const {reportId, organizationId, goToReport} = useRoutes();

    useEffect(() => {
        reportId && organizationId && getReportIntegrations({organizationId, reportId})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId])

    return (
        <Box display={"flex"} width={'100%'} height={'100%'} overflow={"hidden"}>
            <PageWrapper>

                <PageTitleAndActions
                    title={report?.name}
                    actions={[]}
                    handleGoBack={() => reportId && organizationId && goToReport(organizationId, reportId)}
                />

                {integrations.length === 0 && !isAppListLoading && <Typography color={colors.darkBlueText} sx={{margin: '8px 0 0 0'}}>{('No integration found')}</Typography>}

                <S.IntegrationCardsContainer>
                    {isAppListLoading && <AppCardSkeleton />}
                    {integrations.map(app => (
                        <ReportIntegrationCard key={app.id} app={app}/>
                    ))}
                </S.IntegrationCardsContainer>
            </PageWrapper>

        </Box>
    )
}