import React, {FC, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSignIn} from "../../hooks/useSignIn";
import {IconButton, InputAdornment, Link, Menu, MenuItem, TextField, Typography} from "@mui/material";
import {SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";
import {DropdownButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {Language, Visibility, VisibilityOff} from "@mui/icons-material";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {useRoutes} from "../../hooks/useRoutes";
import colors from "../../../../../newShared/theme/colors";

export const BlockSignIn: FC = () => {
    const {t, currentLanguage: {name, code}, handleChooseLanguage} = useMainTranslation('', {keyPrefix: ''});

    const {
        login,
        isLoading: {isCheckingPass}
    } = useSignIn();
    const {goToPassRestore, goToSignUp, goToContactUs} = useRoutes();

    const {form, handleChange} = useForm<{username: string, password: string}>({username: '', password: ''});

    const {anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose} = useThreeDotsMenu();
    const [showPass, setShowPass] = useState(false);


    return (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Log in')}</Typography>

            <DropdownButton value={code} sx={{m: '32px auto 0', alignSelf: 'center'}} variant={"text"}
                            icon={<Language/>} open={!!anchorThreeDotsEl} onClick={handleThreeDotsOpen}>
                <Typography variant={"body1"}>{name}</Typography>
            </DropdownButton>
            <Menu open={!!anchorThreeDotsEl} anchorEl={anchorThreeDotsEl} onClose={handleThreeDotsClose}>
                {SUPPORTED_LANGUAGES.map((e) => (
                    <MenuItem value={e.code} key={e.code}
                              sx={{width: anchorThreeDotsEl?.offsetWidth}}
                              onClick={() => {
                                  handleChooseLanguage(e.code);
                                  handleThreeDotsClose();
                              }}>
                        {e.name}
                    </MenuItem>
                ))}
            </Menu>

            <TextField name={'username'} label={t('Email')} value={form.username} onChange={handleChange}
                       sx={{mt: '16px'}} type={"email"}/>

            <TextField name={'password'} label={t('Password')} value={form.password} onChange={handleChange}
                       sx={{mt: '16px'}} type={showPass ? 'text' : 'password'}
                       InputProps={{endAdornment: (
                               <InputAdornment position="end">
                                   <IconButton onClick={() => setShowPass(prev => !prev)} edge="end">
                                       {showPass ? <VisibilityOff /> : <Visibility />}
                                   </IconButton>
                               </InputAdornment>
                           )}}
            />
            <Link onClick={goToPassRestore}
                  sx={{
                      alignSelf: 'flex-end', cursor: 'pointer',
                      fontWeight: 500, fontSize: '14px', lineHeight: '24px'
                  }}>
                {t('Forgot password?')}
            </Link>

            <LoadingButton loading={isCheckingPass} onClick={() => login(form)}
                           // disabled={!validateNotEmpty(form.password) || !validatePassword(form.username)}
                           disabled={form.username.trim() === '' || form.password.trim() === ''}
                           sx={{mt: '16px', alignSelf: 'flex-start'}}>
                {t('Log in')}
            </LoadingButton>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('New here?')} `}<span onClick={goToSignUp} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Create account')}</span>
            </Typography>
            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('If you have a problem?')} `}<span onClick={goToContactUs} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Contact us')}</span>
            </Typography>
        </>
    )
}