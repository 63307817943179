import React, {FC, useEffect} from 'react';
import {AppBar, Box, Paper, Toolbar} from "@mui/material";

import Lines from "../../../newShared/images/login/lines.svg"
import {useMedia} from "../../../newShared/hooks/useMedia";
import {Routes} from "./routes";
import {useSignIn} from "./hooks/useSignIn";
import colors from "../../../newShared/theme/colors";
import {useMainTranslation} from "../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {VERSION} from "../../../newShared/constants";
import {useRoutes} from "./hooks/useRoutes";
import {LoginLogo} from "../../../newShared/components/TobBarLogo";

export const SignIn: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: ''});
    const {matches_770Down, isLessThenCustomQuery} = useMedia(480);

    const {clean} = useSignIn();
    const {goToLogin} = useRoutes();

    useEffect(() => {
        return () => {clean()}
        // eslint-disable-next-line
    }, [])

    return (
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"} overflow={'hidden'}
             position={"relative"} width={'100%'} height={'100%'}
             sx={{
                 background: 'linear-gradient(107.56deg, #FFFFFF 0%, #F0F6FB 54.69%, #FFFFFF 100%)',
             }}>

            <AppBar color={"transparent"} position={"static"} elevation={0} sx={{backgroundColor: 'transparent'}}>
                <Toolbar disableGutters sx={{width: 'min(100%, 1920px)', m: '0 auto', zIndex: 3, p: '12px 24px'}}>
                    <LoginLogo onClick={goToLogin}/>
                </Toolbar>
            </AppBar>

            <img src={Lines} alt={'background'} style={{
                position: 'absolute',  top: '100px', zIndex: 0,
                ...(document.body.offsetWidth > document.body.offsetHeight ? {width: '100vw'} : {height: '100%', left: '0'}),
            }}/>

            <Box width={'100%'} overflow={'auto'} flexGrow={1}>
                <Paper sx={{
                    width: 'min(690px, 90%)', zIndex: 4, overflow: 'auto', position: 'relative',
                    m: '70px auto 0', p: !matches_770Down ? '32px 100px' : !isLessThenCustomQuery ? '32px 50px' : '32px 16px',
                    boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
                    borderRadius: '10px',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Routes/>

                    <Box width={'100%'} maxHeight={'100px'} minHeight={'48px'} flexGrow={1}/>
                    <span style={{
                        fontWeight: 400, fontSize: '14px', color: colors.text.grey,
                        position: 'absolute', bottom: !matches_770Down ? '32px' : '16px',
                        ...(!revDir ? {right: !matches_770Down ? '32px' : '16px'} : {left: !matches_770Down ? '32px' : '16px'}),
                    }}>{`${t('Version')} ${VERSION}`}</span>
                </Paper>
            </Box>
        </Box>
    )
}
