import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    createOrganizationType,
    InvitationSignUpFormType,
    ManageOrganizationType,
    OrganizationInviteType,
    TExtendedCollaborator,
    TInvitationPageInviteType,
    TInviteExtended,
    TOrganizationWithShortCollabs,
    TOrganizationWithWorkspaces,
    TWorkspaceWithExtendedCollabsAndInvites,
    TWorkspaceWithShortCollabs,
    TWorkspaceWithShortCollabsAndRequester
} from "../types";
import {TPageInfo} from "../../../../newShared/types";


export const getOrganizationsWithWorkspacesWithPaging = async (page: number, count: number, name: string): Promise<{organizations: TOrganizationWithWorkspaces[], pageInfo: TPageInfo, invitesCounter: number}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: ` 
                query getOrganizationsWithWorkspacesWithPagination($pageInfo:PaginationInput!, $name:String!){
                  getOrganizationsWithWorkspacesWithPagination(pageInfo:$pageInfo, name:$name){
                    invitesCounter 
                    pageInfo{
                      page
                      total
                      count
                      sort
                    }
                    organizations{
                      collaborators{
                        role
                        publicId
                      }
                      id
                      name
                      logo
                      subscription
                      subscriptionExpiry
                      configuration{
                        availableWizards
                        availableWorkspaceCount
                        availableCollaboratorsCount
                        availableTargetsVulnerabilityScan
                      }
                      pageInfo{
                        total
                        sort
                        page
                        count
                      }
                      workspaces{
                        id
                        name
                        subscription
                        status
                        collaborators{
                          publicId
                          role
                        }
                        organizationId
                        requester{
                          outsideCollaborator
                          publicId
                          role
                        }
                      }
                      blocked
                      blockedReason
                      trialUsed
                      requester{
                        outsideCollaborator
                        publicId
                        role
                      }
                      invites{
                        emailAddress
                        activationDate
                        inviteStatus
                        creationDate
                        expirationDate
                        id
                        sender{
                          role
                          publicId
                        }
                      }
                    }
                  }
                }
                `,
                variables: {
                    pageInfo: {page, count},
                    name,
                    data: {

                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        // console.log(result.data);
        return result.data.data.getOrganizationsWithWorkspacesWithPagination;
    })
)

export const getWorkspacesByOrgIdWithPaging = async (page: number, count: number, organizationId: string, name: string): Promise<{workspaces: TWorkspaceWithShortCollabsAndRequester[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getWorkspacesWithPaginationByOrganizationI($pageInfo:PaginationInput!, $organizationId:String!, $name: String!){ 
                  getWorkspacesWithPaginationByOrganizationId(pageInfo:$pageInfo, organizationId:$organizationId, name: $name){
                    pageInfo{
                      total
                      sort
                      count
                      page
                    }
                    workspaces{
                      id
                      name
                      subscription
                      status
                      collaborators{
                        publicId
                        role
                      }
                      organizationId
                      requester{
                          outsideCollaborator
                          publicId
                          role
                        }
                    }
                  }
                }
                `,
                variables: {
                    pageInfo: {page, count},
                    organizationId,
                    name,
                    data: {
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getWorkspacesWithPaginationByOrganizationId;
    })
)

export const getOrganizationWithExtendedCollabsAndWorkspacesByID = async (organizationId: string): Promise<ManageOrganizationType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getOrganizationWithExtendedCollabsAndWssById($organizationId:String!){
                  getOrganizationWithWorkspacesAndExtendedCollabs(organizationId:$organizationId){
                     collaborators{
                        publicId
                        role
                        createDate
                        lastLoginDate
                        email
                        firstName
                        lastName
                        status
                      }
                      id
                      name
                      logo
                      subscription
                      subscriptionExpiry
                      configuration{
                        availableWizards
                        availableWorkspaceCount
                        availableCollaboratorsCount
                      }
                      blocked
                      blockedReason
                      trialUsed
                      workspacesCount
                      invites{
                        activationDate
                        creationDate
                        emailAddress
                        expirationDate
                        id
                        inviteStatus
                        role
                        sender{
                          role
                          publicId
                        }
                      }
                  }
                }
                `,
                variables: {
                    organizationId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getOrganizationWithWorkspacesAndExtendedCollabs;
    })
)

export const updateOrganizationName = async (organizationId: string, newName: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateOrganizationName($organizationId:String!, $newName:String!){
                  updateOrganizationName(organizationId:$organizationId, newName:$newName){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    newName
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrganizationName.message;
    })
)

export const updateOrganizationLogo = async (organizationId: string, newLogo: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateOrganizationLogo($organizationId:String!, $newLogo:String!){
                  updateOrganizationLogo(organizationId:$organizationId, newLogo:$newLogo){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    newLogo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrganizationLogo.message;
    })
)

export const updateCollabRole = async (organizationId: string, publicId: string, newRole: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation changeCollabRole($organizationId:String!, $publicId:String!, $newRole:String!){
                  updateCollaboratorRole(organizationId:$organizationId, publicId:$publicId, newRole:$newRole){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    publicId,
                    newRole
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateCollaboratorRole.message;
    })
)

export const updateInviteRole = async (organizationId: string, email: string, newRole: string, inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation changeInviteRole($organizationId:String!, $email:String!, $newRole:String!, $inviteId:String!){
                  updateInviteRole(organizationId:$organizationId, email:$email, newRole:$newRole, inviteId:$inviteId){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    email,
                    newRole,
                    inviteId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateInviteRole.message;
    })
)

export const resendOrganizationInvite = async (organizationId: string, email: string, inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation resendOrganizationInvite($organizationId: String!, $email:String!, $inviteId: String!){
                  resendOrganizationInvite(organizationId:$organizationId, email:$email, inviteId:$inviteId){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    email,
                    inviteId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.resendOrganizationInvite.message;
    })
)

export const cancelOrganizationInvite = async (organizationId: string, email: string, inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation cancelOrganizationInvite($organizationId: String!, $email:String!, $inviteId: String!){
                  cancelOrganizationInvite(organizationId:$organizationId, email:$email, inviteId:$inviteId){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    email,
                    inviteId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.cancelOrganizationInvite.message;
    })
)

export const inviteMemberIntoOrg = async (organizationId: string, email: string, role: string, senderPublicId: string): Promise<OrganizationInviteType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation inviteMemberIntoOrg($organizationId:String!, $email:String!, $senderPublicId:String!, $role:String!){
                  inviteMemberIntoOrg(organizationId:$organizationId, email:$email, senderPublicId:$senderPublicId, role:$role){
                    id
                    emailAddress
                    expirationDate
                    activationDate
                    role
                    sender{
                      role
                      publicId
                    }
                    inviteStatus
                  
                  }
                }
                `,
                variables: {
                    organizationId,
                    email,
                    role,
                    senderPublicId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.inviteMemberIntoOrg;
    })
)

export const deleteOrganizationCollaborator = async (organizationId: string, publicId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteOrganizationCollab($publicId:String!,$organizationId:String!){
                  deleteOrganizationCollaborator(publicId:$publicId, organizationId:$organizationId){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    publicId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteOrganizationCollaborator.message;
    })
)

export const transferOrganizationOwnership = async (organizationId: string, transferToPublicId: string, transferFromPublicId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation transferOrganizationOwnership($transferToPublicId:String!,$organizationId:String!, $transferFromPublicId:String!){
                  transferOrganizationOwnership(transferToPublicId:$transferToPublicId, organizationId:$organizationId, transferFromPublicId:$transferFromPublicId){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    transferToPublicId,
                    transferFromPublicId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.transferOrganizationOwnership.message;
    })
)

export const createWorkspace = async (organizationId: string, workspaceName: string): Promise<TWorkspaceWithShortCollabs> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation createWS($workspaceName:String!, $organizationId: String!){
                  addWorkspace(organizationId:$organizationId, workspaceName: $workspaceName){
                    id
                    name
                    organizationId
                    status
                    subscription
                    collaborators{
                      publicId
                      role
                    }
                  }
                }
                `,
                variables: {
                    organizationId,
                    workspaceName,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addWorkspace;
    })
)

export const createOrganization = async (data: createOrganizationType): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation createOrg($data:CreateOrganizationDto!){
                  addOrganization(data:$data){
                    message
                  }
                }
                `,
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addOrganization.message;
    })
)

export const getWorkspaceById = async (id: string): Promise<TWorkspaceWithExtendedCollabsAndInvites> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getWorkspaceById($id:String!){
                  getWorkspaceById(id:$id){
                    name
                    id
                    organizationId
                    status
                    subscription
                    collaborators{
                      firstName
                      lastName
                      lastLoginDate
                      createDate
                      publicId
                      role
                      email
                    }
                    invites{
                        activationDate
                        creationDate
                        emailAddress
                        expirationDate
                        id
                        inviteStatus
                        role
                        sender{
                          role
                          publicId
                        }
                      }
                  }
                }
                `,
                variables: {
                    id
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getWorkspaceById;
    })
)

//----------WORKSPACES

export const updateWorkspaceName = async (workspaceId: string, newName: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateWorkspaceName($workspaceId:String!, $newName:String!){
                  updateWorkspaceName(workspaceId:$workspaceId, newName:$newName){
                    message
                  }
                }
                `,
                variables: {
                    workspaceId,
                    newName
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateWorkspaceName.message;
    })
)

export const inviteMemberIntoWorkspace = async (workspaceId: string, email: string, role: string, senderPublicId: string): Promise<{invite: OrganizationInviteType | null, collaborator: TExtendedCollaborator | null}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation inviteMemberIntoWorkspace($workspaceId:String!, $email:String!, $senderPublicId:String!, $role:String!){
                  inviteMemberIntoWorkspace(workspaceId:$workspaceId, email:$email, senderPublicId:$senderPublicId, role:$role){
                    invite{
                      id
                      emailAddress
                      expirationDate
                      activationDate
                      role
                      sender{
                          role
                          publicId
                      }
                      inviteStatus
                    }
                    collaborator{
                      firstName
                      lastName
                      lastLoginDate
                      createDate
                      publicId
                      role
                      email
                    }
                  }
                }
                `,
                variables: {
                    workspaceId,
                    email,
                    role,
                    senderPublicId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.inviteMemberIntoWorkspace;
        }catch (e){
            throw new Error(result.data.errors[0].message);
        }
    })
)

export const updateWorkspaceCollaboratorRole = async (workspaceId: string, publicId: string, newRole: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation changeCollabRoleInWs($workspaceId:String!, $publicId:String!, $newRole:String!){
                  updateWorkspaceCollaboratorRole(workspaceId:$workspaceId, publicId:$publicId, newRole:$newRole){
                      message
                  }
                }
                `,
                variables: {
                    workspaceId,
                    publicId,
                    newRole
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateWorkspaceCollaboratorRole.message;
    })
)

export const changeWSInviteRole = async (workspaceId: string, email: string, newRole: string, inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation changeWSInviteRole($workspaceId:String!, $email:String!, $newRole:String!, $inviteId:String!){
                    updateWorkspaceInviteRole(workspaceId:$workspaceId, email:$email, newRole:$newRole, inviteId:$inviteId){
                        message
                    }
                }
                `,
                variables: {
                    workspaceId,
                    email,
                    newRole,
                    inviteId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateWorkspaceInviteRole.message;
    })
)

export const cancelWorkspaceInvite = async (workspaceId: string, email: string, inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation cancelOrganizationInvite($workspaceId: String!, $email:String!, $inviteId: String!){
                    deleteWorkspaceInvite(workspaceId:$workspaceId, email:$email, inviteId:$inviteId){
                        message
                    }
                }
                `,
                variables: {
                    workspaceId,
                    email,
                    inviteId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteWorkspaceInvite.message;
    })
)

export const resendWorkspaceInvite = async (workspaceId: string, email: string, inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation resendOrganizationInvite($workspaceId: String!, $email:String!, $inviteId: String!){
                    resendWorkspaceInvite(workspaceId:$workspaceId, email:$email, inviteId:$inviteId){
                        message
                    }
                }
                `,
                variables: {
                    workspaceId,
                    email,
                    inviteId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.resendWorkspaceInvite.message;
    })
)

export const deleteWorkspace = async (workspaceId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteWS($workspaceId: String!,){
                    deleteWorkspace(workspaceId:$workspaceId){
                        message
                    }
                }
                `,
                variables: {
                    workspaceId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteWorkspace.message;
    })
)

export const deleteWorkspaceCollaborator = async (workspaceId: string, publicId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteWSCollab($workspaceId: String!, $publicId:String!){
                    deleteWorkspaceCollaborator(workspaceId:$workspaceId, publicId:$publicId){
                        message
                    }
                }
                `,
                variables: {
                    workspaceId,
                    publicId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteWorkspaceCollaborator.message;
    })
)

export const getInvites = async (): Promise<TInviteExtended[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getInvites{
                  getInvites{
                    type
                    id
                    inviteStatus
                    role
                    expirationDate
                    activationDate
                    creationDate
                    organizationName
                    emailAddress
                    sender{
                      role
                      publicId
                      firstName
                      lastName
                      email
                    }
                  }
                }
                `,
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getInvites;
    })
)

export const acceptInvite = async (inviteId: string, type: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation acceptInvite($inviteId:String!, $type:String!){
                  acceptInvite(inviteId:$inviteId, type:$type){
                    message
                  }
                }
                `,
                variables: {
                    inviteId,
                    type
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.acceptInvite.message;
    })
)

export const declineInvite = async (inviteId: string, type: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation declineInvite($inviteId:String!, $type:String!){
                  declineInvite(inviteId:$inviteId, type:$type){
                    message
                  }
                }
                `,
                variables: {
                    inviteId,
                    type,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.declineInvite.message;
    })
)

//TInvitationPageInviteType

export const getInviteByIdForAccept = async (inviteId: string, type: string): Promise<TInvitationPageInviteType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getInviteByIdForAccept($inviteId:String!, $type:String!){
                  getInviteByIdForAccept(inviteId:$inviteId, type:$type){
                    id
                    inviteStatus
                    sender{
                      publicId
                      role
                    }
                    role
                    creationDate
                    expirationDate
                    activationDate
                    emailAddress
                    isUserExists
                  }
                }
                `,
                variables: {
                    inviteId,
                    type
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getInviteByIdForAccept;
    })
)

export const createAccountByInvitation = async (data: InvitationSignUpFormType): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation createAccountByInvitation($data:CreateAccountByInviteDto!){
                  createAccountByInvitation(data:$data){
                    message
                  }
                }
                `,
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createAccountByInvitation.message;
    })
)

export const joinWorkspaceByOrgAdmin = async (workspaceId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation joinWorkspaceByOrgAdmin($workspaceId:String!){
                  joinWorkspaceByOrgAdmin(workspaceId:$workspaceId){
                    message
                  }
                }
                `,
                variables: {
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.joinWorkspaceByOrgAdmin.message;
    })
)

export const getOrganizationMembers = async (organizationId: string): Promise<TExtendedCollaborator[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getOrganizationMembers($organizationId:String!){
                  getProfilesOfOrganizationMembers(organizationId: $organizationId){
                    firstName
                    lastName
                    email
                    publicId
                    role
                    status
                    lastLoginDate
                    createDate
                  }
                }
                `,
                variables: {
                    organizationId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getProfilesOfOrganizationMembers;
    })
)

export const updateOrganizationByLicenseCode = async (organizationId: string, licenseCode: string): Promise<TOrganizationWithShortCollabs> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateOrganizationByLicenseCode($licenseCode:String!, $organizationId:String!){
                  updateOrganizationByLicenseCode(licenseCode:$licenseCode, organizationId:$organizationId){
                    subscription
                    subscriptionExpiry
                    configuration{
                      availableWizards
                      availableWorkspaceCount
                      availableCollaboratorsCount
                    }
                    id
                  }
                }
                `,
                variables: {
                    organizationId,
                    licenseCode
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrganizationByLicenseCode;
    })
)

export const generateNewToken = async (organizationId: string, workspaceId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation generateNewToken($organizationId:String!, $workspaceId:String!){
                  generateNewToken(organizationId:$organizationId, workspaceId:$workspaceId) {
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.generateNewToken?.message;
        }catch (ex: any){
            throw new Error(ex.message);
        }
    })
)