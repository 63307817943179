import {PATH_SERVER} from "../../../constants";
import {AxiosResponse} from "axios";
import axiosSync from "../../../utils/axios/axios"
import {buildCommonHeader} from "../../../utils/builders";
import {downloadAuditorEvidenceFile} from "../../../../newDomain/components/auditSpaceNew/api/query";
import {print} from "graphql";

export const getFile = async (fileId: string, filename: string) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadAuditorEvidenceFile),
                variables: {
                    fileId,
                    filename,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadAuditorEvidenceFile as {file: string, filename: string, filetype: string};
    })
)