import {LABEL} from "../../constants";
import React from "react";
import LogoRegulait, {ReactComponent as LogoRegulaitComp} from "../../../newShared/images/logo/regulait-logo.svg"
import LogoComplico, {ReactComponent as LogoComplicoComp} from "../../../newShared/images/logo/complico-logo.svg"
import {useHistory} from "react-router-dom";

export const TobBarLogo = () => {
    const history = useHistory();
    const onClick = () => history.push('/');
    switch (LABEL){
        case "Regulait": return <LogoRegulaitComp onClick={onClick} style={{margin: '0 16px'}}/>;
        case "Complico": return <LogoComplicoComp onClick={onClick} style={{margin: '0 16px'}}/>;
        default: return <LogoRegulaitComp onClick={onClick} style={{margin: '0 16px'}}/>;
    }
}

export const LoginLogo = ({onClick}:{onClick?:() => void}) => {
    switch (LABEL){
        case "Regulait": return <img src={LogoRegulait} alt={'Regulauit logo'} style={{maxHeight: '54px', maxWidth: '40%', cursor: 'pointer'}} onClick={onClick}/>;
        case "Complico": return <img src={LogoComplico} alt={'Regulauit logo'} style={{maxHeight: '54px', maxWidth: '40%', cursor: 'pointer'}} onClick={onClick}/>
        default: return <img src={LogoRegulait} alt={'Regulauit logo'} style={{maxHeight: '54px', maxWidth: '40%', cursor: 'pointer'}} onClick={onClick}/>;
    }
}