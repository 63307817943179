import React, {FC, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSignIn} from "../../hooks/useSignIn";
import {useRoutes} from "../../hooks/useRoutes";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";
import {
    Box,
    Checkbox,
    Collapse,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {DropdownButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {CheckCircleOutlineRounded, Language, Visibility, VisibilityOff} from "@mui/icons-material";
import {LABEL, SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";
import {useFormik} from "formik";
import {validationSchemaRegistration} from "../../constants";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";


export const BlockSignUp: FC = () => {
    const {t, revDir, currentLanguage: {name, code}, handleChooseLanguage} = useMainTranslation('', {keyPrefix: ''});

    const {
        isLoading: {isRegistration},
        registration,
    } = useSignIn();

    const {handleOpenLicenseView} = usePDFView();

    const {errors, touched, values, handleSubmit, handleChange, setFieldTouched: setFormikTouched, setFieldValue} = useFormik<{firstName: string, lastName: string, organizationName: string, email: string, password: string, passwordConfirm: string, licence: boolean}>({
        initialValues: {
            firstName: '',
            lastName: '',
            organizationName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            licence: false,
        },
        validationSchema: validationSchemaRegistration,
        validateOnChange: true,
        isInitialValid: false,
        validateOnMount: true,
        onSubmit: (values) => {
            registration({
                firstName: values.firstName,
                lastName: values.lastName,
                organizationName: values.organizationName,
                password: values.password,
                username: values.email,
                license: values.licence,
                invitationCode: '',
            })
        },
    });

    const {goToLogin} = useRoutes();

    const {anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose} = useThreeDotsMenu();
    const [showPass, setShowPass] = useState(false);
    const [fieldTouched, setFieldTouched] = useState<string>('');

    return (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Create new account')}</Typography>

            <DropdownButton value={code} sx={{m: '32px auto 0', alignSelf: 'center'}} variant={"text"}
                            icon={<Language/>} open={!!anchorThreeDotsEl} onClick={handleThreeDotsOpen}>
                <Typography variant={"body1"}>{name}</Typography>
            </DropdownButton>
            <Menu open={!!anchorThreeDotsEl} anchorEl={anchorThreeDotsEl} onClose={handleThreeDotsClose}>
                {SUPPORTED_LANGUAGES.map((e) => (
                    <MenuItem value={e.code} key={e.code}
                              sx={{width: anchorThreeDotsEl?.offsetWidth}}
                              onClick={() => {
                                  handleChooseLanguage(e.code);
                                  handleThreeDotsClose();
                              }}>
                        {e.name}
                    </MenuItem>
                ))}
            </Menu>

            <form onSubmit={handleSubmit}>
                <TextField name={'firstName'} label={t('First name')} value={values.firstName} onChange={handleChange}
                           sx={{mt: '16px'}}/>
                <TextField name={'lastName'} label={t('Last name')} value={values.lastName} onChange={handleChange}
                           sx={{mt: '16px'}}/>
                <TextField name={'organizationName'} label={t('Organization name')} value={values.organizationName} onChange={handleChange}
                           sx={{mt: '16px'}}/>
                <TextField name={'email'} label={t('Email')} value={values.email} onChange={handleChange}
                           sx={{mt: '16px'}} type={"email"}/>

                <TextField name={'password'} label={t('New password')} value={values.password} onChange={handleChange}
                           sx={{mt: '16px'}} type={showPass ? 'text' : 'password'}
                           error={!!errors.password && fieldTouched !== 'password' && touched.password}
                           onFocus={() => {
                               setFieldTouched('password');
                               setFormikTouched('password', true);
                           }}
                           onBlur={() => setFieldTouched('')}
                           InputProps={{endAdornment: (
                                   <InputAdornment position="end">
                                       <IconButton onClick={() => setShowPass(prev => !prev)} edge="end">
                                           {showPass ? <VisibilityOff /> : <Visibility />}
                                       </IconButton>
                                   </InputAdornment>
                               )}}
                />

                <Collapse in={fieldTouched === 'password'} sx={{width: '100%'}}>
                    <Box display={"flex"} flexDirection={"column"} width={'100%'}
                         sx={{
                             mt: '16px',
                             borderRadius: '8px',
                             padding: '16px',
                             boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)',
                         }}>
                        <Typography variant={"body2"}>{t('Password must meet the following requirements:')}</Typography>

                        <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                            <CheckCircleOutlineRounded color={values.password.length >= 8 ? "success" : 'error'}/>
                            <Typography>
                                {`${t('Minimum 8 symbols')}`}
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                            <CheckCircleOutlineRounded color={/(?=.*[A-Z])/.test(values.password) ? "success" : 'error'}/>
                            <Typography>
                                {`${t('At least one capital letter')}`}
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                            <CheckCircleOutlineRounded color={/(?=.*[a-z])/.test(values.password) ? "success" : 'error'}/>
                            <Typography>
                                {`${t('At least one lowercase letter')}`}
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                            <CheckCircleOutlineRounded color={/(?=.*[0-9])/.test(values.password) ? "success" : 'error'}/>
                            <Typography>
                                {`${t('At least one number')}`}
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={'8px'} mt={'8px'}>
                            <CheckCircleOutlineRounded color={/(?=.*[!@#$%^&*])/.test(values.password) ? "success" : 'error'}/>
                            <Typography>
                                {`${t('At least one of the following symbols')} `}<span style={{fontWeight: 600}}>{'!@#$%^&*'}</span>
                            </Typography>
                        </Box>

                    </Box>
                </Collapse>

                <TextField name={'passwordConfirm'} label={t('Confirm password')} value={values.passwordConfirm} onChange={handleChange}
                           sx={{mt: '16px'}} type={showPass ? 'text' : 'password'}
                           error={!!errors.passwordConfirm && fieldTouched !== 'passwordConfirm' && touched.passwordConfirm}
                           onFocus={() => {
                               setFieldTouched('passwordConfirm');
                               setFormikTouched('passwordConfirm', true);
                           }}
                           onBlur={() => setFieldTouched('')}
                           InputProps={{endAdornment: (
                                   <InputAdornment position="end">
                                       <IconButton onClick={() => setShowPass(prev => !prev)} edge="end">
                                           {showPass ? <VisibilityOff /> : <Visibility />}
                                       </IconButton>
                                   </InputAdornment>
                               )}}
                />

                <Box display={"flex"} alignItems={'center'} gap={'4px'} mt={'8px'} m={!revDir ? '8px 0 0 -10px' : '8px -10px 0 0'}>
                    <Checkbox name={'licence'} value={values.licence} onChange={e => setFieldValue('licence', e.target.checked, true)}/>
                    <Typography variant={"body2"}>{t(`I accept the ${LABEL}`)} </Typography>
                    <Typography variant={"body2"} onClick={handleOpenLicenseView}
                                sx={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    color: colors.linkTextColor
                                }}>
                        {t('Terms of Service')}
                    </Typography>
                </Box>

                <LoadingButton loading={isRegistration}
                               type={'submit'}
                               disabled={!!errors.passwordConfirm || !!errors.password || !!errors.email || !!errors.firstName || !!errors.lastName || !!errors.organizationName || !!errors.licence}
                               sx={{mt: '12px', alignSelf: 'flex-start'}}>
                    {t('Create')}
                </LoadingButton>
            </form>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('Already a member?')} `}<span onClick={goToLogin} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Log In')}</span>
            </Typography>
        </>
    )
}