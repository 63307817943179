import React, {useRef, useState} from 'react';
import {Flex, FlexStart} from "../../../newShared/components/Layouts";

//LICENSE
// @ts-ignore
import ILLicense from "./components/IL_License/IL.pdf";
// @ts-ignore
import USLicense from "./components/US_License/US.pdf";

import {Document, Page} from "react-pdf";
import {IconButton, Slider} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import {TopBarUnProtectedLeftSide} from "./components/TopBar";
// import {Autocomplete} from "@mui/lab";

type LicenceLanguageTypes = 'en' | 'he';
export const LicensePage = () => {
    const reference = useRef(null);

    const [licenseLanguage] = useState<LicenceLanguageTypes>('en');
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);

    const [value, setValue] = React.useState<number>(1.0); 

    const handleChange = (event: any, newValue: number | number[]) => {
        setValue((newValue as number));
    };

    function onDocumentLoadSuccess({ numPages }: {numPages: number | null}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset: number) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const getLicense = () => {
        switch(licenseLanguage){
            case 'he': return ILLicense;
            case 'en': return USLicense;
        }
    }

    return(
        <Flex w={'100vw'} h={'100vh'} direction={'column'} ai={'center'} overflow={'hidden'}>
            <FlexStart p={'0 40px 0 6px'} w="100vw" h={`60px`}
                       minh={`60px`} of={"hidden"} bc={'#0C1629'}
                       position={'sticky'} top={'0'}
                       left={'0'} zi={"3000"} ref={reference}>
                <TopBarUnProtectedLeftSide expand={() => null} expanded={true}/>
            </FlexStart>

            <Flex jc={'center'} disableScrollBar overflowy={'auto'}>
                <Document

                    file={getLicense()}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} scale={value}/>
                </Document>

                <Flex h={'70px'} w={'100%'} jc={'center'} pos={'absolute'} left={'0'} bottom={'0'} background={'#23273F'}>
                    <Flex ai={'center'} jc={'space-between'}>
                        {/*<CustomButton*/}
                        {/*    variant={'contained'}*/}
                        {/*    disabled={pageNumber <= 1}*/}
                        {/*    onClick={previousPage}*/}
                        {/*    style={{margin: '0 10px 0 0'}}*/}
                        {/*>Previous</CustomButton>*/}

                        <IconButton
                            style={{margin: '0 15px 0 0'}}
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            color={'primary'}
                        >
                            <ArrowBackIcon />
                        </IconButton>

                        <IconButton
                            disabled={numPages ? pageNumber >= numPages : true}
                            onClick={nextPage}
                            style={{margin: '0 15px 0 0'}}
                            color={'primary'}
                        >
                            <ArrowForwardIcon/>
                        </IconButton>

                        {/*<CustomButton*/}
                        {/*    variant={'contained'}*/}
                        {/*    disabled={numPages ? pageNumber >= numPages : true}*/}
                        {/*    onClick={nextPage}*/}
                        {/*    style={{margin: '0 10px 0 0'}}*/}
                        {/*>Next</CustomButton>*/}

                        <Flex w={'150px'} ai={'center'}>
                            <Flex onClick={() => setValue(1.0)}>
                                <ZoomOutIcon style={{margin: '0 10px 0 0', cursor: 'pointer', color: '#FAC000'}}/>
                            </Flex>

                            <Slider

                                value={value}
                                onChange={handleChange}
                                aria-labelledby="continuous-slider"
                                min={1.0}
                                max={2.0}
                            />

                            <Flex onClick={() => setValue(2.0)}>
                                <ZoomInIcon style={{margin: '0 0 0 10px', cursor: 'pointer', color: '#FAC000'}}/>
                            </Flex>
                        </Flex>

                        {/*<Autocomplete*/}
                        {/*    disablePortal*/}
                        {/*    id="combo-box-demo"*/}
                        {/*    options={['he', 'en']}*/}
                        {/*    sx={{ margin: '0 0 0 15px', maxHeight: '45px !important', height: '45px !important', padding: '0 !important' }}*/}
                        {/*    color={'secondary'} */}
                        {/*    value={licenseLanguage}*/}
                        {/*    onChange={(e, newValue: string | null) => {if(newValue) setLicenseLanguage(newValue as LicenceLanguageTypes)}}*/}
                        {/*    renderInput={(params) => <TextField {...params} sx={[ {'&.MuiOutlinedInput-root': {padding: 0}} ]}  color={'primary'} label="Language" />}*/}
                        {/*/>*/}
                    </Flex>
                </Flex>
            </Flex>

        </Flex>
    )
}

