import {Box, IconButton, Typography} from "@mui/material";
import React, {ReactElement} from "react";
import CloseIcon from '@mui/icons-material/Close';
import colors from "../../../../../newShared/theme/colors";
import {Flex} from "../../../../../newShared/components/Layouts";
export const AvatarMenuTitle = ({children, name, isMobile = false, email, isDesktop = true, handleClose = () => null}: {children: ReactElement, isMobile?: boolean, name: string, email: string, isDesktop?: boolean, handleClose?: () => void}) => {
    return (
        <Flex p={'10px'} position={'relative'}>
            <Box sx={{width: '100%', display: 'flex', paddingTop: isMobile ? '8px' : '0',  flexDirection: !isDesktop ? 'column' : 'row', gap: '8px', justifyContent: 'flex-start', alignItems: 'center'}}>
                {children}
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: isDesktop ? 'flex-start' : 'center'}}>
                    <Typography color={colors.text.dark} variant={'*bodyText2'}>{name}</Typography>
                    <Typography color={colors.text.grey_dark} variant={'*bodyText2'}>{email}</Typography>
                </Box>
            </Box>

            {isMobile &&
                <IconButton size={'small'} onClick={handleClose} sx={{width: '24px', height: '24px', position: 'absolute', top: '4px', right: '4px'}}>
                    <CloseIcon />
                </IconButton>
            }
        </Flex>
    )
}