import React, {FC, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSignIn} from "../../hooks/useSignIn";
import {Box, Button, TextField, Typography} from "@mui/material";
import {IconTextButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {useRoutes} from "../../hooks/useRoutes";
import * as S from "../../components/styled";
import {Typo} from "../../../../../newShared/components/Typography";
import {downloadAsTXT} from "../../../../../newShared/utils/text";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useSnack} from "../../../../barsEnvironment/snack/hooks/useSnack";
import {LABEL} from "../../../../../newShared/constants";

export const BlockInitialMfaApp: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: ''});

    const {
        initialMfa, startMfa, checkInitialCode,
        isLoading: {isConfigurationMfaApp}
    } = useSignIn();
    const {goToLogin} = useRoutes();

    const [verificationCode, setVerificationCode] = useState<string>('')
    const [showSecret, setShowSecret] = useState<boolean>(false);

    const handleDownLoadRecoveryCodes = () => {
        initialMfa?.recoveryCodes && downloadAsTXT(`${LABEL} recovery codes.txt`, initialMfa?.recoveryCodes.join(`\n`));
    }

    const snack = useSnack();

    return !initialMfa?.isStarted ? (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Two-Factor Authentication')}</Typography>

            <Typography variant={"body1"} mt={'32px'}>
                {t('Two-factor authentication (2FA) is an extra layer of security used when logging into websites or apps.')}
            </Typography>
            <Typography variant={"body1"} mt={'32px'}>
                {t('For MFA by default using email if you want to connect GA click continue.')}
            </Typography>

            <Box display={"flex"} alignItems={"center"} gap={'16px'} mt={'16px'}>
                <Button variant={"outlined"} onClick={goToLogin}>{t('Skip')}</Button>
                <Button variant={"contained"} onClick={startMfa}>{t('Continue')}</Button>
            </Box>
        </>
    ) : !initialMfa.recoveryCodes && initialMfa.secret ? (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Setup authenticator app')}</Typography>

            <Typography variant={"body1"} mt={'32px'}>
                {t('Use a phone app like 1Password, Authy, LastPass Authenticator, etc. to get 2FA codes when prompted during sign-in.')}
            </Typography>

            <S.QrCodeWrapper>
                <img src={initialMfa.qrCode} alt={"qr code"} width={'100%'} style={{maxWidth: '300px'}}/>
            </S.QrCodeWrapper>

            <Typography variant={"body1"} mt={'32px'}>
                {t('Use an authenticator app from your phone to scan. If you are unable to scan, enter this text code instead.')}
            </Typography>

            <S.SecretRevealWrapper>
                <Typography variant={'body1'}>{showSecret ? initialMfa.secret : initialMfa.secret.split('').map((e, id) => id > 4 ? '*' : e).join('')}</Typography>
                <IconTextButton
                    size={'small'}
                    variant={'text'}
                    disabled={!initialMfa.secret.length}
                    icon={showSecret ? <VisibilityOffIcon fontSize="small"/> : <VisibilityIcon fontSize="small"/>}
                    onClick={() => setShowSecret(!showSecret)}
                >
                    <Typography fontWeight={600}>{(!showSecret) ? t('Show') : t('Hide')}</Typography>
                </IconTextButton>

                <IconTextButton
                    size={'small'}
                    variant={'text'}
                    disabled={!initialMfa.secret.length}
                    icon={<ContentCopyIcon fontSize="small"/>}
                    onClick={() => {navigator.clipboard.writeText(initialMfa.secret)
                        .then(() => {snack(t(t('Code copied to clipboard')), "info")})}
                    }
                >
                    <Typography fontWeight={600}>{t('Copy')}</Typography>
                </IconTextButton>
            </S.SecretRevealWrapper>

            <TextField name={'verificationCode'} label={t('Type code')} value={verificationCode} onChange={e => setVerificationCode(e.target.value)}
                       sx={{mt: '16px'}}/>

            <Box display={"flex"} alignItems={"center"} gap={'16px'} mt={'16px'}>
                <Button variant={"outlined"} onClick={goToLogin}>{t('Back')}</Button>
                <LoadingButton variant={"contained"} loading={isConfigurationMfaApp}
                               disabled={verificationCode === ''}
                               onClick={() => initialMfa?.secret && checkInitialCode(verificationCode, initialMfa.secret)}>{t('Next')}</LoadingButton>
            </Box>
        </>
    ) : (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Download your recovery codes')}</Typography>

            <Typography variant={"body1"} mt={'32px'}>
                {t('Here are the recovery codes you can use to sign in if you lose or can\'t access your mobile device.')}
            </Typography>

            <Box display={"flex"} width={'100%'} justifyContent={'space-between'} alignItems={'center'} m={'16px 0 0 0'} >
                <Box display={"flex"} width={'100%'} flexDirection={'column'}>
                    {initialMfa?.recoveryCodes?.slice(0, 8).map(e => (
                        <Box display={"flex"} width={'100%'} justifyContent={'space-between'} key={e}>
                            <Typo fontSize={'16px'} fontWeight={300}>{e}</Typo>
                        </Box>
                    ))}
                </Box>

                <Box display={"flex"} width={'100%'} flexDirection={'column'}>
                    {initialMfa?.recoveryCodes?.slice(8, 16).map(e => (
                        <Box display={"flex"} width={'100%'} justifyContent={'space-between'} key={e}>
                            <Typo fontSize={'16px'} fontWeight={300}>{e}</Typo>
                        </Box>
                    ))}
                </Box>
            </Box>

            <Button variant={'text'} onClick={handleDownLoadRecoveryCodes} sx={{mt: '16px', width: 'fit-content'}}>
                {t('Download')}
            </Button>

            <Typography variant={"body1"} mt={'16px'}>
                {t('Each recovery code can only be used once, but you can regenerate a new set of 10 codes at any time. Store your recovery codes in a safe place.')}
            </Typography>

            <Box display={"flex"} alignItems={"center"} gap={'16px'} mt={'16px'}>

                <Button variant={"outlined"} onClick={goToLogin}>{t('Done')}</Button>
            </Box>
        </>
    )
}