import {useDispatch, useSelector} from "react-redux";
import {
    TSetAuditorsDialog,
    TSetChangeNcrStatusDialog,
    TSetCollectorView,
    TSetPreviewReportDialog,
    TSetRejectReportDialog,
    TSetStatementDialog
} from "../../types";
import {
    reportDialogSelector,
    setAuditorsDialogAction,
    setChangeNcrStatusDialogAction,
    setCollectorViewAction,
    setPreviewDialogAction,
    setRejectReportDialogAction,
    setStatementDialogAction,
    TAuditSpaceSlice
} from "../../store/auditSpace/slice";

type TResponse = {
    auditors: TAuditSpaceSlice["dialogs"]["auditors"] & {setAuditorsDialog: (data: TSetAuditorsDialog) => void},
    rejectReport: TAuditSpaceSlice["dialogs"]["rejectReport"] & {setRejectReportDialog: (data: TSetRejectReportDialog) => void},
    preview: TAuditSpaceSlice["dialogs"]["preview"] & {setPreviewDialog: (data: TSetPreviewReportDialog) => void},
    collectorView: TAuditSpaceSlice["dialogs"]["collectorView"] & {setCollectorView: (data: TSetCollectorView) => void},
    statement: TAuditSpaceSlice["dialogs"]["statement"] & {setStatementDialog: (data: TSetStatementDialog) => void},
    changeStatusNcr: TAuditSpaceSlice["dialogs"]["changeStatusNcr"] & {setChangeNcrStatus: (data: TSetChangeNcrStatusDialog) => void},
}
export const useReportDialogs = (): TResponse => {

    const dispatch = useDispatch();

    const {
        auditors,
        rejectReport,
        preview,
        collectorView,
        statement,
        changeStatusNcr,
    } = useSelector(reportDialogSelector);

    const setAuditorsDialog: TResponse["auditors"]["setAuditorsDialog"] = (data) => {
        dispatch(setAuditorsDialogAction(data))
    }

    const setRejectReportDialog: TResponse["rejectReport"]["setRejectReportDialog"] = (data) => {
        dispatch(setRejectReportDialogAction(data))
    }

    const setPreviewDialog: TResponse["preview"]["setPreviewDialog"] = (data) => {
        dispatch(setPreviewDialogAction(data))
    }

    const setCollectorView: TResponse["collectorView"]["setCollectorView"] = (data) => {
        dispatch(setCollectorViewAction(data))
    }

    const setStatementDialog: TResponse["statement"]["setStatementDialog"] = (data) => {
        dispatch(setStatementDialogAction(data))
    }

    const setChangeNcrStatus: TResponse["changeStatusNcr"]["setChangeNcrStatus"] = (data) => {
        dispatch(setChangeNcrStatusDialogAction(data))
    }

    return {
        auditors: {...auditors, setAuditorsDialog},
        rejectReport: {...rejectReport, setRejectReportDialog},
        preview: {...preview, setPreviewDialog},
        collectorView: {...collectorView, setCollectorView},
        statement: {...statement, setStatementDialog},
        changeStatusNcr: {...changeStatusNcr, setChangeNcrStatus},
    }
}