import {FC} from "react";
import {Avatar, Box, Grid, Skeleton, Typography} from "@mui/material";
import {useAuditSpace} from "../../hooks/useAuditSpace";
import {getReportStatus} from "../../helpers";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {useReportDialogs} from "../../hooks/useReportsDialogs";
import {AddAuditorDialog} from "../dialogs/addAuditorDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/workspaces/hooks/useManageWorkspacesAndOrganizations";
import colors from "../../../../../newShared/theme/colors";
import {AssignmentsGroup} from "../../../../../newShared/components/AssignmentsGroup";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";

export const ReportViewInfo: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'AuditSpace'});

    const {
        controls: {report, organization},
        isLoading: {isLoadingReport},

        deleteAuditorsInfo,
    } = useAuditSpace();

    const {auditors: {setAuditorsDialog}} = useReportDialogs();

    const {user} = UseManageWorkspacesAndOrganizations()
    const isLeader = (Boolean(report?.auditors.some(e => e.type === 'LEADER' && e.id === user?.publicId)));

    const IN_REVIEW = report?.status === 'IN_REVIEW';
    const PUBLISHED = report?.status === 'PUBLISHED';
    const RESPONSE = report?.status === 'RESPONSE';

    const isRejectable = IN_REVIEW || PUBLISHED || RESPONSE;
    return (
        <Grid container columnSpacing={2}>
            <Grid item>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Report status')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={getReportStatus(t, report?.status).color} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? getReportStatus(t, report?.status).status : '' : <Skeleton/>}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Organization')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? organization ? organization.name : '' : <Skeleton/>}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Standards audited')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? report.framework.name : '' : <Skeleton/>}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Reviewed controls')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? `${report.reviewedControls.length}/${report.totalControls}` : '' : <Skeleton/>}</Typography>
                </Box>
            </Grid>

            <Grid item>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Report date')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? parseDateAuto(report.createDate) : '' : <Skeleton/>}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Type of audit')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report?.reportNCR ? report.reportNCR.typeOfAudit : '' : <Skeleton/>}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Lead auditor')}</Typography>
                    <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? report.auditors.find(e => e.type === 'LEADER')?.name || 'NO LEADER' : '' : <Skeleton/>}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>
                    <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Other team members')}</Typography>
                    <AssignmentsGroup assignments={report?.auditors.filter(e => e.type !== 'LEADER').map(e => ({id: e.id, name: e.name})) || []}
                                      handleDelete={isRejectable && isLeader ? (person) => {report && deleteAuditorsInfo(person.id, report.id)} : undefined}/>
                    {isRejectable && isLeader && <Avatar onClick={() => {report && setAuditorsDialog({isOpen: true, auditors: report.auditors})}}
                                                         sx={{
                                                             maxWidth: '28px', maxHeight: '28px', background: 'inherit',
                                                             border: '1px dashed rgba(0, 0, 0, 0.53)',
                                                             '&: hover': {background: 'rgba(0, 0, 0, 0.08)', cursor: 'pointer'},
                                                             marginRight: !revDir ? undefined : '8px',
                                                         }}>
                        <PersonAddIcon style={{
                            maxWidth: '15px', maxHeight: '15px',
                            fill: 'rgba(0, 0, 0, 0.53)'
                        }}/>
                    </Avatar>}
                </Box>
            </Grid>

            {/*<Grid item>*/}
            {/*    <Box display={"flex"} alignItems={"center"} marginTop={'8px'}>*/}
            {/*        <Typography minWidth={'100px'} variant={"body2"} color={colors.text.grey_dark} whiteSpace={"nowrap"} margin={!revDir ? '0 8px 0 0' : '0 0 0 8px'}>{t('Framework')}</Typography>*/}
            {/*        <Typography minWidth={'200px'} variant={"body2"} color={colors.text.dark} whiteSpace={"nowrap"}>{!isLoadingReport ? report ? report.framework.name : '' : <Skeleton/>}</Typography>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}

            <AddAuditorDialog/>
        </Grid>
    )
}